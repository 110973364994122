import React, {PureComponent} from 'react';

type Props = {
    isSelected: boolean,
    item: Object,
    value: any,
    style: Object,
    selectItem: Function,
    onEditItemClickAction: Function,
    id: any,
    onEditItemClick:boolean
}

class SelectResult extends PureComponent<Props> {
    props: Props;

    render() {
        const {value, item, isSelected, id, style, selectItem, index,onEditItemClick,onEditItemClickAction} = this.props
        return (
            <li
                style={{
                    textAlign: "left",
                    fontSize:item.id=='dokuman_tipi_seciniz'?"12px":"",
                    ...style,
                }}
                key={id}

                onClick={() => item && item.disabled==true?"":selectItem(item, index)}
                className={isSelected ? 'selectedItemColor' : item && item.disabled==true? "disabledItem":""}
            >
                {value}

                {
                    onEditItemClick !== false ? <div style={{"float":"right"}}><i onClick={() => item && item.disabled==true?"":onEditItemClickAction(item, index)} className="fa fa-edit"/></div> : ""
                }
            </li>
        );
    }
}


export default SelectResult;