import React, { Component } from "react";
import {
    EkoForm,
    EkoFormButton, EkoFormDate,
    EkoFormInputText, EkoFormSelect
} from "../../components/elements/EkoForm";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";

class SalesInvoiceCustomDeclaration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filePath:"",
            formData: { change: {} },
            formFields: {},
            loading: false,
            blobFile:"",
            blobFileExt:"",
            declaration_types: [
                {
                    name: 'GÇB',
                    id: 0
                },
                {
                    name: 'ETGB',
                    id: 1
                },
                {
                    name: 'BGB',
                    id: 2
                },
            ],
        };
        this.getData();
    }
    getData() {
        var self = this;
        this.setState({ loading: true });
        Api.get("/salesInvoice/getSalesInvoiceDeclaration/"+self.props.sales_invoice_id, function(
            res,
            err
        ) {
            var data = res.data;
            if(data){
                self.setFieldChange('change','declaration_type_id',data.declaration_type_id);
                self.setFieldChange('change','registration_number',data.registration_number);
                self.setFieldChange('change','closing_date',data.closing_date);
                if(data.file){
                    self.setState({filePath: data.file});
                }
            }else{
                self.setFieldChange('change','declaration_type_id','');
                self.setFieldChange('change','registration_number','');
                self.setFieldChange('change','closing_date','');
                self.setState({filePath: ''});
            }
        });
    }

    closeModal() {
        this.setState({loading: false });
        this.props.closeModal(false);
    }

    Save() {
        var self = this;
        if (
            this.state.formData.change.declaration_type_id ||
            this.state.formData.change.registration_number ||
            this.state.formData.change.closing_date
        ) {
            Api.post(
                "/salesInvoice/addOrUpdateSalesInvoiceDeclaration/"+self.props.sales_invoice_id,
                {
                    declaration_type_id: this.state.formData.change.declaration_type_id,
                    registration_number: this.state.formData.change.registration_number,
                    closing_date: this.state.formData.change.closing_date,
                    file: this.state.blobFile.toString(),
                    blobFileExt: this.state.blobFileExt
                },
                (response, err) => {
                    if (response.status === "ok") {
                        toastr.success("Başarılı", "Kaydedildi");
                        self.closeModal();
                    } else if (response.status === "fail") {
                        toastr.error("Hata", err.message);
                    } else {
                        toastr.error("Beklenmedik bir sorunla karşılaşıldı.");
                    }
                }
            );
        } else {
            toastr.error("Lütfen tüm alanları doldurun.");
        }
    }

    setFieldChange = (type, field, value) => {
        let formData = this.state.formData[type];
        formData[field] = value;

        let bigForm = this.state.formData;
        bigForm[type] = formData;
        this.setState({formData: bigForm});

    }

    setFieldMount = (type, field) => null;


    render() {
        const addChangingProps = {
            onChange: this.setFieldChange.bind(this, "change"),
            onMount: this.setFieldMount.bind(this, "change"),
            formData: this.state.formData.change
        };

        return (<EkoForm>
            <br />
            <div className="row">
                <div className="row">
                    <div className="col-md-6">
                        <EkoFormSelect
                            isVertical={true}
                            searchable={true}
                            {...addChangingProps}
                            id={"declaration_type_id"}
                            defaultText={"Seçiniz"}
                            optionId={"id"}
                            optionValue={"name"}
                            options={this.state.declaration_types}
                            label={"Beyanname Türü"}
                        />
                    </div>
                    <div className="col-md-6">
                        <label className="control-line control-label">Gümrük Çıkış Beyannamesi / ETGB (BGB) Tescil No</label>
                        <EkoFormInputText
                            {...addChangingProps}
                            id="registration_number"
                            style={{paddingLeft: "10px"}}
                            label=""
                            placeHolder="Gümrük Çıkış Beyannamesi / ETGB (BGB) Tescil No"
                            isVertical={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <EkoFormDate
                            {...addChangingProps}
                            id="closing_date"
                            dateFormat="DD-MM-YYYY"
                            isVertical={true}
                            clearable={true}
                            label="Kapanış Tarihi"
                        />
                    </div>
                    <div className="col-md-6">
                        <label className="control-line control-label">Yüklenecek Evrak Seçin</label>
                        <label className="btn btn-default btn-file" htmlFor="document" style={{"width": "100%"}}>
                            <i className="far fa-file-image"></i> Evrak Seç
                        </label>
                        <input
                            type="file"
                            id="document"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                var self = this;
                                if (file) {
                                    const reader = new FileReader();
                                    reader.readAsDataURL(file);
                                    const fileExtension = file.name.split('.').pop();
                                    reader.onloadend = () => {
                                        self.setState({ blobFile: reader.result,blobFileExt:fileExtension });
                                    };
                                }
                            }}
                            style={{display: "none"}}
                        />
                        {this.state.filePath ? (
                            <a href={this.state.filePath} target="_blank" style={{display: "block"}}>
                                <i className="fas fa-file-pdf"></i> Evrak Görüntüle
                            </a>
                        ) : null}
                    </div>

                    <div className="col-md-2" style={{"float": "right"}}>
                        <div style={{"height": "15px"}}></div>
                        <EkoFormButton
                            label="Kaydet"
                            isLoading={this.state.loading}
                            className="btn btn-info"
                            style={{display: "inline", width: "100%"}}
                            onClick={this.Save.bind(this)}
                        />
                    </div>
                </div>
            </div>
        </EkoForm>);
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, actions)(SalesInvoiceCustomDeclaration);