import React, { Component } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import {browserHistory, Link, withRouter} from "react-router";
import AddEmployee from "./AddEmployee";
import { toastr } from "react-redux-toastr";
import HelperContainer from "../../util/HelperContainer";
import EkoSpinner from "../../components/elements/EkoSpinner";
import Util from "../../util/Util";
import Api from "../../util/Api";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { FETCH_EMPLOYEES } from "../../actions/types";
import EkoModal from "../../components/elements/EkoModal";
// import HocPermission from "../../components/common/HocPermission";


class Employees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: {},
      showInsertModal: false,
      errors: {},
      validationFields: [],
      employees: [],
      employeeListState: false,
      enterpriseModal: false,
    };
  }

  fetcheEployees() {
    this.props.fetchData("/employee/list", FETCH_EMPLOYEES);
  }

  componentWillMount() {
    this.fetcheEployees();
  }
  componentDidMount(){
    this.setState({employeeListState: true});
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.employees) {
      this.setState({ employees: nextProps.employees });
    }
  }

  openUpdate(id) {
    var self = this;
    let formData = {};
    Api.get("/employee/" + id, function(response) {
      formData = {};
      if (response.employee) {
        formData.employee = HelperContainer.filterObject(response.employee);

        try {
          formData.employee.salary = Util.convertDecimalToMoney(
            formData.employee.salary
          );
          formData.employee.birthdate = Util.convertFromDatabaseDate(
            formData.employee.birthdate
          );
          formData.employee.termination_date = Util.convertFromDatabaseDate(
            formData.employee.termination_date
          );
          formData.employee.employment_date = Util.convertFromDatabaseDate(
            formData.employee.employment_date
          );
        } catch (ex) {
          console.log(ex);
        }
        self.setState({
          showInsertModal: true,
          errors: {},
          formData: formData
        });
      }
    });
  }

  onDelete(id) {
    const self = this;
    toastr.confirm(`Bu kaydı silmek istediğinize emin misiniz?`, {
      onOk: () => {
        Api.delete("/employee/" + id, function(response, err) {
          if (response) {
            self.fetcheEployees();
            toastr.success("Başarılı", "Başarıyla Silindi");
          } else toastr.error("Hata", err.message);
        });
      },
      onCancel: () => {},
      okText: "Evet",
      cancelText: "Hayır"
    });
  }

  openInsert(menuItem) {
    var employee_list = localStorage.getItem("employee_list");
    const user = JSON.parse(localStorage.getItem('user'));
    employee_list = JSON.parse(employee_list);
    if(employee_list.employees.length>=50 && user.plan_id!=="10"){
      this.setState({ enterpriseModal: true, formData: false });
    }else{
      this.setState({ showInsertModal: true, formData: false });
    }
  }

  onSave(formData, formFields) {
    this.fetcheEployees();
    this.setState({ showInsertModal: false, formData: false });
  }

  openEmployeeSalaryPage() {
    browserHistory.push("/dashboard/employee/employeeSalary");
  }

  activeAndPassive(data){
    const self = this;
    var text = "";
    var type = 0;
    if(data.is_active == "1"){
      text = "aktifleştirmek";
      type = 0;
    }else{
        text = "pasifleştirmek";
      type = 1;
    }
    toastr.confirm("Bu çalışanı "+text+" istediğinize emin misiniz?", {
      onOk: () => {
        Api.post("/employee/activeAndPassive", {id: data.id,type:type}, function(response, err) {
          if (response) {
            self.fetcheEployees();
            toastr.success("Başarılı", "İşlem Başarılı");
          } else toastr.error("Hata", err.message);
        });
      },
      onCancel: () => {},
      okText: "Evet",
      cancelText: "Hayır"
    });
  }
  render() {
    if (!this.props.employees) return <EkoSpinner />;
    return (
      <div>
        <AddEmployee
          showModal={this.state.showInsertModal}
          formData={this.state.formData}
          onSave={this.onSave.bind(this)}
          onCancel={() => {
            this.setState({ showInsertModal: false });
          }}
          errors={this.state.errors}
        />
        <div className="portlet light">
          <div className="portlet-title2">
            <div className="actions2">
              {/*    <div className="btn-group btn-group-devided" data-toggle="buttons">
                                <HocPermission pkey="EMPLOYEE_SALARY_LIST">
                                    <button onClick={this.openEmployeeSalaryPage.bind(this)} type="button" id="print"
                                            className="btn blue btn-sm">GEÇMİŞ BORDROLAR
                                    </button>
                                </HocPermission>
                            </div>*/}
            </div>
          </div>
        </div>
        <div className="row">
          {this.state.employees.map((data, key) => {
            return (
              <div key={key} className="col-md-4 col-sm-4">
                <div className="portlet light">
                  <div className="portlet-title">
                    <div
                      style={{
                        width: "230px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                      }}
                      className="caption"
                    >
                      <span
                        title={data.name}
                        className="caption-subject font-blue-sharp bold uppercase"
                      >
                        {data.name}
                      </span>
                    </div>
                    <div className="actions">
                      <DropdownButton
                        id="drop_down"
                        title="İşlemler"
                        bsStyle="primary"
                        noCaret
                        className="btn blue btn-outline btn-sm"
                      >
                        {/*<MenuItem eventKey="4" onSelect={() => {*/}
                        {/*    this.props.router.push("/dashboard/employee/" + data.id + "/salary/add");*/}
                        {/*}}>Bordro Oluştur</MenuItem>*/}
                        <MenuItem
                          eventKey="1"
                          onSelect={() => {
                            this.openUpdate(data.id, 0);
                          }}
                        >
                          Düzenle
                        </MenuItem>
                        <MenuItem
                          eventKey="2"
                          onSelect={() => {
                            this.props.router.push(
                              "/dashboard/transaction/employee/" + data.id
                            );
                          }}
                        >
                          Hesap Hareketleri
                        </MenuItem>
                        <MenuItem
                            eventKey="4"
                            onSelect={() => {
                              this.props.router.push(
                                  "/dashboard/employee/wagesheets/" + data.id
                              );
                            }}
                        >
                          Bordrolar
                        </MenuItem>
                        <MenuItem
                            eventKey="4"
                            onSelect={() => {
                              this.props.router.push(
                                  "/dashboard/PersonnelSalaryAccrual?employee_id=" + data.id
                              );
                            }}
                        >
                          Maaş Tahakkuku Ekle
                        </MenuItem>
                        <MenuItem
                            eventKey="3"
                            onSelect={() => {
                              this.activeAndPassive(data);
                            }}
                        >
                          {
                            data.is_active == "1" ? "Aktifleştir" : "Pasifleştir"
                          }
                        </MenuItem>
                        <MenuItem
                          eventKey="3"
                          onSelect={() => {
                            this.onDelete(data.id, 0);
                          }}
                        >
                          Sil
                        </MenuItem>
                      </DropdownButton>
                    </div>
                  </div>
                  <br />
                  <div className="m-grid">
                    <div
                      className="col-md-6"
                      style={{ borderRight: "1px solid #eee" }}
                    >
                      <div className="font-grey-mint font-sm"> Maaş</div>

                      <div
                        style={{ color: "#000" }}
                        className="uppercase font-md"
                      >
                        {" "}
                        {data.balance_str}{" "}
                      </div>
                    </div>
                    <div style={{ textAlign: "right" }} className="col-md-6">
                      <div className="font-grey-mint font-sm">
                        {" "}
                        Bu Yılki Maliyet
                      </div>

                      <div>{data.year_str}</div>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
            );
          })}
          <div className="col-md-4 col-sm-4">
            <div className="portlet light">
              <p className="text-center text-margin">
                <a
                  className="btn blue btn-outline"
                  data-toggle="modal"
                  onClick={this.openInsert.bind(this)}
                >
                  <i className="fa fa-plus" aria-hidden="true" /> Ekle
                </a>
              </p>
            </div>
          </div>
        </div>
        <EkoModal
            showModal={this.state.enterpriseModal}
            title="Uyarı"
            onHide={() => {
              this.setState({
                enterpriseModal: false
              });
              //this.onCancel();
            }}
        >
          <p style={{"textAlign":"center","marginBottom":"50px"}}>
            Çalışan Sayınız 50 den Fazladır. Bu Durumda Bir Üst Pakete Geçmeniz Gerekli.<br /> <span style={{"fontWeight":"bold"}}>Entrprise Paketi (50'den Fazla Çalışan Seçeneği)</span> Seçmelisiniz.
            <br />
            <br />
            <Link to="/subscriber"  className="btn btn-default blue">
              <i className="icon-envelope" />
              Abone Ol
            </Link>
          </p>
        </EkoModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employees: state.data.employees
  };
}

export default connect(mapStateToProps, actions)(withRouter(Employees));
