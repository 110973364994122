import axios from "axios";
import { browserHistory } from "react-router";
import moment from "moment";
import Api from "../util/Api";
import {toastr} from 'react-redux-toastr'
import {
  AUTH_LOGOUT,
  AUTH_YES,
  CHANGE_PAGE_TITLE,
  ERROR_AUTH,
  FETCH_DT_CONFIGS,
  FETCH_VIDEO_CONFIGS,
  FETCH_VIEW_OPTIONS,
  PAGE_LOADING,
  ADD_DOCUMENT,
  UPLOADED_DOCUMENT
} from "./types";

export const ROOT_URL = process.env.REACT_APP_API_URL;

export function loginUser(params, redirect_url = "/dashboard") {
  return function(dispatch) {
    Api.post("/auth/login", params, function(response, error) {
      if (error) {
        dispatch(authError(error));
      } else {
        dispatch({ type: AUTH_YES });
        // token geldiyse kaydedelim
        localStorage.setItem("token", response.token);
        localStorage.setItem("paynetStatus", response.paynetStatus);
        localStorage.setItem("integrator", response.integrator);
        localStorage.setItem("lastLogin", moment().toISOString());
        localStorage.setItem("login_user", JSON.stringify(response.user));
        localStorage.setItem("user", JSON.stringify(response.user));
        if (response.user.video_configs) {
          localStorage.setItem("videos_config", response.user.video_configs);
          setDefaultStorage();
          updateFetchedData(
            FETCH_VIDEO_CONFIGS,
            JSON.parse(localStorage.getItem("videos_config"))
          );
        }
        // get company settings starts
        axios.get(ROOT_URL + "/getCompanySettings", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
        ).then(response => {
          var data = JSON.stringify(response.data.result, null, 2);
          localStorage.setItem("company_settings", '');
          localStorage.setItem("company_settings", data);
        });
        // get company settings ends
        if (response.app_version) {
          localStorage.setItem("logined_app_version", response.app_version);
        }

        if (!response.isWelcome){
          Api.put('/company/welcome',{ is_welcome : true },(res,err)=>{
              if (res){
                toastr.success(res.message);
              }

              if (err){
                console.log('error',err)
                toastr.error('Bir sorun oluştu lütfen bizimle iletişime geçin !')
              }
          })

          redirect_url = '/welcome'
        }

        if (parseInt(response.user.plan_id, 10) === 0)
          browserHistory.push("/subscriber");
        // browserHistory.push('/newPlan');
        else {
          browserHistory.push(redirect_url);
        }
      }
    });
  };
}

export function checkUser() {
  return function(dispatch) {
    Api.get("/auth/checkUser", function(response, error) {
      if (error) {
        logoutUser();
        window.location = '/login'
      } else {
        localStorage.setItem("user", JSON.stringify(response.user));
      }
    });
  };
}

export function setPageLoading(value) {
  return {
    type: PAGE_LOADING,
    payload: value
  };
}

export function authError(error) {
  return {
    type: ERROR_AUTH,
    payload: error
  };
}

export  function logoutUser() {
  //Api.post("/auth/logout", {}, (response, error) => {});
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("login_user");
  localStorage.removeItem("company_id");
  localStorage.removeItem("paynetStatus");
  localStorage.removeItem("nace_code_list");
  localStorage.removeItem("purchase_expense_categories");
  localStorage.removeItem("dbs_expense_document_type");
  localStorage.removeItem("dbs_special_amount_types");
  localStorage.removeItem("other_sales_types");
  localStorage.removeItem("bank_accounts");
  localStorage.removeItem("exemption_reasons");
  localStorage.removeItem("company_modules");
  localStorage.removeItem("company");
  localStorage.removeItem("fixed_asset_types");
  localStorage.removeItem("getDBSExpenseDocumentType");
  localStorage.removeItem("account_code_list");

  return { type: AUTH_LOGOUT };
}

//NOTE GENCLER tum api fetch islemleri icin tek fonksiyon yeterli, fanteziye gerek yok
export function fetchData(endpoint, type) {
  return function(dispatch) {
    console.log("type", type);

    try {
      dispatch({
        type: type + "_LOADING",
        payload: true
      });

      Api.get(endpoint, function(response, error) {
        dispatch({
          type: type,
          payload: response
        });
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function setValid(type) {
  return function(dispatch) {
    dispatch({
      type: type,
      payload: true
    });
  };
}

function shouldUpdate() {
  const app_version = localStorage.getItem("app_version");
  const logined_app_version = localStorage.getItem("logined_app_version");

  return app_version !== logined_app_version;
}

//NOTE GENCLER bu da localStorage da saklanan datalar, taxOffice gibi
//key=> responsdan donen data key ( taxOffices gibi)
export function fetchCachedData(endpoint, type, key, expires) {
  return function(dispatch) {
    let fromLocal = localStorage.getItem(type);
    if (!fromLocal || shouldUpdate()) {
      axios
        .get(ROOT_URL + endpoint, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        })
        .then(response => {
          localStorage.setItem(
            type,
            JSON.stringify({
              data: response.data[key],
              expires: expires,
              ts: moment().toISOString()
            })
          );
          dispatch({
            type: type,
            payload: response.data[key]
          });
        });
    } else {
      //TODO @bocek, burada expiresa bakip tekrar cekmek gerek yapilacak
      dispatch({
        type: type,
        payload: JSON.parse(fromLocal).data
      });
    }
  };
}

//NOTE GENCLER bu da localStorage da saklanan datalar, taxOffice gibi
//key=> responsdan donen data key ( taxOffices gibi)
export function fetchCachedDataAsKey(endpoint, type, key, expires) {
  return function(dispatch) {
    let fromLocal = localStorage.getItem(type);
    if (!fromLocal || shouldUpdate()) {
      axios
        .get(ROOT_URL + endpoint, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        })
        .then(response => {
          localStorage.setItem(
            type,
            JSON.stringify({
              data: response.data[key],
              expires: expires,
              ts: moment().toISOString()
            })
          );
          let payload = {};
          payload[key] = response.data[key];

          dispatch({
            type: type,
            payload: payload
          });
        });
    } else {
      let payload = {};
      payload[key] = JSON.parse(fromLocal).data;

      //TODO @bocek, burada expiresa bakip tekrar cekmek gerek yapilacak
      dispatch({
        type: type,
        payload: payload
      });
    }
  };
}

export function fetchDtConfigs() {
  return function(dispatch) {
    //NOTE localStorage da varsa tekrar almaya gerek yok
    let config = localStorage.getItem("dtConfigs");
    if (!config || shouldUpdate()) {
      axios
        .get(ROOT_URL + "/data/dtConfigs", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        })
        .then(response => {
          localStorage.setItem(
            "dtConfigs",
            JSON.stringify(response.data.dtConfigs)
          );
          dispatch({
            type: FETCH_DT_CONFIGS,
            payload: response.data.dtConfigs
          });
        }).catch(function (error) {
        if (error.response) {
          if(error.response.status==400){
            document.location.href="/";
          }
        }
      });
    } else {
      dispatch({
        type: FETCH_DT_CONFIGS,
        payload: JSON.parse(config)
      });
    }
  };
}

// export function fetchCurrencies(){
//   return function(dispatch){
//     axios.get("https://openexchangerates.org/api/latest.json?app_id=7e4cbf01bdbb474e803da260a0c79aa9&base=TRY&symbols=USD,EUR,GBP")
//       .then(response => {
//         let rates = response.data.rates;
//         let reversed = {};
//         Sugar.Object.keys(rates).forEach(function(key){
//            reversed[key] = Sugar.Number.round(1/rates[key],5);
//         })
//         dispatch({
//           type: FETCH_CURRENCY_DATA,
//           payload: reversed
//         });
//       })
//     }
//
// }

//CHANGED ESKIDEN DROPDOWN dedigniz api
export function fetchViewOptions(forceToFetch) {
  return function(dispatch) {
    // localStorage da varsa tekrar almaya gerek yok
    let config = localStorage.getItem("viewOptions");
    if ((!config && !forceToFetch) || shouldUpdate()) {
      axios
        .get(ROOT_URL + "/viewOptions", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        })
        .then(response => {
          localStorage.setItem(
            "viewOptions",
            JSON.stringify(response.data.viewOptions)
          );
          setTimeout(() => {
            localStorage.setItem(
              "app_version",
              localStorage.getItem("logined_app_version") || "1.0"
            );
          }, 2000);
          dispatch({
            type: FETCH_VIEW_OPTIONS,
            payload: response.data.viewOptions
          });
        });
    } else {
      dispatch({
        type: FETCH_VIEW_OPTIONS,
        payload: JSON.parse(config)
      });
    }
  };
}
export function setDefaultStorage() {
    axios.get(ROOT_URL + "/company/naceCodeListSelect", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data.naceCode, null, 2);
      localStorage.setItem("nace_code_list", '');
      localStorage.setItem("nace_code_list", data);
    });
    //
  axios.get(ROOT_URL + "/DBS/countryList", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
  ).then(response => {
    var data = JSON.stringify(response.data.countryList, null, 2);
    localStorage.setItem("country_list", '');
    localStorage.setItem("country_list", data);
  });
  //
  axios.get(ROOT_URL + "/DBS/purchaseExpenseCategoriesSelect/list/0", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
  ).then(response => {
    var data = JSON.stringify(response.data.purchaseExpenseCategories, null, 2);
    localStorage.setItem("purchase_expense_categories", '');
    localStorage.setItem("purchase_expense_categories", data);
  });
  //
  axios.get(ROOT_URL + "/company/newTagList", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
  ).then(response => {
    var data = JSON.stringify(response.data.list, null, 2);
    localStorage.setItem("new_tag_list", '');
    localStorage.setItem("new_tag_list", data);
  });
  //
    axios.get(ROOT_URL + "/DBS/vatReductionTypeCodesGet", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      console.log('response',response);
      var data = JSON.stringify(response.data.stoppage_code, null, 2);
      localStorage.setItem("stoppage_code", '');
      localStorage.setItem("stoppage_code", data);
      //
      localStorage.setItem("getAllVatReductionTypeCode", '');
      localStorage.setItem("getAllVatReductionTypeCode", JSON.stringify(response.data, null, 2));
    });
    //
  axios.get(ROOT_URL + "/DBS/getDBSExpenseDocumentType/list", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
  ).then(response => {
    var data = JSON.stringify(response.data.documentType, null, 2);
    localStorage.setItem("dbs_expense_document_type", '');
    localStorage.setItem("dbs_expense_document_type", data);
  });
  //
  axios.get(ROOT_URL + "/DBS/getDBSSpecialAmountTypes/list", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
  ).then(response => {
    var data = JSON.stringify(response.data.specialAmountTypes, null, 2);
    localStorage.setItem("dbs_special_amount_types", '');
    localStorage.setItem("dbs_special_amount_types", data);
  });
  //
  axios.get(ROOT_URL + "/otherSales/types", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
  ).then(response => {
    var data = JSON.stringify(response.data.otherSalesTypes, null, 2);
    localStorage.setItem("other_sales_types", '');
    localStorage.setItem("other_sales_types", data);
  });
  //
  axios.get(ROOT_URL + "/bankAccount/list", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
  ).then(response => {
    var data = JSON.stringify(response.data.bankAccounts, null, 2);
    localStorage.setItem("bank_accounts", '');
    localStorage.setItem("bank_accounts", data);
  });
  //
  if(localStorage.getItem("exemption_reasons")===null) {
    axios.get(ROOT_URL + "/eInvoice/getTaxExemptionReasons", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data.reasons, null, 2);
      localStorage.setItem("exemption_reasons", '');
      localStorage.setItem("exemption_reasons", data);
    });
  }
  //
  if(localStorage.getItem("getDBSExpenseDocumentType")===null) {
    axios.get(ROOT_URL + "/DBS/getDBSExpenseDocumentType/list", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("getDBSExpenseDocumentType", '');
      localStorage.setItem("getDBSExpenseDocumentType", data);
    });
  }
  //
  if(localStorage.getItem("company_modules")===null) {
    axios.get(ROOT_URL + "/company/CompanyModules", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("company_modules", '');
      localStorage.setItem("company_modules", data);
    });
  }
  //
  if(localStorage.getItem("fixed_asset_types")===null) {
    axios.get(ROOT_URL + "/fixedAsset/getTypes", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("fixed_asset_types", '');
      localStorage.setItem("fixed_asset_types", data);
    });
  }
  //
  if(localStorage.getItem("fixed_asset_list")===null) {
    axios.get(ROOT_URL + "/fixedAsset/list", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data.fixedAssets, null, 2);
      localStorage.setItem("fixed_asset_list", '');
      localStorage.setItem("fixed_asset_list", data);
    });
  }
  //
  if(localStorage.getItem("employee_list")===null) {
    axios.get(ROOT_URL + "/employee/activePersonal", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("employee_list", '');
      localStorage.setItem("employee_list", data);
    });
  }
  //
  if(localStorage.getItem("account_code_list")===null) {
    axios.get(ROOT_URL + "/customer/allCustomerAccountCode", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("account_code_list", '');
      localStorage.setItem("account_code_list", data);
    });
  }
  //
  if(localStorage.getItem("item_code_list")===null) {
    axios.get(ROOT_URL + "/item/allItemCode", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("item_code_list", '');
      localStorage.setItem("item_code_list", data);
    });
  }
  //
}
//TODO @bocek , bu satir altindakiler cok da onemli degil sonradan temizleyelim

export function updateFetchedData(type, data) {
  if (type === FETCH_VIDEO_CONFIGS) {
    axios.put(
      ROOT_URL + "/user/updateAttribute",
      { data: { video_configs: JSON.stringify(data) } },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      }
    ).then(response => {}).catch(function (error) {
      if (error.response) {
        if(error.response.status==400){
          document.location.href="/";
        }
      }
    });
  }
  return function(dispatch) {
    dispatch({
      type: type,
      payload: data
    });
  };
}

export function changePageTitle(newTitle) {
  return function(dispatch) {
    dispatch({ type: CHANGE_PAGE_TITLE, payload: newTitle });
  };
}

export function setAddDocument(value) {
  return {
    type: ADD_DOCUMENT,
    payload: value
  };
}

export function setUploadedDocument(value) {
  return {
    type: UPLOADED_DOCUMENT,
    payload: value
  };
}
