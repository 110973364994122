import React, { Component } from "react";
import {
  EkoForm,
  EkoFormButton,
  EkoFormDate,
  EkoFormInputText,
  EkoFormSelect, EkoFormSwitch
} from "../../components/elements/EkoForm";
//import {Nav, NavItem, Tab, Row} from 'react-bootstrap'
import { Modal } from "react-bootstrap";
import EkoModal from "../../components/elements/EkoModal";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import moment from "moment";
import Util from "../../util/Util";

import { FETCH_BANK_ACCOUNTS_ALL } from "../../actions/types";
import EkoSpinner from "../../components/elements/EkoSpinner";
import NewTagDocumentModal from "../Company/newTagDocument";

class AddBank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      showModal: false,
      saving: false,
      errors: {},
      formFields: {},
      showAdvancedOptionsBank: false,
      bankAdvancedOpened: false,
      banks_in_turkey: {},
      banks_in_turkey_loaded: false,
      page_loading: false,
      obifin_integration_data: {},
      obifin_integration_data_loaded: false,
      obifin_accounts_list: {},
      obifin_accounts_list_loaded: false,
      activateThisBank: true,
      toggleActivateThisBankDefaultValue: true,
      editing_bank_obifin_data: {},
      editing_bank_obifin_data_loaded: false,
      lock_obifin_integration: false,
      showNewTagModal: false,
      turkiye_bank_list: [],
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:17,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
    };
    //this.fetchBankAccounts.bind(this);
    this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS_ALL);
  }
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = (formData) => {
    formData = formData || this.state.formData;
    if (formData.bank.id) {
      Api.get("/company/newTagRecordDocumentList/17/" + formData.bank.id, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }else{
          this.resetNewTagModalList();
        }
      });
    }else{
      this.resetNewTagModalList();
    }
  }
  resetNewTagModalList = () => {
    this.setState(prevState => ({
      newTagRows: {
        ...prevState.newTagRows,
        rows: [
          { id:null,new_tag_type_id:17,new_tag_id: "",record_id:null, rate: "%100,00" }
        ],
      },
    }));
  }
  defaultFormData() {
    return {
      bank: {}
    };
  }

  static defaultProps = {
    status: 2
  };

  fetchBankAccounts() {
    //this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS_ALL);
  }

  componentWillMount() {
    let formData = this.props.formData || this.defaultFormData();
    let errors = {};
    //this.fetchBankAccounts();
    this.setState({ formData: formData, errors: errors });
  }
  fetchRequired(id) {
    this.getBankApis();
    this.getTurkiyeBankList();
    this.getObifinIntegrationData(id);
    this.getObifinAccountsList();

  }

  componentWillReceiveProps(nextProps) {
    let formData = nextProps.formData || this.defaultFormData();
    this.getNewTagModalList(formData);
    this.setState({
      formData: formData,
      banks_in_turkey_loaded: true,
      obifin_integration_data_loaded: false,
      obifin_accounts_list_loaded: false,
      bankAdvancedOpened: false,
      banks_in_turkey: {},
      showAdvancedOptionsBank: false,
      activateThisBank: true,
      toggleActivateThisBankDefaultValue: true,
      editing_bank_obifin_data_loaded: false,
      lock_obifin_integration: false,
    });
    if (nextProps.showModal == true) {
      this.fetchRequired(formData.bank.id);
    }

    if (nextProps.bankAccountType) {
      this.setFieldChange(
          "bank",
          "bank_account_type",
          nextProps.bankAccountType
      );
      this.setState({ errors: {}, showModal: nextProps.showModal });
    } else {
      this.setState({
        formData: formData,
        errors: {},
        showModal: nextProps.showModal
      });
    }
  }

  setCurrencyRate() {
    const self = this;
    if (
        this.state.formData.bank.currency &&
        this.state.formData.bank.start_date
    ) {
      let date = moment(this.state.formData.bank.start_date).format(
          "YYYY-MM-DD"
      );
      let currency = this.state.formData.bank.currency;
      Api.getCurrencyRate(currency, date, (rate, err) => {
        if (rate) {
          let currency_rate = Util.convertDecimalToString(rate, 4);
          let formData = this.state.formData;
          formData.bank = Object.assign({}, formData.bank, {
            currency_rate: currency_rate
          });
          self.setState({ formData: formData });
        }
      });
    }
  }

  setFieldChange(type, field, value) {
    if (type === "bank" && field === "currency_rate") {
      let clean = value.replace(/[^0-9,]/g, "").replace(/(,.*?),(.*,)?/, "$1");
      if (clean !== value) value = clean;
    }

    let formData = this.state.formData[type];
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;

    if (type === "bank" && (field === "currency" || field === "start_date")) {
      this.setCurrencyRate();
    }
    if (type === "bank" && field === "bank_account_type") {
      switch (value) {
        case 3:
          bigForm.bank.currency = "TRY";
          break;
        case 4:
          bigForm.bank.currency = "TRY";
          break;
        default:
          bigForm.bank.currency = "TRY";
          break;
      }
    }
    if (type == "bank" && field == "obifin_accounts_list_id") {
      if (value > 0 && this.state.obifin_accounts_list) {
        let accounts = this.state.obifin_accounts_list;
        if (!Array.isArray(accounts)) {
          accounts = Object.values(accounts);
        }
        // Sayısal karşılaştırma yapmak için value ve x.Id'yi Number() ile çeviriyoruz.
        let found_elemet = accounts.find(x => Number(x.Id) === Number(value));
        if (found_elemet) {
          let obifin_bank_id = found_elemet.BankaKodu;
          let obifin_bank_iban = found_elemet.IBAN;
          let obifin_bank_sube = found_elemet.SubeNo;
          let obifin_bank_hesap_no = found_elemet.HesapNo;
          bigForm.bank.obifin_bank_id = obifin_bank_id;
          bigForm.bank.iban = obifin_bank_iban;
          bigForm.bank.sube_kodu = obifin_bank_sube;
          bigForm.bank.hesap_no = obifin_bank_hesap_no;
        }
      }
    }

    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  onSave() {
    const self = this;
    self.setState({ saving: true });

    let amount_fc = Util.convertMoneyToDecimal(
        this.state.formData.bank.amount_fc || "0,00"
    );
    let opening_balance = Util.convertMoneyToDecimal(
        this.state.formData.bank.opening_balance || "0,00"
    );
    let interest_rate_total =
        Util.convertMoneyToDecimal(
            this.state.formData.bank.interest_rate_total
                ? this.state.formData.bank.interest_rate_total.replace("%", "")
                : "0,00"
        ) / 100;
    let currency_rate = Util.convertMoneyToDecimal(
        this.state.formData.bank.currency_rate || "0,00"
    );
    let card_number = Util.replaceAll(
        this.state.formData.bank.card_number || "",
        "-",
        ""
    );
    let start_date = Util.convertToDatabaseDate(
        this.state.formData.bank.start_date
    );
    let expiry_date = this.state.formData.bank.expiry_date;
    let statement_date = this.state.formData.bank.statement_date;

    let bank = Object.assign({}, self.state.formData.bank, {
      opening_balance: opening_balance,
      amount: opening_balance,
      interest_rate_total: interest_rate_total,
      start_date: start_date,
      description: this.state.formData.bank.name,
      card_number: card_number,
      currency_rate: currency_rate,
      statement_date: statement_date,
      expiry_date: expiry_date,
      newTagRows:self.state.newTagRows
    });

    let errors = self.state.errors;
    let url = "";
    let call_obifin = 0;
    // if set in state.formData.bank.obifin_bank_id check obifin_accounts_list_id is selected
    if (this.state.formData.bank.obifin_bank_id) {
      if (!this.state.formData.bank.obifin_accounts_list_id || this.state.formData.bank.obifin_accounts_list_id == "") {
        self.setState({ saving: false });
        toastr.error("Kayıtlı Banka Hesabı listesinden seçim yapınız. Yeni ekliyorsanız 'Yeni Ekleyeceğim' seçiniz.");
        return;
      }
    }


    if (this.state.formData.bank.bank_account_type === 5) {
      url = "/loan";
      bank.amount = amount_fc;
      bank.balance = amount_fc;
    } else {
      url = "/bankAccount";
      call_obifin = 1;
    }

    let func = "post";

    if (bank.id) {
      func = "put";
      if (this.state.formData.bank.bank_account_type !== 5) {
        url = "/bankAccount/" + bank.id;
        call_obifin = 1;
      } else {
        url = "/loan/" + bank.id;
        bank.amount = amount_fc;
        bank.balance = amount_fc;
      }
    }

    Api[func](url, bank, function(response, postErrors) {
      if (postErrors) {
        errors.bank = postErrors;
        if (errors.bank.description) errors.bank.name = errors.bank.description;

        if (postErrors.message) {
          toastr.error(postErrors.message);
        }
        self.setState({ saving: false, errors: errors });
      } else {

        // send other request for obifin actions
        if (call_obifin == 1){
          bank.system_account_id = response.bankAccount.id;
          Api.post("/obifinSaveBankAccount", bank, (responsex, errx) => {
            if (errx != false) {
              toastr.error("Hata!", "Açık bankacılık ile bağlantı sırasında bir hata oluştu", {timeOut: 10000});
            } else {
              if (responsex.result == 99) {

              }
              else if (responsex.result == 1) {
                toastr.success("Başarılı!", "Açık bankacılık için API eklendi. Banka hesap hareketlerinize İŞLEMLER > TAHSİLAT veya İŞLEMLER > ÖDEME menülerinden hemen ulaşabilirsiniz.", {timeOut: 10000});
              }
              else if (responsex.result == 11) {
                toastr.success("Başarılı!", "Açık bankacılık ile bağlantı sağlandı ve kaydedildi. Banka hesabı onaylandıktan sonra hesap hareketlerine İŞLEMLER > TAHSİLAT veya İŞLEMLER > ÖDEME menülerinden hemen ulaşabilirsiniz.", {timeOut: 10000});
              }
              else if (responsex.result == 3 || responsex.result == 33) {
                toastr.error("Başarısız!", "Açık bankacılık için API eklendi ancak veritabanına kayıt sırasında hata oluştu.", {timeOut: 10000});
              }
              else if (responsex.result == 22 || responsex.result == 44) {
                toastr.error("Başarısız!", responsex.hata, {timeOut: 10000});
                toastr.error("Başarısız!", "Yeni eklediğiniz banka hesabını düzenleyerek açık bankacılık bağlantınızı tekrar deneyebilirsiniz.", {timeOut: 10000});
              }
              else {
                toastr.error("Hata!", "Açık bankacılık entegrasyonu ile bağlantı sırasında bir hata oluştu-2.", {timeOut: 10000});              }
            }
          });
        }
        if (bank.id) {
          toastr.success("Başarılı", "Başarıyla Güncellendi");
          self.setState({ saving: false, errors: {} });
        } else {
          self.setState({
            saving: false,
            errors: {},
            formData: self.defaultFormData()
          });
          toastr.success("Başarılı", "Kasa/Banka başarıyla kaydedildi");
        }
        self.props.onSave();
      }
    });
  }

  onCancel() {
    this.resetNewTagModalList();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  getBtnText() {
    if (!this.state.formData.bank.id) return "Kaydet";
    else return "Güncelle";
  }

  onlyNumericNumber = e => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  toggleBankAdvancedOptions(state) {
    this.setState({ showAdvancedOptionsBank: state, bankAdvancedOpened: state });
  }
  getBankApis() {
    let _self = this;
    _self.setState({banks_in_turkey_loaded: false });
    let params = {};
    Api.get("/getCompanyActivatedObifinBankAPIs", (response, err) => {
      if (err != false) {
        toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
      } else {
        let getData = response.result;
        _self.setState({banks_in_turkey: getData, banks_in_turkey_loaded: true });
      }
    });
  }

  getTurkiyeBankList() {
    Api.get("/getTurkiyeObifinBankList", (response, err) => {
      let _self = this;
      let getData = response.result;
      _self.setState({turkiye_bank_list: getData });
    });
  }
  getObifinIntegrationData(id) {
    let _self = this;
    _self.setState({obifin_integration_data_loaded: false});
    Api.get("/getObifinIntegrationData", (response, err) => {
      if (err != false) {
        toastr.error("Hata!", "Açık bankacılık entegrasyon bilgileri alınamadı");
      } else {
        let getData = response.result;
        _self.setState({obifin_integration_data: getData, obifin_integration_data_loaded: true});
        if (id > 0){
          this.getCompanyObifinBank(id);
        }
      }
    });
  }
  getObifinAccountsList() {
    let _self = this;
    _self.setState({obifin_accounts_list_loaded: false});
    Api.get("/getObifinAccountsList", (response, err) => {
      if (err != false) {
        toastr.error("Hata!", "Açık bankacılık entegrasyonu üzerinden banka bilgileri alınamadı");
      } else {
        let getData = response.result;
        _self.setState({obifin_accounts_list: getData, obifin_accounts_list_loaded: true });
      }
    });
  }
  getCompanyObifinBank(id) {
    let _self= this;
    _self.setState({editing_bank_obifin_data_loaded: false});
    setTimeout(() => {
      Api.get("/getCompanyObifinBank/" + id, (response, err) => {
        if (err != false) {
          toastr.error("Hata!", "Açık bankacılık entegrasyonu üzerinden banka bilgileri alınamadı");
        } else {
          let getData = response.result;
          _self.setState({editing_bank_obifin_data: getData, editing_bank_obifin_data_loaded: true});
          if (getData.obifin_bank_account_id > 0) {
            _self.setFieldChange(
                "bank",
                "obifin_accounts_list_id",
                ""+getData.obifin_bank_account_id+""
            );
            _self.setFieldChange(
                "bank",
                "obifin_bank_id",
                ""+getData.obifin_bank_name+""
            );
            setTimeout(() => {
              const selectEl = document.getElementById("obifin_accounts_list_id");
              if (selectEl) {
                const event = new Event("change", { bubbles: true });
                selectEl.dispatchEvent(event);
              }
            }, 300);

          }else if (getData.obifin_bank_account_id == -1) {
            _self.setState({lock_obifin_integration: true});
          }
          if (getData.system_bank_account_id == id) {
            _self.toggleBankAdvancedOptions(true);
          }
        }
      });
    }, 200);
  }
  toggleActivateThisBank(state) {
    this.setState({ activateThisBank: state, toggleActivateThisBankDefaultValue: state });
  }

  render() {
    let _selfx = this;
    let currencies = this.props.currencies;
    let bankAccounts = this.props.bankAccounts;
    let bankAccountTypes = this.props.viewOptions
        ? this.props.viewOptions.bank_accounts.bank_account_type
        : [];
    let btn = this.getBtnText();
    let title = this.state.formData.bank.id ? "Banka Güncelle" : "Hesap Ekle";
    const bankProps = {
      onChange: this.setFieldChange.bind(this, "bank"),
      onMount: this.setFieldMount.bind(this, "bank"),
      formData: this.state.formData.bank,
      errors: this.state.errors.bank
    };
    const displayBankAdvanced = this.state.bankAdvancedOpened ? "" : "none";
    let banks_list = this.state.banks_in_turkey;
    let obifin_recored_banks_list = this.state.obifin_accounts_list;
    // remake accounts list with a new array object with Id, BankaAdi, HesapNo, SubeNo, ParaBirimi
    let new_obifin_accounts_list = [];
    let bankx = {};
    bankx.Id = "0";
    bankx.BankaAdi = "Yeni ekleyeceğim";
    new_obifin_accounts_list.push(bankx);

    if (obifin_recored_banks_list && obifin_recored_banks_list.length > 0) {
      obifin_recored_banks_list.map((item, index) => {
        let banks_object = {};
        banks_object.Id = item.Id;
        banks_object.BankaAdi = item.BankaAdi + ", HesapNo: " + item.HesapNo + ", ŞubeNo: " + item.SubeNo + " (" + item.ParaBirimi + ")";
        new_obifin_accounts_list.push(banks_object);
      });
    }

    return (
        <div>
          <EkoModal
              showModal={this.state.showModal}
              title={title}
              refreshData={this.fetchBankAccounts.bind(this)}
              onHide={() => {
                this.setState({
                  showModal: false,
                  formData: this.defaultFormData(),
                  errors: {}
                });
                this.onCancel();
              }}
              spinner={this.state.saving}
          >
            {(this.state.banks_in_turkey_loaded == false && this.state.obifin_integration_data_loaded == false && this.state.obifin_accounts_list_loaded == false && this.state.editing_bank_obifin_data_loaded == false) ? <EkoSpinner /> :
                <>
                  <EkoForm formId={"add_bank_modal"}>
                    <div className="row">
                      <div style={{ paddingRight: "15px" }} className="col-md-6">
                        <EkoFormSelect
                            {...bankProps}
                            disabled={
                                this.state.formData.bank.id || this.props.bankAccountType
                            }
                            id="bank_account_type"
                            isVertical={true}
                            defaultText="Seçiniz"
                            options={bankAccountTypes}
                            label="Hesap Tipi"
                        />
                      </div>
                      <div className="col-md-6">
                        <EkoFormSelect
                            {...bankProps}
                            id="currency"
                            disabled={
                                this.state.formData.bank.id ||
                                this.state.formData.bank.bank_account_type === 3 ||
                                this.state.formData.bank.bank_account_type === 4
                            }
                            isVertical={true}
                            optionValue={"code"}
                            optionId={"code"}
                            defaultText="Seçiniz"
                            options={currencies}
                            label="Döviz Tipi"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <EkoFormInputText
                            {...bankProps}
                            label="Hesap İsmi"
                            id="name"
                            isVertical={true}
                        />
                      </div>
                      {/* {this.state.formData.bank.bank_account_type === 5 ?
                        <div className={'col-md-6'}>
                            <EkoFormSelect {...bankProps}
                                           disabled={this.state.formData.bank.id}
                                           id="bank_account_id"
                                           isVertical={true}
                                           defaultText="Seçiniz"
                                           options={bankAccounts}
                                           label="Banka Hesabı"
                            />
                        </div>
                        : ""
                    } */}
                      <div className="col-md-6">
                        <EkoFormInputText
                            {...bankProps}
                            label="Açılış Bakiyesi"
                            id="opening_balance"
                            placeHolder={"0,00"}
                            type="money"
                            isVertical={true}
                        />
                      </div>
                      <div className="col-md-6">
                        <EkoFormDate
                            {...bankProps}
                            label={"Açılış Tarihi"}
                            id={"opening_date"}
                            dateFormat="DD-MM-YYYY"
                            isVertical={true}
                        />
                      </div>
                    </div>
                    {this.state.formData.bank.bank_account_type === 3 || this.state.formData.bank.bank_account_type === 2 ? (
                        <div className="row">
                          <div className="col-md-6">
                            <EkoFormInputText
                                {...bankProps}
                                label="Kartınızın son 4 hanesi"
                                placeHolder={"Son 4 Hane"}
                                id="last_number"
                                type="mask"
                                mask="\*\*\*\* \*\*\*\* \*\*\*\* 9999"
                                onKeyPress={this.onlyNumericNumber}
                                isVertical={true}
                            />
                          </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {this.state.formData.bank && this.state.formData.bank.bank_account_type !== 1 ? (<div className="row">
                      <div className="col-md-6">
                        <EkoFormSelect
                            {...bankProps}
                            id="turkiye_bank_id"
                            isVertical={true}
                            optionValue={"bank_name"}
                            optionId={"id"}
                            defaultText="Seçiniz"
                            options={this.state.turkiye_bank_list??[]}
                            label="Banka Seçiniz"
                            searchable={true}
                        />
                      </div>
                    </div>) : (
                        ""
                    )}
                    {this.state.formData.bank && this.state.formData.bank.bank_account_type == 2 ? (
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <EkoFormSwitch
                                onChange={this.toggleBankAdvancedOptions.bind(this)}
                                value={this.state.bankAdvancedOpened}
                                label="Banka Entegrasyonu"
                            />
                          </div>
                          <div className="col-md-12">
                            <div style={{ display: displayBankAdvanced }}>
                              {
                                this.state.lock_obifin_integration == true ?
                                    <div className={"alert alert-warning"}>
                                      <p>Bu hesap için açık bankacılık entegrasyonunuz ONAY beklemektedir. Entegrasyon ile ilgili düzenleme yapabilmek için lütfen onay sürecini bekleyiniz.</p>
                                    </div>
                                    :
                                    this.state.obifin_integration_data != null && this.state.obifin_integration_data.obifin_username != "" && new_obifin_accounts_list.length > 0 && banks_list.length > 0 ?
                                        <>
                                          <div className={"alert alert-info"}>
                                            <p>Açık bankacılık entegrasyonunuz aktiftir. Dilerseniz daha önceden tanımladığınız bankalar var ise seçim yapıp ekleyeceğiniz bankayı eşleştirebilirsiniz. Dilerseniz de <b>"Yeni Ekleyeceğim"</b> seçeneği ile yeni banka tanımlayabilirsiniz.</p>
                                          </div>
                                          <div className="col-md-12">
                                            <EkoFormSelect
                                                {...bankProps}
                                                id="obifin_accounts_list_id"
                                                isVertical={true}
                                                optionValue={"BankaAdi"}
                                                optionId={"Id"}
                                                defaultText="LÜTFEN SEÇİM YAPINIZ"
                                                options={new_obifin_accounts_list}
                                                label="Kayıtlı Banka Hesabı Seçiniz"
                                                searchable={true}
                                            />

                                          </div>
                                          <div className="col-md-6">
                                            <EkoFormSelect
                                                {...bankProps}
                                                id="obifin_bank_id"
                                                isVertical={true}
                                                optionValue={"bank_name"}
                                                optionId={"bank_code"}
                                                defaultText="Seçiniz"
                                                options={banks_list}
                                                label="Banka Seçiniz"
                                                searchable={true}
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <EkoFormInputText
                                                {...bankProps}
                                                label="IBAN"
                                                id="iban"
                                                isVertical={true}
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <EkoFormInputText
                                                {...bankProps}
                                                label="Şube Kodu"
                                                id="sube_kodu"
                                                isVertical={true}
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <EkoFormInputText
                                                {...bankProps}
                                                label="Hesap Numarası"
                                                id="hesap_no"
                                                isVertical={true}
                                            />
                                          </div>
                                        </>
                                        : <>
                                          <div className={"alert alert-danger"}>
                                            <p>Hesap hareketlerinizin sistemimize otomatik olarak yansıması için <a href="/dashboard/obifin">Entegrasyonlar > Banka Entegrasyonu</a> üzerinden entegrasyonunuzu aktif hale getirebilirsiniz.</p>
                                          </div>
                                        </>
                              }

                            </div>
                          </div>

                        </div>
                    ) : (
                        ""
                    )}

                    {this.state.formData.bank && this.state.formData.bank.bank_account_type === 3 ? (
                        <div className="row">
                          <div className="col-md-3">
                            <EkoFormInputText
                                {...bankProps}
                                label="Hesap Kesim Tarihi"
                                placeHolder={"1-28 arası"}
                                id="statement_date"
                                type="numeric"
                                onKeyPress={this.onlyNumericNumber}
                                isVertical={true}
                            />
                          </div>

                          <div className="col-md-3">
                            <EkoFormInputText
                                {...bankProps}
                                label="Son Ödeme Tarihi"
                                placeHolder={"1-28 arası"}
                                id="expiry_date"
                                type="numeric"
                                onKeyPress={this.onlyNumericNumber}
                                isVertical={true}
                            />
                          </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {/* {this.state.formData.bank.bank_account_type === 5 ?
                    <div
                        className={'addOptions'}>
                        <div className="row">
                            <div className="col-md-6">
                                <EkoFormInputText {...bankProps}
                                                  disabled={this.state.formData.bank.id}
                                                  label="Kredi Anapara Tutarı"
                                                  id="amount_fc"
                                                  type="money"
                                                  isVertical={true}/>
                            </div>
                            <div className="col-md-6">
                                <EkoFormDate
                                    {...bankProps}
                                    id="start_date"
                                    dateFormat="DD-MM-YYYY"
                                    isVertical={true}
                                    clearable={false}
                                    label="Kredi Kullanım Tarihi"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <EkoFormInputText {...bankProps}
                                                  label="Kredi Süresi (Ay)"
                                                  id="term"
                                                  type="number"
                                                  isVertical={true}/>
                            </div>
                            <div className="col-md-4">
                                <EkoFormInputText {...bankProps}
                                                  disabled={(this.state.formData.bank.currency === 'TRY')}
                                                  label="Kredi Kullanım Kuru"
                                                  id="currency_rate"
                                                  isVertical={true}/>
                            </div>
                            <div className="col-md-4">
                                <EkoFormInputText {...bankProps}
                                                  label="Yıllık Faiz Oranı"
                                                  id="interest_rate_total"
                                                  type="percent"
                                                  isVertical={true}/>
                            </div>
                        </div>
                    </div>
                    : ""
                } */}
                    <Modal.Footer>
                      <br/>
                      <div className="row buttonProccess2">
                        <EkoFormButton
                            label={btn}
                            faClass="fa fa-plus"
                            isLoading={this.state.saving}
                            onClick={this.onSave.bind(this)}
                        />
                        <EkoFormButton
                            label="İptal"
                            className="btn"
                            onClick={this.onCancel.bind(this)}
                        />
                      </div>
                      <hr/>
                      <div style={{"textAlign": "left"}}>
                        <a onClick={this.openNewTagModal}>Etiket Ekle</a>
                      </div>
                    </Modal.Footer>
                  </EkoForm>
                </>
            }

          </EkoModal>
          <NewTagDocumentModal
              newTagRows={this.state.newTagRows}
              saveNewTag={this.saveNewTag}
              showNewTagModal={this.state.showNewTagModal}
              closeNewTagModal={this.closeNewTagModal}
          />
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bankAccounts: state.data.bankAccountsAll,
    currencies: state.data.currencies,
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(AddBank);
