import React, { Component } from "react";
import PropTypes from "prop-types";
import enhanceWithClickOutside from "react-click-outside";
import {
  Col,
  ControlLabel,
  FormGroup,
  HelpBlock,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import TooltipService from "../../../services/TooltipService";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import List from "react-virtualized/dist/commonjs/List";
import SelectResult from "../SelectResult";
import { CellMeasurer, CellMeasurerCache } from "react-virtualized";
import {EkoForm} from "../EkoForm";
import EkoFormDate from "./EkoFormDate";
import EkoModal from "../EkoModal";

class EkoFormSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedItem: "",
      selectedIndex: null,
      value: "",
      searchInput: null,
      spinnerModal:true,
      selectNotesWarningModal:false,
      selectNotesWarningModalTxt:"",
    };

    this.selectedItem = null;
    this.list = null;
    this.value = null;
    this.error = null;

    this._cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 29
    });
  }

  getValue = () => this.state.value;
  getSelectedValue = () =>
      (this.props.formData && this.props.formData[this.props.id]) ||
      (this.props.formData && this.props.formData[this.props.id] === 0)
          ? this.props.formData[this.props.id]
          : this.props.value;

  componentWillMount() {
    if (this.props.onMount) this.props.onMount(this.props.id);

    this.listenKeyPress();
  }

  componentWillUnmount() {
    this.removeListener();
  }

  handleClickOutside = () =>
      this.state.open &&
      this.setState({
        open: false
      });

  openCloseList() {
    if (!this.props.disabled && !this.props.readOnly) {
      this.setState({ open: !this.state.open });
    }
  }

  closeSelectItemModal() {
    this.setState({selectNotesWarningModal:false});
  }

  selectItem(item, index, hide = true) {
    if(item && item.notes && item.notes!=''){
      this.setState({selectNotesWarningModal:true,selectNotesWarningModalTxt:item.notes});
    }
    if (hide !== false) {
      this.setState({ open: !this.state.open, selectedIndex: index }, () =>
          this.props.onChange(this.props.id, item[this.props.optionId])
      );
    }
  }

  onClickClear() {
    if (!this.props.readOnly) {
      this.props.onChange(this.props.id, this.props.value);
    }
  }

  onSearchableChange(e) {
    if (this.props.onSearchChange) {
      this.props.onSearchChange(e.target.value);
    }

    this.setState({ value: e.target.value, selectedIndex: undefined });
  }

  turkishToUpper = value => {
    if (!(value instanceof String)) {
      value = value ? value.toString() : "";
    }

    const letters = { i: "İ", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç", ı: "I" };
    return value
        .replace(/[#iışğüçö]/g, letter => letters[letter])
        .toUpperCase();
  };

  onAddNewItemClick() {
    this.props.onAddNewItemClick();
    this.openCloseList();
  }
  onEditItemClicks(item,index) {
    console.log('itemitemitem',item);
    this.props.onEditItemClick(item,index,item);
    this.openCloseList();
  }
  filterOptions = () => {
    let options = this.props.options;
    const searchedValue = this.turkishToUpper(this.getValue());
    let searchFilter = this.props.searchFilter
        ? this.props.searchFilter
        : this.props.optionValue;

    options = options?.filter(option => {
      return (
          option[searchFilter] &&
          this.turkishToUpper(option[searchFilter]).includes(searchedValue)
      );
    });

    if (options?.length === 0 && this.props.defaultKey) {
      const defaultKey = this.turkishToUpper(this.props.defaultKey);
      options = this.props.options?.filter(option => {
        return (
            option[searchFilter] &&
            this.turkishToUpper(option[searchFilter]).includes(defaultKey)
        );
      });
    }

    return options;
  };
  /**
   * şu anda seçili olan itemi bulur
   *
   * @return  itemi bulur ve getirir
   */
  findSelectedItem = options => {
    const value = this.getSelectedValue();

    return options
        .map((option, index) => {
          if (option[this.props.optionId] === value) {
            return index;
          }

          return undefined;
        })
        .filter(item => {
          return item !== undefined;
        });
  };

  /**
   *
   * @param mixed up
   * @return void
   */
  handleUpDown = up => {
    let options = this.filterOptions();
    let selectedItem = this.findSelectedItem(options);

    if (selectedItem.length > 0) {
      selectedItem = selectedItem[0];
    } else {
      selectedItem = -1;
    }

    let nextItem = 0;
    if (up === "up") {
      if (selectedItem < options?.length - 1) {
        nextItem = selectedItem + 1;

        this.selectItem(options[nextItem], nextItem, false);
      }
    } else {
      if (selectedItem >= 1) {
        nextItem = selectedItem - 1;

        this.selectItem(options[nextItem], nextItem, false);
      }
    }
  };

  /**
   * @param e
   *
   * yukarı ve aşağıya tıklamayı algılar
   *
   */
  handler = e => {
    if (this.state.open === false) {
      return true;
    }

    if (e.keyCode === 38) {
      this.handleUpDown("down");
      e.preventDefault();
    } else if (e.keyCode === 40) {
      this.handleUpDown("up");

      e.preventDefault();
    } else if (e.keyCode === 13) {
      this.setState({ open: !this.state.open });

      e.preventDefault();
    }
  };

  listenKeyPress = () => {
    window.addEventListener("keydown", this.handler);
  };

  removeListener = () => {
    window.removeEventListener("keydown", this.handler);
  };

  _rowRenderer = ({ index, key, parent, style }, options, selectedItem) => {
    let content = null;
    if (index === 0 && this.props.searchable) {
      content = (
          <li key={key}>
            <input
                placeholder={"Arayınız..."}
                type="text"
                autoFocus
                className="form-control"
                onChange={this.onSearchableChange.bind(this)}
                value={this.getValue() ? this.getValue() : ""}
            />
          </li>
      );
    } else if (index === 1 && this.props.onAddNewItemClick) {
      content = (
          <li
              key={key}
              style={style}
              className="no-results"
              onClick={this.onAddNewItemClick.bind(this)}
          >
            <i className="fa fa-plus"></i>Yeni Ekle
          </li>
      );
    } else {
      let selectIndex =
          this.props.searchable && this.props.onAddNewItemClick
              ? index - 2
              : this.props.searchable || this.props.onAddNewItemClick
                  ? index - 1
                  : index;

      let item = options[selectIndex];

      if (!item) {
        return null;
      }
      let value = item[this.props.optionValue];

      let isSelected = selectedItem
          ? selectedItem[this.props.optionId] === item[this.props.optionId]
          : false;

      content = (
          <SelectResult
              id={key}
              key={key}
              selectItem={this.selectItem.bind(this)}
              isSelected={isSelected}
              item={item}
              value={value}
              style={style}
              onEditItemClickAction={this.onEditItemClicks.bind(this)}
              onEditItemClick={
            this.props.onEditItemClick
           }
          />
      );
    }

    return (
        <CellMeasurer
            cache={this._cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
        >
          {content}
        </CellMeasurer>
    );
  };

  calculateHeight = (length, options, searchable, onAdd) => {
    if (length > 4) {
      return 160;
    }

    if (options?.length === 0) {
      return searchable && onAdd ? 90 : searchable || onAdd ? 45 : 30;
    }

    let height = options?.length * 29;

    if (searchable) {
      height += 45;
    }

    if (onAdd) {
      height += 45;
    }

    return height;
  };

  renderInput() {
    let options = this.props.options;
    let dropdownClassName = "dropdown-list ";
    if (this.state.open) {
      dropdownClassName += "openBlock";
    } else {
      dropdownClassName += "closeBlock";
    }
    const defaultText = this.props.defaultText;
    const clearable = this.props.clearable;
    const searchable = this.props.searchable;
    const value = this.getSelectedValue();

    const selectedItem = options?.filter(
        option => option[this.props.optionId] === value
    )[0];

    const defaultCaretStyle = { marginTop: "-10px" };
    if (!selectedItem && defaultText === "") {
      defaultCaretStyle.marginTop = "5px";
    }
    const defaultStyle = { float: "left", marginRight: "0px", width: "80%" };
    const dropdownStyle = { width: "80%", marginTop: "34px", minWidth: 70 };

    if (!clearable) {

      defaultStyle.width = "100%";
      dropdownStyle.width = "100%";
    } else {
      defaultStyle.width = "90%";
      dropdownStyle.width = "90%";
    }
    if (this.props.width) dropdownStyle.width = this.props.width;
    if(dropdownStyle.width=="100px"){dropdownStyle.width="200px";}
    const emptyDropdownStyle = { ...defaultStyle, height: "34px" };
    let buttonDefaultStyle = selectedItem ? defaultStyle : emptyDropdownStyle;
    options = this.filterOptions();

    let selectedOptionValue =
        this.props.selectedOptionValue || this.props.optionValue;
    let readOnlyStyle = this.props.readOnly
        ? { backgroundColor: "#EEF1F5", cursor: "not-allowed" }
        : this.props.id=='stoppage_code'?{width:"180px"}:this.props.id=='vat_reduction_type_id'?{width:"170px"}:this.props.id=='stoppage_code_id'?{width:"170px"}:this.props.id=='nace_code_id'?{width:"180px"}:this.props.id=='nace_code'?{width:"180px"}:this.props.id=='expense_category_id'?{width:"180px"}:{};
    //  const errorStyle = (this.props.tooltip && this.props.errors && this.props.errors[this.props.id]) ? {backgroundColor:'red',color:'white'} : {}
    //  readOnlyStyle = Object.assign(readOnlyStyle,errorStyle);
    buttonDefaultStyle = { ...buttonDefaultStyle, ...readOnlyStyle };

    if (this.state.open) {
      this.listenKeyPress();
    } else {
      this.removeListener();
    }

    let add =
        searchable && this.props.onAddNewItemClick
            ? 2
            : this.props.onAddNewItemClick || this.props.searchable
                ? 1
                : 0;

    let length = options?.length + add;

    return (
        <div
            className="row btn-group  bootstrap-select bs-select form-control"
            style={readOnlyStyle}
            title={selectedItem ? selectedItem[selectedOptionValue] : defaultText}
        >
          <div
              onClick={this.openCloseList.bind(this)}
              disabled={this.props.disabled}
              className={
                  this.props.disabled +
                  " btn dropdown-toggle btn-default dropdown-button"
              }
              style={buttonDefaultStyle}
          >
            <div
                style={{
                  textOverflow: "ellipsis",
                  width: "85%",
                  overflow: "hidden"
                }}
            >
              {selectedItem ? selectedItem[selectedOptionValue] : defaultText}
            </div>
            <span className="caret pull-right" style={defaultCaretStyle} />
          </div>
          {clearable ? (
              <div
                  id="clearButton"
                  className="clear-button"
                  onClick={this.onClickClear.bind(this)}
                  style={{
                    height: "34px",
                    color: "#555",
                    textAlign: "center",
                    border: "1px solid #ccc",
                    width: "10%",
                    float: "left",
                    paddingTop: "5px"
                  }}
              >
                <i className="fa fa-times" />
              </div>
          ) : (
              ""
          )}
          <div
              ref={div => (this.list = div)}
              className={dropdownClassName}
              style={dropdownStyle}
          >
            <ul>
              <AutoSizer disableHeight>
                {({ width }) => (
                    <List
                        className="List"
                        height={this.calculateHeight(
                            length,
                            options,
                            searchable,
                            !!this.props.onAddNewItemClick
                        )}
                        overscanRowCount={5}
                        scrollToIndex={this.state.selectedIndex || undefined}
                        rowCount={length}
                        rowHeight={this._cache.rowHeight}
                        rowRenderer={items =>
                            this._rowRenderer(items, options, selectedItem)
                        }
                        width={width}
                    />
                )}
              </AutoSizer>
            </ul>
          </div>
          <EkoModal
              headerStyle={{
                color: "red",textAlign:"center",marginTop:"40%"
              }}
              showModal={this.state.selectNotesWarningModal}
              spinner={this.state.spinnerModal}
              onHide={this.closeSelectItemModal.bind(this)}
              title={"Uyarı!"}
          >
            <div style={{textAlign:"center"}}>{this.state.selectNotesWarningModalTxt}</div>
          </EkoModal>
        </div>

    );
  }

  render() {
    this._cache.clearAll();
    let validationState = null;
    let tooltip = "";
    const error =
        this.props.errors && this.props.errors[this.props.id] ? (
            <ControlLabel>{this.props.errors[this.props.id]}</ControlLabel>
        ) : (
            false
        );
    if (error) {
      validationState = "error";
      tooltip = (
          <Tooltip id="tooltip">{this.props.errors[this.props.id][0]}</Tooltip>
      );
    }

    let classForTooltip = "";
    if (this.props.tooltip && error) classForTooltip = "input-icon right";

    const help = this.props.help ? (
        <HelpBlock>{this.props.help}</HelpBlock>
    ) : (
        ""
    );
    let tooltipId = this.props.tooltipId ? this.props.tooltipId : this.props.id;

    if (this.props.justInput) return this.renderInput();
    return !this.props.isVertical ? (
        <FormGroup style={this.props.formStyle} controlId={this.props.id} validationState={validationState}>
          {this.props.label && (
              <Col
                  componentClass={ControlLabel}
                  md={parseInt(this.props.labelMd, 10)}
              >
                {TooltipService.getLabelWithTooltip(tooltipId, this.props.label)}
                &nbsp;{" "}
                {this.props.required ? <span className="required"> * </span> : ""}
              </Col>
          )}
          <Col md={parseInt(this.props.colMd, 10)}>
            <div className={classForTooltip}>
              {error && this.props.tooltip ? (
                  <OverlayTrigger placement="left" overlay={tooltip}>
                    <i className="fas fa-exclamation-triangle tooltips"></i>
                  </OverlayTrigger>
              ) : (
                  ""
              )}
              {this.renderInput()}

              {error && !this.props.tooltip ? error : help}
            </div>
          </Col>
        </FormGroup>
    ) : (
        <FormGroup style={this.props.formStyle} controlId={this.props.id} validationState={validationState}>
          {this.props.label && (
              <Col componentClass={ControlLabel} className="control-line">
                {TooltipService.getLabelWithTooltip(
                    this.props.id,
                    this.props.label
                )}
                &nbsp;{" "}
                {this.props.required ? <span className="required"> * </span> : ""}
              </Col>
          )}
          <div className={classForTooltip}>
            {this.renderInput()}
            {error && this.props.tooltip ? (
                <OverlayTrigger placement="left" overlay={tooltip}>
                  <i className="fas fa-exclamation-triangle tooltips"></i>
                </OverlayTrigger>
            ) : (
                ""
            )}
            {error && !this.props.tooltip ? error : help}
          </div>
        </FormGroup>
    );
  }
}

EkoFormSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  id: PropTypes.string.isRequired,
  optionId: PropTypes.string,
  optionValue: PropTypes.string,
  searchFilter: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onEditItemClick: PropTypes.func,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  onAddNewItemClick: PropTypes.func,
  readOnly: PropTypes.bool,
  tooltipId: PropTypes.string,
  width: PropTypes.string,
  onSearchChange: PropTypes.func
};

EkoFormSelect.defaultProps = {
  options: [],
  optionId: "id",
  optionValue: "name",
  disabled: false,
  defaultText: "BİRİNİ SEÇİNİZ",
  clearable: false,
  searchable: false,
  labelMd: 5,
  formStyle: {},
  colMd: 6,
  onEditItemClick:false
};

export default enhanceWithClickOutside(EkoFormSelect);
