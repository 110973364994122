import React, { Component } from "react";
import {
  BootstrapTable,
  SearchField,
  TableHeaderColumn
} from "react-bootstrap-table";
import { Dropdown, MenuItem } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import EkoDatePicker from "./EkoDatePicker";
import Util from "../../util/Util";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Sugar from "sugar";
import moment from "moment";
import PropTypes from "prop-types";
import { Link } from "react-router";
import EkoSpinner from "../elements/EkoSpinner";
import Api from "../../util/Api";
import ImportModal from "./ImportModal";
import ImportDetailModal from "./ImportDetailModal";
import ReactTooltip from "react-tooltip";
import ImportDetailModal2 from "./ImportDetailModal2";
import ImportDetailModal3 from "./ImportDetailModal3";
import TradeReconciliationModal from "./TradeReconciliationModal";
import EkoModal from "./EkoModal";
// import jquery
import $ from "jquery";
class EkoDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReportId:0,
      modal: false,
      loadingItems: false,
      isSelected: false,
      filterOpen: false,
      eximOpen: false,
      isView: false,
      eximOpenAll: false,
      importModal: false,
      importDetailModal: false,
      importDetailModal2: false,
      importDetailModal3: false,
      tradeReconciliationModal: false,
      downloadExcel: false,
      eInformation:false,
      filterBtnGroup: "btn-group",
      eximBtnGroup: "btn-group",
      eximBtnGroupAll: "btn-group",
      headers: [],
      filteredItems: [],
      date2: "",
      date1: "",
      customRowButtons: "",
      isDataFetched: false,
      statusFlag: true,
      popupVisible: false,
      gelirTuru:[],
      gelirKayitTuru:[],
      gelirKayitAltTuru:[],
    };

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }
  modal(value) {
    if(this.props.name === "cheque"){
      this.setState({selectedReportId:30});
    }

    this.setState({ modal: value });
  }
  static defaultProps = {
    selectRow: true,
    addButtonShow: true,
    addUpdateShow: true,
    addDeleteShow: true,
    tableProps: {},
    loading: false,
    showDelete: true
  };

  handleClickOutside() {
    this.setState({ filterOpen: false, filterBtnGroup: "btn-group" });
  }

  dbsSelectChange = (row,val,type,count) => {
    if(type=='gelirTuru'){
      this.state.gelirTuru[count].val=val;
    }
    this.handleRowSelect(row, true);
  };



  componentWillMount() {
    //NOTE @bocek dtConfig sadece datatablelarda lazim, oyuzden datatable icinde
    //cekmek mantikli, yoksa genel birsey olsa dashboard/Index componentinde
    //cekerdim

    if (this.props.onRef) this.props.onRef(this);

    this.props.fetchDtConfigs();
    this.getCompanyEinformation();
    if (this.props.headers) {
      this.setState({
        headers: this.props.headers
      });
    }
  }
  getCompanyEinformation(){
    let companyId = JSON.parse(localStorage.getItem("user")).company_id;
    Api.get("/eInformation/show/" + companyId, (response, err) => {
      if(typeof response.eInformation.id==='undefined'){
        this.setState({eInformation:true});
        return true;
      }else{
        this.setState({eInformation:false});
        return false;
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const self = this;

    if (!this.props.headers && nextProps.dtConfig && nextProps.name) {

      const dtConfig = Util.filterDtConfig(nextProps.dtConfig, nextProps.name);
      console.log("dtconfig", dtConfig);

      /*  if(!this.state.loadingItems){
             this.props.fetchData(dtConfig.api_url,dtConfig.type);
             this.setState({loadingItems:true});
             }*/

      //  if(Sugar.Array.isEmpty(this.state.headers))

      let localConf = this.getLocalConf(nextProps.name);

      if (localConf && localConf.headers)
        this.setState({ headers: localConf.headers });
      else this.setState({ headers: dtConfig[nextProps.name] });
    }

    /*  if(nextProps.data && nextProps.data[this.props.name]){
         this.setState({filteredItems:nextProps.data[this.props.name]});
         }*/

    if (nextProps.data) {
      console.log("nextprops", nextProps.data);
      this.setState({ filteredItems: nextProps.data, isDataFetched: true });
    } else {
      this.setState({
        isDataFetched: false,
        filteredItems: []
      });
    }

    if (nextProps.createCustomRowButtons) {
      this.setState({
        customRowButtons: this.createCustomRowButtons(this.state, nextProps)
      });
    }
  }

  onDateChange = (dateField, value, formattedValue) => {
    const dtConfig =
      this.props.dtConfigLocal ||
      Util.filterDtConfig(this.props.dtConfig, this.props.name);
    var minDate = this.state.date1;
    var maxDate = this.state.date2;

    if (dateField === "date1") {
      if (formattedValue === null) {
        this.setState({ date1: "" });
        minDate = "";
      } else {
        this.setState({
          date1: moment(formattedValue, "DD-MM-YYYY").format("YYYY-MM-DD")
        });
        minDate = moment(formattedValue, "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    } else {
      if (formattedValue === null) {
        this.setState({ date2: "" });
        maxDate = "";
      } else {
        this.setState({
          date2: moment(formattedValue, "DD-MM-YYYY").format("YYYY-MM-DD")
        });
        maxDate = moment(formattedValue, "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    }

    if (this.props.customPagination || this.props.onDateChange) {
      this.props.onDateDataChange(
        minDate && minDate !== "" ? minDate : false,
        maxDate && maxDate !== "" ? maxDate : false,
        dtConfig.date_filter_column
      );
    } else {
      // let filteredItems = this.props.data[this.props.name].filter(function(item){
      let filteredItems = this.props.data
        ? this.props.data.filter(function(item) {
            const itemDate = moment(
              item[dtConfig.date_filter_column],
              "DD-MM-YYYY"
            ).format("YYYY-MM-DD");

            const momentItem = moment(itemDate);

            if (minDate === "" && maxDate === "") return true;
            else if (maxDate === "") return momentItem.isSameOrAfter(minDate);
            else if (minDate === "") return momentItem.isSameOrBefore(maxDate);
            else
              return (
                momentItem.isSameOrBefore(maxDate) &&
                momentItem.isSameOrAfter(minDate)
              );
          })
        : [];

      this.setState({ filteredItems: filteredItems, isDataFetched: true });
    }
  };
  onInvoiceStatusChange = (val) => {
    if (this.props.onInvoiceStatusChange) {
      this.props.onInvoiceStatusChange(val);
    }
  }
  handleDeleteButtonClick = onClick => {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      toastr.confirm(`Bu kaydı silmek istediğinize emin misiniz?`, {
        onOk: () => {
          this.props.onDelete(this.state.selectedRow);
          this.setState({
            isSelected: false,
            deleteDisable: true,
            statusFlag: true
          });
        },
        onCancel: () => {
          if (this.dataTableRef) {
            this.dataTableRef.reset();
          }

          this.setState({
            isSelected: false,
            deleteDisable: true,
            statusFlag: true
          });
        },
        okText: "Evet",
        cancelText: "Hayır"
      });
    }
  };

  canceledEInvoiceSales() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      toastr.confirm(`Alıcı tarafından reddedilen ve Ekohesap'taki statüsü red gözükmeyen faturanızın Ekohesap'taki durumu red olarak değiştirilecektir. Emin misiniz?`, {
        onOk: () => {
          Api.get("/salesInvoice/canceledInvoice/" + this.state.selectedRow.id, (response, err) => {

          });
          this.setState({
            isSelected: false,
            statusFlag: true
          });
        },
        onCancel: () => {
          if (this.dataTableRef) {
            this.dataTableRef.reset();
          }
          this.setState({
            isSelected: false,
            statusFlag: true
          });
        },
        okText: "Devam Et",
        cancelText: "İptal"
      });
    }
  };

  handleDeleteButtonClick2(onClick) {
    let data = this.state.selectedRow;

    if (this.state.selectedRow === undefined || !this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      toastr.confirm(`Bu kaydı silmek istediğinize emin misiniz?`, {
        onOk: () => {
          this.props.onDelete(this.state.selectedRow);
          this.setState({
            isSelected: false,
            deleteDisable: true,
            eximBtnGroupAll: "btn-group",
            statusFlag: true,
            selectedRow: {}
          });
          console.log("selected row",this.state.selectedRow);
        },
        onCancel: () => {
          if (this.dataTableRef) {
            this.dataTableRef.reset();
          }
          this.setState({ isSelected: false, deleteDisable: true });
        },
        okText: "Evet",
        cancelText: "Hayır"
      });
    }
  }

  onInsertMenuSelect(eventKey) {
    this.props.openInsert(eventKey);
  }

  initMultipleInsertButton(menuItems) {
    const menuItemView = menuItems.map(menuItem => (
      <MenuItem
        eventKey={menuItem.id}
        key={menuItem.id}
        onSelect={this.onInsertMenuSelect.bind(this)}
      >
        {menuItem.name}
      </MenuItem>
    ));

    const title = (
      <span>
        <i className="fa fa-plus" />
        &nbsp;Ekle
      </span>
    );
    return (
      <Dropdown id="dropdown-custom-1">
        <Dropdown.Toggle
          title=""
          noCaret
          className="btn blue dropdown-toggle btn-sm"
        >
          {title}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-align dropdown-menu">
          {" "}
          {menuItemView}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  createCustomNewButtons(onClick) {
    if (!this.props.showDelete) {
      return <div />;
    }

    const dtConfig = Util.filterDtConfig(this.props.dtConfig, this.props.name);
    var apiUrl = process.env.REACT_APP_API_URL;
    var apiUrl2 = process.env.REACT_APP_API_URL;
    apiUrl2 = apiUrl2.substring(0, apiUrl2.length-3);
    const time = new Date();
    return (
      // <-- salesInvoice sayfası için kurallar

      // status --> 9 olmalı ve e-arşiv iptal kalkmalı yerine sil gelmeli
      // einvoice type --> 0 sa yazdır butonu gelecek
      // einvoice type --> 1 se gönder butonu gelecek

      // <---- cheque sayfası için kurallar
      // is_collection === 0 ise && to_id boş ise cirolanabilir.
      // is_collection === 0 && to_id dolu && from_id === null ise ödeme gelecek.
      // is_collection === 0 && from_id !== null && to_id === null ise tahsilat
      // is_collection === 1 ise Sil ve Güncelle

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <div
          className={this.state.eximBtnGroupAll}
          ref={node => {
            this.node = node;
          }}
        >
          <button
            onClick={this.onHeaderEximClickedTransaction.bind(this)}
            type="button"
            className="btn white dropdown-toggle btn-sm"
            data-toggle="dropdown"
          >
            {" "}
            İşlemler
            <i className="fa fa-angle-down" />
          </button>

          <ul className="dropdown-menu transaction_button">
            {(this.state.statusFlag && dtConfig.name !== "salesInvoice") ||
            (dtConfig.name !== "salesInvoice" && this.state.selectedRow) &&  dtConfig.name !== "loanPaymentPlan" &&  dtConfig.name !== "depreciation" &&  dtConfig.name !== "fixedAsset" &&  dtConfig.name !== "fixedAssetList" &&  dtConfig.name !== "fixedAssetSalesList" ? (
                <li>
                <a onClick={() => this.handleDeleteButtonClick(onClick)}>
                  <i className={"fa fa-trash"} /> Sil
                </a>
              </li>
            ) : (
              ""
            )}
            {(this.state.statusFlag && dtConfig.name === "loanPaymentPlan") ||
                (this.state.selectedRow &&
                dtConfig.name === "loanPaymentPlan" &&
                parseInt(this.state.selectedRow.is_paid, 10) != 0 ? (
                    <li>
                      <a onClick={this.payCancel}>
                        <i className={"fa fa-times"} /> Ödeme İptal Et
                      </a>
                    </li>
                ) : (
                    ""
                ))}
            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (dtConfig.name === "salesInvoice" &&
              this.state.selectedRow &&
              this.state.selectedRow.sent === null) ? (
              <li>
                <a onClick={() => this.handleDeleteButtonClick2(onClick)}>
                  <i className={"fa fa-trash"} /> Sil
                </a>
              </li>
            ) : (
              ""
            )}

            {dtConfig.name === "salesInvoice" &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.sent.status &&
            this.state.selectedRow.sent.status === 2 ? (
              <li>
                <a onClick={() => this.handleDeleteButtonClick2(onClick)}>
                  <i className={"fa fa-trash"} /> Sil
                </a>
              </li>
            ) : (
              ""
            )}

            {dtConfig.name === "salesInvoice" &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.sent.status === 9 ? (
              <li>
                <a onClick={() => this.handleDeleteButtonClick(onClick)}>
                  <i className={"fa fa-trash"} /> Sil
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name !== "salesInvoice") ||
            (dtConfig.name !== "salesInvoice" && dtConfig.name !== "soleprofv2s" &&
              this.state.selectedRow &&
              this.props.addUpdateShow) ? (
              <li>
                <a onClick={this.openUpdate.bind(this)}>
                  <i className={"fa fa-edit"} /> Güncelle
                </a>
              </li>
            ) : (
              ""
            )}
            {(this.state.statusFlag && dtConfig.name === "soleprofv2s") ||
            (dtConfig.name === "soleprofv2s" &&
                this.state.selectedRow &&
                this.state.selectedRow.sent === null) ? (
                <li>
                  <a onClick={this.openUpdate.bind(this)}>
                    <i className={"fa fa-edit"} /> Güncelle
                  </a>
                </li>
            ) : (
                ""
            )}
            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (dtConfig.name === "salesInvoice" &&
              this.state.selectedRow &&
              this.props.addUpdateShow &&
              this.state.selectedRow.sent === null) ? (
              <li>
                <a onClick={this.openUpdate.bind(this)}>
                  <i className={"fa fa-edit"} /> Güncelle
                </a>
              </li>
            ) : (
              ""
            )}

            {dtConfig.name === "purchaseInvoice" ||
            (dtConfig.name === "salesInvoice" && this.state.selectedRow) ? (
              <li>
                <a onClick={this.openDetail.bind(this)}>
                  <i className={"fa fa-info"} /> Detay
                </a>
              </li>
            ) : (
              ""
            )}
            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (dtConfig.name === "salesInvoice" &&
              this.state.selectedRow &&
              this.state.selectedRow.is_exempt === 0) ? (
              <li>
                <a onClick={this.openCopied.bind(this)}>
                  <i className={"fa fa-copy"} /> Kopyala
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (this.state.selectedRow &&
              dtConfig.name === "salesInvoice"
              // && this.state.selectedRow.einvoice_type === 0 &&
              //this.state.selectedRow.is_exempt !== 1
            ) ? (
              <li>
                <a onClick={this.openSelfPrint.bind(this)}>
                  <i className={"fa fa-print"} /> Yazdır
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (this.state.selectedRow && dtConfig.name === "salesInvoice") ? (
              <li>
                <a onClick={this.askCollection.bind(this)}>
                  <i className="fas fa-compress" /> Kredi Kartı ile Ödeme İste
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name === "customer") ||
            (this.state.selectedRow &&
              dtConfig.name === "customer" &&
              this.state.selectedRow.balance > 0) ? (
              <li>
                <a onClick={this.askCollection.bind(this)}>
                  <i className="fas fa-compress" /> Kredi Kartı ile Ödeme İste
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (this.state.selectedRow &&
              dtConfig.name === "salesInvoice" &&
              this.state.selectedRow.einvoice_type === 1 &&
              this.state.selectedRow.is_exempt !== 1) ? (
              <li>
                <a onClick={this.eInvoicePreview.bind(this)}>
                  <i className={"fa fa-eye"} /> e-Fatura Önizle{" "}
                </a>
              </li>
            ) : (
              ""
            )}
            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (this.state.selectedRow &&
                this.state.selectedRow.earchive_pdf == null &&
                this.state.eInformation===true &&
                dtConfig.name === "salesInvoice" &&
                this.state.selectedRow.is_exempt !== 1) ? (
                <li>
                  <a onClick={this.eArchivesendButton.bind(this)}>
                    <i className={"fa fa-share-square"} /> e-Arşiv Fatura Gönder
                  </a>
                </li>
            ) : (
                ""
            )}
            {(dtConfig.name === "soleprofv2s") &&
            (this.state.selectedRow &&
                this.state.selectedRow.sent===null &&
                dtConfig.name === "soleprofv2s") ? (
                <li>
                  <a onClick={this.soleprofSendButton.bind(this)}>
                    <i className={"fa fa-share-square"} /> e-SMM Gönder
                  </a>
                </li>
            ) : (
                ""
            )}
            {(dtConfig.name === "soleprofv2s") &&
            (this.state.selectedRow &&
                this.state.selectedRow.sent!==null &&
                this.state.selectedRow.sent.status!==9 &&
                dtConfig.name === "soleprofv2s") ? (
                <li>
                  <a onClick={this.openSMMCancel.bind(this)}>
                    <i className={"fa fa-ban"} /> e-SMM İptal
                  </a>
                </li>
            ) : (
                ""
            )}
            {(dtConfig.name === "soleprofv2s") &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.sent.xml ? (
                <li>
                  <a
                      target="_blank"
                      href={
                          "https://earsiv.co/fatura.php?f=ESMM/EOutBoxes/" +
                          this.state.selectedRow.sent.xml
                      }
                  >
                    <i className={"fa fa-eye"} /> XML Görüntüle
                  </a>
                </li>
            ) : (
                ""
            )}

            {(dtConfig.name === "soleprofv2s") &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.sent.pdf ? (
                <li>
                  <a
                      target="_blank"
                      href={
                          "https://earsiv.co/fatura.php?f=ESMM/EOutBoxes/" +
                          this.state.selectedRow.sent.pdf
                      }
                  >
                    <i className={"fa fa-eye"} /> PDF Görüntüle
                  </a>
                </li>
            ) : (
                ""
            )}
            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (this.state.selectedRow &&
                this.state.selectedRow.earchive_pdf != null &&
                this.state.eInformation===true &&
                dtConfig.name === "salesInvoice" &&
                this.state.selectedRow.is_exempt !== 1) ? (
                <li>
                  <a onClick={this.eArchiveShowButton.bind(this)}>
                    <i className={"fa fa-share-square"} /> e-Arşiv Fatura Göster
                  </a>
                </li>
            ) : (
                ""
            )}
            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (this.state.selectedRow &&
                this.state.selectedRow.earchive_pdf != null &&
                this.state.eInformation===true &&
                dtConfig.name === "salesInvoice" &&
                this.state.selectedRow.is_exempt !== 1) ? (
                <li>
                  <a onClick={this.eArchiveShowButton.bind(this)}>
                    <i className={"fa fa-ban"} /> e-Arşiv Fatura İptal
                  </a>
                </li>
            ) : (
                ""
            )}
            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (this.state.selectedRow &&
              dtConfig.name === "salesInvoice" &&
              this.state.selectedRow.einvoice_type === 1 &&
              this.state.selectedRow.is_exempt !== 1) ? (
              <li>
                <a onClick={this.sendButton.bind(this)}>
                  <i className={"fa fa-share-square"} /> e-Fatura Gönder
                </a>
              </li>
            ) : (
              ""
            )}
            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (this.state.selectedRow &&
              dtConfig.name === "salesInvoice" &&
              this.state.selectedRow.is_exempt === 1) ? (
              <li>
                <a onClick={this.showMethod.bind(this)}>
                  <i className={"fa fa-copy"} /> e-Fatura Göster
                </a>
              </li>
            ) : (
              ""
            )}
            {(dtConfig.name === "proformas" && this.state.selectedRow)? (
              <li>
                <a onClick={this.showMethod.bind(this)}>
                  <i className={"fa fa-copy"} /> Göster
                </a>
              </li>
            ) : (
              ""
            )}
            {/* {(this.state.isView === true && dtConfig.name === "proformas") || (this.state.selectedRow && this.state.selectedRow.status === 3) ?  */}
            {(dtConfig.name === "proformas" && this.state.selectedRow) ? (
                <li>
                  <a onClick={this.partialTransformProforma.bind(this)}>
                    <i className="fas fa-handshake"></i> Kısmi Satışa Dönüştür
                  </a>
                </li>
            ) : (
                ""
            )}

            {dtConfig.name === "proformas" ? (
                <li>
                  <a onClick={this.transformInvoiceAllProforma.bind(this)}>
                    <i className="far fa-share-square" />{" "}
                    <span style={{ paddingLeft: 3 }}>Toplu Satışa Dönüştür </span>
                  </a>
                </li>
            ) : (
                ""
            )}
            {(dtConfig.name === "proformas" &&
              this.state.selectedRow &&
                (this.state.selectedRow.status === 3|| this.state.selectedRow.status === 2 || this.state.selectedRow.status === 1)) ? (
              <li>
                <a onClick={this.transformInvoice.bind(this)}>
                  <i className="far fa-handshake" />{" "}
                  <span style={{ paddingLeft: 3 }}>Satışa Dönüştür </span>
                </a>
              </li>
            ) : (
              ""
            )}
            {(dtConfig.name === "proformas" &&
                this.state.selectedRow &&
                (this.state.selectedRow.status === 3|| this.state.selectedRow.status === 2 || this.state.selectedRow.status === 1)) ? (
                <li>
                  <a onClick={this.transformSalesShipment.bind(this)}>
                    <i className="far fa-handshake" />{" "}
                    <span style={{ paddingLeft: 3 }}>İrsaliyeye Dönüştür </span>
                  </a>
                </li>
            ) : (
                ""
            )}
            {(this.state.statusFlag && dtConfig.name === "salesShipment") ||
            (dtConfig.name === "salesShipment" &&
                this.state.selectedRow &&
                this.state.selectedRow.sent === null) ? (
                <li>
                  <a onClick={this.openUpdate.bind(this)}>
                    <i className={"fa fa-edit"} /> Güncelle
                  </a>
                </li>
            ) : (
                ""
            )}
            {dtConfig.name === "salesShipment" && this.state.selectedRow ? (
                <li>
                  <a onClick={this.partialTransformInvoice.bind(this)}>
                    <i className="fa fa-handshake" />{" "}
                    <span style={{ paddingLeft: 3 }}>Kısmi Satışa Dönüştür </span>
                  </a>
                </li>
            ) : (
                ""
            )}
            {dtConfig.name === "salesShipment" && this.state.selectedRow && this.state.selectedRow.sales_invoice_id === 0 ? (
                <li>
                  <a onClick={this.transformInvoice.bind(this)}>
                    <i className="far fa-handshake" />{" "}
                    <span style={{ paddingLeft: 3 }}>Satışa Dönüştür </span>
                  </a>
                </li>
            ) : (
                ""
            )}
            {dtConfig.name === "salesShipment" ? (
                <li>
                  <a onClick={this.transformInvoiceAll.bind(this)}>
                    <i className="far fa-share-square" />{" "}
                    <span style={{ paddingLeft: 3 }}>Toplu Satışa Dönüştür </span>
                  </a>
                </li>
            ) : (
                ""
            )}
            {dtConfig.name === "salesShipment" && this.state.selectedRow ? (
                <li>
                  <a onClick={this.similarSalesShipmentTable.bind(this)}>
                    <i className="fa fa-reply" />{" "}
                    <span style={{ paddingLeft: 3 }}>Benzer İrsaliye Oluştur </span>
                  </a>
                </li>
            ) : (
                ""
            )}
            {dtConfig.name === "salesShipment" && this.state.selectedRow && this.state.selectedRow.sent === null ? (
                <li>
                  <a onClick={this.salesShipmentSendButton.bind(this)}>
                    <i className="far fa-share-square" />{" "}
                    <span style={{ paddingLeft: 3 }}>e-İrsaliye Gönder </span>
                  </a>
                </li>
            ) : (
                ""
            )}
            {(this.state.selectedRow && dtConfig.name === "salesShipment" && this.state.selectedRow.sent === null) ? (
                <li>
                  <a onClick={this.salesShipmentPreview.bind(this)}>
                    <i className={"fa fa-eye"} /> e-İrsaliye Önizle{" "}
                  </a>
                </li>
            ) : (
                ""
            )}
            {dtConfig.name === "salesShipment" &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.sent.pdf ? (
                <li>
                  <a
                      target="_blank"
                      href={
                          "https://earsiv.co/fatura.php?f=Irsaliye/EOutBoxes/" +
                          this.state.selectedRow.sent.pdf
                      }
                  >
                    <i className={"fa fa-eye"} /> PDF Görüntüle
                  </a>
                </li>
            ) : (
                ""
            )}
            {dtConfig.name === "salesShipment" &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.sent.envelope_response_pdf ? (
                <li>
                  <a
                      target="_blank"
                      href={
                          "https://earsiv.co/fatura.php?f=Irsaliye/EOutBoxes/" +
                          this.state.selectedRow.sent.envelope_response_pdf
                      }
                  >
                    <i className={"fa fa-eye"} /> Yanıt PDF Görüntüle
                  </a>
                </li>
            ) : (
                ""
            )}
            {dtConfig.name === "salesShipment" &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.sent.xml ? (
                <li>
                  <a
                      target="_blank"
                      href={
                          "https://earsiv.co/fatura.php?f=Irsaliye/EOutBoxes/" +
                          this.state.selectedRow.sent.xml
                      }
                  >
                    <i className={"fa fa-eye"} /> XML Görüntüle
                  </a>
                </li>
            ) : (
                ""
            )}
            {dtConfig.name === "salesShipment" &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.sent.envelope_response_xml ? (
                <li>
                  <a
                      target="_blank"
                      href={
                          "https://earsiv.co/fatura.php?f=Irsaliye/EOutBoxes/" +
                          this.state.selectedRow.sent.envelope_response_xml
                      }
                  >
                    <i className={"fa fa-eye"} /> Yanıt XML Görüntüle
                  </a>
                </li>
            ) : (
                ""
            )}
            {(dtConfig.name === "proformas" &&
              this.state.selectedRow &&
              this.state.selectedRow.status === 1) ? (
              <li>
                <a onClick={this.acceptStatus.bind(this)}>
                  <i className="fas fa-check"></i> Kabul Et
                </a>
              </li>
            ) : (
              ""
            )}
            {(dtConfig.name === "proformas" &&
              this.state.selectedRow &&
              this.state.selectedRow.status === 1) ? (
              <li>
                <a onClick={this.rejectStatus.bind(this)}>
                  <i className="fas fa-times"></i> Reddet
                </a>
              </li>
            ) : (
              ""
            )}
            {(dtConfig.name === "proformas" &&
              this.state.selectedRow &&
              this.state.selectedRow.status === 4 &&
              this.state.selectedRow.sales_invoice_id !== 0) ? (
              <li>
                <a onClick={this.goToSource.bind(this)}>
                  <i className="fas fa-arrow-right"></i> Faturaya Git
                </a>
              </li>
            ) : (
              ""
            )}
            {(this.state.statusFlag && dtConfig.name === "customer") ||
            (dtConfig.name === "customer" && this.state.selectedRow) ? (
              <li>
                <a onClick={this.accountTransaction.bind(this)}>
                  <i className="fas fa-receipt" /> Hesap Hareketleri
                </a>
              </li>
            ) : (
              ""
            )}
            {dtConfig.name === "warehouses" && this.state.selectedRow ? (
                <li>
                  <a onClick={this.accountTransaction.bind(this)}>
                    <i className="fas fa-receipt" /> Hesap Hareketleri
                  </a>
                </li>
            ) : (
                ""
            )}

            {dtConfig.name === "fixedAsset" && this.state.selectedRow && this.props.fixedAssetAccountTransaction!=false ? (
                <li>
                  <a onClick={this.fixedAssetAccountTransaction.bind(this)}>
                    <i className="fas fa-receipt" /> Hesap Hareketleri
                  </a>
                </li>
            ) : (
                ""
            )}
            {dtConfig.name === "fixedAsset" && this.state.selectedRow && this.props.fixedAssetAccountTransaction!=false ? (
                <li>
                  <a onClick={this.fixedAssetDepreciationStop.bind(this)}>
                    <i className="fas fa-ban" /> Amortisman Atmayı Durdur
                  </a>
                </li>
            ) : (
                ""
            )}

            {(this.state.statusFlag && dtConfig.name === "supplier") ||
            (dtConfig.name === "supplier" && this.state.selectedRow) ? (
              <li>
                <a onClick={this.accountTransaction.bind(this)}>
                  <i className="fas fa-receipt" /> Hesap Hareketleri
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name === "customer") ||
            (dtConfig.name === "customer" && this.state.selectedRow) ? (
              <li>
                <a onClick={this.addCollectionn.bind(this)}>
                  <i className="fas fa-coins i-color3" />{" "}
                  <span style={{ paddingLeft: 3 }}>Tahsilat Ekle</span>
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name === "customer") ? (
                <li>
                  <a onClick={this.showBulkPaymentModalTable.bind(this)}>
                    <i className="fas fa-list i-color3" />{" "}
                    <span style={{ paddingLeft: 3 }}>Toplu Ödeme Talebi Oluştur</span>
                  </a>
                </li>
            ) : (
                ""
            )}

            {(this.state.statusFlag && (dtConfig.name === "customer" || dtConfig.name === "supplier")) ? (
                <li>
                  <a onClick={this.showTradeReconciliationModalTable.bind(this)}>
                    <i className="fas fa-list i-color3" />{" "}
                    <span style={{ paddingLeft: 3 }}>Toplu Mutabakat Gönder</span>
                  </a>
                </li>
            ) : (
                ""
            )}
            {(this.state.selectedRow && (dtConfig.name === "customer" || dtConfig.name === "supplier")) ? (
                <li>
                  <a onClick={this.onTradeReconciliation.bind(this, true)}>
                    <i className="fas fa-list i-color3"/>{" "}
                    <span style={{paddingLeft: 3}}>Mutabakat Gönder / Göster</span>
                  </a>
                  <TradeReconciliationModal
                      showModal={this.state.tradeReconciliationModal}
                      dtConfig={dtConfig}
                      importId={this.state.selectedRow?.id??null}
                      type={dtConfig.name === "customerTransaction" || dtConfig.name === "customer"?0:1}
                      closeModal={() => this.onTradeReconciliation(false)}
                  />
                </li>
            ) : (
                ""
            )}

            {(this.state.selectedRow && (dtConfig.name === "customer" || dtConfig.name === "supplier")) ? (
                <li>
                  <a onClick={this.onImportOpenCloseDetail2.bind(this, true)}>
                    <i className="fas fa-list i-color3"/>{" "}
                    <span style={{paddingLeft: 3}}>Birleştirilmiş Hesap Hareketleri</span>
                  </a>
                  <EkoModal
                      showModal={this.state.importDetailModal2}
                      title="Muavin"
                      bsSize="xlg" // xlg
                      onHide={() => {
                        this.setState({
                          importDetailModal2: false
                        });
                        //this.onCancel();
                      }}
                      spinner={this.state.saving}
                  >
                    <iframe
                        style={{overflow: 'visible'}}
                        ref="iframe"
                        src={apiUrl2+"/rapor/index.php?time=" + time.getTime() + "&rapor_id=27&name=" + dtConfig.name + "Transaction&customer_id=" + this.state.selectedRow.id + "&t=" + localStorage.getItem('token')}
                        scrolling="auto"
                        frameBorder="0"
                        width="100%"
                        height={800}
                    />
                  </EkoModal>
                </li>
            ) : (
                ""
            )}

            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (dtConfig.name === "salesInvoice" &&
              this.state.selectedRow &&
                this.state.selectedRow.customer_id &&
                !this.state.selectedRow.payable_id) ? (
              <li>
                <a onClick={this.addCollectionn.bind(this)}>
                  <i className="fas fa-coins i-color3" />{" "}
                  <span style={{ paddingLeft: 3 }}>Tahsilat Ekle</span>
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (dtConfig.name === "salesInvoice" && this.state.selectedRow) ? (
                <li>
                  <a onClick={this.newTagModal.bind(this)}>
                    <i className="fa fa-circle i-color3" />{" "}
                    <span style={{ paddingLeft: 3 }}>Etiket İşlemleri</span>
                  </a>
                </li>
            ) : (
                ""
            )}

            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (dtConfig.name === "salesInvoice" && this.state.selectedRow) ? (
                <li>
                  <a onClick={this.salesInvoiceCustomDeclarationModal.bind(this)}>
                    <i className="fa fa-book i-color3" />{" "}
                    <span style={{ paddingLeft: 3 }}>Gümrük Beyannamesi İşlemleri</span>
                  </a>
                </li>
            ) : (
                ""
            )}

            {(this.state.statusFlag && dtConfig.name === "supplier") ||
            (dtConfig.name === "supplier" && this.state.selectedRow) ? (
              <li>
                <a onClick={this.addCollectionn.bind(this)}>
                  <i className="icon-credit-card i-color2" />
                  <span style={{ paddingLeft: 3 }}>Ödeme Ekle</span>
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name === "purchaseInvoice") ||
            (dtConfig.name === "purchaseInvoice" &&
              this.state.selectedRow &&
                this.state.selectedRow.supplier_id &&
                !this.state.selectedRow.payable_id) ? (
              <li>
                <a onClick={this.addCollectionn.bind(this)}>
                  <i className="icon-credit-card i-color2" />
                  <span style={{ paddingLeft: 3 }}>Ödeme Ekle</span>
                </a>
              </li>
            ) : (
              ""
            )}
            {(dtConfig.name === "purchaseInvoice" && this.state.selectedRow)? (
                <li>
                  <a href={"/dashboard/salesInvoice/add/toSupplier?sales_return="+this.state.selectedRow.id}>
                    <i className="fa fa-arrow-left" />
                    <span style={{ paddingLeft: 3 }}>İade Faturası Oluştur</span>
                  </a>
                </li>
            ) : (
                ""
            )}
            {(dtConfig.name === "purchaseInvoice" && this.state.selectedRow) ? (
                <li>
                  <a href={"/dashboard/salesInvoice/add/toCustomer?purchase_return="+this.state.selectedRow.id}>
                    <i className="far fa-handshake" />{" "}
                    <span style={{ paddingLeft: 3 }}>Satışa Dönüştür </span>
                  </a>
                </li>
            ) : (
                ""
            )}
            {(dtConfig.name === "purchaseInvoice" && this.state.selectedRow) ? (
                <li>
                  <a href={"/dashboard/salesInvoice/proforma/add?sales_return="+this.state.selectedRow.id}>
                    <i className="far fa-handshake" />{" "}
                    <span style={{ paddingLeft: 3 }}>Teklife Dönüştür </span>
                  </a>
                </li>
            ) : (
                ""
            )}
            {(this.state.statusFlag && dtConfig.name === "cheque") ||
            (dtConfig.name === "cheque" &&
              this.state.selectedRow &&
              this.state.selectedRow.to_id === null &&
              this.state.selectedRow.is_collection === 0) ? (
              <li>
                <a onClick={this.giro.bind(this)}>
                  <i className={"fa fa-share"} /> Çek Cirolama
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name === "cheque") ||
            (dtConfig.name === "cheque" &&
                this.state.selectedRow &&
                this.state.selectedRow.status_cid === 2
            ) ? (
                <li>
                  <a onClick={this.backGiroTable.bind(this)}>
                    <i className={"fa fa-arrow-left"} /> Ciroyu Geri Al
                  </a>
                </li>
            ) : (
                ""
            )}

            {(this.state.statusFlag && dtConfig.name === "cheque") ||
            (dtConfig.name === "cheque" &&
              this.state.selectedRow &&
              this.state.selectedRow.is_collection === 0 &&
              this.state.selectedRow.to_id !== null &&
              this.state.selectedRow.from_id === null) ? (
              <li>
                <a onClick={this.openNewCollection.bind(this)}>
                  <i className={"far fa-money-bill-alt"} />
                  <span style={{ paddingLeft: 3 }}>Ödeme</span>
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name === "cheque") ||
            (dtConfig.name === "cheque" &&
              this.state.selectedRow &&
              this.state.selectedRow.is_collection === 0 &&
              this.state.selectedRow.to_id === null &&
              this.state.selectedRow.from_id !== null) ? (
              <li>
                <a onClick={this.openNewCollection.bind(this)}>
                  <i className={"far fa-money-bill-alt"} />
                  <span style={{ paddingLeft: 3 }}>Tahsilat</span>
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name === "cheque") ||
            (dtConfig.name === "cheque" && this.state.selectedRow) ? (
              <li>
                <a onClick={this.openReceipt.bind(this)}>
                  <i className="fas fa-coins"></i> Makbuz
                </a>
              </li>
            ) : (
              ""
            )}

            {(dtConfig.name === "cheque") ? (
                <li>
                  <a onClick={this.onImportOpenCloseDetail2.bind(this, true)}>
                    <i className="fas fa-list i-color3"/>{" "}
                    <span style={{paddingLeft: 3}}>Toplu Makbuz</span>
                  </a>
                  <EkoModal
                      showModal={this.state.importDetailModal2}
                      title="Toplu Makbuz"
                      bsSize="xlg" // xlg
                      onHide={() => {
                        this.setState({
                          importDetailModal2: false
                        });
                        //this.onCancel();
                      }}
                      spinner={this.state.saving}
                  >
                    <iframe
                        style={{overflow: 'visible'}}
                        ref="iframe"
                        src={apiUrl2+"view_receipt/index.php?time=" + time.getTime() + "&company_id="+JSON.parse(localStorage.getItem("login_user")).company_id}
                        scrolling="auto"
                        frameBorder="0"
                        width="100%"
                        height={800}
                    />
                  </EkoModal>
                </li>
            ) : (
                ""
            )}

            {(this.state.statusFlag && dtConfig.name === "payment") ||
            (dtConfig.name === "payment" &&
              this.state.selectedRow &&
                (this.state.selectedRow.method === 1  || this.state.selectedRow.method === 2 ||this.state.selectedRow.method === 3)) ? (
              <li>
                <a onClick={this.openReceipt.bind(this)}>
                  <i className="fas fa-coins"></i> Makbuz
                </a>
              </li>
            ) : (
              ""
            )}

            {(this.state.statusFlag && dtConfig.name === "loanPaymentPlan") ||
              (this.state.selectedRow &&
              dtConfig.name === "loanPaymentPlan" &&
              parseInt(this.state.selectedRow.is_paid, 10) != 1 ? (
                <li>
                  <a onClick={this.pay}>
                    <i className={"far fa-money-bill-alt"} /> Ödeme Yap
                  </a>
                </li>
              ) : (
                ""
              ))}
            {(this.state.statusFlag && dtConfig.name === "collection") ||
            (dtConfig.name === "collection" &&
              this.state.selectedRow &&
                (this.state.selectedRow.method === 1  || this.state.selectedRow.method === 2 ||this.state.selectedRow.method === 3 ||this.state.selectedRow.method === 4)) ? (
              <li>
                <a onClick={this.openReceipt.bind(this)}>
                  <i className="fas fa-coins"></i> Makbuz
                </a>
              </li>
            ) : (
              ""
            )}
            {(this.state.statusFlag && dtConfig.name === "collection") ||
            (dtConfig.name === "collection" &&  this.state.selectedRow && this.state.selectedRow.type===1) ? (
                <li>
                  <a onClick={this.transformCollectionInvoice.bind(this)}>
                    <i className="fas fa-list"></i> Faturaya Dönüştür
                  </a>
                </li>
            ) : (
                ""
            )}

            {(this.state.statusFlag &&
              (dtConfig.name === "customer" ||
                dtConfig.name === "item" ||
                dtConfig.name === "supplier")) ||
            dtConfig.name === "customer" ||
            dtConfig.name === "item" ||
            (dtConfig.name === "supplier" && this.state.selectedRow) ? (
              <li>
                <a onClick={this.passiveActive.bind(this)}>
                  <i className="fas fa-toggle-on" />{" "}
                  {this.state.selectedRow &&
                  this.state.selectedRow.is_active === 1
                    ? " Pasifleştir "
                    : " Aktifleştir "}
                </a>
              </li>
            ) : (
              ""
            )}

            {dtConfig.name === "purchaseInvoice" &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.sent.pdf &&
            this.state.selectedRow.is_exempt !== 1 ? (
              <li>
                <a
                  target="_blank"
                  href={
                    "https://earsiv.co/fatura.php?f=EFatura/EInBoxes/" +
                    this.state.selectedRow.sent.pdf
                  }
                >
                  <i className={"fa fa-eye"} /> PDF Görüntüle
                </a>
              </li>
            ) : (
              ""
            )}
            {dtConfig.name === "purchaseInvoice" &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.sent.xml &&
            this.state.selectedRow.is_exempt !== 1 ? (
                <li>
                  <a
                      target="_blank"
                      href={
                          "https://earsiv.co/fatura.php?f=EFatura/EInBoxes/" +
                          this.state.selectedRow.sent.xml
                      }
                  >
                    <i className={"fa fa-eye"} /> XML Görüntüle
                  </a>
                </li>
            ) : (
                ""
            )}
            {dtConfig.name === "salesInvoice" &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.sent.pdf &&
            this.state.selectedRow.is_exempt !== 1 ? (
              <li>
                <a
                  target="_blank"
                  href={
                    "https://earsiv.co/fatura.php?f=EFatura/EOutBoxes/" +
                    this.state.selectedRow.sent.pdf
                  }
                >
                  <i className={"fa fa-eye"} /> PDF Görüntüle
                </a>
              </li>
            ) : (
              ""
            )}
            {dtConfig.name === "salesInvoice" &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.sent.xml &&
            this.state.selectedRow.is_exempt !== 1 ? (
                <li>
                  <a
                      target="_blank"
                      href={
                          apiUrl + "/salesInvoiceGetXMLForIntegrator/" +
                          this.state.selectedRow.sent.uuid+"/"+JSON.parse(localStorage.getItem("login_user")).company_id+".xml"
                      }
                  >
                    <i className={"fa fa-eye"} /> XML Görüntüle
                  </a>
                </li>
            ) : (
                ""
            )}
            {(this.state.statusFlag && dtConfig.name === "salesInvoice") ||
            (dtConfig.name === "salesInvoice" &&
              this.state.selectedRow &&
              this.state.selectedRow.sent &&
              this.state.selectedRow.sent.pdf &&
              this.state.selectedRow.einvoice_type === 3 &&
              this.state.selectedRow.is_exempt !== 1 &&
              this.state.selectedRow.sent.status !== 9) ? (
              <li>
                <a onClick={this.openArchiveCancel.bind(this)}>
                  <i className="fa fa-ban" />
                  &nbsp;e-Arşiv İptal
                </a>
              </li>
            ) : (
              ""
            )}
            {/*dtConfig.name === "item" && this.state.selectedRow ? (
              <li>
                <a onClick={this.openChangingModal.bind(this)}>
                  <i className="fa fa-sitemap"></i> Değişenler Ekle
                </a>
              </li>
            ) : (
              ""
            )*/}
            {dtConfig.name === "production-planing" &&
            this.state.selectedRow &&
            this.state.selectedRow.status.toLowerCase() !== "üretildi"
                ? (
                    <li>
                      <a onClick={this.setProduced.bind(this)}>
                        <i class="fa fa-check"></i> Üretildi
                      </a>
                    </li>
                ) : (
                    ""
                )}
            {dtConfig.name === "warehouses" && this.state.selectedRow ? (
                <li>
                  <a onClick={this.setMain.bind(this)}>
                    <i className="fa fa-sitemap"></i> Ana Depo Seç
                  </a>
                </li>
            ) : (
                ""
            )}
            {dtConfig.name === "warehouses" && this.state.selectedRow ? (
                <li>
                  <a href={"/dashboard/warehouses/products/"+this.state.selectedRow.id}>
                    <i className="fa fa-list"></i> Depodaki Ürünler
                  </a>
                </li>
            ) : (
                ""
            )}
            {(this.state.statusFlag && dtConfig.name === "item") ||
            (dtConfig.name === "item" && this.state.selectedRow) ? (
              <li>
                <a onClick={this.itemTransaction.bind(this)}>
                  <i className="fas fa-receipt" /> Hesap Hareketleri
                </a>
              </li>
            ) : (
              ""
            )}
            {dtConfig.name === "salesInvoice" &&
            this.state.selectedRow &&
            this.state.selectedRow.sent &&
            this.state.selectedRow.is_exempt !== 1 ? (
                <li>
                  <a onClick={this.canceledEInvoiceSales.bind(this)}>
                    <i className={"fa fa-ban"} /> e-Fatura Red Onayı
                  </a>
                </li>
            ) : (
                ""
            )}
          </ul>
        </div>
        <div>
          {this.state.selectedRow && this.state.selectedRow.error_message ? (
            <div>
              <a data-tip={this.state.selectedRow.error_message}>
                <div>
                  <button
                    style={{ marginLeft: "10px" }}
                    type="button"
                    className="btn btn-danger btn-sm"
                  >
                    HATAYI GÖRÜNTÜLE
                  </button>
                </div>
              </a>
              <ReactTooltip
                place="top"
                type="error"
                effect="solid"
                multiline={true}
              >
                {this.state.selectedRow.error_message
                  .split(". ")
                  .map((el, i) => (
                    <p key={i}>{el}</p>
                  ))}
              </ReactTooltip>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  openChangingModal() {
    if (this.props.openChangingModal) {
      this.props.openChangingModal(this.state.selectedRow);
    }
  }

  itemTransaction() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz.");
    } else {
      if (this.props.itemTransaction) {
        this.props.itemTransaction(this.state.selectedRow);
      }
    }
  }

  openDetail() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.openDetail) {
        this.props.openDetail(this.state.selectedRow);
      }
    }
  }

  openCopied() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.openCopied) {
        this.props.openCopied(this.state.selectedRow);
      }
    }
  }

  openSelfPrint(props) {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.openSelfPrint) {
        this.props.openSelfPrint(this.state, props);
      }
    }
  }

  sendButton(props) {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.sendButton) {
        this.props.sendButton(this.state, props);
      }
    }
  }

  salesShipmentSendButton(props) {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.salesShipmentSendButton) {
        this.props.salesShipmentSendButton(this.state, props);
      }
    }
  }
  similarSalesShipmentTable(props) {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.similarSalesShipment) {
        this.props.similarSalesShipment(this.state, props);
      }
    }
  }

  soleprofSendButton(props) {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.soleprofSendButton) {
        this.props.soleprofSendButton(this.state, props);
      }
    }
  }

  eArchivesendButton(props) {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.eArchivesendButton) {
        this.props.eArchivesendButton(this.state, props);
      }
    }
  }

  eInvoicePreview() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.previewButton) {
        this.props.previewButton(this.state.selectedRow);
      }
    }
  }
  salesShipmentPreview() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.previewButton) {
        this.props.previewButton(this.state.selectedRow);
      }
    }
  }

  pay = () => {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.payButton) {
        this.props.payButton(this.state);
      }
    }
  };
  payCancel = () => {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet ödenmiş kayıt seçmelisiniz");
    } else {
      toastr.confirm(`Bu ödeme kaydınız iptal etmek istediğinize emin misiniz?`, {
        onOk: () => {
          this.props.payCancelButton(this.state);
        },
        onCancel: () => {
          if (this.dataTableRef) {
            this.dataTableRef.reset();
          }
        },
        okText: "Evet",
        cancelText: "Hayır"
      });
    }
  };
  askCollection() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.askCollection) {
        this.props.askCollection(this.state.selectedRow);
      }
    }
  }

  showMethod() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.showMethod) {
        this.props.showMethod(this.state.selectedRow);
      }
    }
  }

  eArchiveShowButton() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.eArchiveShowButton) {
        this.props.eArchiveShowButton(this.state.selectedRow);
      }
    }
  }

  transformInvoice() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.transformInvoice) {
        this.props.transformInvoice(this.state.selectedRow);
      }
    }
  }
  transformSalesShipment() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.transformSalesShipment) {
        this.props.transformSalesShipment(this.state.selectedRow);
      }
    }
  }
  transformCollectionInvoice() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.transformCollectionInvoice) {
        this.props.transformCollectionInvoice(this.state.selectedRow);
      }
    }
  }
  partialTransformInvoice() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.partialTransformInvoice) {
        this.props.partialTransformInvoice(this.state.selectedRow);
      }
    }
  }

  partialTransformProforma() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.partialTransformProforma) {
        this.props.partialTransformProforma(this.state.selectedRow);
      }
    }
  }

  transformInvoiceAll() {
    this.props.transformInvoiceAll();
  }
  transformInvoiceAllProforma() {
    this.props.transformInvoiceAllProforma();
  }

  acceptStatus() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.acceptStatus) {
        this.props.acceptStatus(this.state.selectedRow);
      }
    }
  }

  rejectStatus() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.rejectStatus) {
        this.props.rejectStatus(this.state.selectedRow);
      }
    }
  }

  goToSource() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.goToSource) {
        this.props.goToSource(this.state.selectedRow);
      }
    }
  }

  openUpdate() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.openUpdate) {
        this.props.openUpdate(this.state.selectedRow);
      }
    }
  }

  accountTransaction() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.accountTransaction) {
        this.props.accountTransaction(this.state.selectedRow);
      }
    }
  }

  fixedAssetAccountTransaction() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.fixedAssetAccountTransaction) {
        this.props.fixedAssetAccountTransaction(this.state.selectedRow);
      }
    }
  }

  fixedAssetDepreciationStop() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.fixedAssetDepreciationStop) {
        this.props.fixedAssetDepreciationStop(this.state.selectedRow);
      }
    }
  }

  addCollectionn() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.addCollectionn) {
        this.props.addCollectionn(this.state.selectedRow);
      }
    }
  }
  newTagModal() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.newTagModal) {
        this.props.newTagModal(this.state.selectedRow);
      }
    }
  }

  salesInvoiceCustomDeclarationModal() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.salesInvoiceCustomDeclarationModal) {
        this.props.salesInvoiceCustomDeclarationModal(this.state.selectedRow);
      }
    }
  }
  showBulkPaymentModalTable() {
    if (this.props.showBulkPaymentModal) {
      this.props.showBulkPaymentModal(this.state.selectedRow);
    }
  }

  showTradeReconciliationModalTable() {
    if (this.props.showTradeReconciliationModal) {
      this.props.showTradeReconciliationModal(this.state.selectedRow);
    }
  }

  giro() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.giro) {
        this.props.giro(this.state.selectedRow);
      }
    }
  }
  backGiroTable() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.backGiro) {
        this.props.backGiro(this.state.selectedRow);
      }
    }
  }

  openNewCollection() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.openNewCollection) {
        this.props.openNewCollection(this.state.selectedRow);
      }
    }
  }

  openReceipt() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.openReceipt) {
        this.props.openReceipt(this.state.selectedRow);
      }
    }
  }

  createCustomSearchField(props) {
    return (
      <SearchField
        className="input-sm input-small input-inline mresearchbox search-filter"
        defaultValue={props.defaultSearch}
        placeholder="Ara ..."
      />
    );
  }

  createCustomRowButtons(props) {
    if (this.props.createCustomRowButtons) {
      return this.props.createCustomRowButtons(this.state, props);
    } else return "";
  }

  openArchiveCancel(props) {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.openArchiveCancel) {
        return this.props.openArchiveCancel(this.state, props);
      } else {
        return "";
      }
    }
  }

  openSMMCancel(props) {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.openSMMCancel) {
        return this.props.openSMMCancel(this.state, props);
      } else {
        return "";
      }
    }
  }

  passiveActive(props) {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.passiveActive) {
        return this.props.passiveActive(this.state, props);
      } else return "";
    }
  }

  createTabMenu(props) {
    let selectedTab = this.props.selectedTab;
    if (this.props.tabMenu) {
      let tabMenus = [];
      this.props.tabMenu.forEach(function(menuItem) {
        let className = "caption-subject bold";
        if (menuItem.id === selectedTab) className += " active";
        tabMenus.push(
          <li key={menuItem.id} className={className}>
            <Link to={menuItem.link}>{menuItem.title}</Link>
          </li>
        );
      });

      const menu = tabMenus.map(menuItem => menuItem);
      return <nav>{menu}</nav>;
    } else return "";
  }

  createCustomToolBar(props) {
    let addButton = this.props.insertMenu ? (
      this.initMultipleInsertButton(this.props.insertMenu)
    ) : (
      <button
        className="btn btn-default blue btn-sm"
        onClick={this.props.openInsert}
      >
        <i className="fa fa-plus" />
        &nbsp; Ekle
      </button>
    );
    addButton = this.props.addButtonShow ? addButton : "";

    const dtConfig = Util.filterDtConfig(this.props.dtConfig, this.props.name);

    return (
      <div className="row">
        <div className="col-md-6">
          {this.props.hideSelectButtons ? (
            ""
          ) : (
            <span>
              {props.components.deleteBtn}
              {/* {this.state.isSelected && this.props.addUpdateShow ?
                                <button className="btn blue tablebuttons btn-sm" onClick={this.openUpdate.bind(this)}>
                                <span>
                                  <i className="fa fa-edit"></i> Güncelle
                                </span></button>
                                : ''} */}
            </span>
          )}

          {this.createCustomRowButtons(this, props)}
        </div>
        <div className="col-md-6">
          <span className="pull-right">
            <div style={{float: "left", marginRight: "5px"}}>
              {dtConfig.name==="salesInvoice" ?
                  <select
                      onChange={e => { this.onInvoiceStatusChange(e.target.value) }}
                      className="form-control" style={{"height":"31px","color":"#555","fontWeight":"600","fontSize":"12px"}}>
                    <option value={0}>FATURA DURUMU SEÇİN</option>
                    <option value={1}>Entegratöre Gönderildi</option>
                    <option value={2}>Entegratöre İletildi</option>
                    <option value={3}>Hatalı Fatura</option>
                    <option value={4}>Yanıt Bekleniyor</option>
                    <option value={5}>Kabul Edildi</option>
                    <option value={6}>Alıcı Reddetti</option>
                    <option value={8}>Gönderim Aşamasında</option>
                    <option value={9}>E-Arşiv Gönderildi</option>
                    <option value={10}>İptal Ettiniz</option>
                    <option value={11}>Gönderime Hazır</option>
                  </select>
              :""}
              </div>
            <div style={{float: "left", marginRight: "5px"}}>
              {props.components.searchPanel}
            </div>
              {this.initHeaderFilter()}
              &nbsp;
              {addButton}
              {/*<button className="btn btn-default blue" onClick={this.openInsert.bind(this) }>Ekle</button>*/}
          </span>
        </div>
      </div>
  );
  }

  getLocalConf(name) {
    let localConf = localStorage.getItem("dtConfig_" + name);
    if (localConf) {
      localConf = JSON.parse(localConf);
    } else {
      localConf = {};
    }
    return localConf;
  }

  setLocalConf(localConf) {
    localStorage.setItem(
      "dtConfig_" + this.props.name,
      JSON.stringify(localConf)
    );
  }

  onHeaderFilterChecked(checkId) {
    let visibleHeaders = Sugar.Array.filter(this.state.headers, function(obj) {
      return obj.status === 1;
    });
    let headers = this.state.headers;

    headers.forEach(header => {
      if (header.id === checkId) {
        if (visibleHeaders.length === 1 && header.status === 1) {
          window.alert("En az bir kolon görünmek zorunda");
          return;
        }
        header.status = header.status === 1 ? 0 : 1;
      }
    });
    this.setState({ headers: headers });
    let localConf = this.getLocalConf(this.props.name);
    localConf.headers = headers;
    this.setLocalConf(localConf);
  }

  onHeaderFilterClicked() {
    if (!this.state.filterOpen) {
      this.setState({ filterOpen: true, filterBtnGroup: "btn-group open" });
      document.addEventListener("click", this.handleOutsideClickTitle, false);
    } else {
      this.setState({ filterOpen: false, filterBtnGroup: "btn-group" });
      document.removeEventListener(
        "click",
        this.handleOutsideClickTitle,
        false
      );
    }
  }

  onHeaderEximClickedTransaction() {
    if (!this.state.eximOpenAll) {
      // attach/remove event handler
      this.setState({ eximOpenAll: true, eximBtnGroupAll: "btn-group open" });
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      this.setState({ eximOpenAll: false, eximBtnGroupAll: "btn-group" });
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
  }

  handleOutsideClick = e => {
    // ignore clicks on the component itself
    if (this.node !== null) {
      if (this.node.contains(e.target)) {
        document.removeEventListener("click", this.handleOutsideClick, false);
        this.setState({ eximOpenAll: false, eximBtnGroupAll: "btn-group" });
      } else {
        if (this.state.eximOpenAll) {
          this.setState({ eximOpenAll: false, eximBtnGroupAll: "btn-group" });
          document.removeEventListener("click", this.handleOutsideClick, false);
        }
      }
    }
  };

  handleOutsideClickTitle = e => {
    // ignore clicks on the component itself
    if (this.node2.contains(e.target)) {
      document.removeEventListener(
        "click",
        this.handleOutsideClickTitle,
        false
      );
      this.setState({ filterOpen: false, filterBtnGroup: "btn-group" });
      return null;
    } else {
      if (this.state.filterOpen) {
        this.setState({ filterOpen: false, filterBtnGroup: "btn-group" });
        document.removeEventListener(
          "click",
          this.handleOutsideClickTitle,
          false
        );
      }
    }
  };

  handleOutsideClickTransfer = e => {
    // ignore clicks on the component itself
    if (this.node3.contains(e.target)) {
      document.removeEventListener(
        "click",
        this.handleOutsideClickTransfer,
        false
      );
      this.setState({ eximOpen: false, eximBtnGroup: "btn-group" });
      return null;
    } else {
      if (this.state.eximOpen) {
        this.setState({ eximOpen: false, eximBtnGroup: "btn-group" });
        document.removeEventListener(
          "click",
          this.handleOutsideClickTransfer,
          false
        );
      }
    }
  };

  onHeaderEximClicked() {
    if (!this.state.eximOpen) {
      this.setState({ eximOpen: true, eximBtnGroup: "btn-group open" });
      document.addEventListener(
        "click",
        this.handleOutsideClickTransfer,
        false
      );
    } else {
      this.setState({ eximOpen: false, eximBtnGroup: "btn-group" });
      document.removeEventListener(
        "click",
        this.handleOutsideClickTransfer,
        false
      );
    }
  }

  renderPaginationPanel = props => {
    return (
      <div>
        <div className="table-list-item">
          {props.components.sizePerPageDropdown}
        </div>
        <div className="table-list-paging">{props.components.pageList}</div>
      </div>
    );
  };

  initHeaderFilter() {
    var apiUrl = process.env.REACT_APP_API_URL;
    apiUrl = apiUrl.substring(0, apiUrl.length-3);
    const time = new Date();
    //localStorage da son halini sakla
    if (!this.state.headers || this.state.headers.length < 4) return;
    const headers = this.state.headers;
    const dtConfig = Util.filterDtConfig(this.props.dtConfig, this.props.name);
    //  const options = this.props.options.filter( o => o.column_name !== this.props.keyOption)
    return (
      <span>
        <div
          className={this.state.filterBtnGroup}
          ref={node2 => {
            this.node2 = node2;
          }}
        >
          <button
            onClick={this.onHeaderFilterClicked.bind(this)}
            type="button"
            className="btn white dropdown-toggle btn-sm"
            data-toggle="dropdown"
          >
            Başlıklar
            <i className="fa fa-angle-down" />
          </button>
          <div
            className="dropdown-menu hold-on-click dropdown-checkboxes"
            role="menu"
          >
            {headers.map(header => {
              return (
                <label key={header.id}>
                  <input
                    onChange={this.onHeaderFilterChecked.bind(this, header.id)}
                    type="checkbox"
                    data-column={1}
                    checked={header.status === 1 ? true : false}
                  />{" "}
                  {header.header_name}
                </label>
              );
            })}
          </div>
        </div>
        {
          this.props.name === "cheque" ?
              <button
                  style={{marginLeft: "5px"}}
                  onClick={this.modal.bind(this, true)}
                  type="button"
                  className="btn white dropdown-toggle btn-sm">
                Rapor Al
              </button>
              :""
        }
        {dtConfig.name === "customerTransaction" ||
        dtConfig.name === "supplierTransaction" ? (
            <div style={{marginLeft: "5px",marginRight: "5px",float:"inline-start"}}>
              <button

                  onClick={this.onImportOpenCloseDetail2.bind(this, true)}
                  type="button"
                  className="btn white dropdown-toggle btn-sm">
                Muavin
              </button>
              <EkoModal
                  showModal={this.state.importDetailModal2}
                  title="Muavin"
                  bsSize="xlg" // xlg
                  onHide={() => {
                    console.log("xxxx");
                    this.setState({
                      importDetailModal2: false
                    });
                    //this.onCancel();
                  }}
                  spinner={this.state.saving}
              >
                <iframe
                    style={{overflow: 'visible'}}
                    ref="iframe"
                    src={apiUrl + "rapor/index.php?time=" + time.getTime() + "&rapor_id=27&name=" + dtConfig.name + "&customer_id=" + this.props.exportImportId + "&t=" + localStorage.getItem('token')}
                    scrolling="auto"
                    frameBorder="0"
                    width="100%"
                    height={800}
                />
              </EkoModal>
            </div>
        ) : (
            ""
        )}
        {this.props.hideExportMenu !== true &&
        (parseInt(dtConfig.has_import, 10) !== 0 ||
            parseInt(dtConfig.has_export, 10) !== 0) ? (
            <div
                className={this.state.eximBtnGroup}
                ref={node3 => {
                  this.node3 = node3;
                }}
            >
              <button
                  style={{marginLeft: "5px"}}
                  onClick={this.onHeaderEximClicked.bind(this)}
                  type="button"
                  className="btn white dropdown-toggle btn-sm"
                  data-toggle="dropdown"
              >
                Excele Aktar
                <i className="fa fa-angle-down"/>
              </button>
              <ul className="dropdown-menu excel_export">
                {parseInt(dtConfig.has_import, 10) !== 0 ? (
                    <li>
                      <a onClick={this.onImportOpenClose.bind(this, true)}>
                        <i className={"fa fa-sign-in"}/> İçe Aktar
                      </a>
                      <ImportModal
                          showModal={this.state.importModal}
                          dtConfig={dtConfig}
                          importId={this.props.exportImportId}
                    closeModal={() => this.onImportOpenClose(false)}
                    onFetch={this.props.onFetch ? this.props.onFetch : null}
                  />
                </li>
              ) : (
                ""
              )}
              {parseInt(dtConfig.has_export, 10) !== 0 ? (
                <li>
                  <a onClick={this.onExportClicked.bind(this)}>
                    {this.state.downloadExcel ? (
                      <i className={"fa fa-spinner fa-pulse fa-1x fa-fw"} />
                    ) : (
                      <span>
                        <i className="fa fa-sign-out" /> Dışa Aktar
                      </span>
                    )}
                  </a>
                </li>
              ) : (
                ""
              )}
              {(dtConfig.name === "customer" ||
                  dtConfig.name === "supplier") && parseInt(dtConfig.has_export, 10) !== 0 ? (
                  <li>
                    <a onClick={this.onImportOpenCloseDetail3.bind(this, true)}>
                      {this.state.downloadExcel ? (
                          <i className={"fa fa-spinner fa-pulse fa-1x fa-fw"} />
                      ) : (
                          <span>
                        <i className="fa fa-sign-out" /> Toplu Bakiye
                      </span>
                      )}
                    </a>
                    <ImportDetailModal3
                        showModal={this.state.importDetailModal3}
                        dtConfig={dtConfig}
                        importId={this.props.exportImportId}
                        closeModal={() => this.onImportOpenCloseDetail3(false)}
                    />
                  </li>
              ) : (
                  ""
              )}
              {dtConfig.name === "customerTransaction" ||
              dtConfig.name === "supplierTransaction" ? (
                <li>
                  <a onClick={this.onImportOpenCloseDetail.bind(this, true)}>
                    <span>
                      <i className="fa fa-sign-out" /> Detaylı Hesap Hareketleri
                    </span>
                  </a>

                  <ImportDetailModal
                    showModal={this.state.importDetailModal}
                    dtConfig={dtConfig}
                    importId={this.props.exportImportId}
                    closeModal={() => this.onImportOpenCloseDetail(false)}
                  />
                </li>
              ) : (
                ""
              )}
              {dtConfig.name === "customerTransaction" ||
              dtConfig.name === "supplierTransaction" ? (
                  <li>
                    <a onClick={this.onTradeReconciliation.bind(this, true)}>
                    <span>
                      <i className="fa fa-sign-out" /> Mutabakat
                    </span>
                    </a>

                    <TradeReconciliationModal
                        showModal={this.state.tradeReconciliationModal}
                        dtConfig={dtConfig}
                        importId={this.props.exportImportId}
                        type={dtConfig.name === "customerTransaction"?0:1}
                        closeModal={() => this.onTradeReconciliation(false)}
                    />
                  </li>
              ) : (
                  ""
              )}
            </ul>
          </div>
        ) : (
          ""
        )}
      </span>
    );
  }

  onImportOpenClose(value) {
    this.setState({ importModal: value });
  }

  onImportOpenCloseDetail(value) {
    this.setState({ importDetailModal: value });
  }
  onTradeReconciliation(value) {
    this.setState({ tradeReconciliationModal: value });
  }
  onImportOpenCloseDetail2(value) {
    this.setState({ importDetailModal2: value });
  }
  onImportOpenCloseDetail3(value) {
    this.setState({ importDetailModal3: value });
  }
  onExportAggregateClicked() {
    const self = this;
    const dtConfig = Util.filterDtConfig(this.props.dtConfig, this.props.name);
    self.setState({ downloadExcel: true });
    let params = {};
    if (this.props.exportImportId)
      params = {
        id: this.props.exportImportId,
        date1: this.state.date1,
        date2: this.state.date2
      };
    else if (this.state.date1 || this.state.date2) {
      params = {
        date1: this.state.date1,
        date2: this.state.date2
      };
    }
    Api.postArrayBuffer(
        "/" + dtConfig.name + "/customerAggregateBalance",
        params,
        (response, err) => {
          if (response) {
            // const reader = new FileReader();
            let blob = new Blob([response], {
              type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
            // reader.readAsDataURL(blob);
            let downloadUrl = URL.createObjectURL(blob);
            let a = document.createElement("a");
            moment.locale("tr");
            a.href = downloadUrl;
            a.download =
                dtConfig.page_title +
                " " +
                moment().format("DD-MM-YYYY HH_mm_ss") +
                ".xlsx";
            document.body.appendChild(a);
            a.click();
            self.setState({ downloadExcel: false });
          } else {
            self.setState({ downloadExcel: false });
            toastr.error("Hata", err);
          }
        }
    );
  }
  onExportClicked() {
    const self = this;
    const dtConfig = Util.filterDtConfig(this.props.dtConfig, this.props.name);
    self.setState({ downloadExcel: true });
    let params = {};
    if (this.props.exportImportId)
      params = {
        id: this.props.exportImportId,
        date1: this.state.date1,
        date2: this.state.date2
      };
    else if (this.state.date1 || this.state.date2) {
      params = {
        date1: this.state.date1,
        date2: this.state.date2
      };
    }
    Api.postArrayBuffer(
      "/" + dtConfig.name + "/excel/export",
      params,
      (response, err) => {
        if (response) {
          // const reader = new FileReader();
          let blob = new Blob([response], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
          // reader.readAsDataURL(blob);
          let downloadUrl = URL.createObjectURL(blob);
          let a = document.createElement("a");
          moment.locale("tr");
          a.href = downloadUrl;
          a.download =
            dtConfig.page_title +
            " " +
            moment().format("DD-MM-YYYY HH_mm_ss") +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          self.setState({ downloadExcel: false });
        } else {
          self.setState({ downloadExcel: false });
          toastr.error("Hata", err);
        }
      }
    );
  }

  intDatePickers() {
    const dtConfig = Util.filterDtConfig(this.props.dtConfig, this.props.name);

    if (!dtConfig) {
      return "";
    }

    if (dtConfig.date_filter_column !== "") {
      const datePicker1 = (
        <EkoDatePicker
          onChange={this.onDateChange}
          name="date1"
          dateFormat="DD-MM-YYYY"
          placeholder="Başlangıç Tarihi"
          value={this.state.date1}
          showClearButton={true}
        />
      );

      const datePicker2 = (
        <EkoDatePicker
          onChange={this.onDateChange}
          name="date2"
          dateFormat="DD-MM-YYYY"
          placeholder="Bitiş Tarihi"
          value={this.state.date2}
          showClearButton={true}
        />
      );

      return (
        <table>
          <tbody>
            <tr>
              <td></td>
              <td>
                <div className="input-icon right">{datePicker1}</div>
              </td>
              <td>&nbsp;&nbsp;&nbsp;</td>
              <td>
                <div className="input-icon right">{datePicker2}</div>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  }

  handleRowSelect(row, isSelected) {
    this.setState({
      isSelected: isSelected,
      selectedRow: row,
      isView: !this.state.isView
    });

    if (isSelected) {
      this.setState({ statusFlag: false });
    }
    if (!isSelected) {
      this.setState({ statusFlag: true, selectedRow: undefined });
    }

    if (isSelected && this.props.onSelectedRowChange) {
      this.props.onSelectedRowChange(row);
    }
  }

  cleanSelect = () => {
    if (this.dataTableRef) {
      this.dataTableRef.reset();
    }
    this.setState({ isSelected: false, selectedRow: {} });
  };

  setNoDataText() {
    if (!this.props.loading && this.state.isDataFetched) {
      return this.props.notFoundText || "Gösterilecek bir kayıt bulunmuyor.";
    }

    return <EkoSpinner />;
  }

  customSort(a, b, order, key) {
    let dataA = a[key];
    let dataB = b[key];
    // order is desc or asc
    if (/[#$₺€£]/g.test(dataA)) {
      //tutar ise
      dataA = parseFloat(dataA.replace(/[#.$₺€£]/g, "").replace(",", "."));
      dataB = parseFloat(dataB.replace(/[#.$₺€£]/g, "").replace(",", "."));

      if (order === "desc") {
        return dataB - dataA;
      } else {
        return dataA - dataB;
      }
    } else {
      if (dataA.includes("-") && dataA.length === 10) {
        // tarih ise
        let dateA = moment(dataA)._f
          ? moment(dataA)
          : moment(dataA, "DD-MM-YYYY");
        if (dateA._isValid && dateA._f !== "YYYY-MM-DD") {
          dataA = moment(dataA, "DD-MM-YYYY").format("YYYY-MM-DD");
          dataB = moment(dataB, "DD-MM-YYYY").format("YYYY-MM-DD");
        }
      }
      if (order === "desc") {
        return dataA.localeCompare(dataB, "tr");
      } else {
        return dataB.localeCompare(dataA, "tr");
      }
    }
  }


  dbsShowButton(cell, row) {
    return (
        <div>
          <button
              onClick={this.props.openModal}
              type="button"
              className="btn blue dropdown-toggle btn-sm"
              data-toggle="dropdown"
          >
            <i className="fa fa-eye" />
          </button>
        </div>
    );
  }
   gelirTuruFormatter(cell, row) {
     if(this.state.gelirTuru.length < 10){
       this.state.gelirTuru.push({id: row.id, val: row.dbsParameterSelectedVal.satisTuruKodu});
     }
     let obj = this.state.gelirTuru.findIndex(o => o.id == row.id);
    return (
        <div>
          <select className="form-control" value={this.state.gelirTuru[obj].val} onChange={(e) => this.dbsSelectChange(row,e.target.value,'gelirTuru',obj)}  /* onChange={(e) => this.setState({ gelirTuru[obj].id: e.target.value})} */ >
            {row.dbsParameter.satisTuruKodu.map((e, key) => {
              return <option key={e.Kod} value={e.Kod}>{e.Aciklama}</option>;
            })}
          </select>
        </div>
    );
  }

  gelirKayitTuruFormatter(cell, row) {
    this.state.gelirKayitTuru.push({id: row.id, val: row.dbsParameterSelectedVal.satisTuruKodu});
    return (
        <div>
          <select className="form-control" id="twoSelect" value={row.dbsParameterSelectedVal.gelirKayitTuruKodu}>
            {row.dbsParameter.gelirKayitTuruKodu.map((e, key) => {
              return <option key={e.Kod} value={e.Kod}>{e.Aciklama}</option>;
            })}
          </select>
        </div>
    );
  }

  gelirKayitAltTuruFormatter(cell, row) {
    this.state.gelirKayitAltTuru.push({id: row.id, val: row.dbsParameterSelectedVal.satisTuruKodu});
    return (
        <div>
          <select className="form-control" id="threeSelect" value={row.dbsParameterSelectedVal.gelirKayitAltTuruKodu}>
            {row.dbsParameter.gelirKayitAltTuruKodu.map((e, key) => {
              return <option key={e.Kod} value={e.Kod}>{e.Aciklama}</option>;
            })}
          </select>
        </div>
    );
  }
  addRecipe() {
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.addRecipe) {
        this.props.addRecipe(this.state.selectedRow);
      }
    }
  }
  setMain(){
    if (!this.state.isSelected) {
      toastr.error("İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz");
    } else {
      if (this.props.setMain) {
        this.props.setMain(this.state.selectedRow);
      }
    }
  }
  setProduced(){
    if(!this.state.isSelected){
      toastr.error("Hata!",'İşlem yapabilmeniz için 1 adet kayıt seçmelisiniz!');
    }else{
      if(this.props.setProduced){
        this.props.setProduced(this.state.selectedRow);
      }else{
        toastr.error("Hata!",'Bir hata oluştu! Lütfen daha sonra tekrar deneyiniz!')
      }
    }
  }
  render() {
    const dtConfig = Util.filterDtConfig(this.props.dtConfig, this.props.name);

    const selectRow = this.props.selectRow
      ? {
          mode: "radio",
          clickToSelect: true,
          bgColor: "rgb(238, 193, 213)",
          onSelect: this.handleRowSelect.bind(this)
        }
      : {};

    let options = {
      noDataText: this.setNoDataText(),
      paginationPanel: this.renderPaginationPanel,
      deleteBtn: this.createCustomNewButtons.bind(this),
      toolBar: this.createCustomToolBar.bind(this),
      searchField: this.createCustomSearchField.bind(this)
      /*
                 insertModalHeader: this.createCustomModalHeader,
                 insertModalBody: this.createCustomModalBody,
                 insertModalFooter: this.createCustomModalFooter,

                 */
    };

    if (this.props.options) {
      options = Sugar.Object.merge(options, this.props.options);
    }

    let headers = Sugar.Array.filter(this.state.headers, function(obj) {
      return obj.status === 1;
    });

    if (headers.length === 0 && this.state.headers.length > 0) {
      //en az bir tane goruneli
      headers.push(Sugar.Array.first(this.state.headers));
    }

    if (this.props.hiddenColumns) {
      headers = [...headers, ...this.props.hiddenColumns];
    }

    console.log("headers",headers);

    let data;

    if (
      this.props.name === "soleprofSalesInvoice" &&
      this.state.filteredItems[0] &&
      this.state.filteredItems[0].tax_identity_number
    ) {
      data = [];
      options.noDataText = <EkoSpinner />;
    } else {
      data = this.state.filteredItems;
    }

    const priceFormatter = (cell, row) => {
      let rowCss = "";
      for (var i in data) {
        if (data[i].id === row.id) {
          for (var j in data[i]) {
            if (cell == data[i][j]) {
              rowCss = data[i][j + "_css"];
            }
          }
        }
      }
      return `<div style="${rowCss}">${cell}</div>`;
    };

    function link(cell, row) {
      cell = cell.trim();
      if (dtConfig.name === "salesInvoice") {
        if (row && row.sent && row.sent.pdf) {
          return `<div><a href="https://earsiv.co/fatura.php?f=EFatura/EOutBoxes/${row.sent.pdf}" target="_blank">${cell}</a></div>`;
        } else {
          return `<div>${cell}</div>`;
        }
      } else if (dtConfig.name === "purchaseInvoice") {
        if (row && row.sent && row.sent.pdf) {
          return `<div><a href="https://earsiv.co/fatura.php?f=EFatura/EInBoxes/${row.sent.pdf}" target="_blank">${cell}</a></div>`;
        } else {
          return `<div>${cell}</div>`;
        }
      } else if (dtConfig.name === "salesShipment") {
        if (row && row.sent && row.sent.pdf) {
          return `<div><a href="https://earsiv.co/fatura.php?f=Irsaliye/EOutBoxes/${row.sent.pdf}" target="_blank">${cell}</a></div>`;
        } else {
          return `<div>${cell}</div>`;
        }
      } else if (dtConfig.name === "purchaseReceipt") {
        if (row && row.sent && row.sent.pdf) {
          return `<div><a href="https://earsiv.co/fatura.php?f=Irsaliye/EInBoxes/${row.sent.pdf}" target="_blank">${cell}</a></div>`;
        } else {
          return `<div>${cell}</div>`;
        }
      }
      if (cell.length != 0) {
        if (cell.length == 10) {
          return `<div><a href="tel:0${cell}">${cell}</a></div>`;
        } else {
          return `<div>${cell}</div>`;
        }
      } else {
        return `<div></div>`;
      }
    }
// after page loaded, read table > tr > td html and add td title attribute with text
    // this is for responsive table
    setTimeout(() => {
      $(".table-overflow-cell table tr td").each(function() {
        $(this).attr("title", $(this).text());
      });
    } , 1000);
    const time = new Date();
    var apiUrl = process.env.REACT_APP_API_URL;
    apiUrl = apiUrl.substring(0, apiUrl.length-3);
    return (
      <div className="portlet light ">
        {this.props.tabMenu ? (
          <div className="portlet-title">
            <div className="caption font-dark">
              <span className="caption-subject bold uppercase">
                {this.props.title || dtConfig.page_title}
              </span>
            </div>
            {this.props.sideLink ? (
              <div className="pull-right">{this.props.sideLink}</div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div className="portlet-title">
          {this.props.tabMenu ? (
            this.createTabMenu()
          ) : (
            <div className="caption font-dark">
              <span className="caption-subject bold uppercase">
                {" "}
                {this.props.title || dtConfig.page_title}
              </span>
              {this.props.subTitle ? (
                <p
                  dangerouslySetInnerHTML={{ __html: this.props.subTitle }}
                  style={{ color: "#a5a2a8", fontWeight: "bold" }}
                />
              ) : (
                ""
              )}
            </div>
          )}
          <div className="actions">{this.intDatePickers()}</div>
        </div>
        <div className="portlet-body table-overflow-cell">
        <div style={{ wordWrap: "break-word" }}>
            {headers.length > 0 ? (
              <BootstrapTable
                data={data}
                pagination
                ref={ref => (this.dataTableRef = ref)}
                deleteRow={true}
                insertRow={true}
                statusFlag={this.state.statusFlag}
                striped
                hover
                condensed
                options={options}
                tableHeaderClass="tableHeader"
                selectRow={selectRow}
                search={
                  this.props.dtConfigLocal
                    ? this.props.dtConfigLocal.has_search === true
                    : dtConfig.has_search === 1
                }
                keyField="id"
                {...this.props.tableProps}
              >
                {headers.map((header, key) => (
                  <TableHeaderColumn
                    key={header.id}
                    dataField={header.column_name}
                    //columnClassName='td-column-string-example'
                    hidden={header.isHidden}
                    isKey={header.isKey || header.column_name === "id"}
                    dataFormat={
                      header.id==32 || header.id == 66 || header.id == 55 || header.id == 420  || header.id == 426 ? link : priceFormatter
                    }
                    columnClassName={
                      this.props.columnClassNames &&
                      this.props.columnClassNames[key]
                    }
                    sortFunc={this.customSort.bind(this)}
                    dataSort
                  >
                    {header.header_name}
                  </TableHeaderColumn>
                ))}
              </BootstrapTable>
            ) : (
              ""
            )}
          </div>
          <EkoModal
              showModal={this.state.modal}
              title="Web Raporları"
              bsSize="xlg"
              onHide={() => {
                this.setState({
                  modal: false
                });
              }}
              spinner={this.state.saving}
          >
            <iframe
                style={{overflow: 'visible'}}
                ref="iframe"
                src={apiUrl + "rapor/index.php?time=" + time.getTime() + "&t=" + localStorage.getItem('token') + "&rapor_id="+this.state.selectedReportId}
                scrolling="auto"
                frameBorder="0"
                width="100%"
                height={800}
            />
          </EkoModal>
        </div>
      </div>
    );
  }
}

EkoDataTable.propTypes = {
  name: PropTypes.string.isRequired,
  tabMenu: PropTypes.array,
  selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sideLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  tableProps: PropTypes.object,
  selectRow: PropTypes.bool,
  addButtonShow: PropTypes.bool,
  addUpdateShow: PropTypes.bool,
  addDeleteShow: PropTypes.bool,
  createCustomRowButtons: PropTypes.func,
  loading: PropTypes.bool,
  headers: PropTypes.array,
  onSelectedRowChange: PropTypes.func,
  showDelete: PropTypes.bool,
  title: PropTypes.string,
  insertMenu: PropTypes.array,
  openUpdate: PropTypes.func,
  accountTransaction: PropTypes.func,
  itemTransaction: PropTypes.func,
  acceptStatus: PropTypes.func,
  openNewCollection: PropTypes.func,
  giro: PropTypes.func,
  backGiro: PropTypes.func,
  showMethod: PropTypes.func,
  eArchiveShowButton: PropTypes.func,
  addCollectionn: PropTypes.func,
  showBulkPaymentModal: PropTypes.func,
  showTradeReconciliationModal: PropTypes.func,
  sendButton: PropTypes.func,
  eArchivesendButton: PropTypes.func,
  openDetail: PropTypes.func,
  goToSource: PropTypes.func,
  openCopied: PropTypes.func,
  passiveActive: PropTypes.func,
  openReceipt: PropTypes.func,
  openInsert: PropTypes.func,
  openArchiveCancel: PropTypes.func,
  openSMMCancel: PropTypes.func,
  rejectStatus: PropTypes.func,
  transformInvoice: PropTypes.func,
  openSelfPrint: PropTypes.func,
  onDelete: PropTypes.func,
  openChangingModal: PropTypes.func,
  previewButton: PropTypes.func,
  hiddenColumns: PropTypes.array,
  addRecipe: PropTypes.func,
  setProduced: PropTypes.func,
  similarSalesShipment: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    // data : state.d
    //
    //
    dtConfig: state.data.dtConfig
  };
}

export default connect(mapStateToProps, actions)(EkoDataTable);
