import React, { Component } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoFormInput from "../../components/elements/form/EkoFormInputText";
import {EkoFormDate, EkoFormInputText, EkoFormSelect} from "../../components/elements/EkoForm";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";
import Util from "../../util/Util";
import moment from "moment";
import EkoButtonOutline from "../../components/forms/EkoButtonOutline";
import ModalDriver from "./ModalDriver";
import ModalNote from "./ModalNote";
import ModalTrailer from "./ModalTrailer";
import ModalOtherInformation from "./ModalOtherInformation";
import AddItem from "../Item/AddItem";
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import NewTagDocumentModal from "../Company/newTagDocument";
import HelperContainer from "../../util/HelperContainer";
import AddCustomer from "../Customer/AddCustomer";
class SalesShipmentAddUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultData;
  }

  componentWillMount() {
    var self = this;
    this.setState({
      cities: JSON.parse(localStorage.getItem("fetch_cities")).data,
      counties: JSON.parse(localStorage.getItem("fetch_counties")).data,
      currency_list: JSON.parse(localStorage.getItem("fetch_currencies")).data,
    });
    if (this.props.params && this.props.params.id) {
      this.setState({ isUpdate: true });
      const id = this.props.params && this.props.params.id;

      Api.get("/salesShipment/show/" + id, (response, err) => {
        if (response && response.status === "ok") {
          response.salesShipment.currency_rate = response.salesShipment.currency_rate.replace(".", ",");
            response.lines.forEach(function (row, index) {
              response.lines[index] = Object.assign(self.row, row);
            });
          this.setState({
            driverRows:response.salesShipment.driverRows.rows.length==0?this.state.driverRows:response.salesShipment.driverRows,
            trailerRows:response.salesShipment.trailerRows.rows.length==0?this.state.trailerRows:response.salesShipment.trailerRows,
            noteRows:response.salesShipment.noteRows.rows.length==0?this.state.noteRows:response.salesShipment.noteRows,
            salesShipment: response.salesShipment,
            rows: response.lines,
          },function () {
            this.openModalOtherInformation(false);
          })
          this.setCurrency(response.salesShipment.currency);
        } else if (response.status === "fail" || err) {
          toastr.error(
              "HATA",
              "Bu kayıt için güncelleme işlemini yapamazsınız. Lütfen DESTEK butonundan bize ulaşın."
          );
          this.props.router.push("/dashboard/salesInvoice/salesShipment");
        }
      });
    }else{
      this.openModalOtherInformation(false);
      this.onFieldChange("currency", 1);
      //@oguz get proforma basladı
      const {
        router: {
          location: { query },
        },
      } = self.props;
      if (query.proforma) {
        Api.get("/proforma/" + query.proforma, (response, err) => {
          if (response && response.status === "ok") {
            this.setCurrency(response.proforma.currency);
            this.onFieldChange("customer_id", response.proforma.customer_id)
            this.state.salesShipment.currency_rate = response.proforma.currency_rate;
            Api.get("/proformaLine/showAll/" + query.proforma, (responseLineProforma, err) => {
              if (responseLineProforma && responseLineProforma.status === "ok") {
                responseLineProforma.proformaLines.forEach(function (row, index) {
                  responseLineProforma.proformaLines[index].unit_price_fc = responseLineProforma.proformaLines[index].unit_price_fc.replace('.',',');
                });
                this.setState({
                  rows: responseLineProforma.proformaLines,
                });
              }
            });
          } else if (response.status === "fail" || err) {
            toastr.error(
                "HATA",
                ""
            );
            this.props.router.push("/dashboard/salesInvoice/salesShipment");
          }
        });
      }
      //@oguz get proforma bitti
    }

    // fetch dropdown data's
    this.fetchSelectData();
    this.fetchDatax();
    this.getNewTagModalList();
  }


  async fetchDatax() {
    var self  = this;
    const warehouses = await this.fetchWarehouses();
    const updatedRows = await Promise.all(
        self.state.rows.map(async (row, index) => {
          if (self.state.rows[index].item_id) {
            const warehouses1 = await self.fetchWarehousesToItem(self.state.formData?.rows[index].item_id);
            if (row[1] && typeof row[1]["data"] !== undefined) {
              row[1]["data"] = warehouses1;
            }
          } else {
            if (row[1] && typeof row[1]["data"] !== undefined) {
              row[1]["data"] = warehouses;
            }
          }
          return row;
        })
    );

    this.setState({
      rows: updatedRows,
      warehouses
    });
  }

  fetchWarehouses = async () => {
    let { data } = await Api.getAsync("/warehouses/showAll");

    return (data && data.items) || [];
  };
  fetchWarehousesToItem = async (itemId) => {
    let { data } = await Api.getAsync("/warehouses/showAllWarehouseItems/"+itemId);
    return (data && data.items) || [];
  };
  fetchSelectData = () => {
    // customer
    Api.get("/customer/list", (res, err) => {
      if (!err) {
        this.setState({ customer: res.customers });
      }
    });

    this.fetchWarehouses().then((warehouses) => {
      this.setState({
        warehouses,
      });
    });

    // items
    Api.get("/item/showAll", (res, err) => {
      if (!err) {
        let items =
            res &&
            res.items
                .filter((item) => item.is_sales)
                .map(function (item) {
                  item.codeName = item.item_code + " : " + item.item_name;
                  return item;
                });

        this.setState({ items: items });
      }
    });
  };

  get defaultData() {
    return {
      ItemModalFormData:false,
      warehouses: [],
      rows: [this.row],
      showAddWarehouse: false,
      errors: {
        rows: [],
      },
      error: [],
      customer: [],
      salesShipment: {},
      saving: false,
      items: [],
      isUpdate: false,
      updateRows: {
        deleted: [],
      },
      driverModal: false,
      noteModal: false,
      trailerModal: false,
      driverRows: {
        rows: [{ id:null,firstname: "", lastname: "", identity_number: "" }],
      },
      noteRows: {
        rows: [{ id:null,note: "" }],
      },
      trailerRows: {
        rows: [{ id:null,TransportEquipment: ""}],
      },
      ModalOtherInformationFormData:  {item: {}},
      ModalOtherInformation:false,
      selectedLine: 0,
      showAddItem: false,
      firstRunEdit:0,
      showNewTagModal: false,
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:9,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
      showCustomer: false,
      formDataCustomer: AddCustomer.getDefaultForm(),
      detailIsOpened: false,
      gercekTuzel: false,
      customerActiveTab: "address",
    };
  }
  onAddNewItemClick(index) {
    this.setState({ selectedLine: index, showAddItem: true });
  }
  onEditItemClick(index, item) {
    var self = this;
    const id = item.id;
    Api.get("/item/" + id, function(response) {
      let formDataItemModal = {};
      formDataItemModal.item = HelperContainer.filterObject(response.item);
      self.setState({
        ItemModalFormData: formDataItemModal
      });
    });
    this.setState({ selectedLine: index, showAddItem: true });
  }
  onItemSaved() {
    this.setState({ showAddItem: false });
    this.fetchItems();
  }
  fetchItems() {
    const self = this;
    Api.get("/item/showAll", function (response, err) {
      let items = response.items.map(function (item) {
        item.codeName = item.item_code + ":" + item.item_name;
        return item;
      });
      items = items.filter(function (item) {
        return item.is_sales === 1;
      });
      self.setState({ items:items });
    });
  }
  get row() {
    let defaultRow = [
      {
        type: "select",
        id: "item_id",
        optionValue: "codeName",
        selectedOptionValue: "item_code",
        optionId: "id",
        defaultText: "Seçiniz",
        searchable: true,
        data: [],
        onAddNewItemClick: this.onAddNewItemClick.bind(this, 0),
        onEditItemClick: this.onEditItemClick.bind(this, 0),
      },
      {
        type: "select",
        id: "warehouse_id",
        optionValue: "name",
        optionId: "id",
        defaultText: "SEÇ",
        searchable: true,
        data: [],
      },
      {
        label: "",
        placeholder: "Açıklama",
        type: "text",
        colMd: 12,
        id: "description",
        vertical: true,
      },
      {
        label: "",
        placeholder: "Miktar",
        type: "money",
        precision: 6,
        colMd: 12,
        vertical: true,
        id: "quantity",
      },
      {
        label: "",
        placeholder: "Birim Fiyat",
        type: "money",
        precision: 4,
        colMd: 12,
        id: "unit_price_fc",
        vertical: true,
      },
      {
        type: "select",
        id: "unit_id",
        defaultText: "",
        optionValue: "description",
        disabled: true,
        optionId: "id",
        searchable: true,
        data: []
      },
      {
        label: "",
        placeholder: "Toplam Tutar",
        type: "money",
        precision: 4,
        colMd: 12,
        id: "amount_fc",
        vertical: true,
        disabled:true
      },
    ];

    defaultRow[0].data = this.state
        ? this.state.items
            ? this.state.items
            : []
        : [];
    defaultRow[1].data = this.state
        ? this.state.warehouses
            ? this.state.warehouses
            : []
        : [];
    defaultRow[5].data = JSON.parse(localStorage.getItem("fetch_units")).data;
    return defaultRow;
  }
  setCurrency(currency) {
    this.setState({
      salesShipment: {
        ...this.state.salesShipment,
        currency: JSON.parse(localStorage.getItem("fetch_currencies")).data.find(x => x.code === currency).id
      },
    });
  }
  onFieldChange = (field, value) => {
    var self = this;
    if (field === "customer_id") {
      Api.get("/salesShipment/customerAddress/" + value, (res, err) => {
        if (res) {
          let { address } = res;
          this.setState({
            salesShipment: {
              ...this.state.salesShipment,
              [field]: value,
              address,
            },
          });
        }
      });
    }
    if (field === "currency") {
      let get_currency_name = JSON.parse(localStorage.getItem("fetch_currencies")).data.find(x => x.id === value).code;
      Api.getCurrencyRate(
          get_currency_name,
          moment(this.state.formData?.salesShipment.time).format("YYYY-MM-DD"),
          function (rate, err) {
            if (rate) {
              self.onFieldChange("currency_rate", Util.convertDecimalToString(rate, 4));
              if(rate==1){
                self.state.salesShipment.currency_rate=Util.convertDecimalToString(1.0000, 4);
              }
            }
          }
      );
    }
    if (field === "warehouse_id") {
      self.state.rows.forEach(function (row, index) {
        self.state.rows[index]['warehouse_id'] = value;
      })
    }
    this.setState({
      salesShipment: {
        ...this.state.salesShipment,
        [field]: value,
      },
    });
  };

  onMount = () => {
    console.log("ON MOUNT");
  };

  onSave = () => {
    this.setState({ saving: true });

    const header = this.state.salesShipment;
    if (this.state.isUpdate) {
      header["id"] = this.props.params.id;
    }
    const rowMap = this.state.rows;
    const rows = [];
    var total_amount = 0;
    var total_amount_fc = 0;
    var amount = 0;
    var amount_fc = 0;
    var unit_price = 0;
    var unit_price_fc = 0;
    rowMap.map((e) => {
      if(header['currency']!=1){
        //@oguz döviz gelirse
        amount = e.amount_fc*Util.convertMoneyToDecimal(header["currency_rate"]);
        amount_fc = e.amount_fc;
        unit_price = Util.convertMoneyToDecimal(e.unit_price_fc)*Util.convertMoneyToDecimal(header["currency_rate"]);
        unit_price_fc = Util.convertMoneyToDecimal(e.unit_price_fc);
      }else{
        //@oguz TRY gelirse
        amount = e.amount_fc;
        amount_fc = e.amount_fc;
        unit_price = Util.convertMoneyToDecimal(e.unit_price_fc);
        unit_price_fc = Util.convertMoneyToDecimal(e.unit_price_fc);
      }
      rows.push({
        id: this.state.isUpdate ? e.id : null,
        quantity: Util.convertMoneyToDecimal(e.quantity),
        unit_id: e.unit_id,
        warehouse_id: e.warehouse_id,
        description: e.description,
        item_id: e.item_id,
        amount: amount,
        amount_fc: amount_fc,
        unit_price: unit_price,
        unit_price_fc: unit_price_fc,
      });
      total_amount += Util.convertPercentToNumber(amount);
      total_amount_fc += Util.convertPercentToNumber(amount_fc);
    });
    header["total_amount_fc"] = total_amount_fc;
    header["total_amount"] = total_amount;
    header["currency_rate"] = Util.convertMoneyToDecimal(header["currency_rate"]);
    header['currency'] = JSON.parse(localStorage.getItem("fetch_currencies")).data.find(x => x.id === header['currency']).code;
    header['trailer'] = this.state.trailerRows;
    header['driver'] = this.state.driverRows;
    header['other_information'] = this.state.ModalOtherInformationFormData;
    header['shipment_note'] = this.state.noteRows;
    const data = {
      header,
      rows,
      rowChange: this.state.updateRows,
      newTagRows:this.state.newTagRows
    };

    const request = this.state.isUpdate ? Api.put : Api.post;
    request("/salesShipment", data, (res, err) => {
      if (res) {
        if (res.status === "header_validation") {
          this.setState({
            error: res.message,
            saving: false,
          });
          header['currency'] = JSON.parse(localStorage.getItem("fetch_currencies")).data.find(x => x.code === header['currency']).id;
        } else if (res.status === "row_validation") {
          this.setState({
            errors: {
              rows: res.message,
            },
            error: [],
            saving: false,
          });
          header['currency'] = JSON.parse(localStorage.getItem("fetch_currencies")).data.find(x => x.code === header['currency']).id;
        } else if (res.status === "ok") {
          toastr.success("Başarılı", res.message);
          this.props.router.push("/dashboard/salesInvoice/salesShipment");
        } else if (res.status === "fail") {
          toastr.error("Hata", res.message);
          header['currency'] = JSON.parse(localStorage.getItem("fetch_currencies")).data.find(x => x.code === header['currency']).id;
        }
      }
      this.setState({ saving: false });
    });
  };

  goBack = () => {
    this.props.router.push("/dashboard/salesInvoice/salesShipment");
  };

  onChange = ({ rows }) => {
console.log('onChange');
    this.setState({ rows });
  };

  onRowDelete = (index, rowData) => {
    if (Object.values(rowData).length > 0) {
      let deleted = this.state.updateRows.deleted;
      deleted.push(rowData);
    }
  };
  componentDidUpdate() {
    var self = this;
    self.state.rows.map(function (row, index) {
      if(row){
        if(self.props.params && self.props.params.id && self.state.firstRunEdit<10 && row.item_id!==undefined){
        self.onInvoiceLineReallyChange(index, "item_id", '', row.item_id);
          self.setState({
            firstRunEdit:self.state.firstRunEdit+1,
          });
      }
      }

    });
  }
  onInvoiceLineChange(index, rowData, field, oldRowData) {
    let rows = this.state.rows;
    let row = rows[index];
    if (field === "quantity") {
      row.amount_fc=(Util.convertMoneyToDecimal(row.quantity)*Util.convertMoneyToDecimal(row.unit_price_fc)).toFixed(2);
    }
    if (field === "unit_price_fc") {
      row.amount_fc=(Util.convertMoneyToDecimal(row.unit_price_fc)*Util.convertMoneyToDecimal(row.quantity)).toFixed(2);
    }
    if (field === "item_id") {
      Api.get("/item/" + rowData.item_id, async (res, err) => {
        if (!err) {
          if(row.description===undefined){
            row.description = res.item.item_name;
          }
          row.unit_id = res.item.unit_id;
          row.amount_fc=(Util.convertMoneyToDecimal(res.item.sales_unit_price)*Util.convertMoneyToDecimal(row.quantity?row.quantity:1)).toFixed();
          row.unit_price_fc=Util.convertMoneyToDecimal(res.item.sales_unit_price);
          if(row[1].data){
            let warehousesList = await this.fetchWarehousesToItem(rowData.item_id);
            row[1].data = warehousesList;
          }
          this.setState({
            ...this.state.rows,
            row,
          });
        }
      });
    }
  }
  onInvoiceLineReallyChange = (index, field, value, oldValue) => {
    let rows = this.state.rows;
    let row = rows[index];
    if (field === "quantity") {
      row.amount_fc=(Util.convertMoneyToDecimal(value)*Util.convertMoneyToDecimal(row.unit_price_fc)).toFixed(2);
      this.setState({
        ...this.state.rows,
        row,
      });
    }
    if (field === "unit_price_fc") {

      row.amount_fc=(Util.convertMoneyToDecimal(value)*Util.convertMoneyToDecimal(row.quantity)).toFixed(2);
      this.setState({
        ...this.state.rows,
        row,
      });
    }
  };
  addWarehouse = () => {
    this.setState({
      showAddWarehouse: true,
    });
  };

  onAddWarehouse = (id) => {
    this.fetchWarehouses().then((warehouses) => {
      this.setState({
        warehouses,
        salesShipment: {
          ...this.state.salesShipment,
          warehouse_id: id,
        },
        showAddWarehouse: false,
      });
    });
  };
  closeNoteModal = () => {
    this.setState({
      noteModal: !this.state.noteModal,
    });
  };
  openNoteModal = () => {
    this.setState({
      noteModal: !this.state.noteModal,
    });
  };
  saveNote = (rows) => {
    this.setState({
      noteRows: rows,
    });
  };
  closeDriverModal = () => {
    this.setState({
      driverModal: !this.state.driverModal,
    });
  };
  openDriverModal = () => {
    this.setState({
      driverModal: !this.state.driverModal,
    });
  };
  saveDriver = (rows) => {
    this.setState({
      driverRows: rows,
    });
  };
  closeTrailerModal = () => {
    this.setState({
      trailerModal: !this.state.trailerModal,
    });
  };
  openTrailerModal = () => {
    this.setState({
      trailerModal: !this.state.trailerModal,
    });
  };
  saveTrailer = (rows) => {
    this.setState({
      trailerRows: rows,
    });
  };
  saveModalOtherInformation = (fd) => {
    this.setState({
      ModalOtherInformation: !this.state.ModalOtherInformation,
      ModalOtherInformationFormData: fd
    });
  };
  closeModalOtherInformation = () => {
    this.setState({
      ModalOtherInformation: !this.state.ModalOtherInformation,
    });
  };
  openModalOtherInformation = (opened=true) => {
    const self = this;
    if(this.state.ModalOtherInformationFormData.item.tab1_id===undefined){
      var ModalOtherInformationForm = ModalOtherInformation.defaultData();
      ModalOtherInformationForm.item = {
        "tab1_id": "",
        "tab1_shortname": "",
        "tab1_address": "",
        "tab1_city_id": "",
        "tab1_county_id": "",
        "tab1_post_code": "",

        "tab2_firstname": "",
        "tab2_lastname": "",
        "tab2_tax_identity_number": "",
        "tab2_post_code": "",
        "tab2_address": "",
        "tab2_tax_office_id": "",
        "tab2_city_id": "",
        "tab2_county_id": "",
        "tab2_email": "",
        "tab2_phone": "",
        "tab2_fax": "",
        "tab2_web_addres": "",

        "tab3_firstname": "",
        "tab3_lastname": "",
        "tab3_tax_identity_number": "",
        "tab3_post_code": "",
        "tab3_address": "",
        "tab3_tax_office_id": "",
        "tab3_city_id": "",
        "tab3_county_id": "",
        "tab3_email": "",
        "tab3_phone": "",
        "tab3_fax": "",
        "tab3_web_addres": "",

        "tab4_firstname": "",
        "tab4_lastname": "",
        "tab4_tax_identity_number": "",
        "tab4_post_code": "",
        "tab4_address": "",
        "tab4_tax_office_id": "",
        "tab4_city_id": "",
        "tab4_county_id": "",
        "tab4_email": "",
        "tab4_phone": "",
        "tab4_fax": "",
        "tab4_web_addres": "",

        "tab5_firstname": "",
        "tab5_lastname": "",
        "tab5_tax_identity_number": "",
        "tab5_post_code": "",
        "tab5_address": "",
        "tab5_tax_office_id": "",
        "tab5_city_id": "",
        "tab5_county_id": "",
        "tab5_email": "",
        "tab5_phone": "",
        "tab5_fax": "",
        "tab5_web_addres": "",
      };
      if (this.props.params && this.props.params.id) {
        if(this.state.salesShipment.DeliveryAddress !== null){
          var da = this.state.salesShipment.DeliveryAddress;
          ModalOtherInformationForm.item.tab1_id = da.id;
          ModalOtherInformationForm.item.tab1_shortname = da.shortname;
          ModalOtherInformationForm.item.tab1_address = da.address;
          ModalOtherInformationForm.item.tab1_city_id = da.city_id;
          ModalOtherInformationForm.item.tab1_county_id = da.county_id;
          ModalOtherInformationForm.item.tab1_post_code = da.post_code;
        }
        if(this.state.salesShipment.CarrierParty !== null){
          var cp = this.state.salesShipment.CarrierParty;
          ModalOtherInformationForm.item.tab2_id = cp.id;
          ModalOtherInformationForm.item.tab2_firstname = cp.firstname;
          ModalOtherInformationForm.item.tab2_lastname = cp.lastname;
          ModalOtherInformationForm.item.tab2_tax_identity_number = cp.tax_identity_number;
          ModalOtherInformationForm.item.tab2_post_code = cp.post_code;
          ModalOtherInformationForm.item.tab2_address = cp.address;
          ModalOtherInformationForm.item.tab2_tax_office_id = cp.tax_office_id;
          ModalOtherInformationForm.item.tab2_city_id = cp.city_id;
          ModalOtherInformationForm.item.tab2_county_id = cp.county_id;
          ModalOtherInformationForm.item.tab2_email = cp.email;
          ModalOtherInformationForm.item.tab2_phone = cp.phone;
          ModalOtherInformationForm.item.tab2_fax = cp.fax;
          ModalOtherInformationForm.item.tab2_web_addres = cp.web_adres;
        }
        if(this.state.salesShipment.SellerSupplierParty !== null){
          var bcp = this.state.salesShipment.BuyerCustomerParty;
          ModalOtherInformationForm.item.tab3_id = bcp.id;
          ModalOtherInformationForm.item.tab3_firstname = bcp.firstname;
          ModalOtherInformationForm.item.tab3_lastname = bcp.lastname;
          ModalOtherInformationForm.item.tab3_tax_identity_number = bcp.tax_identity_number;
          ModalOtherInformationForm.item.tab3_post_code = bcp.post_code;
          ModalOtherInformationForm.item.tab3_address = bcp.address;
          ModalOtherInformationForm.item.tab3_tax_office_id = bcp.tax_office_id;
          ModalOtherInformationForm.item.tab3_city_id = bcp.city_id;
          ModalOtherInformationForm.item.tab3_county_id = bcp.county_id;
          ModalOtherInformationForm.item.tab3_email = bcp.email;
          ModalOtherInformationForm.item.tab3_phone = bcp.phone;
          ModalOtherInformationForm.item.tab3_fax = bcp.fax;
          ModalOtherInformationForm.item.tab3_web_addres = bcp.web_adres;
        }
        if(this.state.salesShipment.SellerSupplierParty !== null){
          var ssp = this.state.salesShipment.SellerSupplierParty;
          ModalOtherInformationForm.item.tab4_id = ssp.id;
          ModalOtherInformationForm.item.tab4_firstname = ssp.firstname;
          ModalOtherInformationForm.item.tab4_lastname = ssp.lastname;
          ModalOtherInformationForm.item.tab4_tax_identity_number = ssp.tax_identity_number;
          ModalOtherInformationForm.item.tab4_post_code = ssp.post_code;
          ModalOtherInformationForm.item.tab4_address = ssp.address;
          ModalOtherInformationForm.item.tab4_tax_office_id = ssp.tax_office_id;
          ModalOtherInformationForm.item.tab4_city_id = ssp.city_id;
          ModalOtherInformationForm.item.tab4_county_id = ssp.county_id;
          ModalOtherInformationForm.item.tab4_email = ssp.email;
          ModalOtherInformationForm.item.tab4_phone = ssp.phone;
          ModalOtherInformationForm.item.tab4_fax = ssp.fax;
          ModalOtherInformationForm.item.tab4_web_addres = ssp.web_adres;
        }
        if(this.state.salesShipment.OriginatorCustomerParty !== null){
          var ocp = this.state.salesShipment.OriginatorCustomerParty;
          ModalOtherInformationForm.item.tab5_id = ocp.id;
          ModalOtherInformationForm.item.tab5_firstname = ocp.firstname;
          ModalOtherInformationForm.item.tab5_lastname = ocp.lastname;
          ModalOtherInformationForm.item.tab5_tax_identity_number = ocp.tax_identity_number;
          ModalOtherInformationForm.item.tab5_post_code = ocp.post_code;
          ModalOtherInformationForm.item.tab5_address = ocp.address;
          ModalOtherInformationForm.item.tab5_tax_office_id = ocp.tax_office_id;
          ModalOtherInformationForm.item.tab5_city_id = ocp.city_id;
          ModalOtherInformationForm.item.tab5_county_id = ocp.county_id;
          ModalOtherInformationForm.item.tab5_email = ocp.email;
          ModalOtherInformationForm.item.tab5_phone = ocp.phone;
          ModalOtherInformationForm.item.tab5_fax = ocp.fax;
          ModalOtherInformationForm.item.tab5_web_addres = ocp.web_adres;
        }
      }
    }else{
      var ModalOtherInformationForm = this.state.ModalOtherInformationFormData;
    }
    self.setState({ ModalOtherInformationFormData: ModalOtherInformationForm }, function () {
      if(opened){
        self.setState({
          ModalOtherInformation: !this.state.ModalOtherInformation,
        });
      }
    });
  };
  onInvoiceLineAdded() {
    var formData = this.state;
    formData.rows[formData.rows.length - 1] = this.row;
    this.setState({
      rows: formData.rows,
    });
  }
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = () => {
    if (this.props.params && this.props.params.id) {
      Api.get("/company/newTagRecordDocumentList/9/" + this.props.params.id, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }
      });
    }
  }

  onAddNewFromWhoClick(data) {
    this.executeDocumentLogic(data);
  }

  executeDocumentLogic(data) {
    this.setState({
      showCustomer: true,
      formDataCustomer: data || AddCustomer.getDefaultForm(),
      detailIsOpened: data ? true : false,
      gercekTuzel: data?.tckn ? true : false,
    });
  }
  onCustomerSave(id) {
    this.setState(
        {
          showCustomer: false,
          fromWhoAddress: false,
          fromWhoAddress2: false,
          formData: {
            ...this.state.formData,
            customer_id: null,
            fromWhoId: null,
          },
        },
        () => {
          Api.get("/customer/list", (res, err) => {
            if (!err) {
              this.setState({ customer: res.customers });
              setTimeout(() => this.onFieldChange("customer_id", id), 1000);
            }
          });

        }
    );
  }
  render() {
    var self = this;
    const inputProps = {
      onChange: this.onFieldChange.bind(this),
      onMount: this.onMount.bind(this),
      formData: this.state.salesShipment,
      errors: this.state.error,
    };
    return (
        <div>
          {/* SalesShipment Header */}
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard/salesInvoice/salesShipment")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          <div className="portlet light" style={{paddingBottom: 100}}>
            <div className="portlet-title">
              <div className="caption font-dark">
              <span className="caption-subject bold uppercase">
                Sevk İrsaliyesi
              </span>
              </div>
            </div>
            <div className="portlet-body">
              <div className="alldiv">
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-12">
                      <EkoFormSelect
                          searchable={true}
                          isVertical
                          {...inputProps}
                          onAddNewItemClick={this.onAddNewFromWhoClick.bind(this)}
                          id={"customer_id"}
                          defaultText={"Seçiniz"}
                          optionId={"id"}
                          options={this.state.customer}
                          optionValue={"name"}
                          label={"Kime"}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-6">
                        <EkoFormSelect
                            {...inputProps}
                            onChange={this.onFieldChange.bind(this)}
                            searchable={true}
                            id="currency"
                            isVertical={true}
                            optionValue="code"
                            defaultText="Seçiniz"
                            options={this.state.currency_list}
                            label="Döviz Tipi"
                        />
                      </div>
                      <div className="col-md-6">
                        <EkoFormInputText
                            {...inputProps}
                            id="currency_rate"
                            isVertical={true}
                            label="Kur"
                        ></EkoFormInputText>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="control-label control-line">Adres</label>

                      <textarea
                          onChange={(data) =>
                              this.onFieldChange("address", data.target.value)
                          }
                          name="address"
                          style={{height: 120}}
                          value={this.state.salesShipment.address ?? ""}
                          placeholder={"Adres"}
                          className={
                            "form-control adress-area address-editable address-area"
                          }
                      ></textarea>
                      <label
                          style={{color: "red"}}
                          className="control-label control-line"
                      >
                        {/*Hata mesajı*/}
                        {this.state.error ? this.state.error.address ?? "" : ""}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="control-label control-line">Not (İrsaliyede Yer Almayacak)</label>
                    <textarea
                        onChange={(data) =>
                            this.onFieldChange("note", data.target.value)
                        }
                        name="note"
                        style={{height: 120}}
                        value={this.state.salesShipment.note ?? ""}
                        placeholder={"Not Giriniz Giriniz (İrsaliyede Yer Almayacak)"}
                        className={
                          "form-control adress-area address-editable address-area"
                        }
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="heighte row">
                    <EkoFormSelect
                        searchable={true}
                        {...inputProps}
                        id={"warehouse_id"}
                        defaultText={"Seçiniz"}
                        optionId={"id"}
                        options={this.state.warehouses}
                        optionValue={"name"}
                        label={"Depo"}
                    />
                  </div>
                  <div className="heighte row">
                    <EkoFormInput
                        {...inputProps}
                        id={"document_no"}
                        type={"text"}
                        placeHolder={"Döküman No"}
                        label={"Döküman No"}
                    />
                  </div>
                  <div className="heighte row">
                    <EkoFormDate
                        {...inputProps}
                        id={"date"}
                        dateFormat="DD-MM-YYYY"
                        isVertical={false}
                        clearable
                        label={"İrsaliye Tarihi"}
                    />
                  </div>
                  <div className="heighte row">
                    <EkoFormDate
                        {...inputProps}
                        id={"time"}
                        dateFormat="DD-MM-YYYY"
                        isVertical={false}
                        clearable
                        label={"Sevk Tarihi"}
                    />
                  </div>
                  <div className="heighte row">
                    {
                      /*
                      <EkoFormInput
                        {...inputProps}
                        colMd={6}
                        id={"description"}
                        type={"text"}
                        placeHolder={"İrsaliye Açıklaması"}
                        label={"İrsaliye Açıklaması"}
                    />
                      */
                    }
                    <div style={{"height":"10px"}}></div>
                    <EkoButtonOutline
                        buttonProps={{style: {width: "100%"}}}
                        onClick={this.openNoteModal}
                        label={"İrsaliye Açıklaması"}
                    />
                  </div>
                  <div className="heighte row" style={{"marginTop": "15px"}}>
                    <div className="col-md-6">
                      <EkoButtonOutline
                          buttonProps={{style: {width: "100%"}}}
                          onClick={this.openDriverModal}
                          label={"Şoför Bilgileri"}
                      />
                    </div>
                    <div className="col-md-6">
                      <EkoButtonOutline
                          buttonProps={{style: {width: "100%"}}}
                          onClick={this.openTrailerModal}
                          label={"Dorse Bilgileri"}
                      />
                    </div>
                    <div className="col-md-12" style={{"marginTop": "10px"}}>
                      <EkoButtonOutline
                          buttonProps={{style: {width: "100%"}}}
                          onClick={this.openModalOtherInformation}
                          label={"Satın Alan, Sağlayan, Taşıyıcı, Teslim Adresi"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"/>
            </div>
            <EkoInputTableButtonGroup
                orderNumber={true}
                onSave={this.onSave.bind(this)}
                onCancel={this.goBack}
                saving={this.state.saving}
                onChange={this.onChange.bind(this)}
                errors={this.state.errors}
                formData={this.state}
                buttonSaveLabel={"Kaydet"}
                onRowDelete={this.onRowDelete.bind(this)}
                onRealChange={this.onInvoiceLineReallyChange.bind(this)}
                onChangeRow={this.onInvoiceLineChange.bind(this)}
                onRowAdd={this.onInvoiceLineAdded.bind(this)}
                data={{
                  headers: [
                    {label: "Ürün Adı", width: "15%"},
                    {label: "Depo", width: "10%"},
                    {label: "Açıklama", width: "20%"},
                    {label: "Miktar", width: "10%"},
                    {label: "Birim Fiyat", width: "15%"},
                    {label: "Birim", width: "15%"},
                    {label: "Fiyat", width: "15%"},
                  ],
                  rows: this.state.rows.map((row) => {

                    if (row.length !== undefined) {
                      row[0].data = this.state.items;
                      return row;
                    }

                    let defaultRow = this.row;
                    defaultRow[0].data = this.state.items;
                    defaultRow[1].data = this.state.warehouses;
                    defaultRow[5].data = JSON.parse(localStorage.getItem("fetch_units")).data;

                    return defaultRow;

                  }),
                }}
            />
            <hr/>
            <a onClick={this.openNewTagModal} style={{top: -5, position: "relative"}}>Etiket Ekle</a>
            <NewTagDocumentModal
                newTagRows={this.state.newTagRows}
                saveNewTag={this.saveNewTag}
                showNewTagModal={this.state.showNewTagModal}
                closeNewTagModal={this.closeNewTagModal}
            />
          </div>
          <ModalDriver
              driverRows={this.state.driverRows}
              saveDriver={this.saveDriver}
              showModal={this.state.driverModal}
              closeModal={this.closeDriverModal}
          />
          <ModalNote
              noteRows={this.state.noteRows}
              saveNote={this.saveNote}
              showModal={this.state.noteModal}
              closeModal={this.closeNoteModal}
          />
          <ModalTrailer
              trailerRows={this.state.trailerRows}
              saveTrailer={this.saveTrailer}
              showModal={this.state.trailerModal}
              closeModal={this.closeTrailerModal}
          />
          <ModalOtherInformation
              pageFormData={this.state}
              formData={this.state.ModalOtherInformationFormData}
              onSave={this.saveModalOtherInformation}
              showModal={this.state.ModalOtherInformation}
              closeModal={this.closeModalOtherInformation}
          />
          <AddItem
              showModal={this.state.showAddItem}
              formData={this.state.ItemModalFormData}
              onSave={this.onItemSaved.bind(this)}
              onCancel={() => {
                this.setState({showAddItem: false});
              }}
              errors={false}
          />
          <AddCustomer
              activeTab={this.state.customerActiveTab}
              showModal={this.state.showCustomer}
              detailIsOpened={this.state.detailIsOpened}
              gercekTuzel={this.state.gercekTuzel}
              formData={this.state.formDataCustomer}
              onSave={this.onCustomerSave.bind(this)}
              onCancel={() => {
                this.setState({ showCustomer: false });
              }}
              errors={{}}
          />
        </div>
    );
  }
}

export default SalesShipmentAddUpdate;