import React, { Component } from "react";
import {
    EkoForm,
    EkoFormButton,
    EkoFormSelect,
} from "../../components/elements/EkoForm";
import * as actions from "../../actions";
import { connect } from "react-redux";
import {
    FETCH_DECLARATION_TYPES
} from "../../actions/types";
import { browserHistory } from "react-router";
import { toastr } from "react-redux-toastr";

import DeclarationsIframe from "./DeclarationsIframe";

import HelperContainer from "../../util/HelperContainer";
import EkoSpinner from "../../components/elements/EkoSpinner";
import Util from "../../util/Util";
import Api from "../../util/Api";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { empty } from "rxjs/Observer";

class Declarations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            errors: {},
            formFields: {},
            selected: {},
            showInsertModal: false,
            // vat_periods alanını dinamik hale getiriyoruz:
            vat_periods: this.generateVatPeriods(),
            declaration_cycles: [],
            current_vat_period: 1
        };
    }

    /**
     * generateVatPeriods()
     *
     * 2023 yılından başlayıp, güncel yıla kadar (örneğin 2025) aşağıdaki periyot türlerine göre dinamik dönem verileri üretir:
     *
     * 1. Aylık: Her yıl için 12 ay (Aralık'tan Ocak'a doğru sıralanır)
     * 2. Çeyrek: Her yıl için Q1 (Ocak-Şubat-Mart), Q2 (Nisan-Mayıs-Haziran), Q3 (Temmuz-Ağustos-Eylül), Q4 (Ekim-Kasım-Aralık)
     * 3. Yıllık: Her yıl için Ocak-Aralık dönemi
     * 4. Normal Dönem: Her yıl için çeyrek şeklinde, ancak isimlendirme "1. Dönem", "2. Dönem", vb. şeklinde
     * 5. Normal Dönem (3 periyot): Her yıl için 1-3 ay, 1-6 ay ve 1-9 ay dönemlerini oluşturur
     *
     * Her periyot nesnesinde "id" değeri, ilgili başlangıç ve bitiş aylarını birleştiren bir string (ör. "202501_202501"),
     * "name" ise gösterim adıdır.
     */
    generateVatPeriods() {
        const startYear = 2023;
        const currentYear = new Date().getFullYear();
        const monthNames = [
            "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
            "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
        ];

        // Her periyot türü için boş diziler oluşturuyoruz.
        let periods = {
            1: [], // Aylık periyotlar
            2: [], // Çeyrek periyotlar (ay isimleriyle)
            3: [], // Yıllık periyotlar
            4: [], // Normal Dönem (çeyrek şeklinde, "1. Dönem" vb.)
            5: []  // Normal Dönem (3 periyot: 1-3, 1-6, 1-9 ay)
        };

        // En güncel yıl en üstte olacak şekilde (azalan sıra) yıllar üzerinden döngü kuruyoruz.
        for (let year = currentYear; year >= startYear; year--) {

            // 1. Aylık periyotlar: Her yıl için Aralık'tan Ocak'a doğru
            for (let m = 12; m >= 1; m--) {
                const monthStr = m.toString().padStart(2, '0'); // Örn: 1 => "01"
                const id = `${year}${monthStr}_${year}${monthStr}`;
                const name = `${year} - ${monthNames[m - 1]}`;
                periods[1].push({ id, name });
            }

            // 2. Çeyrek periyotlar (ay isimleriyle): Q1, Q2, Q3, Q4
            // İç döngüde periyotlar artan sırada oluşturulmaktadır.
            for (let q = 0; q < 4; q++) {
                const startMonth = q * 3 + 1;
                const endMonth = startMonth + 2;
                const startMonthStr = startMonth.toString().padStart(2, '0');
                const endMonthStr = endMonth.toString().padStart(2, '0');
                const id = `${year}${startMonthStr}_${year}${endMonthStr}`;
                const name = `${year} - ${monthNames[startMonth - 1]}-${monthNames[startMonth]}-${monthNames[endMonth - 1]}`;
                periods[2].push({ id, name });
            }

            // 3. Yıllık periyot: Ocak'tan Aralık'a
            {
                const id = `${year}01_${year}12`;
                const name = `${year} - Ocak-Aralık`;
                periods[3].push({ id, name });
            }

            // 4. Normal Dönem (çeyrek şeklinde, "1. Dönem", "2. Dönem", vb.):
            for (let q = 0; q < 4; q++) {
                const startMonth = q * 3 + 1;
                const endMonth = startMonth + 2;
                const startMonthStr = startMonth.toString().padStart(2, '0');
                const endMonthStr = endMonth.toString().padStart(2, '0');
                const id = `${year}${startMonthStr}_${year}${endMonthStr}`;
                const name = `${year} - ${q + 1}. Dönem`;
                periods[4].push({ id, name });
            }

            // 5. Normal Dönem (3 periyot):
            //    - 1. periyot: Ocak-Üçüncü ay (1-3)
            //    - 2. periyot: Ocak-Alta ay (1-6)
            //    - 3. periyot: Ocak-Dokuzuncu ay (1-9)
            {
                let id = `${year}01_${year}03`;
                let name = `${year} - 1. Dönem`;
                periods[5].push({ id, name });

                id = `${year}01_${year}06`;
                name = `${year} - 2. Dönem`;
                periods[5].push({ id, name });

                id = `${year}01_${year}09`;
                name = `${year} - 3. Dönem`;
                periods[5].push({ id, name });
            }
        }
        return periods;
    }

    componentWillMount() {
        let formData = this.props.formData || this.defaultFormData();
        let errors = {};
        this.props.fetchCachedDataAsKey(
            "/data/declaration_types",
            FETCH_DECLARATION_TYPES,
            "declaration_types",
            "1:day"
        );
        this.setState({ formData: formData, errors: errors });
    }

    componentDidMount() {
        this.props.changePageTitle("Beyanname Yönetimi");
    }

    defaultFormData() {
        let serviceType = process.env.SERVICE_TYPE;
        let theToken = localStorage.getItem("token");
        return {
            declaration: { "type_id": "", "type_cycle": "", "vat_period": "", "token": theToken }
        };
    }

    fieldOnChange(type, field, value) {
        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;

        let bigForm = this.state.formData;
        bigForm[type] = formData;

        let selectedItemCycle, getDeclarationTypeItems = [];
        let tmp_declaration_cycles = [];
        if (field === "type_id") {
            getDeclarationTypeItems = JSON.parse(localStorage.getItem('fetch_declaration_types'));
            getDeclarationTypeItems.data.forEach(function(val) {
                if (val.id === value) {
                    selectedItemCycle = val.type_cycles.split(',');
                    selectedItemCycle.forEach(element => {
                        if (element === "1") { // Aylık
                            tmp_declaration_cycles.push({ "id": element, "name": "Aylık" });
                        }
                        if (element === "2") { // Çeyrek
                            tmp_declaration_cycles.push({ "id": element, "name": "Çeyrek" });
                        }
                        if (element === "3") { // Yıllık
                            tmp_declaration_cycles.push({ "id": element, "name": "Yıllık" });
                        }
                        if (element === "4") { // Normal Dönem
                            tmp_declaration_cycles.push({ "id": element, "name": "Normal Dönem" });
                        }
                        if (element === "5") { // Normal Dönem
                            tmp_declaration_cycles.push({ "id": element, "name": "Normal Dönem" });
                        }
                    });
                }
            });
            this.setState({ declaration_cycles: tmp_declaration_cycles });
        } else if (field === "type_cycle") {
            this.setState({ current_vat_period: value });
        }
        this.setState({ formData: bigForm, errors: errors, showInsertModal: false });
    }

    fieldOnMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else {
            formFields[type].push(field);
        }
        this.setState({ formFields: formFields });
    }

    onSave() {
        let i = this.state.formData.declaration.type_id;
        let c = this.state.formData.declaration.type_cycle;
        let p = this.state.formData.declaration.vat_period;
        if (c > 0 && p > 0 && i > 0) {
            toastr.success("Lütfen bekleyin, veri giriş ekranına yönlendiriliyorsunuz.");
            browserHistory.push(`/dashboard/declarations/${i}/${c}/${p}`);
        } else {
            toastr.error("Tüm alanların seçimi zorunludur.");
        }
    }

    onSaveOpenModal() {
        this.setState({ showInsertModal: true });
    }

    render() {
        const declarationsProps = {
            onChange: this.fieldOnChange.bind(this, "declaration"),
            onMount: this.fieldOnMount.bind(this, "declaration"),
            formData: this.state.formData.declaration,
            errors: this.state.errors.declaration
        };
        const declaration_types = this.props.declaration_types || [];

        return (
            <div>
                <a
                    style={{ bottom: "6px", position: "relative", color: "grey" }}
                    onClick={Util.backButton.bind(this, "/dashboard")}
                >
                    <i className="fa fa-arrow-left"></i> Geri Dön
                </a>
                <div className="portlet light">
                    <div className="portlet-title">
                        <div className="caption font-dark">
                            <span className="caption-subject bold uppercase">Beyanname Oluştur &amp; Görüntüle</span>
                        </div>
                    </div>
                    <div className="portlet-body">
                        <EkoForm hortizonal={false} formId="add-new-declarations">
                            <div className="row">
                                <div className="col-md-4 col-xs-12">
                                    <EkoFormSelect
                                        {...declarationsProps}
                                        searchable={true}
                                        id="type_id"
                                        clearable={true}
                                        options={declaration_types}
                                        optionValue="type_name"
                                        optionId="id"
                                        label="Beyanname Tipi"
                                        required={true}
                                        labelMd="12"
                                        colMd="12"
                                        isVertical={true}
                                    />
                                </div>
                                <div className="col-md-4 col-xs-12">
                                    <EkoFormSelect
                                        {...declarationsProps}
                                        searchable={true}
                                        id="type_cycle"
                                        clearable={true}
                                        options={this.state.declaration_cycles}
                                        optionValue="name"
                                        optionId="id"
                                        label="Dönem Tipi"
                                        required={true}
                                        labelMd="12"
                                        colMd="12"
                                        isVertical={true}
                                    />
                                </div>
                                <div className="col-md-4 col-xs-12">
                                    <EkoFormSelect
                                        {...declarationsProps}
                                        searchable={true}
                                        id="vat_period"
                                        clearable={true}
                                        options={this.state.vat_periods[this.state.current_vat_period]}
                                        optionValue="name"
                                        optionId="id"
                                        label="Dönem Seçiniz"
                                        required={true}
                                        labelMd="12"
                                        colMd="12"
                                        isVertical={true}
                                    />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-12 col-xs-12 text-center mt-4">
                                    <DeclarationsIframe
                                        showModal={this.state.showInsertModal}
                                        formData={this.state.formData}
                                        onCancel={() => {
                                            this.setState({ showInsertModal: false });
                                        }}
                                        errors={this.state.errors}
                                    />
                                    <EkoFormButton
                                        label="Görüntüle &amp; Oluştur"
                                        faClass="fa fa-eye"
                                        onClick={this.onSaveOpenModal.bind(this)}
                                    />
                                </div>
                            </div>
                        </EkoForm>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions,
        declaration_types: state.data.declaration_types,
        declaration_cycles: state.data.declaration_cycles
    };
}

export default connect(mapStateToProps, actions)(Declarations);
