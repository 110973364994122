import React, { Component } from "react";
import PropTypes from "prop-types";
import Util from "../../../util/Util";
import enhanceWithClickOutside from "react-click-outside";
import {
  Col,
  ControlLabel,
  FormGroup,
  HelpBlock,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import _ from "lodash";
class EkoFormAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      value: props.value || "",
      selectedItem: "",
      defaultText: "",
    };
    this.selectRef = React.createRef();
  }

  static propTypes = {
    optionValue: PropTypes.string,
    selectValue: PropTypes.string,
    searchValue: PropTypes.string,
    value: PropTypes.string,
    formData: PropTypes.object,
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    dropdownStyle: PropTypes.object,
    //  onSelectAutoComplete: PropTypes.function
  };

  static defaultProps = {
    options: [],
    optionValue: "name",
    selectValue: null,
    searchValue: null,
    value: "",
    type: "",
    labelMd: 5,
    colMd: 6,
    disabled: false,
  };

  componentWillMount() {
    this.props.onMount(this.props.id);
  }

  openList() {
    this.setState({ open: true });
  }

  handleClickOutside() {
    this.setState({ open: false });
  }

  closeList() {
    this.setState({ open: false });
  }

  selectItem(item) {
    this.setState({
      selectedItem: item,
      open: !this.state.open,
      value: item[this.props.optionValue],
    });
    //  this.props.onSelectAutoComplete(item[this.props.optionValue],this.props.name)
    this.props.onSelect(
      this.props.id,
      item[this.props.selectValue || this.props.optionValue]
    );
  }

  onChange(e) {
    this.setState({ open: true, value: e.target.value });
    this.props.onChange(this.props.id, e.target.value);
  }

  renderInput() {
    const {
      optionValue,
      searchValue,
      formData,
      options,
      dropdownStyle,
      className,
    } = this.props;
    const width = this.selectRef?.current?.clientWidth;
    let optionsT = [...options];
    let dropdownClassName = "dropdown-list ";
    if (this.state.open) {
      dropdownClassName += "openBlock";
    } else {
      dropdownClassName += "closeBlock";
    }
    const selectedItem = this.state.selectedItem;
    const defaultText = this.state.defaultText;
    // formData[optionValue]
    const value = formData[optionValue];
    optionsT = options.filter(
      (option) =>
        option[searchValue || optionValue] &&
        option[searchValue || optionValue]
          .toLocaleLowerCase("tr-TR")
          .includes(value?.toLocaleLowerCase("tr-TR"))
    );

    return (
      <div className={className || ""}>
        <input
          ref={this.selectRef}
          type="text"
          className="form-control"
          onChange={this.onChange.bind(this)}
          value={value ? value : defaultText}
        />

        {!Util.isEmpty(optionsT) ? (
          <div
            style={{ ...dropdownStyle, width, position: "absolute" }}
            className={dropdownClassName}
          >
            <ul>
              {value && (value.length > 2 || this.props.searchCharacterLimit) && optionsT.map((e, i) => {
                return (
                  <li
                    key={i}
                    onClick={this.selectItem.bind(this, e)}
                    className={
                      selectedItem &&
                      selectedItem[searchValue || optionValue] ===
                        e[searchValue || optionValue]
                        ? "selectedItemColor"
                        : ""
                    }
                  >
                    {e[searchValue || optionValue]}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  render() {
    const key = this.props.alias_id || this.props.id;

    const error =
      this.props.errors && this.props.errors[key] ? (
        <ControlLabel>{this.props.errors[key]}</ControlLabel>
      ) : (
        false
      );
    let validationState = null;
    if (error) {
      validationState = "error";
    }
    const help = this.props.help ? (
      <HelpBlock>{this.props.help}</HelpBlock>
    ) : (
      ""
    );
    let tooltip = "";
    if (error) {
      validationState = "error";
      tooltip = (
        <Tooltip id="tooltip">{this.props.errors[this.props.id][0]}</Tooltip>
      );
    }
    let classForTooltip = "";
    if (this.props.tooltip && error) classForTooltip = "input-icon right";
    let tooltipId = this.props.tooltipId ? this.props.tooltipId : this.props.id;

    return !this.props.isVertical ? (
      <FormGroup controlId={this.props.id} validationState={validationState}>
        {this.props.label && (
          <Col
            componentClass={ControlLabel}
            md={parseInt(this.props.labelMd, 10)}
          >
            {this.props.label}&nbsp;{" "}
            {this.props.required ? <span className="required"> * </span> : ""}
          </Col>
        )}
        <Col md={parseInt(this.props.colMd, 10)}>
          <div className={classForTooltip}>
            {error && this.props.tooltip ? (
              <OverlayTrigger placement="left" overlay={tooltip}>
                <i className="fas fa-exclamation-triangle tooltips"></i>
              </OverlayTrigger>
            ) : (
              ""
            )}
            {this.renderInput()}
            {error && !this.props.tooltip ? error : help}
          </div>
        </Col>
        {this.props.children}
      </FormGroup>
    ) : (
      <FormGroup controlId={this.props.id} validationState={validationState}>
        {this.props.label && (
          <Col
            componentClass={ControlLabel}
            md={parseInt(this.props.labelMd, 10) || 5}
            className="control-line"
            style={this.props.labelStyle}
          >
            {this.props.label}&nbsp;{" "}
            {this.props.required ? <span className="required"> * </span> : ""}
          </Col>
        )}
        <div className={classForTooltip}>
          {error && this.props.tooltip ? (
            <OverlayTrigger placement="left" overlay={tooltip}>
              <i className="fas fa-exclamation-triangle tooltips"></i>
            </OverlayTrigger>
          ) : (
            ""
          )}
          {this.renderInput()}
          {error && !this.props.tooltip ? error : help}
        </div>
      </FormGroup>
    );
  }
}

export default enhanceWithClickOutside(EkoFormAutoComplete);
