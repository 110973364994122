import React, { Component } from "react";
import {
  EkoForm,
  EkoFormButton,
  EkoFormInputText,
  EkoFormSelect
} from "../../components/elements/EkoForm";
import HelperContainer from "../../util/HelperContainer";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import EkoSpinner from "../../components/elements/EkoSpinner";
import { browserHistory } from "react-router";
import moment from "moment";

import { FETCH_COMPANY } from "../../actions/types";

class UpdateCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      saving: false,
      errors: {},
      saved: false,
      formFields: {},
      vknLoad: false,
    };
  }

  defaultFormData() {
    return {
      company: {}
    };
  }
  onVknSearch() {
    const self = this;
    let formData = self.state.formData;
    self.setState({ vknLoad: true });
    Api.post(
        "/customer/searchVkn",
        { identity: formData.company.tax_identity_number },
        function (response, error) {
          self.setState({ vknLoad: false });
          if (error) {
            if(error.system_error){
              toastr.error("Hata", error.system_error);
            }
            self.setState({
              errors: {
                ...self.state.errors,
                company: {
                  ...self.state.errors.company,
                  tax_identity_number:
                      "Vergi No / TC Kimlik No bilgisi hatalıdır lütfen kontrol ediniz!",
                },
              },
            });
          } else {
            const info = response.data?.info || {};
            const taxOffice = response.data?.taxOffice || {};
            const c = formData.company;
            const gercekTuzel = info.gercekTuzel
            if (info?.companyName) {
              self.setState({
                errors: {
                  ...self.state.errors,
                  company: {
                    ...self.state.errors.company,
                    tax_identity_number: null,
                  },
                },
              });
              self.setState({
                gercekTuzel,
                formData: {
                  ...formData,
                  company: {
                    ...formData.company,
                    firstname: info?.firstname || c.firstname,
                    lastname: info?.lastname || c.lastname || "",
                    tax_office_code: taxOffice?.code || c.tax_office_code,
                    address: info.address || formData.address.address,
                    city_id: info.city_id || formData.address.city_id,
                    county_id: info.county_id || formData.address.county_id
                  },
                },
              });
            } else {
              self.setState({
                errors: {
                  ...self.state.errors,
                  company: {
                    ...self.state.errors.company,
                    tax_identity_number:
                        "Vergi No / TC Kimlik No bilgisi hatalıdır lütfen kontrol ediniz!",
                  },
                },
              });
            }
          }
        }
    );
  }
  componentWillMount() {
    let formData = this.props.formData || this.defaultFormData();
    let errors = {};
    this.props.fetchData("/company", FETCH_COMPANY);

    this.setState({ formData: formData, errors: errors });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.company &&
      this.state.formData.company !== nextProps.company &&
      this.state.saved === false
    ) {
      this.getCompany(HelperContainer.filterObject(nextProps.company));
    }
  }

  getCompany = values => {
    let formData = this.state.formData;
    formData.company = Object.assign({}, values, {});
    this.setState({ formData: formData });
  };

  setFieldChange(type, field, value) {
    let formData = this.state.formData[type];
    if (field === "city_id") {
      if (formData[field] !== value) {
        formData["county_id"] = null;
      }
    }
    formData[field] = value;
    let errors = this.state.errors || {};
    if (errors[type]) errors[type][field] = false;

    let bigForm = this.state.formData;
    bigForm[type] = formData;
    if (type === "company" && field === "tax_office_code") {
      let selected = this.props.taxOffices.filter(data => {
        return data.code === value;
      });
      bigForm.company.tax_office_name = selected[0].name || "";
    }
    this.setState({ formData: bigForm, errors: errors });
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  onSave() {
    const self = this;
    self.setState({ saving: true });

    let phone = self.state.formData.company.phone || "";

    let company = Object.assign({}, self.state.formData.company, {
      phone: phone.replace(/[#() -]/g, ""), // amount_fc
      expiry_date: Util.convertFromDatabaseDate(
        self.state.formData.company.expiry_date
      ),
      lock_before: Util.convertFromDatabaseDate(
        self.state.formData.company.lock_before
      )
    });

    let errors = self.state.errors;

    let url = "/company";
    let func = "put";

    Api[func](url, company, function(response, postErrors) {
      if (postErrors) {
        errors.company = postErrors;
        self.setState({ saving: false, errors: errors });
      } else {
        if (company.id) {
          toastr.success("Başarılı", "Başarıyla Güncellendi");
          let formData = self.state.formData;
          self.setState({ saving: false, errors: {}, saved: true });

          this.props.formData = formData;
        }
        self.props.onSave();
      }
    });
  }

  onCancel() {
    browserHistory.goBack();
  }

  taxOfficeAddNewColumn() {
    let taxOffices = this.props.taxOffices || [];
    taxOffices.map(function(item) {
      if (item && item.city) {
        item.value = item.name + " - " + item.city.name;
      }

      return item;
    });
    return taxOffices;
  }

  sgkKurumTipi(){
return [
  {
    name: 'HASTANE',
    id: 'SAGLIK_HAS'
  },
  {
    name: 'ECZANE',
    id: 'SAGLIK_ECZ'
  },
  {
    name: 'OPTİK',
    id: 'SAGLIK_OPT'
  },
  {
    name: 'MEDİKAL',
    id: 'SAGLIK_MED'
  },
  {
    name: 'ABONELIK',
    id: 'ABONELIK'
  },
  {
    name: 'MAL/HİZMET',
    id: 'MAL_HIZMET'
  },
  {
    name: 'DİĞER',
    id: 'DIGER'
  },
];
  }

  getCounties(id) {
    let counties = this.props.counties || [];
    return counties.filter(data => {
      return data.city_id === id;
    });
  }

  onAccountReset() {
    const user = JSON.parse(localStorage.getItem("user"));
    let today = new Date();
    const d = new Date(user.created_at);
    const t = new Date(today);
    d.setMonth(d.getMonth() + 2);
    console.log(today);
    console.log(t);
    if (today > d){
      toastr.warning("Hesabınızı sıfırlamak için lütfen şirket yetkiliniz destek hattımızdan bizimle iletişime geçsin. Destek hattımız 0850 346 97 78")
    }
    else{

      toastr.confirm(`Bu hesabı sıfırlamak istediğinize emin misiniz? (Dikkat! Tüm verileriniz silinecektir.)`, {
        onOk: () => {
          Api.get("/company/deleteAccountRequest" , function(response, err) {
          });
          toastr.success(`E-Posta adresinize onay linki gönderdik lütfen kontrol ediniz.`)

        },
        onCancel: () => {},
        okText: "Evet",
        cancelText: "Hayır"
      });
    }
  }



  render() {
    console.log("formdata", this.state.formData);
    if (!this.props.company) return <EkoSpinner />;
    let taxOffices = this.taxOfficeAddNewColumn();
    let sgkKurumTipi = this.sgkKurumTipi();
    let cities = this.props.cities || [];
    let counties = this.getCounties(this.state.formData.company.city_id || "");
    document.body.className += " page-header-menu-fixed";

    const companyProps = {
      onChange: this.setFieldChange.bind(this, "company"),
      onMount: this.setFieldMount.bind(this, "company"),
      formData: this.state.formData.company,
      errors: this.state.errors.company
    };
    return (
      <EkoForm formId={"update_company"}>
        <div className="row">
          <div className="col-md-8">
            <p>
              Vergi/TC No
              <EkoFormButton
                  label="Sorgula"
                  style={{marginLeft: "10px"}}
                  isLoading={this.state.vknLoad}
                  onClick={this.onVknSearch.bind(this)}
              />
            </p>
            <EkoFormInputText
                {...companyProps}
                id="tax_identity_number"
                mask="99999999999"
                maskChar=""
                type="mask"
                isVertical={true}
            />
          </div>
        </div>
        <div className="col-md-4 ">
          <EkoFormInputText
            {...companyProps}
            label="Adı/Firma Ünvanı"
            id="firstname"
            isVertical={true}
          />
          <EkoFormInputText
            {...companyProps}
            label="Soyadı/Firma Ünvanı Devamı"
            id="lastname"
            isVertical={true}
          />
          <EkoFormInputText
            {...companyProps}
            label="Mersis No"
            id="mersis_no"
            type="mask"
            maskChar=""
            mask="9999999999999999"
            isVertical={true}
          />
          <EkoFormInputText
            {...companyProps}
            label="İrtibat Telefon No"
            id="phone"
            type="mask"
            mask="(999) 999-99-99"
            isVertical={true}
          />
          <EkoFormInputText
            {...companyProps}
            label="Açık Adres"
            id="address"
            isVertical={true}
          />
          <EkoFormInputText
              {...companyProps}
              label="SGK Mükellef Kodu"
              id="sgk_mukellef_kodu"
              maxLength={"26"}
              isVertical={true}
          />
          {/* <EkoFormInputText {...companyProps}
                                                 label="Noterler Birliği Kullanıcı Adı"
                                                 id="notary_accountant_identity"
                                                 isVertical={true}/>*/}
        </div>
        <div className={"col-md-4 "}>
          <EkoFormSelect
            {...companyProps}
            id="tax_office_code"
            searchable={true}
            optionId={"code"}
            optionValue={"value"}
            isVertical={true}
            defaultText="Seçiniz"
            options={taxOffices}
            label="Vergi Dairesi Adı"
          />

          <EkoFormInputText
            {...companyProps}
            label="Ticaret Sicil No"
            id="trade_register_number"
            type="mask"
            mask="99999999999"
            maskChar=""
            isVertical={true}
          />
          <EkoFormInputText
            {...companyProps}
            label="Şirket/Yetkili E-mail"
            id="email"
            isVertical={true}
          />
          <div className="col-md-4">
            <EkoFormSelect
              {...companyProps}
              searchable={true}
              id="city_id"
              isVertical={true}
              defaultText="Seçiniz"
              options={cities}
              label="İl"
            />
          </div>
          <div className="col-md-4">
            <EkoFormSelect
              {...companyProps}
              searchable={true}
              id="county_id"
              isVertical={true}
              defaultText="Seçiniz"
              options={counties}
              label="İlçe"
            />
          </div>
          <div className="col-md-4">
            <EkoFormInputText
              {...companyProps}
              label="Adres Kodu"
              id="address_code"
              isVertical={true}
            />
          </div>
          <EkoFormSelect
              {...companyProps}
              id="sgk_kurum_tipi"
              searchable={true}
              optionId={"id"}
              optionValue={"name"}
              isVertical={true}
              defaultText="Seçiniz"
              options={sgkKurumTipi}
              label="SGK Kurum Tipi"
          />
          <EkoFormInputText
              {...companyProps}
              label="Şirket Iban Numarası"
              placeHolder={"TR660000000000000000000000"}
              id="iban"
              maxLength={"26"}
              isVertical={true}
          />
          {/*<EkoFormInputText {...companyProps}
                                              label="Noterler Birliği Şifre"
                                              id="notary_accountant_password"
                                              type="password"
                                              isVertical={true}/> */}
        </div>
        <div className="clearfix" />
        <br />
        <div>
          <EkoFormButton
            label={"Kaydet"}
            faClass="fa fa-plus"
            isLoading={this.state.saving}
            onClick={this.onSave.bind(this)}
          />
          <EkoFormButton
            label="İptal"
            className="btn"
            onClick={() => browserHistory.goBack()}
          />
          <EkoFormButton
              label="Hesabımı Sıfırla"
              style={{backgroundColor: "#f02a00", color:"#FFFFFF", float: "right"}}
              className="btn"
              onClick={() => this.onAccountReset()}

          />
        </div>
      </EkoForm>
    );
  }
}

function mapStateToProps(state) {
  return {
    taxOffices: state.data.taxOffices,
    cities: state.data.cities,
    counties: state.data.counties,
    company: state.data.company
  };
}

export default connect(mapStateToProps, actions)(UpdateCompany);
