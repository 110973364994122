import React, { Component } from "react";
import {
    EkoForm,
    EkoFormButton,
    EkoFormSelect,
} from "../../components/elements/EkoForm";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { toastr } from "react-redux-toastr";

import HelperContainer from "../../util/HelperContainer";
import EkoSpinner from "../../components/elements/EkoSpinner";
import Util from "../../util/Util";
import Api from "../../util/Api";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { empty } from "rxjs/Observer";
import {FETCH_DECLARATION_TYPES} from "../../actions/types";
import axios from "axios";
import {ROOT_URL} from "../../actions";

class SoftwareSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            loading: false,
            currentCompanySettings: {},
            newCompanySettings: {},
        };
    }
    componentWillMount() {
        let errors = {};
        this.setState({ errors: errors });
    }
    async componentDidMount() {
        await this.getCurrentCompanySettings();
        this.props.changePageTitle("Yazılım Ayarları");
        this.setState({ loading: false });
    }
    async getCurrentCompanySettings() {
        try {
            this.setState({ loading: true });
            const response = await axios.get(ROOT_URL + "/getCompanySettings", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            const currentCompanySettings = response.data.result || {};
            localStorage.setItem("company_settings", JSON.stringify(currentCompanySettings));
            this.setState({ currentCompanySettings });
            this.setState({ loading: false });
        } catch (error) {
            this.setState({ loading: false });
            console.error("Error fetching company settings:", error);
            toastr.error("Ayarlar yüklenirken bir hata oluştu.");
        }
    }
    onInputChange(id, value) {
        let newCompanySettings = this.state.newCompanySettings || {};
        newCompanySettings[id] = value;
        this.setState({ newCompanySettings });
        console.log(newCompanySettings);
    }
    onSaveForm() {
        this.setState({loading: true});
        let newCompanySettings = this.state.newCompanySettings;
        Api.post(
        "/updateCompanySettings", newCompanySettings,
        (response, postErrors) => {
            if (response.result == 1) {
                toastr.success("Başarılı", "Ayarlarınız güncellendi");
                this.setState({loading: false});
                this.getCurrentCompanySettings();
            }
            else{
                toastr.error("Hata", "Ayarlarınız güncellenemedi");
                this.setState({loading: false});
            }
            if (postErrors) {
                this.setState({errors: postErrors});
                this.setState({loading: false});
            }
        });
    }
    render() {
        if (this.state.loading) {
            return (
                <div style={{ textAlign: "center", paddingTop: "100px" }}>
                    <EkoSpinner />
                    <p>Ayarlar yükleniyor...</p>
                </div>
            );
        }
        return (
            <div>
                <a
                    style={{ bottom: "6px", position: "relative", color: "grey" }}
                    onClick={Util.backButton.bind(this, "/dashboard")}
                >
                    <i className="fa fa-arrow-left"></i> Geri Dön
                </a>
                <div className="portlet light">
                    <div className="portlet-title">
                        <div className="caption font-dark">
                            <span className="caption-subject bold uppercase">Yazılım Ayarları</span>
                        </div>
                    </div>
                    <div className="portlet-body">
                        <EkoForm hortizonal={false} formId="add-new-declarations">
                            <div className="row">
                                <div className={"col-md-4"}>
                                    <select className={"form-control"} onChange={(e) => this.onInputChange("is_barcode_usage", e.target.value)} style={{}}>
                                        <option value={0} selected={this.state.currentCompanySettings.is_barcode_usage == 0}>Barkod Kullanımı: Kapalı</option>
                                        <option value={1} selected={this.state.currentCompanySettings.is_barcode_usage == 1}>Barkod Kullanımı: Açık</option>
                                    </select>
                                </div>
                            </div>
                            <div className={"row"} style={{marginTop: "30px"}}>
                                <div className={"col-md-12"}>
                                    <EkoFormButton
                                        label="Ayarları Kaydet"
                                        faClass="fa fa-eye"
                                        onClick={this.onSaveForm.bind(this)}
                                    />
                                </div>
                            </div>
                        </EkoForm>

                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions,
    };
}
export default connect(mapStateToProps, actions)(SoftwareSettings);
