import React, { Component } from "react";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";

class ProductPlaningList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planing: [],
            loading: true,
            showDelete: true,
        };
    }
    fetchPlans() {
        Api.get("/production-planing/list", (res, err) => {
            if (!err && res.status === "ok") {
                this.setState({
                    planing: res.items,
                    loading: false,
                });
            } else {
                toastr.error("Hata!", res.message);
            }
        });
    }
    componentWillMount() {
        this.fetchPlans();
    }

    openInsert() {
        window.location.replace("/dashboard/production-planing/add");
    }

    openUpdate(row) {
        window.location.replace("/dashboard/production-planing/edit/" + row.id);
    }

    setProduced(plan) {
        Api.put(
            "/production-planing/setProduced",
            {
                plan_id: plan.id,
            },
            (resp, err) => {
                if (!err) {
                    toastr.success("Başarılı!", "Plan başarıyla Üretildi işaretlendi.");
                    this.fetchPlans();
                } else {
                    toastr.error("Hata", resp.message);
                }
            }
        );
    }

    onDelete({ id }) {
        Api.delete("/production-planing/" + id, (response, errors) => {
            if (response) {
                this.fetchPlans();
            }
        });
    }

    onSelectedRowChange(row) {
        /*this.setState({
            showDelete: row.status.toLowerCase() !== "üretildi",
        });*/
    }

    render() {
        const insertMenu =
            this.props.viewOptions && this.props.viewOptions.transferWarehouses
                ? this.props.viewOptions.transferWarehouses.addRecordMenu
                : false;

        return (
            <div>
                <EkoDataTable
                    insertMenu={insertMenu}
                    name={"production-planing"}
                    onSelectedRowChange={this.onSelectedRowChange.bind(this)}
                    openInsert={this.openInsert.bind(this)}
                    openUpdate={this.openUpdate.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    data={this.state.planing}
                    loading={this.state.loading}
                    showDelete={this.state.showDelete}
                    setProduced={this.setProduced}
                    title={"Üretim Planlama"}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.data.planing ? state.data.planing.planing : undefined,
        viewOptions: state.data.viewOptions,
    };
};

export default connect(mapStateToProps, actions)(ProductPlaningList);
