import React, { Component } from "react";
import Layout from "../../components/layouts/Dashboard";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { FETCH_PLAN_CHECK, FETCH_PLANS } from "../../actions/types";
import EkoSpinner from "../../components/elements/EkoSpinner";
import { browserHistory } from "react-router";
import PromotionModal from "../Promotion/PromotionModal";
import Api from "../../util/Api";
import ReduxToastr, {toastr} from 'react-redux-toastr'
import {EkoFormSwitch} from "../../components/elements/EkoForm";


class Subscriber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectId: 0,
      payment_type: 'aylik',
      modulesList: [],
      selectedModules: [],
      switchValue:false,
      bank_account_integration_package:"1",
      kontor:"0",
      kontor_title:"Kontör Seçin"
    };
  }

  componentWillMount() {
    this.getModulesList();
    this.props.fetchData("/plan/showAll", FETCH_PLANS);
    this.props.fetchData("/plan/checkPlans", FETCH_PLAN_CHECK);
  }

  componentWillReceiveProps(nextProps) {
    const user = JSON.parse(localStorage.getItem('user'));
    //this.selectPackage(user.company_type != 9 && user.company_type!=6?4:7);
    if (nextProps.checkPlans) {
      if (
        !nextProps.checkPlans.isOwner &&
        parseInt(nextProps.checkPlans.expiryDays, 10) !== 0
      )
        this.props.router.push("/dashboard");
    }
  }

  selectPackage(id) {
    if(id==9){
      //this.selectModules(1);
      this.selectModules(2);
      this.selectModules(3);
      this.selectModules(4);
      this.selectModules(5);
      this.selectModules(6);
    }else{
      this.setState({selectedModules:[]});
    }
    this.setState({ selectId: id });
    if(id==4 || id==9 || id==10){
        this.setState({kontor_title:"e-Fatura Paketi Kontör Seçin"});
    }else if(id==7){
      this.setState({kontor_title:"e-Smm Paketi Kontör Seçin"});
    }else if(id==8){
      this.setState({kontor_title:"e-Ticaret Paketi Kontör Seçin"});
    }
  }
  selectModules(id) {
    const { selectedModules } = this.state;
    const isSelected = selectedModules.includes(id);

    if (isSelected) {
      //@oguz Eğer id zaten seçiliyse, onu listeden kaldır.
      this.setState((prevState) => ({
        selectedModules: prevState.selectedModules.filter((moduleId) => moduleId !== id),
      }));
    } else {
      //@oguz Eğer id seçili değilse, onu listeye ekle.
      this.setState((prevState) => ({
        selectedModules: [...prevState.selectedModules, id],
      }));
    }
  }
  getSelectedPackageBankAccountIntegrationPackage(id) {
    const { selectedModules } = this.state;
    const x = selectedModules.includes(1);
    if(x===false){
        return ["",0];
    }
    const foundItem = this.state.modulesExtra.hesap_hareketi.find(item => item.id === parseInt(id, 10));
    if(foundItem){
      return [foundItem.name,parseInt(foundItem.price),parseInt(foundItem.val)];
    } else {
      return ["",0,0];
    }
  }

  getSelectedKontor(id) {
    if (this.state.modulesExtra && this.state.modulesExtra.kontor) {
      const foundItem = this.state.modulesExtra.kontor.find(item => item.id === parseInt(id, 10));
      if(foundItem){
        return [foundItem.name,parseInt(foundItem.price),parseInt(foundItem.val)];
      } else {
        return ["",0,0];
      }
    }else{
      return ["",0,0];
    }
  }

  openModal() {
    if(this.totalAmount()!==0){
    const checkPlans = this.props.checkPlans;
    var modulesExtra = {
      'kontor_id': this.state.kontor,
      'kontor_name': this.getSelectedKontor(this.state.kontor)[0],
      'kontor_price': this.getSelectedKontor(this.state.kontor)[1],
      'kontor_val': this.getSelectedKontor(this.state.kontor)[2],
      'bank_account_integration_package_id': this.state.bank_account_integration_package,
      'bank_account_integration_package_name': this.getSelectedPackageBankAccountIntegrationPackage(this.state.bank_account_integration_package)[0],
      'bank_account_integration_package_price': this.getSelectedPackageBankAccountIntegrationPackage(this.state.bank_account_integration_package)[1],
      'bank_account_integration_package_val': this.getSelectedPackageBankAccountIntegrationPackage(this.state.bank_account_integration_package)[2],
    };
    var cart_body = {
        "payment_type":this.state.payment_type,
        "plan_id":this.state.selectId,
        "modules":this.state.selectedModules,
        "modules_extra":modulesExtra,
        "payment_amount":this.totalAmount() + this.totalAmount()*0.20,
        "vat_amount":this.totalAmount()*0.20,
        "total_amount":this.totalAmount() + this.totalAmount()*0.20,
    };
    localStorage.setItem("cart_body", JSON.stringify(cart_body, null, 2));
    if (parseInt(checkPlans.isSubscribed, 10) === 0 && !checkPlans.campaign)
      this.setState({ showModal: true });
    else this.go();
  }else{
    toastr.error("Hata", "Lütfen Seçim Yapınız.");
    }
  }

  go() {
    //if (this.state.selectId)
      browserHistory.push("subscriberPayment/" + this.state.selectId);
  }
  paymentTypeChange(type){
    this.setState({payment_type:type});
  }
  isModuleSelected(id) {
    return this.state.selectedModules.includes(id);
  }
  updateSwitch(newSwitchStatus) {
    let _self = this;
    _self.setState({ switchValue: newSwitchStatus });
  }
  updateBankIntegrationPackage(val) {
    this.setState({ bank_account_integration_package: val }, () => {

    });
  }
  updateKontorVal(val) {
    this.setState({ kontor: val }, () => {

    });
  }
  getModulesList() {
    var _self = this;
    Api.get("/plan/getModulesList", (response, err) => {
        if (err) {
            toastr.error("Hata", "Modüller listelenirken hata oluştu.");
        } else {
            _self.setState({ modulesList: response.result });
        }
    });
    //
    Api.get("/plan/getModulesExtraList", (response, err) => {
      if (err) {
        toastr.error("Hata", "Modüller Extra listelenirken hata oluştu.");
      } else {
        _self.setState({ modulesExtra: response });
      }
    });
  }
  getSelectedPackageData(){
    let checkPlans = this.props.checkPlans;
    let plans = this.props.plans && checkPlans.isOwner ? this.props.plans : [];
    let newPlans = plans.filter(plan => plan.is_web === 1);
    var rt = "";
    newPlans.map((item, index) => {
      if(this.state.selectId===item.id){
        rt = {'desc':item.description,"price":(this.state.payment_type==='aylik'?parseInt(item.price_exc_vat, 10):parseInt(item.annually_price, 10))+" ₺"};
      }
    })
    return rt;
  }
  totalAmount() {
    const { modulesList, selectedModules } = this.state;
    let checkPlans = this.props.checkPlans;
    let plans = this.props.plans && checkPlans.isOwner ? this.props.plans : [];
    let newPlans = plans.filter(plan => plan.is_web === 1);

    let toplam = 0;
    selectedModules.forEach((selectedId) => {
      const selectedModule = modulesList.find((module) => module.id === selectedId);
      if (selectedModule) {
        if(this.state.selectId===10){
          toplam += parseInt(this.state.payment_type==='aylik'?selectedModule.enterprise_price_monthly:selectedModule.enterprise_price_yearly, 10);
        }else{
          toplam += parseInt(this.state.payment_type==='aylik'?selectedModule.price_monthly:selectedModule.price_yearly, 10);
        }
      }
    });
    if(this.state.selectId===9) {
      toplam = 0;
    }
    //
    newPlans.map((item, index) => {
      if(this.state.selectId===item.id){
        toplam += parseInt(this.state.payment_type==='aylik'?item.price_exc_vat:item.annually_price, 10);
      }
    })

    return toplam+
        this.getSelectedPackageBankAccountIntegrationPackage(this.state.bank_account_integration_package)[1]+
        this.getSelectedKontor(this.state.kontor)[1]
        ;
  }
    generateSelectedModulesJSX() {
        return this.state.selectedModules.length>0?this.state.selectedModules.map((selectedId) => {
            const selectedModule = this.state.modulesList.find((module) => module.id === selectedId);
            if (selectedModule) {
                return (
                    <div className={"row"} key={selectedModule.id}>
                        <div className={"col-md-6"} style={{"fontSize": "18px"}}>{selectedModule.name}</div>
                        <div className={"col-md-4"} style={{"fontSize": "18px"}}>{
                            this.state.selectId===10?
                                parseInt(this.state.payment_type==='aylik'?selectedModule.enterprise_price_monthly:selectedModule.enterprise_price_yearly, 10)
                                    :
                                this.state.selectId===9
                                    ?
                                parseInt(0, 10)+" ₺"
                                    :
                                parseInt(this.state.payment_type==='aylik'?selectedModule.price_monthly:selectedModule.price_yearly, 10)+" ₺"
                        }</div>
                        <div className={"col-md-2"} style={{"color": "red", "fontSize": "18px"}} onClick={this.selectModules.bind(this, selectedModule.id)}>X</div>
                      {
                        selectedModule.id===1?
                          <div className={"col-md-12 text-center"} style={{"fontSize": "14px","color":"gray"}}>{this.getSelectedPackageBankAccountIntegrationPackage(this.state.bank_account_integration_package)[0]} ({this.getSelectedPackageBankAccountIntegrationPackage(this.state.bank_account_integration_package)[1]} TL)</div>
                            :""
                      }
                    </div>
                );
            }
            return null;
        }):null;
    }
  render() {
    if (!this.props.checkPlans || !this.props.plans) return <EkoSpinner />;
    let checkPlans = this.props.checkPlans;
    let plans = this.props.plans && checkPlans.isOwner ? this.props.plans : [];
    let newPlans = plans.filter(plan => plan.is_web === 1);
    const user = JSON.parse(localStorage.getItem('user'));
    return (
      <Layout visibleMenu={true} subscribeDesc={checkPlans.isOwner}>
        <div className={"row"} style={{"width":"600px","margin":"0px auto"}}>
          <div onClick={this.paymentTypeChange.bind(this,'aylik')} className={"col-md-12 subs_kutu "+(this.state.payment_type==='aylik'?"subs_kutu_active":"")} style={{"width":"48%","padding":"0px","height":"120px"}}>
            <h3>Aylık Ödeme</h3>
          </div>
          <div onClick={this.paymentTypeChange.bind(this,'yillik')} className={"col-md-12 subs_kutu "+(this.state.payment_type==='yillik'?"subs_kutu_active":"")} style={{"width":"48%","padding":"0px","height":"120px"}}>
            <h3>Yıllık Ödeme</h3>
            <h2>%40 <span style={{"color":"gray","fontSize":"16px"}}>Kazanın</span></h2>
          </div>
          {user.company_type != 9 && user.company_type!=6?<div style={{"marginTop":"160px","marginBottom":"20px"}}>
            <div className="row">
              <div onClick={(newSwitchStatus) => this.updateSwitch(false)} className={this.state.switchValue?"col-md-3 subs_kutu":"col-md-3 subs_kutu subs_kutu_active"} style={{"height":"60px","width":"48%"}}>İşletmem 50 Çalışandan Az</div>
              <div onClick={(newSwitchStatus) => this.updateSwitch(true)} className={this.state.switchValue?"col-md-3 subs_kutu subs_kutu_active":"col-md-3 subs_kutu"} style={{"height":"60px","width":"48%"}}>İşletmem 50 Çalışan ve Üstü</div>
            </div>
          </div>:""}
        </div>
        {checkPlans &&
        parseInt(checkPlans.isSubscribed, 10) === 0 &&
        checkPlans.isOwner ? (
          <div className="campaign-end">
            <i className="fa fa-clock-o fa-3x" aria-hidden="true" />
            <h4 className="alert-heading">
              <b>
                {checkPlans && checkPlans.expiryDays === 0
                  ? "Deneme Süreniz Sona Erdi"
                  : "Deneme sürenizin bitmesine " +
                    checkPlans.expiryDays +
                    " gün kaldı"}
              </b>
            </h4>
            <p>
              Size uygun paketi seçerek aboneliğinizi başlatabilir ve EkoHesap'ı
              kullanmaya devam edebilirsiniz.
            </p>
            <div className="clearfix" />
          </div>
        ) : (
          ""
        )}
        <div className="row flex">
          {!checkPlans.isOwner ? (
            <div
              className="campaign-end col-md-6"
              style={{
                margin: "auto",
                backgroundColor: "white",
                textAlign: "center"
              }}
            >
              <p style={{ color: "black", fontWeight: "bold" }}>
                Üyelik süreniz dolmuştur. Hesabınızı kullanmaya devam edebilmek
                için ödeme yapmanız gerekmektedir. Lütfen hesap sahibi ile
                iletişime geçiniz.
              </p>
              <div className="clearfix" />
            </div>
          ) : (
            ""
          )}
          {newPlans.map((item, index) => {
            let contents = item.content.split(",");
            return (
                <div key={index} className={`col-md-4 subscription-center ${this.state.switchValue === true && item.id === 10 || this.state.switchValue === false && item.id !== 10 ? '' : 'hidden'}`}>
                <div
                  className="portlet light subscription-height"
                  style={{
                    minHeight: 660
                  }}
                >
                  <div className="portlet-title">
                    <div className="caption  text-center">
                      <span className="caption-subject font-red-sharp bold uppercase">
                        {item.description}
                      </span>
                    </div>
                  </div>
                  <table className="tableFeature">
                    <tbody>
                      {contents.map((content, index) => {
                        return (
                          <tr style={{ color: "#333" }} key={index}>
                            <td dangerouslySetInnerHTML={{ __html: content }} />
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="clearfix" />

                  <div className="subscription-price">
                    <span className="text-features">
                      <span>
                        <span
                          style={{
                            fontSize: 22
                          }}
                        >
                          {this.state.payment_type==='aylik'?parseInt(item.price_exc_vat, 10):parseInt(item.annually_price, 10)}
                        </span>
                        <span
                          style={{
                            marginBottom: 3,
                            fontSize: 25
                          }}
                        >
                          ₺
                        </span>{" "}
                      </span>{" "}
                      / {this.state.payment_type==='aylik'?"Aylık":"Yıllık"}
                    </span>
                    <br />
                    <button
                      style={{
                        marginTop: 10,
                        backgroundColor:(this.state.selectId===item.id?"red":"gray")
                      }}
                      onClick={this.selectPackage.bind(this, item.id)}
                      className="btn red flex-width"
                      data-toggle="modal"
                    >
                      Paketi Seç
                    </button>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
            );
          })}
          <PromotionModal
            showModal={this.state.showModal}
            buttonName="Promosyon Kodum Yok"
            closeModal={() => {
              this.setState({ showModal: false });
              this.go();
            }}
          />
        </div>
        <div className={"row"}>
          <div className={"col-md-12 subs_kutu"} style={{"width":"99%","padding":"0px","height":"130px"}}>
            <h3>Ek Modül Seçin</h3>
            <p><span style={{"fontWeight":"bold"}}>Aşağıdaki ek modüller; işletmenizi, hesaplarınızı daha kolay ve rahat yönetmenizi sağlar.</span><br />Kullanıcılarımızın %84'ü en az 1 ek modül kullanıyor.<br />Not: Basit paket seçiminde herhangi bir ek modül desteklenmemektedir.</p>
          </div>
          {this.state.modulesList.map((item, index) => {
            return (
                <div className={"col-md-12 subs_kutu"} style={{"display":this.state.selectId==7 && (item.id==2||item.id==4||item.id==5||item.id==6)?" none":""}}>
                  <i className={item.icon}></i>
                  <h3>{item.name}</h3>
                  <p>
                    {item.desc}
                    <br />
                    <div className={"row"}>
                      <div className={"col-md-12"}>
                        {item.id === 1 ? (
                            <select
                                onChange={(val) => this.updateBankIntegrationPackage(val.target.value)}
                                className={"form-control"}
                                style={{width: "300px", margin: "0px auto"}}
                            >
                              {/* İlk seçenek olarak "Seçiniz" */}
                              <option value="" disabled selected>
                                Seçiniz
                              </option>

                              {this.state.modulesExtra?.hesap_hareketi &&
                                  this.state.modulesExtra.hesap_hareketi
                                      .filter((e) => {
                                        // Yıllık veya aylık filtreleme
                                        if (this.state.payment_type === "yillik") {
                                          return e.name.toLowerCase().includes("yıllık");
                                        } else if (this.state.payment_type === "aylik") {
                                          return e.name.toLowerCase().includes("aylık");
                                        }
                                        return true; // Varsayılan olarak tümünü göster
                                      })
                                      .map((e, key) => {
                                        return (
                                            <option key={e.id} value={e.id}>
                                              {e.name + " (" + e.price + " TL)"}
                                            </option>
                                        );
                                      })}
                            </select>
                        ) : (
                            ""
                        )}
                      </div>

                      <div className={"col-md-2"}>&nbsp;</div>
                      <div className={"col-md-5"}>
                        {item.id !== 1 ? <h3 className={"price"}>{
                          this.state.selectId === 10 ?
                              this.state.payment_type === 'aylik' ? parseInt(item.enterprise_price_monthly, 10) : parseInt(item.enterprise_price_yearly, 10) :
                              this.state.payment_type === 'aylik' ? parseInt(item.price_monthly, 10) : parseInt(item.price_yearly, 10)} ₺
                          / {this.state.payment_type === 'aylik' ? "Aylık" : "Yıllık"}
                        </h3> : ""}
                      </div>
                      <div className={"col-md-3"}>
                        <a style={{backgroundColor: "red"}} disabled={this.isModuleSelected(item.id)}
                           onClick={this.selectModules.bind(this, item.id)} className={"btn red sec"}>Ekle</a>
                      </div>
                    </div>
                  </p>
                </div>
            );
          })}
          <div className={"row"}>
            {this.state.selectId != 3 ?
                <div className={"col-md-12 subs_kutu"} style={{"width": "33%", "padding": "0px", "height": "110px"}}>
                  <h3>{this.state.kontor_title}</h3>
                  <select onChange={(val) => this.updateKontorVal(val.target.value)} className={"form-control"}
                          style={{"width": "300px", "margin": "0px auto"}}>
                    <option value={0}>Kontör İstemiyorum</option>
                    {this.state.modulesExtra?.kontor && this.state.modulesExtra.kontor.map((e, key) => {
                      return <option key={e.id} value={e.id}>{e.name + " (" + e.price + " TL)"}</option>;
                    })}
                  </select>
                </div> : ""}
            <div style={{"clear": "both"}}></div>
            <div className={"col-md-12 subs_kutu"} style={{"width": "48%", "padding": "0px", "height": "270px"}}>
              <h3>Seçilen Paket ve Modüller</h3>
              {
                this.state.selectId !== 0 ? <div className={"row"}>
                  <div className={"col-md-6"} style={{"fontSize":"18px"}}>{this.getSelectedPackageData()['desc']}</div>
                  <div className={"col-md-4"} style={{"fontSize":"18px"}}>{this.getSelectedPackageData()['price']}</div>
                  <div className={"col-md-2"} style={{"color": "red", "fontSize": "18px"}} onClick={this.selectPackage.bind(this,0)}>X</div>
                </div>:""
              }
              {
                this.state.selectId!==0 && this.state.kontor !=="0" ?<div className={"row"}>
                  <div className={"col-md-12"}  style={{"fontSize": "14px","color":"gray"}}>{this.getSelectedKontor(this.state.kontor)[0]} ({this.getSelectedKontor(this.state.kontor)[1]} TL)</div>
                </div>:""
              }
              {this.generateSelectedModulesJSX()}
            </div>
            <div className={"col-md-12 subs_kutu"} style={{"width":"48%","padding":"0px","height":"270px"}}>
              <h3>Sepet Toplam ve Ödeme Adımı</h3>
              <h4>Sepet Toplamı: {this.totalAmount()} ₺</h4>
              <h4>Kdv Toplamı: {this.totalAmount()*0.20} ₺</h4>
              <h4>Genel Toplam: {this.totalAmount() + this.totalAmount()*0.20} ₺</h4>
              <a onClick={this.openModal.bind(this)} className={"btn btn-primary sec"}>Ödeme Adımına Git</a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    pageLoading: state.page.pageLoading,
    pageTitle: state.page.title,
    plans: state.data.plans,
    checkPlans: state.data.checkPlans
  };
}

export default connect(mapStateToProps, actions)(Subscriber);
