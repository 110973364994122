import React, { Component } from 'react';
import { EkoForm, EkoFormButton } from "../../components/elements/EkoForm";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { browserHistory } from "react-router";
import { FiEye, FiEyeOff } from 'react-icons/fi';

class GovermentLoginInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SMMApiKey: "",
            SMMApiSecret: "",
            EArsivUsername: "",
            EArsivPassword: "",
            VergiKimlikNo: "",
            TCKimlikNo: "",
            showPasswords: {
                SMMApiKey: false,
                SMMApiSecret: false,
                EArsivUsername: false,
                EArsivPassword: false,
            }
        };
    }

    componentWillMount() {
        this.fetch();
    }

    fetch() {
        Api.get("/company/goverment-information", (data, errors) => {
            if (errors) {
                this.setState({ dataLoading: false });
                return;
            }
            data = data.data;
            this.setState({
                SMMApiKey: data.SMMApiKey,
                SMMApiSecret: data.SMMApiSecret,
                EArsivUsername: data.EArsivUsername,
                EArsivPassword: data.EArsivPassword,
                VergiKimlikNo: data.VergiKimlikNo,
                TCKimlikNo: data.TCKimlikNo,
            });
        });
    }

    togglePasswordVisibility = (key) => {
        this.setState((prevState) => ({
            showPasswords: {
                ...prevState.showPasswords,
                [key]: !prevState.showPasswords[key],
            }
        }));
    };

    onSave() {
        const params = {
            SMMApiKey: this.state.SMMApiKey,
            SMMApiSecret: this.state.SMMApiSecret,
            EArsivUsername: this.state.EArsivUsername,
            EArsivPassword: this.state.EArsivPassword,
            VergiKimlikNo: this.state.VergiKimlikNo,
            TCKimlikNo: this.state.TCKimlikNo,
        };
        if(this.state.EArsivUsername){
            if(this.state.EArsivUsername=='' || this.state.EArsivUsername.length>=11){
                toastr.error("Hata!", "Kaydedilmedi. Girilen Earşiv VKN Bilgisi Yanlış");
                return false;
            }
            if (isNaN(this.state.EArsivUsername)) {
                toastr.error("Hata!", "Kaydedilmedi. Girilen Earşiv VKN Bilgisi Yanlış");
                return false;
            }
        }
        Api.post("/company/goverment-information-update", params, (response, err) => {
            if (err != false) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                toastr.success("Başarılı", "Bilgiler Başarıyla Güncellendi.");
            }
        });
    }

    renderPasswordInput(label, stateKey) {
        const isPasswordVisible = this.state.showPasswords[stateKey];
        return (
            <div style={{ position: "relative", marginBottom: "20px" }}>
                <p style={{ padding: "0", margin: "0" }}>{label}</p>
                <input
                    placeholder={label}
                    type={isPasswordVisible ? "text" : "password"}
                    className="form-control"
                    value={this.state[stateKey]}
                    onChange={(evt) => this.setState({ [stateKey]: evt.target.value })}
                />
                <span
                    style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer"
                    }}
                    onClick={() => this.togglePasswordVisibility(stateKey)}
                >
                    {isPasswordVisible ? <FiEyeOff style={{"marginTop":"24px"}} /> : <FiEye style={{"marginTop":"24px"}} />}
                </span>
            </div>
        );
    }

    render() {
        return (
            <>
                <EkoForm>
                    <div className="row">
                        <div className="col-md-6" style={{ borderRight: "3px solid #ddd", padding: "20px" }}>
                            <h3 className="h3 text-center">Defter Beyan Sistemi Bilgileri</h3>
                            {this.renderPasswordInput("SMM Api Key", "SMMApiKey")}
                            {this.renderPasswordInput("SMM Api Secret", "SMMApiSecret")}
                        </div>
                        <div className="col-md-6" style={{ padding: "20px" }}>
                            <h3 className="h3 text-center">Interaktif / E-Arşiv Portal Bilgileri</h3>
                            {this.renderPasswordInput("E-Arşiv Portal Kullanıcı Adı", "EArsivUsername")}
                            {this.renderPasswordInput("E-Arşiv Portal Parola", "EArsivPassword")}
                            <br />
                            <p style={{ padding: "0", margin: "0" }}>Vergi Kimlik No</p>
                            <input
                                placeholder={"Vergi Kimlik No"}
                                type="text"
                                className="form-control"
                                value={this.state.VergiKimlikNo}
                                onChange={(evt) => this.setState({ VergiKimlikNo: evt.target.value })}
                            />
                            <br />
                            <p style={{ padding: "0", margin: "0" }}>TC Kimlik No</p>
                            <input
                                placeholder={"TC Kimlik No"}
                                type="text"
                                className="form-control"
                                value={this.state.TCKimlikNo}
                                onChange={(evt) => this.setState({ TCKimlikNo: evt.target.value })}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                        <EkoFormButton
                            label={"Kaydet"}
                            faClass="fa fa-plus"
                            isLoading={this.state.saving}
                            onClick={() => this.onSave()}
                        />
                        <EkoFormButton
                            label="İptal"
                            className="btn"
                            onClick={() => browserHistory.goBack()}
                        />
                    </div>
                </EkoForm>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.data.company
    };
}

export default connect(mapStateToProps, actions)(GovermentLoginInformation);
