import React, {Component} from 'react'
import {EkoForm, EkoFormButton, EkoFormInputText} from '../../components/elements/EkoForm'
import {Modal} from 'react-bootstrap'
import Api from '../../util/Api'
import * as actions from '../../actions'
import {connect} from 'react-redux'
import EkoModal from '../../components/elements/EkoModal'
import HelperContainer from '../../util/HelperContainer'
import {toastr} from 'react-redux-toastr'
import {browserHistory} from 'react-router'
import EkoSpinner from '../../components/elements/EkoSpinner'
import Util from "../../util/Util";
import {FETCH_USER,} from '../../actions/types'

class UpdateUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {user: {}, password: {}},
            saving: false,
            savingModal: false,
            showModal: false,
            errors: {},
            formFields: {},
        }
    }

    componentWillMount() {
        this.props.fetchData('/user', FETCH_USER);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user && this.state.formData.user !== nextProps.user) {
            let user = HelperContainer.filterObject(nextProps.user);
            delete user['is_locked']
            delete user['activation_code']
            delete user['is_activated']
            let formData = this.state.formData;
            formData.user = Object.assign({}, user, {})
            this.setState({formData: formData});
        }
    }

    setFieldChange(type, field, value) {

        console.log(type);
        console.log(field);
        console.log(value);

        let formData = this.state.formData[type];
        formData[field] = value;
        let errors = this.state.errors || {};


        if (errors[type] && errors[type][field])
            errors[type][field] = false;
        let state = this.state;
        state.formData[type] = formData;
        state.errors = errors;
        this.setState(state);
    }

    setFieldMount(type, field) {
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        }
        else formFields[type].push(field);
        this.setState({formFields: formFields});
    }

    onCancel() {
        browserHistory.goBack();
    }

    onSave() {
        const self = this;
        self.setState({saving: true});

        let phone = self.state.formData.user.phone || "";
        let id = self.state.formData.user.id;

        let user = Object.assign({}, self.state.formData.user, {
            phone: phone.replace(/[#() -]/g, ''),
            updated_id: id,
        });

        let errors = self.state.errors;

        let url = "/user";
        let func = "put";

        Api[func](url, user, function (response, postErrors) {
            if (postErrors) {
                errors.user = postErrors;
                self.setState({saving: false, errors: errors});
            } else {
                if (user.id) {
                    toastr.success('Başarılı', 'Başarıyla Güncellendi');
                    self.setState({saving: false, errors: {}});
                }
            }

        });
    }

    closeModal() {
        let formData = Object.assign({}, this.state.formData, {
            password: {}
        })
        this.setState({showModal: false, formData: formData, errors: {}})
    }

    onSaveModal() {
        const self = this;
        let post = self.state.formData.password;
        let errors = self.state.errors;

        let url = "/user/changePassword";
        let func = "put";

        Api[func](url, post, function (response, postErrors) {
            if (postErrors) {
                errors.password = postErrors;
                self.setState({saving: false, errors: errors});
            } else {
                toastr.success('Başarılı', response.message);
                self.setState({saving: false, errors: {}});
                self.closeModal();
            }

        });
    }

    render() {
        if (!this.props.user) return <EkoSpinner/>
        const passwordProps = {
            onChange: this.setFieldChange.bind(this, "password"),
            onMount: this.setFieldMount.bind(this, "password"),
            formData: this.state.formData.password,
            errors: this.state.errors.password
        }
        const userProps = {
            onChange: this.setFieldChange.bind(this, "user"),
            onMount: this.setFieldMount.bind(this, "user"),
            formData: this.state.formData.user,
            errors: this.state.errors.user
        }

        var phone = this.state.formData.user.phone || "";
        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
                <div className="portlet light ">
                    <div className="portlet-title">
                        <div className="caption font-dark">
                            <span className="caption-subject bold uppercase"> Profilim</span>
                        </div>
                    </div>
                    <div className="portlet-body">
                        <div className="row">
                            {phone === '' ? (
                                <div style={{ color: 'red', padding: '10px', border: '1px solid red', borderRadius: '5px' }}>
                                    Telefon numarası boş olamaz! Devam etmek için lütfen telefon numaranızı giriniz.
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="col-md-3">
                                <EkoFormInputText {...userProps}
                                                  id="firstname"
                                                  label="Ad"
                                                  isVertical
                                                  iconClass={'icon-user'}>
                                </EkoFormInputText>
                            </div>
                            <div className="col-md-3">
                                <EkoFormInputText {...userProps}
                                                  id="lastname"
                                                  label="Soyad"
                                                  iconClass={'icon-user'}
                                                  isVertical/>
                            </div>
                            <div className="col-md-3">
                                <EkoFormInputText {...userProps}
                                                  id="email"
                                                  label="E-posta"
                                                  disabled={true}
                                                  isVertical
                                                  iconClass={'icon-envelope'}>
                                </EkoFormInputText>
                            </div>
                            <div className="col-md-3">
                                <EkoFormInputText {...userProps}
                                                  id="phone"
                                                  label="Telefon"
                                                  type="mask"
                                                  mask="(999) 999-99-99"
                                                  iconClass={'icon-screen-smartphone'}
                                                  isVertical/>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="buttonProccess">
                        <EkoFormButton label="Şifremi Değiştir"
                                       className="btn btn-default red"
                                       faClass="fa fa-lock"
                                       onClick={() => {
                                           this.setState({showModal: true})
                                       }}
                        />
                        <EkoFormButton label={'Kaydet'}
                                       faClass="fa fa-plus"
                                       isLoading={this.state.saving}
                                       onClick={this.onSave.bind(this)}
                        />
                        <EkoFormButton label="İptal" className="btn"
                                       onClick={() => browserHistory.goBack()}
                        />
                    </div>
                    <EkoModal
                        showModal={this.state.showModal}
                        title={'Şifre Güncelle'}
                        onHide={this.closeModal.bind(this)}
                        spinner={this.state.savingModal}>
                        <EkoForm formId={"update_user_modal"}>
                            <div className="row">
                                <div className="col-md-6">
                                    <EkoFormInputText {...passwordProps}
                                                      id="old_password"
                                                      type="password"
                                                      label="Mevcut Şifre"
                                                      isVertical/>
                                </div>
                                <div className="col-md-6"/>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <EkoFormInputText {...passwordProps}
                                                      id="password"
                                                      type="password"
                                                      label="Yeni Şifre"
                                                      isVertical/>
                                </div>
                                <div className="col-md-6">
                                    <EkoFormInputText {...passwordProps}
                                                      id="password_confirmation"
                                                      type="password"
                                                      label="Yeni Şifre Tekrar"
                                                      isVertical/>
                                </div>
                            </div>
                            <Modal.Footer>
                                <br/>
                                <div className="row buttonProccess2">
                                    <EkoFormButton label={'Değiştir'}
                                                   isLoading={this.state.savingModal}
                                                   onClick={this.onSaveModal.bind(this)}
                                    />
                                    <EkoFormButton label="İptal" className="btn"
                                                   onClick={this.closeModal.bind(this)}
                                    />
                                </div>
                            </Modal.Footer>
                        </EkoForm>
                    </EkoModal>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        user: state.data.user,
    };
}

export default connect(mapStateToProps, actions)(UpdateUser);
