import React, { Component } from "react";
import ForibaModal from "../forms/ForibaModal";
import { toastr } from "react-redux-toastr";
import Util from "../../util/Util";
class Integrations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showNesModal: false
        };
    }

    paynet() {
        window.location.href = "paynetApplication";
    }

    foriba() {
        this.setState({ showModal: true });
    }

    nes() {
        this.setState({ showNesModal: true });
    }
    obifin() {
        window.location.href = "/dashboard/obifin";
    }
    marketplace(){
        this.props.router.push('/dashboard/marketplace')
    }
    zreport(){
        this.props.router.push('/dashboard/zreportIntegration')
    }
    flexodeme() {
        this.props.router.push('/dashboard/flexodeme')
    }

    render() {
        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

                <div style={{display: "flex"}}>
                    <div className="row">
                        <div
                            className="col-md-4 col-sm-6 col-xs-12 mb-4" style={{cursor: "pointer"}}
                            onClick={this.foriba.bind(this)}
                        >
                            <div className="portlet light">
                                <div className="portlet-title">
                                    <div
                                        style={{
                                            height: "30px",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                        className="caption"
                                    >
                <span
                    title="span"
                    className="caption-subject font-blue-sharp bold uppercase"
                >
                  Foriba
                </span>
                                    </div>
                                    <div style={{textAlign: "end", marginBottom: "10px", height: "60px"}}>
                                        <img
                                            src="/app/images/foriba.png"
                                            height={50}
                                            alt="foriba"
                                        />
                                    </div>
                                </div>
                                <div className="row container-fluid p-0" style={{height: "75px"}}>
                                    <strong>
                                        <center>
                                            e-Fatura | e-Defter <br/>
                                            e-Arşiv Fatura | e-İrsaliye
                                        </center>
                                    </strong>
                                </div>
                                <div className="bank-type"></div>
                            </div>
                        </div>

                        <div
                            className="col-md-4 col-sm-6 col-xs-12 mb-4" style={{cursor: "pointer"}}
                            onClick={this.nes.bind(this)}
                        >
                            <div className="portlet light">
                                <div className="portlet-title">
                                    <div
                                        style={{
                                            height: "30px",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                        className="caption"
                                    >
                <span
                    title="span"
                    className="caption-subject font-blue-sharp bold uppercase"
                >
                  Nesbilgi
                </span>
                                    </div>
                                    <div style={{textAlign: "end", marginBottom: "10px", height: "60px"}}>
                                        <img
                                            src="https://nes.com.tr/wp-content/themes/nes/dist/svg/NES-Logo-Red-2.svg"
                                            height={25}
                                            alt="Nesbilgi"
                                        />
                                    </div>
                                </div>
                                <div className="row container-fluid p-0" style={{height: "75px"}}>
                                    <strong>
                                        <center>
                                            e-Fatura | e-Defter <br/>
                                            e-Arşiv Fatura | e-İrsaliye
                                        </center>
                                    </strong>
                                </div>
                                <div className="bank-type"></div>
                            </div>
                        </div>

                        <div
                            className="col-md-4 col-sm-6 col-xs-12 mb-4" style={{cursor: "pointer"}}
                            onClick={this.flexodeme.bind(this)}
                        >
                            <div className="portlet light">
                                <div className="portlet-title">
                                    <div
                                        style={{
                                            height: "30px",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                        className="caption"
                                    >
                                        <span title="span" className="caption-subject font-blue-sharp bold uppercase">FlexÖde.me</span>
                                    </div>
                                    <div style={{textAlign: "end", marginBottom: "10px", height: "60px"}}>
                                        <img
                                            src="https://app.flexode.me/assets/images/flexodeme_logo_dark_blue.png"
                                            width={120}
                                            alt="flexödeme"
                                        />
                                    </div>
                                </div>
                                <div className="row container-fluid p-0" style={{height: "75px"}}>
                                    <strong>
                                        <center>Sanal POS hesaplarınızı bağlayarak tahsilatlarınızı sistem üzerinden gerçekleştirin.</center>
                                    </strong>
                                </div>
                                <div className="bank-type"></div>
                            </div>
                        </div>

                        <div
                            className="col-md-4 col-sm-6 col-xs-12 mb-4" style={{cursor: "pointer"}}
                            onClick={this.paynet.bind(this)}
                        >
                            <div className="portlet light">
                                <div className="portlet-title">
                                    <div
                                        style={{
                                            height: "30px",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                        className="caption"
                                    >
                <span
                    title="span"
                    className="caption-subject font-blue-sharp bold uppercase"
                >
                  Paynet
                </span>
                                    </div>
                                    <div style={{textAlign: "end", marginBottom: "10px", height: "60px"}}>
                                        <img
                                            src="/app/images/paynet.png"
                                            width={100}
                                            height={80}
                                            alt="iyzico"
                                        />
                                    </div>
                                </div>
                                <div className="row container-fluid p-0" style={{height: "75px"}}>
                                    <strong>
                                        <center>Kredi Kartı ile Online Tahsilat Platformu</center>
                                    </strong>
                                </div>
                                <div className="bank-type"></div>
                            </div>
                        </div>


                        <div
                            className="col-md-4 col-sm-6 col-xs-12 mb-4" style={{cursor: "pointer"}}
                            onClick={this.obifin.bind(this)}
                        >
                            <div className="portlet light">
                                <div className="portlet-title">
                                    <div
                                        style={{
                                            height: "65px",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                        className="caption"
                                    >
                <span
                    title="span"
                    className="caption-subject font-blue-sharp bold uppercase"
                >
                  BANKA ENTEGRASYONU
                </span>
                                    </div>
                                    <div style={{textAlign: "end", marginBottom: "10px", height: "60px"}}>
                                        <img
                                            src="https://www.obifin.com/storage/2022/10/obifin-logo.png"
                                            height={38}
                                            alt="obifin"
                                        />
                                    </div>
                                </div>
                                <div className="row container-fluid p-0" style={{height: "75px"}}>
                                    <strong>
                                        <center>Açık Bankacılık
                                            Servis Bankacılığı Platformu
                                        </center>
                                    </strong>
                                </div>
                                <div className="bank-type"></div>
                            </div>
                        </div>

                        <div
                            className="col-md-4 col-sm-6 col-xs-12 mb-4" style={{cursor: "pointer"}}
                            onClick={this.marketplace.bind(this)}
                        >
                            <div className="portlet light">
                                <div className="portlet-title">
                                    <div
                                        style={{
                                            height: "65px",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                        className="caption"
                                    >
                <span
                    title="span"
                    className="caption-subject font-blue-sharp bold uppercase"
                >
                  Pazaryeri Entegrasyonu
                </span>
                                    </div>
                                    <div style={{textAlign: "end", marginBottom: "10px", height: "60px"}}>
                                        <img
                                            src="/app/images/logo-default.png"
                                            width={120}
                                            height={38}
                                            alt="obifin"
                                        />
                                    </div>
                                </div>
                                <div className="row container-fluid p-0" style={{height: "75px"}}>
                                    <strong>
                                        <center>Pazaryeri Sipariş Bilgileri</center>
                                    </strong>
                                </div>
                                <div className="bank-type"></div>
                            </div>
                        </div>
                        <div
                            className="col-md-4 col-sm-6 col-xs-12 mb-4" style={{cursor: "pointer"}}
                            onClick={this.zreport.bind(this)}
                        >
                            <div className="portlet light">
                                <div className="portlet-title">
                                    <div
                                        style={{
                                            height: "65px",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis"
                                        }}
                                        className="caption"
                                    >
                <span
                    title="span"
                    className="caption-subject font-blue-sharp bold uppercase"
                >
                  Z Raporu Entegrasyonu
                </span>
                                    </div>
                                    <div style={{textAlign: "end", marginBottom: "10px", height: "60px"}}>
                                        <img
                                            src="/app/images/logo-default.png"
                                            width={120}
                                            height={38}
                                            alt="obifin"
                                        />
                                    </div>
                                </div>
                                <div className="row container-fluid p-0" style={{height: "75px"}}>
                                    <strong>
                                        <center>Z Raporu Entegrasyon Bilgileri</center>
                                    </strong>
                                </div>
                                <div className="bank-type"></div>
                            </div>
                        </div>
                    </div>


                    <ForibaModal
                        showModal={this.state.showModal}
                        closeModal={() => {
                            this.setState({showModal: false});
                        }}
                        integrator="Foriba"
                    />
                    <ForibaModal
                        showModal={this.state.showNesModal}
                        closeModal={() => {
                            this.setState({showNesModal: false});
                        }}
                        integrator="Nesbilgi"
                    />
                </div>
            </div>
        );
    }
}

export default Integrations;
