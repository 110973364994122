import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { CSS } from "../../css/tab_datatable";
import Style from "style-it";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { FETCH_PROFORMAS } from "../../actions/types";
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";
import UtilHelper from "../../util/UtilHelper";
import EkoButtonOutline from "../../components/forms/EkoButtonOutline";
import Util from "../../util/Util";
import EkoModal from "../../components/elements/EkoModal";

/**
 *
 */
class Proforma extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      proformas: [],
      showUpdate: false,
      partialTransformInvoiceRow: [],
      partialTransformInvoiceModal:false,
      SendButtonDisableController:false,
      transformInvoiceAllModal: false,
      checkBox: [],
      modal: false,
      selectedRowModal:0
    };

    this.table = null;
  }

  componentWillMount() {
    this.fetchData();
  }

  fetchData() {
    this.props.fetchData("/proforma/list", FETCH_PROFORMAS);
  }

  openInsert(menuItem) {
    //localStorage.setItem("new_collection_from_who_type", menuItem);

    if (menuItem === 1) {
      this.props.router.push("/dashboard/salesInvoice/add/toCustomer");
    } else if (menuItem === 2) {
      this.props.router.push("/dashboard/salesInvoice/add/toSupplier");
    } else if (menuItem === 3) {
      this.props.router.push("/dashboard/salesInvoice/zreports/add");
    } else if (menuItem === 4) {
      this.props.router.push("/dashboard/salesInvoice/proforma/add");
    }else if (menuItem === 5) {
      this.props.router.push('/dashboard/salesInvoice/otherSales/add')
    }else if (menuItem === 6) {
      this.props.router.push('/dashboard/salesInvoice/soleprofv2/add')
    } else if (menuItem === 7) {
      this.props.router.push('/dashboard/salesInvoice/salesShipment/add')
    }
  }

  openUpdate(row) {
    this.props.router.push("/dashboard/salesInvoice/proforma/edit/" + row.id);
  }

  showMethod(selectedRow) {
    console.log('showMethod');
    this.setState({ modal: true, selectedRowModal:selectedRow.id });
  }

  get moneyFields() {
    return [
      "price_amount_fc",
      "discount_amount_fc",
      "net_amount_fc",
      "vat_amount_fc",
      "reduction_amount_fc",
      "total_amount_fc"
    ];
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.proformas) {
      const proformas = nextProps.proformas.map(report => {
        const moneyFields = UtilHelper.money.mapDecimalToMoney(
            this.moneyFields,
            report
        );
        const dateFields = {
          //proforma_date: Util.convertFromDatabaseDate(report.proforma_date),
          //due_date: Util.convertFromDatabaseDate(report.due_date)
        };

        return {
          ...report,
          ...moneyFields,
          ...dateFields
        };
      });

      this.setState({
        proformas
      });
    }
  }

  onDelete(row) {
    Api.delete("/proforma/" + row.id, (response, error) => {
      if (!error && response.status === "ok") {
        toastr.success("Başarıyla Silindi");
        this.fetchData();
      } else {
        toastr.error(error.message ? error.message : "Silinemedi");
      }
    });
  }

  get items() {
    var user_json = JSON.parse(localStorage.getItem("user"));
    var list = [];
    if(user_json.company_type == 9 || user_json.company_type == 6){
      window.location.replace("/dashboard/salesInvoice/soleprofv2s");
    }
    if(user_json.company_type == 9 || user_json.company_type == 6){
      list =  [
        {
          id: 5,
          link: "/dashboard/salesInvoice/soleprofv2s",
          title: "Serbest Meslek Makbuzu"
        },
        {
          id: 2,
          link: "/dashboard/salesInvoice/zreports",
          title: "Z Raporları"
        },
        {
          id: 3,
          link: '/dashboard/salesInvoice/proformas',
          title: 'Teklifler/Siparişler'
        },
        {
          id: 4,
          link: "/dashboard/salesInvoice/otherSales",
          title: "Diğer Gelirler"
        }
      ];
    }else{
      list =  [
        {
          id: 1,
          link: '/dashboard/salesInvoice',
          title: 'Satışlar'
        },

        {
          id: 2,
          link: '/dashboard/salesInvoice/zreports',
          title: 'Z Raporları'
        },
        {
          id: 3,
          link: '/dashboard/salesInvoice/proformas',
          title: 'Teklifler/Siparişler'
        },
        {
          id: 4,
          link: "/dashboard/salesInvoice/otherSales",
          title: "Diğer Gelirler"
        },
      ];
      if(Util.companyModules(2)===true){
        //@oguz stok modulu satın alındıysa
        list.push({
          id: 7,
          link: "/dashboard/salesInvoice/salesShipment",
          title: "Sevk İrsaliyesi"
        },);
      }
    }
    return list;
  }

  transformInvoice(selectedRow) {
    this.props.router.push(
        "/dashboard/salesInvoice/add/toCustomer?proforma=" + selectedRow.id
    );
  }
  transformSalesShipment(selectedRow) {
    this.props.router.push(
        "/dashboard/salesInvoice/salesShipment/add?proforma=" + selectedRow.id
    );
  }

  goToSource(selectedRow) {
    if (parseInt(selectedRow.sales_invoice_id, 10) !== 0)
      this.props.router.push(
          "/dashboard/salesInvoice/edit/" + selectedRow.sales_invoice_id
      );
  }

  acceptStatus(selectedRow) {
    this.updateStatus(3, selectedRow.id, selectedRow);
  }

  rejectStatus(selectedRow) {
    this.updateStatus(2, selectedRow.id, selectedRow);
  }

  updateStatus = (status, id, row) => {
    Api.post(
        "/proforma/update-status/" + id,
        {
          status: status
        },
        (response, error) => {
          if (!error) {
            // verileri güncelle ve seçimi sıfırla
            this.table.cleanSelect();
            this.fetchData();
            toastr.success("İşlem başarılı!");
          } else {
            const message = error.message || "İşlem başarısız";

            toastr.error(message);
          }
        }
    );
  };

  onSelectedRowChange = selected => {
    const { status } = selected;

    this.setState({ showUpdate: parseInt(status, 10) === 1 });
  };

  createCustomButtons = ({ isSelected, selectedRow }) => {
    if (isSelected) {
      const { status } = selectedRow;

      return <span></span>;
    }
  };
  partialTransformProforma(selectedRow) {
    Api.get(
        '/proformaLine/showAll/'+selectedRow.id,
        (response, err) => {
          if (response && response.status === 'ok') {
            this.setState({partialTransformInvoiceRow: response.proformaLines,checkBox:[],SendButtonDisableController:false,partialTransformInvoiceModal:true})
          }
          if (err) {
            toastr.error('HATA', "")
            this.setState({partialTransformInvoiceRow: [],checkBox:[],SendButtonDisableController:false,partialTransformInvoiceModal:false})
          }
        }
    );
  }
  partialTransformInvoiceCloseModal() {
    this.setState({partialTransformInvoiceModal:false,partialTransformInvoiceRow: [],checkBox:[],SendButtonDisableController:true})
  }

  partialTransformInvoiceAllSend() {
    if(this.state.checkBox.length>0){
      this.props.router.push("/dashboard/salesInvoice/add/toCustomer?proformaLine=" + this.state.checkBox.map((item)=>item.id).join(","));
    }
  }
  checkboxChange = (invoiceId) => {
    invoiceId = parseInt(invoiceId);
    const checkBox = this.state.checkBox.slice(); // Create a copy of the checkBox array
    const index = checkBox.findIndex(element => element.id === invoiceId);

    if (index === -1) {
      // If the item is not found, add it to the array
      checkBox.push({ id: invoiceId });
    } else {
      // If the item is found, remove it from the array
      checkBox.splice(index, 1);
    }

    this.setState({ checkBox }); // Update the state with the new checkBox array
  }
  transformInvoiceAllProforma() {
    this.setState({transformInvoiceAllModal: true})
  }
  transformInvoiceAllcloseModal() {
    this.setState({transformInvoiceAllModal: false,checkBox:[],SendButtonDisableController:true})
  }
  transformInvoiceAllSend() {
    if(this.state.checkBox.length>0){
      const customerIds = new Set();

      this.state.proformas.forEach((item, index) => {
        if (this.state.checkBox.some(element => element.id === item.id)) {
          customerIds.add(item.customer_id);
        }
      });
      if (customerIds.size === 1) {
        this.props.router.push("/dashboard/salesInvoice/add/toCustomer?proforma=" + this.state.checkBox.map((item)=>item.id).join(","));
      } else {
        toastr.error('HATA', 'Seçtiğiniz proformaların müşterileri aynı olmalıdır.');
        return;
      }
    }
  }
  render() {
    const time = new Date();
    var apiUrl = process.env.REACT_APP_API_URL;
    apiUrl = apiUrl.substring(0, apiUrl.length-3);
    const insertMenux = this.props.viewOptions
        ? this.props.viewOptions.sales.addRecordMenu
        : false;
    if(insertMenux!=false){
      var user_json = JSON.parse(localStorage.getItem("user"));
      if(user_json.company_type == 9 || user_json.company_type == 6){
        var insertMenu = insertMenux.filter(obj => obj.id !== 1 && obj.id !== 2);
        insertMenu.unshift({id: 6, name: 'Serbest Meslek Makbuzu'});
      }else{
        insertMenu = insertMenux;
      }
    }

    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          {Style.it(
              CSS,
              <div>
                <EkoDataTable
                    onRef={item => (this.table = item)}
                    title="Teklifler/Siparişler"
                    tabMenu={this.items}
                    selectedTab={3}
                    showDelete
                    onSelectedRowChange={this.onSelectedRowChange}
                    addUpdateShow={this.state.showUpdate}
                    showMethod={this.showMethod.bind(this)}
                    createCustomRowButtons={this.createCustomButtons}
                    openInsert={this.openInsert.bind(this)}
                    goToSource={this.goToSource.bind(this)}
                    transformInvoice={this.transformInvoice.bind(this)}
                    transformSalesShipment={this.transformSalesShipment.bind(this)}
                    acceptStatus={this.acceptStatus.bind(this)}
                    rejectStatus={this.rejectStatus.bind(this)}
                    openUpdate={this.openUpdate.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    transformInvoiceAllProforma={this.transformInvoiceAllProforma.bind(this)}
                    data={this.state.proformas}
                    insertMenu={insertMenu}
                    name={"proformas"}
                    partialTransformProforma={this.partialTransformProforma.bind(this)}
                />
                <EkoModal
                    bsSize="xlg"
                    hideSelectButtons
                    showModal={this.state.transformInvoiceAllModal}
                    onHide={this.transformInvoiceAllcloseModal.bind(this)}
                >
                  <div style={{"padding": "20px"}}>
                    <h4 style={{"paddingBottom": "10px"}}>Faturaya dönüştürmek istediğiniz proformaları seçiniz.</h4>
                    <div>
                      <table className="table table-striped not-table-layout">

                        <thead>
                        <th>#</th>
                        <th>Tarih</th>
                        <th>Teklif/Sipariş No</th>
                        <th>Müşteri Adı</th>
                        <th>Toplam Tutar</th>
                        </thead>
                        <tbody>
                        {
                          this.state.proformas.map((item, index) => {

                            return (
                                item.sales_invoice_id === 0 || item.sales_invoice_id === null ? <tr key={index}>
                                  <td style={{width: 15}}>
                                    <input
                                        type="checkbox"
                                        checked={this.state.checkBox.some(element => element.id === item.id)}
                                        onChange={() => {
                                          this.checkboxChange(item.id);
                                        }}
                                    />
                                  </td>
                                  <td>{item.proforma_date}</td>
                                  <td>{item.proforma_num}</td>
                                  <td>{item.customer?.firstname} {item.customer?.lastname}</td>
                                  <td>{item.total_amount_fc_str}</td>
                                </tr> : ""
                            );
                          })
                        }

                        </tbody>
                      </table>

                    </div>
                    <div className="text-right" style={{"marginTop": "10px"}}>
                      <button
                          disabled={this.state.SendButtonDisableController}
                          className="btn btn-primary"
                          onClick={() => {
                            this.transformInvoiceAllSend();
                          }}
                      >
                        Faturaya Dönüştür
                      </button>
                      <button
                          className="btn btn-danger"
                          onClick={() => {
                            this.transformInvoiceAllcloseModal();
                          }}
                      >
                        İptal Et
                      </button>
                    </div>
                  </div>
                </EkoModal>
                <EkoModal
                    bsSize="xlg"
                    hideSelectButtons
                    showModal={this.state.partialTransformInvoiceModal}
                    onHide={this.partialTransformInvoiceCloseModal.bind(this)}
                >
                  <div style={{"padding": "20px"}}>
                    <h4 style={{"paddingBottom": "10px"}}>Faturaya dönüştürmek istediğiniz proforma satırlarını seçiniz.</h4>
                    <div>
                      <table className="table table-striped not-table-layout">

                        <thead>
                        <th>#</th>
                        <th>Ürün Adı</th>
                        <th>Açıklama</th>
                        <th>Miktar</th>
                        <th>Birim Fiyat</th>
                        <th>Birim</th>
                        <th>Fiyat</th>
                        <th>#</th>
                        </thead>
                        <tbody>
                        {
                          this.state.partialTransformInvoiceRow.map((item, index) => {

                            return (
                                <tr key={index}>
                                  <td style={{width: 15}}>
                                    <input
                                        type="checkbox"
                                        checked={this.state.checkBox.some(element => element.id === item.id)}
                                        onChange={() => {
                                          this.checkboxChange(item.id);
                                        }}
                                    />
                                  </td>
                                  <td>{item.item_name}</td>
                                  <td>{item.description}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.unit_price_fc}</td>
                                  <td>{item.unit_name}</td>
                                  <td>{item.total_amount_fc}</td>
                                  <td>{item.transform_invoice_line===0?"Faturaya Eklenmedi":"Faturaya Eklendi"}</td>
                                </tr>
                            );
                          })
                        }

                        </tbody>
                      </table>

                    </div>
                    <div className="text-right" style={{"marginTop": "10px"}}>
                      <button
                          disabled={this.state.SendButtonDisableController}
                          className="btn btn-primary"
                          onClick={() => {
                            this.partialTransformInvoiceAllSend();
                          }}
                      >
                        Faturaya Dönüştür
                      </button>
                      <button
                          className="btn btn-danger"
                          onClick={() => {
                            this.partialTransformInvoiceCloseModal();
                          }}
                      >
                        İptal Et
                      </button>
                    </div>
                  </div>
                </EkoModal>
                <EkoModal
                    showModal={this.state.modal}
                    title="Proforma Görüntüle"
                    bsSize="xlg" // xlg
                    onHide={() => {
                      this.setState({
                        modal: false
                      });
                      //this.onCancel();
                    }}
                >
                  <iframe
                      style={{overflow: 'visible'}}
                      ref="iframe"
                      src={apiUrl + "view_proforma/index.php?proforma_id="+this.state.selectedRowModal+"&time=" + time.getTime() + "&t=" + localStorage.getItem('token')}
                      scrolling="auto"
                      frameBorder="0"
                      width="100%"
                      height={800}
                  />
                </EkoModal>
              </div>
          )}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    proformas: state.data.proformas
        ? state.data.proformas.proformas
        : undefined,
    viewOptions: state.data.viewOptions
  };
};

export default connect(mapStateToProps, actions)(Proforma);
