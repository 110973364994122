import React, { Component } from "react";
import sugarRemoveAt from "sugar/array/removeAt";
import sugarFind from "sugar/array/find";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import { Button, DropdownButton, MenuItem } from "react-bootstrap";
import AddPurchaseInvoiceHeader from "./AddPurchaseInvoiceHeader";
import Util from "../../util/Util";
import moment from "moment";
import AddItem from "../Item/AddItem";
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import CompanyTypePermission from "../../services/CompanyTypePermission";
import { parseSelfEmploymentData } from "../../services/SelfEmploymentDataService";
import UtilHelper from "../../util/UtilHelper";
import { FETCH_BANK_ACCOUNTS_ALL } from "../../actions/types";
import { withRouter } from "react-router";
import AddOtherTax from "../../components/forms/AddOtherTax";
import LoginHelper from "../../services/LoginHelper";
import axios from "axios";
import { ROOT_URL } from "../../actions";
import Sugar from "sugar";
import SelectItemModal from "../../components/elements/SelectItemModal";
import EkoModal from "../../components/elements/EkoModal";
import ChangingModal from "../SalesInvoice/ChangingModal";
import AddAsset from "../Asset/AddAsset";
import NewTagDocumentModal from "../Company/newTagDocument";
import HelperContainer from "../../util/HelperContainer";

//update
class AddPurchaseInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formDataAsset: false,
      showAssetModal: false,
      selectedAssetLine: 0,
      naceCodeList: [],
      showAddItem: false,
      showAddTax: false,
      method: "",
      bank_currency: "",
      formData: props.formData,
      calculatedFormData: {},
      saving: false,
      openWarningModal: false,
      taxForm: AddOtherTax.getDefaultForm(),
      selectedLine: 0,
      errors: props.errors,
      items: {},
      ChangingModal: false,
      ChangingModalData: [],
      ChangingModalRowData: [],
      deleteRowIds: [],
      deleteTaxIds: [],
      tableData: this.tableDataF(),
      otherAmount: null,
      serial_order_choose: 1,
      ItemModalFormData:false,
      itemModalValues: {
        showItemModal: false,
        refresh: false,
        isChecked: false,
      },
      passModal:false,
      warehouses: [],
      showNewTagModal: false,
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:2,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
    };
  }
  tableDataF() {
    const self = this;
    const documentType = self.props.documentType;
    var data = null;
    if (documentType == 2) {
      data = {
        headers: [
          { label: "Ürün / Hizmet", width: "11%" },
          {
            label: "Faaliyet Adı",
            width: "11%",
            hidden: this.naceCodeHidden(),
          },
          { label: "Açıklama", width: "14%" },
          { label: "Miktar", width: "5%" },
          { label: "Birim Fiyatı", width: "7%" },
          { label: "Birim", width: "7%" },
          { label: "İsk. %", width: "5%" },
          { label: "İsk. Tutar", width: "7%" },
          { label: "Tutar", width: "9%" },
          { label: "KDV %", width: "6%" },
          { label: "KDV", width: "7%" },
          { label: "Diğer Vergiler", width: "9%" },
        ],
        footer: [],
        footerData: {},
        footerTable: [
          {
            id: "price_amount_fc",
            label: "Tutar",
            visible: true,
          },
          {
            id: "discount_amount_fc",
            label: "İskonto",
            visible: true,
          },
          {
            id: "net_amount_fc",
            label: "Fatura Matrahı",
            visible: true,
          },
          {
            id: "vat_amount_fc",
            label: "KDV Tutarı",
            visible: true,
          },
          {
            id: "taxes_sbjvat_amount_fc",
            label: "Diğ. Vergiler",
            visible: false,
          },
          {
            id: "taxes_excvat_amount_fc",
            label: "Diğ. Vergiler",
            visible: false,
          },
          {
            id: "vat_reduction_amount_fc",
            label: "KDV Tevkifatı",
            visible: true,
          },
          {
            id: "stoppage_amount_fc",
            label: "Stopaj",
            visible: true,
          },
          {
            id: "payable_amount_fc",
            label: "Ödenecek",
            visible: false,
          },
          {
            id: "total_amount_fc",
            label: "Genel Toplam",
            className: "tplus-plus",
            visible: true,
          },
        ],
        customFooter: null,
        rows: this.defaultRows(),
      };
    }else if(documentType === 4){
      data = {
        headers: [
          { label: "Depo", width: "10%", hide: !Util.companyModules(2) },
          { label: "Açıklama", width: "20%" },
          { label: "Miktar", width: "5%" },
          { label: "Birim Fiyatı", width: "7%" },
          { label: "Birim", width: "7%" },
          { label: "İsk. %", width: "5%" },
          { label: "İsk. Tutar", width: "7%" },
          { label: "Tutar", width: "9%" },
          { label: "KDV %", width: "6%" },
          { label: "KDV", width: "7%" },
          { label: "Diğer Vergiler", width: "9%" },
        ],
        footer: [],
        footerData: {},
        footerTable: [
          {
            id: "price_amount_fc",
            label: "Tutar",
            visible: true,
          },
          {
            id: "discount_amount_fc",
            label: "İskonto",
            visible: true,
          },
          {
            id: "net_amount_fc",
            label: "Fatura Matrahı",
            visible: true,
          },
          {
            id: "vat_amount_fc",
            label: "KDV Tutarı",
            visible: true,
          },
          {
            id: "taxes_sbjvat_amount_fc",
            label: "Diğ. Vergiler",
            visible: false,
          },
          {
            id: "taxes_excvat_amount_fc",
            label: "Diğ. Vergiler",
            visible: false,
          },
          {
            id: "vat_reduction_amount_fc",
            label: "KDV Tevkifatı",
            visible: true,
          },
          {
            id: "stoppage_amount_fc",
            label: "Stopaj",
            visible: true,
          },
          {
            id: "payable_amount_fc",
            label: "Ödenecek",
            visible: false,
          },
          {
            id: "total_amount_fc",
            label: "Genel Toplam",
            className: "tplus-plus",
            visible: true,
          },
        ],
        customFooter: null,
        rows: this.defaultRows(),
        warehouses: [],
      };
    } else {
      data = {
        headers: [
          { label: documentType == 4?"Demirbaş Tipi":"Ürün / Hizmet", width: "18%" },
          { label: "Depo", width: "10%", hide: !Util.companyModules(2) },
          { label: "Açıklama", width: "20%" },
          { label: "Miktar", width: "5%" },
          { label: "Birim Fiyatı", width: "7%" },
          { label: "Birim", width: "7%" },
          { label: "İsk. %", width: "5%" },
          { label: "İsk. Tutar", width: "7%" },
          { label: "Tutar", width: "9%" },
          { label: "KDV %", width: "6%" },
          { label: "KDV", width: "7%" },
          { label: "Diğer Vergiler", width: "9%" },
        ],
        footer: [],
        footerData: {},
        footerTable: [
          {
            id: "price_amount_fc",
            label: "Tutar",
            visible: true,
          },
          {
            id: "discount_amount_fc",
            label: "İskonto",
            visible: true,
          },
          {
            id: "net_amount_fc",
            label: "Fatura Matrahı",
            visible: true,
          },
          {
            id: "vat_amount_fc",
            label: "KDV Tutarı",
            visible: true,
          },
          {
            id: "taxes_sbjvat_amount_fc",
            label: "Diğ. Vergiler",
            visible: false,
          },
          {
            id: "taxes_excvat_amount_fc",
            label: "Diğ. Vergiler",
            visible: false,
          },
          {
            id: "vat_reduction_amount_fc",
            label: "KDV Tevkifatı",
            visible: true,
          },
          {
            id: "stoppage_amount_fc",
            label: "Stopaj",
            visible: true,
          },
          {
            id: "payable_amount_fc",
            label: "Ödenecek",
            visible: false,
          },
          {
            id: "total_amount_fc",
            label: "Genel Toplam",
            className: "tplus-plus",
            visible: true,
          },
        ],
        customFooter: null,
        rows: this.defaultRows(),
        warehouses: [],
      };
    }

    if(documentType===4 && !this.props.router.params.id){
      data.headers.push({ label: "Demirbaş Bilgisi", width: "9%" });
    }
    return data;
  }

  defaultRows() {
    const self = this;
    const documentType = self.props.documentType;
    var rows = null;
    if (documentType == 2) {
      rows = [
        [
          {
            type: "select",
            id: "item_id",
            optionValue: "codeName",
            searchFilter: "codeName",
            selectedOptionValue: "item_code",
            defaultKey: false,
            optionId: "id",
            defaultText: "SEÇ",
            searchable: true,
            data: [],
          },
          {
            type: "select",
            hidden: this.naceCodeHidden(),
            id: "nace_code_id",
            optionValue: "description",
            //defaultKey: 'diğer',
            searchFilter: "key_words",
            optionId: "id",
            defaultText: "SEÇ",
            searchable: true,
            data: JSON.parse(localStorage.getItem("nace_code_list")),
          },
          { type: "text", id: "description", vertical: true },
          {
            type: "money",
            id: "quantity",
            vertical: true,
            value: 1,
            precision: 6,
          },
          {
            type: "money",
            precision: 8,
            id: "unit_price_fc",
            vertical: true,
            value: 0,
            currency: true,
          },
          {
            type: "select",
            id: "unit_id",
            defaultText: "",
            optionValue: "description",
            disabled: false,
            optionId: "id",
            searchable: true,
            data: [],
          },
          { type: "percent", id: "discount_rate", vertical: true, value: 0 },
          { type: "money", id: "discount_amount_fc", vertical: true },
          { type: "money", id: "net_amount_fc", vertical: true },
          {
            type: "select",
            id: "vat_rate_type_id",
            defaultText: "",
            optionValue: "name",
            optionId: "id",
            data: [],
          },
          { type: "money", id: "vat_amount_fc", vertical: true },
          {
            type: "custom",
            id: "button",
            tdClass: "ticon2",
            props: { itemCount: 0, warning: false },
            onRender: this.invoiceLineButtonGroup.bind(this, 0),
          },
          //{ type: 'clickarea',id:'other_tax',tdClass:'ticon2',faClass: 'fa fa-plus',onClick:this.onOtherTaxClick.bind(this,0)}
        ],
      ];
    } else {
      rows = [
        [
          {
            type: "select",
            id: "item_id",
            optionValue: "codeName",
            searchFilter: "codeName",
            selectedOptionValue: "item_code",
            defaultKey: false,
            optionId: "id",
            defaultText: "SEÇ",
            searchable: true,
            onAddNewItemClick: this.onAddNewItemClick.bind(this, 0),
            onEditItemClick: this.onEditItemClick.bind(this, 0),
            data: [],
          },
          {
            type: "select",
            id: "warehouse_id",
            optionValue: "name",
            optionId: "id",
            defaultText: "SEÇ",
            searchable: true,
            data: [],
            hide: !Util.companyModules(2),
          },
          // { type: "text", id: "description", vertical: true },
          {
            type: "complete",
            id: "description",
            optionId: "description",
            data: [],
            vertical: true,
            optionValue: "description",
            searchValue: "item_name",
            selectValue: "id",
            onSelect: this.onSelectDescription.bind(this, 0),
          },
          {
            type: "money",
            id: "quantity",
            vertical: true,
            value: 1,
            precision: 6,
          },
          {
            type: "money",
            precision: 8,
            id: "unit_price_fc",
            vertical: true,
            value: 0,
            currency: true,
          },
          {
            type: "select",
            id: "unit_id",
            defaultText: "",
            optionValue: "description",
            disabled: false,
            optionId: "id",
            searchable: true,
            data: [],
          },
          { type: "percent", id: "discount_rate", vertical: true, value: 0 },
          { type: "money", id: "discount_amount_fc", vertical: true },
          { type: "money", id: "net_amount_fc", vertical: true },
          {
            type: "select",
            id: "vat_rate_type_id",
            defaultText: "",
            optionValue: "name",
            optionId: "id",
            data: [],
          },
          { type: "money", id: "vat_amount_fc", vertical: true },
          {
            type: "custom",
            id: "button",
            tdClass: "ticon2",
            props: { itemCount: 0, warning: false },
            onRender: this.invoiceLineButtonGroup.bind(this, 0),
          },
          {
            hidden: !Util.companyModules(2),
            type: "clickarea",
            id: "item_oem_number",
            tdClass: "ticon2",
            faClass: "fa fa-shopping-basket",
            onClick: this.onOpenChangingModal.bind(this, 0),
          },
          {
            hidden:this.props.router.params.id || documentType !== 4?true:false,
            type: "custom",
            id: "asset_modal",
            tdClass: "ticon2",
            faClass: 'fa fa-plus',
            props: { itemCount: 0, warning: false },
            onRender: this.invoiceLineButtonGroupAsset.bind(this, 0),
            onClick:this.onOpenAssetModal.bind(this,0)
          }
        ],
      ];
    }
    rows = rows.map(function (row, index) {
      if (documentType === 4) {
        row[0].data = JSON.parse(localStorage.getItem("fixed_asset_types"));
        row[0].onAddNewItemClick = false;
        row[0].onEditItemClick = false;
        row[0].selectedOptionValue = "name";
        row[0].optionValue = "name";
        row[0].optionId = "id";
        row[0].searchFilter = "name";
        row[0].defaultKey = "name";
        row[0].id = "fixed_asset_type_id";
        row[0].hidden = true;
      }
      return row;
    });
    return rows;
  }
  onOpenAssetModal(index) {
    var self = this;
    var asset = this.state.formData.rows[index].asset;
    var data = {};
    data = {asset: asset===undefined?{}:asset};
    self.setState({ selectedAssetLine: index, formDataAsset: data }, function () {
      self.setState({ showAssetModal: true });
    });
  }
  onSaveAsset(data) {
    let formData = this.state.formData;
    const index = this.state.selectedAssetLine;
    formData.rows[index].asset = data;
    this.setState({
      showAssetModal: false,
      //formDataAsset: [],
      selectedAssetLine: 0,
      formData:formData
    });
  }
  invoiceLineButtonGroupAsset(index, props) {
    var self = this;
    var ret = (
        <Button
            className="btn"
            style={{ width: "100%" }}
            //onClick={this.onInvoiceLineButtonClick.bind(this, "add", index)}
        >
          {self.state.formData.rows[index].asset?<i className="fa fa-list" />:<i className="fa fa-plus" />}
        </Button>
    );
    return ret;
  }
  onInvoiceLineButtonClick(id, index) {
    if (id === "add") {
      return this.onOtherTaxClick(index);
    } else if (id === "delete") {
      this.setState({ selectedLine: index }, function () {
        this.onTaxFormSave(false);
      });
    }
  }
  openWarningModal = () => {
    this.setState({ openWarningModal: true });
  };
  closeWarningModal() {
    this.setState({ openWarningModal: false });
  }
  onSelectDescription(index, id, value) {
    const defaultRowData = {
      item_id: 0,
      fixed_asset_type_id: 0,
      item_code: "",
      description: "t",
      quantity: "1",
      unit_price_fc: 0,
      discount_rate: 0,
      unit_id: 0,
      nace_code_id: 111,
      discount_amount_fc: "0,00",
      expense_category_id: null,
      net_amount_fc: "0,00",
      vat_rate_type_id: 0,
      vat_amount_fc: "0,00",
    };
    const self = this;
    const formData = { ...self.state.formData };
    let rows = [...formData.rows];
    if(!self.props.isGetIn){
      rows[index] = defaultRowData;
      this.setState({ formData: { ...formData, rows } }, () => {
        self.onInvoiceLineChange(
          index,
          { ...defaultRowData, item_id: value },
          "item_id"
        );
      });
    } else {
      const desc = this.state.items[value]?.item_name;
      rows[index] = { ...rows[index], item_id: value, description: desc };
      this.setState({ formData: { ...formData, rows } });
    }
    
  }

  invoiceLineButtonGroup(index, props) {
    const title = props.itemCount + " Adet";
    const menuItems = [];
    let ret = "";

    if (props.itemCount > 0) {
      menuItems.push({
        id: "add",
        name: (
          <span>
            <i className="fa fa-edit" />
            Düzenle
          </span>
        ),
      });
      menuItems.push({
        id: "delete",
        name: (
          <span>
            <i className="fa fa-trash" />
            Temizle
          </span>
        ),
      });
      const menuItemView = menuItems.map((menuItem) => (
        <MenuItem
          eventKey={menuItem.id}
          key={menuItem.id}
          onSelect={this.onInvoiceLineButtonClick.bind(
            this,
            menuItem.id,
            index
          )}
        >
          {menuItem.name}
        </MenuItem>
      ));
      ret = (
        <DropdownButton
          id="drop_down"
          style={{ width: "100%" }}
          title={title}
          noCaret
          className="btn dropdown-toggle btn-sm"
        >
          {menuItemView}
        </DropdownButton>
      );
    } else {
      ret = (
        <Button
          className="btn"
          style={{ width: "100%" }}
          onClick={this.onInvoiceLineButtonClick.bind(this, "add", index)}
        >
          <i className="fa fa-plus" />
        </Button>
      );
    }

    return ret;
    /*if(warning === '') return ret
        else
        return   <OverlayTrigger placement="top" overlay={warning}>
          {ret}
        </OverlayTrigger>*/
  }

  shouldComponentUpdate(nextProps, nextState) {
    //NOTE hersey gelince render etsin
    return (
      !Util.isUndefined(nextProps.vatRateTypes) &&
      !Util.isUndefined(nextProps.vatReductionTypeCodes) &&
      !Util.isUndefined(nextProps.units) &&
      !Util.isUndefined(nextProps.taxTypes)
    );
  }
  onOpenChangingModal(index) {
    const self = this;
    let rowData = this.state.formData.rows[index];
    let subItemData = this.state.formData.rows[index].subItems;
    if (rowData.item_id !== 0) {
      if (subItemData === undefined || subItemData.length === 0) {
        Api.get(
          "/subItem/showAllPurchaseInvoices/" +
            rowData.item_id +
            "/" +
            rowData.id ?? 0,
          function (res, err) {
            if (res.status === "fail") {
              self.setState({ ChangingModalData: [] });
              toastr.warning(res.message);
            } else if (res.status === "ok") {
              self.setState({
                selectedChangingLine: index,
                ChangingModalData: res.items,
                ChangingModal: true,
                ChangingModalRowData: rowData,
              });
            }
          }
        );
      } else {
        self.setState({
          selectedChangingLine: index,
          ChangingModalData: subItemData,
          ChangingModal: true,
          ChangingModalRowData: rowData,
        });
      }
    } else {
      toastr.warning("Lütfen önce ürün seçin.");
    }
  }
  onChangingModalSave(data) {
    let formData = this.state.formData;
    const index = this.state.selectedChangingLine;
    formData.rows[index].subItems = data;
    this.setState({
      ChangingModal: false,
      ChangingModalData: [],
      formData: formData,
    });
  }
  onOtherTaxClick(index) {
    const self = this;
    let taxForm = AddOtherTax.getDefaultForm();
    let otherTaxes = this.state.formData.rows[index].otherTaxes;
    if (otherTaxes) taxForm = { rows: [...otherTaxes] };
    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    taxForm.vat_amount_fc = calculatedFormData.rows[index].vat_amount_fc;
    taxForm.net_amount_fc = calculatedFormData.rows[index].net_amount_fc;
    self.setState({ selectedLine: index, taxForm: taxForm }, function () {
      self.setState({ showAddTax: true });
    });
  }
  fetchWarehouses = async () => {
    let { data } = await Api.getAsync("/warehouses/showAll");
    return (data && data.items) || [];
  };
  fetchWarehousesToItem = async (itemId) => {
    let { data } = await Api.getAsync(
      "/warehouses/showAllWarehouseItems/" + itemId
    );
    return (data && data.items) || [];
  };
  fetchItems(setFirstItem, docType) {
    const self = this;
    const documentType = docType ? docType : self.props.documentType;
    if (documentType !== 2 && documentType !== 4) {
      let formData = self.state.formData;
      if (formData.invoice.document_type_tag !== undefined) {
        var selectedExpenseDocumentType = formData.invoice.document_type_tag;
        const documentType = JSON.parse(
          localStorage.getItem("getDBSExpenseDocumentType")
        );
        const get = Sugar.Array.find(
          documentType.documentType,
          function (type) {
            return type.id === selectedExpenseDocumentType;
          }
        );
        if (get !== undefined && get.code !== undefined) {
          this.setState({ serial_order_choose: get.serial_order_choose });
        }
      }
      Api.get("/item/showAllShortVersion", function (response, err) {
        let items = response.items.map(function (item) {
          item.codeName = item.item_code + ":" + item.item_name;
          return item;
        });
        items = items.filter((item) =>
          documentType === 3 ? item.is_sales === 1 : item.is_purchase === 1
        );

        let stateItems = {};
        items.forEach(function (item, index) {
          stateItems[item.id] = item;
        });

        let tableData = self.state.tableData;
        tableData.rows.map((row) => {
          row[0].data = items;
          if (documentType === 2) {
            row[4].data = items.map((t) => ({
              ...t,
              description:
                t[
                  documentType === 3
                    ? "sales_description"
                    : "purchase_description"
                ],
            }));
          } else {
            row[2].data = items.map((t) => ({
              ...t,
              description:
                t[
                  documentType === 3
                    ? "sales_description"
                    : "purchase_description"
                ],
            }));
          }
          return row;
        });

        const index = self.state.selectedLine || 0;
        tableData.rows[index][0]["data"] = items;
        self.setState({ tableData: tableData, items: stateItems }, function () {
          if (setFirstItem) {
            let formData = self.state.formData;
            formData.rows[index].item_id = items[0].id;
            formData.rows[index].item_code = items[0].item_code;
            self.setState({ formData: formData }, function () {
              self.onInvoiceLineChange(index, formData.rows[index], "item_id");
            });
          }
        });
      });
    } else if (documentType === 2) {
      let formData = self.state.formData;
      if (formData.invoice.document_type_tag !== undefined) {
        var selectedExpenseDocumentType = formData.invoice.document_type_tag;
        const documentType = JSON.parse(
          localStorage.getItem("getDBSExpenseDocumentType")
        );
        const get = Sugar.Array.find(
          documentType.documentType,
          function (type) {
            return type.id === selectedExpenseDocumentType;
          }
        );
        if (get.code !== undefined) {
          this.setState({ serial_order_choose: get.serial_order_choose });
          Api.get(
            "/DBS/purchaseExpenseCategoriesSelect/list/1/" + get.code,
            function (response, err) {
              let items = response.purchaseExpenseCategories.map(function (
                item
              ) {
                item.codeName = item.description;
                return item;
              });
              let stateItems = {};
              items.forEach(function (item, index) {
                stateItems[item.id] = item;
              });

              let tableData = self.state.tableData;
              tableData.rows.map((row) => {
                row[0].data = items;
                row[0].selectedOptionValue = "codeName";
                row[0].searchFilter = "key_words";
                //row[0].defaultKey = "diğer";
                row[0].id = "expense_category_id";
                return row;
              });
              tableData.headers[0].label = "Kategori";
              self.setState({ tableData: tableData, items: stateItems });
            }
          );
        }
      } else {
        let tableData = self.state.tableData;
        var items = [
          {
            id: "dokuman_tipi_seciniz",
            key_words:
              'Doküman Tipini Seçiniz. Doküman Tipi Yoksa "Henüz Belgelendirilmemiş" Seçiniz',
            codeName:
              'Doküman Tipini Seçiniz. Doküman Tipi Yoksa "Henüz Belgelendirilmemiş" Seçiniz',
            default: 0,
            disabled: true,
          },
        ];
        tableData.rows.map((row) => {
          row[0].data = items;
          row[0].selectedOptionValue = "codeName";
          row[0].searchFilter = "key_words";
          //row[0].defaultKey = "diğer";
          row[0].id = "expense_category_id";
          return row;
        });
        tableData.headers[0].label = "Kategori";
        self.setState({ tableData: tableData, items: null });
      }
    }  else if (documentType === 4) {
      let tableData = self.state.tableData;
      tableData.rows.map(function (row, index) {
          row[0].data = JSON.parse(localStorage.getItem("fixed_asset_types"));
          row[0].onAddNewItemClick = false;
          row[0].onEditItemClick = false;
          row[0].selectedOptionValue = "name";
          row[0].optionValue = "name";
          row[0].optionId = "id";
          row[0].searchFilter = "name";
          row[0].defaultKey = "name";
          row[0].id = "fixed_asset_type_id";
        row[0].hidden = true;
        return row;
      });
      self.setState({ tableData: tableData, items: null });
    }else {
      Api.get("/data/expenseCategories/2", function (response, err) {
        let items = response.expenseCategories.map(function (item) {
          item.codeName = item.description;
          return item;
        });
        let stateItems = {};
        items.forEach(function (item, index) {
          stateItems[item.id] = item;
        });

        let tableData = self.state.tableData;
        tableData.rows.map((row) => {
          row[0].data = items;
          row[0].onAddNewItemClick = null;
          row[0].onEditItemClick = null;
          row[0].selectedOptionValue = "codeName";
          row[0].searchFilter = "key_words";
          row[0].defaultKey = "diğer";
          row[0].id = "expense_category_id";

          return row;
        });
        tableData.headers[0].label = "Kategori";

        self.setState({ tableData: tableData, items: stateItems });
      });
    }
  }

  onAddNewItemClick(index) {
    this.setState({ selectedLine: index, showAddItem: true });
  }
  onEditItemClick(index, item) {
    var self = this;
    const id = item.id;
    Api.get("/item/" + id, function(response) {
      let formDataItemModal = {};
      formDataItemModal.item = HelperContainer.filterObject(response.item);
      self.setState({
        ItemModalFormData: formDataItemModal
      });
    });
    this.setState({ selectedLine: index, showAddItem: true });
  }
  onItemSaved() {
    this.setState({ showAddItem: false });
    this.fetchItems(true);
  }

  componentWillMount() {
    var self = this;
    this.fetchItems(false);
    this.getNewTagModalList();
    if (this.props.documentType !== 2) {
      if (this.state.warehouses.length === 0) {
        this.fetchWarehouses().then((warehouses) => {
          let tableData = this.state.tableData;
          tableData.rows = tableData.rows.map(function (row, index) {
            if (self.state.formData.rows[index].item_id) {
              self
                .fetchWarehousesToItem(self.state.formData.rows[index].item_id)
                .then((warehouses1) => {
                  row[1]["data"] = warehouses1;
                });
            } else {
              row[1]["data"] = warehouses;
            }
            self.onInvoiceLineChange(
              index,
              self.state.formData.rows[index],
              "warehouse_id"
            );
            return row;
          });
          this.setState({
            warehouses,
            tableData,
          });
        });
      }
    }
    try {
      this.prepareFormToPost(this.state.formData);
    } catch (ex) {
      console.log(ex);
    }

    this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS_ALL);
    if (!this.props.router.params.id) {
      var json = JSON.parse(localStorage.getItem("nace_code_list"));
      if (json.length > 1) {
        let result = json.find((obj) => obj.varsayilan === 1);
        this.state.formData.rows[0].nace_code_id = result.id;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    var count = 0;
    if (this.props.documentType == 2) {
      count = 0;
    }
    if (
      JSON.stringify(nextProps.formData) !== JSON.stringify(this.props.formData)
    ) {
      this.setState({ formData: nextProps.formData });
    }

    if (
      nextProps.vatRateTypes &&
      nextProps.vatReductionTypeCodes &&
      nextProps.units
    ) {
      let tableData = this.state.tableData;
      tableData.rows[0][9 + count]["data"] =
        nextProps.vatRateTypes || this.props.vatRateTypes;
      tableData.rows[0][5 + count]["data"] =
        nextProps.units || this.props.units;

      this.setState({ tableData: tableData });
    }
    if (nextProps.otherAmount && !this.state.otherAmount) {
      let tableData = this.state.tableData;
      // tableData.footerData.taxes_excvat_amount_fc = Util.convertDecimalToMoney(nextProps.otherAmount)
      let key =
        nextProps.otherAmount < 0
          ? "stoppage_amount_fc"
          : "taxes_excvat_amount_fc";
      tableData.footerTable.map((tableEl) => {
        if (tableEl.id === key) {
          tableEl.visible = true;
        }
        return tableEl;
      });

      this.setState({
        tableData: tableData,
        otherAmount: nextProps.otherAmount,
      });
      this.prepareFormToPost(this.state.formData, nextProps.otherAmount);
    }
    if (nextProps.calculate && !this.props.calculate) {
      this.prepareFormToPost(nextProps.formData);
      this.setState({ calculate: false });
    }
    if (nextProps.isGetInUpdate && !this.props.isGetInUpdate) {
      const rows = nextProps.formData.rows;
      const self = this;
      rows.forEach((r, i) => {
        self.onInvoiceLineChange(i, r, "unit_price_fc");
      });
    }
    this.prepareFormToPost(this.state.formData);
    this.renewTableData(nextProps.formData);
  }
  naceCodeHidden() {
    try {
      this.setState({
        naceCodeList: JSON.parse(localStorage.getItem("nace_code_list")),
      });
      if (JSON.parse(localStorage.getItem("nace_code_list")).length <= 1) {
        return true;
      } else {
        return false;
      }
      //console.log("try");
    } catch (error) {
      console.log("catch error", error);
      axios
        .get(ROOT_URL + "/company/naceCodeListSelect", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          var data = JSON.stringify(response.data.naceCode, null, 2);
          localStorage.setItem("nace_code_list", "");
          localStorage.setItem("nace_code_list", data);
        });
      window.setTimeout(function () {
        window.location.href = "/dashboard";
      }, 3000);
    }
  }
  componentWillUpdate(nextProps, nextState) {
    let tableData = nextState.tableData;
    let formData = nextState.formData;
    let errors = nextState.errors;
    var count = 0;
    if (this.props.documentType == 2) {
      count = 0;
    }
    if (
      formData &&
      (formData.rows.length > tableData.rows.length ||
        formData.rows.length > errors.rows.length)
    ) {
      while (formData.rows.length > tableData.rows.length) {
        let row = this.defaultRows()[0];
        if (nextProps.documentType === 2) {
          row[0]["selectedOptionValue"] = "codeName";
          row[0]["searchFilter"] = "key_words";
          row[0]["defaultKey"] = "diğer";
          row[0]["id"] = "expense_category_id";
        }
        row[0].data = tableData.rows[0][0].data;

        row[9 + count]["data"] =
          nextProps.vatRateTypes || this.props.vatRateTypes;
        row[5 + count]["data"] = nextProps.units || this.props.units;
        tableData.rows.push(row); //TODO bunu inputabledaki addRow gibi clonlamak lazim ilerde sikini yaratir @bocek
      }

      const defaultErrors = nextProps.errors;
      while (errors.rows.length < formData.rows.length)
        errors.rows.push(defaultErrors.rows[0]);
      const self = this;
      this.setState({ tableData: tableData, errors: errors });
      self.renewTableData();
    } else if (
      formData &&
      formData.rows.length === 1 &&
      (tableData.rows.length > 1 || tableData.rows.length > 1)
    ) {
      //edit den add safasina geclince
      let tableData = this.state.tableData;
      tableData.rows = this.defaultRows();
      this.setState({
        errors: this.props.defaultErrors(),
        tableData: tableData,
      });
    }
    if(this.state.formData.invoice.purchase_types===1){
      let tableData = this.state.tableData;
      const formData = this.state.formData;
      tableData.rows.forEach(function (row, index) {
        if (tableData.rows[index][0].disabled != true) {
          tableData.rows[index][0].disabled = true;
        }
      });
    }else if(this.state.formData.invoice.purchase_types===2 || this.state.formData.invoice.purchase_types===0){
      let tableData = this.state.tableData;
      const formData = this.state.formData;
      tableData.rows.forEach(function (row, index) {
        if (tableData.rows[index][0].disabled != false) {
          tableData.rows[index][0].disabled = false;
        }
      });
    }
  }
  getTaxSubjectToVat(taxId) {
    if (taxId && this.props.taxTypes) {
      const taxType = Sugar.Array.find(this.props.taxTypes, function (type) {
        return type.id === taxId;
      });
      return taxType.subject_to_vat;
    }
    return 0;
  }
  getTaxReductionType(taxId) {
    if (taxId && this.props.taxTypes) {
      const taxType = Sugar.Array.find(this.props.taxTypes, function (type) {
        return type.id === taxId;
      });
      return taxType.reduction_type;
    }
    return 0;
  }

  getReductionRate(reductionCode) {
    if (this.props.vatReductionTypeCodes) {
      const reduction = Sugar.Array.find(
        this.props.vatReductionTypeCodes,
        function (type) {
          return type.code === reductionCode;
        }
      );
      return Util.convertNumberToPercent(reduction.rate);
    }
    return Util.convertNumberToPercent(0);
  }

  prepareFormToPost(formData, getOtherAmount) {
    let calculatedFormData = {};
    const self = this;
    let otherAmount = getOtherAmount ? getOtherAmount : this.state.otherAmount;

    if (formData.invoice) {
      calculatedFormData.invoice = { ...formData.invoice };
      calculatedFormData.invoice.document_type = this.props.documentType;
      calculatedFormData.invoice.description =
        calculatedFormData.invoice.legal_no + " nolu fatura";
      if (this.props.documentType === 3) {
        calculatedFormData.invoice.customer_id = Util.parseInt(
          calculatedFormData.invoice.customer_id
        );
        calculatedFormData.invoice.supplier_id = null;
      } else {
        calculatedFormData.invoice.supplier_id = Util.parseInt(
          calculatedFormData.invoice.supplier_id
        );
        calculatedFormData.invoice.customer_id = null;
      }
      calculatedFormData.invoice.currency_rate = Util.convertMoneyToDecimal(
        calculatedFormData.invoice.currency_rate
      );
      calculatedFormData.invoice.date = calculatedFormData.invoice.date
        ? moment(calculatedFormData.invoice.date).format("YYYY-MM-DD")
        : null;
      calculatedFormData.invoice.due_date = calculatedFormData.invoice.due_date
        ? moment(calculatedFormData.invoice.due_date).format("YYYY-MM-DD")
        : null;
      calculatedFormData.invoice.shipping_date = calculatedFormData.invoice
        .shipping_date
        ? moment(calculatedFormData.invoice.shipping_date).format("YYYY-MM-DD")
        : null;
    }

    let footer = {
      price_amount: 0,
      price_amount_fc: 0,
      discount_amount: 0,
      discount_amount_fc: 0,
      net_amount: 0,
      net_amount_fc: 0,
      taxes_sbjvat_amount: 0,
      taxes_sbjvat_amount_fc: 0,
      vat_amount: 0,
      vat_amount_fc: 0,
      taxes_excvat_amount: 0,
      taxes_excvat_amount_fc: 0,
      total_amount: 0,
      total_amount_fc: 0,
      vat_reduction_amount: 0,
      vat_reduction_amount_fc: 0,
      stoppage_amount: 0,
      stoppage_amount_fc: 0,
      payable_amount: 0,
      payable_amount_fc: 0,
    };

    if (formData.rows) {
      const currencyRate = calculatedFormData.invoice.currency_rate || 1;
      calculatedFormData.rows = formData.rows.map(function (frow) {
        let row = { ...frow };
        if (row.item_id === 0) row.item_id = null;
        row.quantity = Util.convertMoneyToDecimal(row.quantity);
        row.discount_amount_fc = Util.convertMoneyToDecimal(
          row.discount_amount_fc
        );
        row.discount_amount = row.discount_amount_fc * currencyRate;

        row.unit_price_fc = UtilHelper.money.convertMoneyToDecimal8(
          row.unit_price_fc
        );

        row.unit_price = row.unit_price_fc * currencyRate;
        if (self.props.isGetIn) {
          if (
            row.expense_category_id === undefined &&
            self.props.documentType == 2
          ) {
            row.expense_category_id = null;
          }
        }

        row.net_amount_fc = Util.convertMoneyToDecimal(row.net_amount_fc);
        row.net_amount = row.net_amount_fc * currencyRate;

        row.discount_rate = Util.convertPercentToNumber(row.discount_rate);

        row.vat_amount_fc = Util.convertMoneyToDecimal(row.vat_amount_fc);
        row.vat_amount = row.vat_amount_fc * currencyRate;

        if (row.vat_rate_type_id && !row.vat_rate) {
          const vatRate = sugarFind(self.props.vatRateTypes, function (type) {
            return type.id === row.vat_rate_type_id;
          });
          if (vatRate) row.vat_rate = vatRate.rate;
        } else row.vat_rate = row.vat_rate || 0;
        row.taxes_sbjvat_amount_fc = 0;
        row.taxes_excvat_amount_fc = 0;
        row.stoppage_amount_fc = 0;
        row.vat_reduction_amount_fc = 0;
        if (self.props.isGetIn) {
          //@oguz bir satır alttaki kodu 19 şubat 2024 te kaldırdım tevkifat satır toplamlarının alınmasını engelliyordu ne işe yaradığını anlayamadım sorun çıkarabilir TODO:
          // row.otherTaxes = row.otherTaxess;
        }
        if (row.otherTaxes) {
          let otherTaxes = [];
          row.otherTaxes.forEach(function (rowOtherTax) {
            let otherTax = { ...rowOtherTax };
            otherTax.tax_amount_fc = Util.convertMoneyToDecimal(
              otherTax.tax_amount_fc
            );
            otherTax.tax_rate = Util.convertPercentToNumber(otherTax.tax_rate);

            if (otherTax.tax_type_code === "9015")
              otherTax.tax_amount_fc = otherTax.tax_rate * row.vat_amount_fc;
            else otherTax.tax_amount_fc = otherTax.tax_rate * row.net_amount_fc;

            otherTax.tax_amount = otherTax.tax_amount_fc * currencyRate;

            if (otherTax.tax_type_id && !otherTax.subject_to_vat) {
              otherTax.subject_to_vat = self.getTaxSubjectToVat(
                otherTax.tax_type_id
              );
              otherTax.reduction_type_id = self.getTaxReductionType(
                otherTax.tax_type_id
              );
            }
            if (otherTax.subject_to_vat === 1) {
              row.taxes_sbjvat_amount_fc += otherTax.tax_amount_fc;
            } else if (
              !otherTax.vat_reduction_type_code &&
              otherTax.reduction_type_id === 0
            ) {
              row.taxes_excvat_amount_fc += otherTax.tax_amount_fc;
            }

            if (otherTax.reduction_type_id === 1)
              row.stoppage_amount_fc += otherTax.tax_amount_fc;

            if (otherTax.tax_type_code === "9015") {
              row.vat_reduction_amount_fc = Util.convertMoneyToDecimal(
                Util.convertDecimalToMoney(
                  otherTax.tax_rate * row.vat_amount_fc
                )
              );
              row.vat_reduction_amount = Util.convertMoneyToDecimal(
                  Util.convertDecimalToMoney(
                      otherTax.tax_rate * row.vat_amount
                  )
              );
              // otherTax.tax_rate * row.vat_amount_fc;
            }
            if (otherTax.tax_type_code === "OZELMATRAH") {
              otherTax.tax_amount = Util.convertMoneyToDecimal(
                rowOtherTax.tax_amount_fc
              );
              otherTax.tax_amount_fc = Util.convertMoneyToDecimal(
                rowOtherTax.tax_amount_fc
              );
              var taxVatRate =
                Util.convertMoneyToDecimal(
                  parseInt(rowOtherTax.tax_rate.replace("%", ""))
                ) / 100;
              footer.vat_amount +=
                Util.convertMoneyToDecimal(rowOtherTax.tax_amount_fc) *
                taxVatRate;
              footer.vat_amount_fc +=
                Util.convertMoneyToDecimal(rowOtherTax.tax_amount_fc) *
                taxVatRate;
            }
            otherTaxes.push(otherTax);
          });
          delete row.otherTaxes;
          row.otherTaxes = otherTaxes;
        } else row.otherTaxes = [];
        row.total_amount_fc =
          row.net_amount_fc +
          row.vat_amount_fc +
          row.taxes_sbjvat_amount_fc +
          row.taxes_excvat_amount_fc;
        /*+ row.taxes_excvat_amount_fc*/
        row.total_amount = row.total_amount_fc * currencyRate;

        row.taxes_sbjvat_amount = row.taxes_sbjvat_amount_fc * currencyRate;
        row.taxes_excvat_amount = row.taxes_excvat_amount_fc * currencyRate;
        row.stoppage_amount = row.stoppage_amount_fc * currencyRate;
        row.vat_reduction_amount = row.vat_reduction_amount_fc * currencyRate;

        footer.price_amount += row.net_amount + row.discount_amount;
        footer.price_amount_fc += row.net_amount_fc + row.discount_amount_fc;
        footer.net_amount += row.net_amount;
        footer.net_amount_fc += row.net_amount_fc;
        footer.discount_amount += row.discount_amount;
        footer.discount_amount_fc += row.discount_amount_fc;
        footer.vat_amount += row.vat_amount;
        footer.vat_amount_fc += row.vat_amount_fc;
        footer.taxes_sbjvat_amount += row.taxes_sbjvat_amount;
        footer.taxes_sbjvat_amount_fc += row.taxes_sbjvat_amount_fc;
        footer.taxes_excvat_amount += row.taxes_excvat_amount;
        footer.taxes_excvat_amount_fc += row.taxes_excvat_amount_fc;
        footer.total_amount += row.total_amount;
        footer.total_amount_fc += row.total_amount_fc;
        footer.vat_reduction_amount += row.vat_reduction_amount;
        footer.vat_reduction_amount_fc += row.vat_reduction_amount_fc;
        footer.stoppage_amount += row.stoppage_amount;
        footer.stoppage_amount_fc += row.stoppage_amount_fc;

        return row;
      });
    }
    footer.receivable_amount =
      footer.total_amount -
      footer.vat_reduction_amount -
      footer.stoppage_amount;
    footer.receivable_amount_fc =
      footer.total_amount_fc -
      footer.vat_reduction_amount_fc -
      footer.stoppage_amount_fc;

    footer.payable_amount =
      footer.total_amount -
      footer.vat_reduction_amount -
      footer.stoppage_amount;
    footer.payable_amount_fc =
      footer.total_amount_fc -
      footer.vat_reduction_amount_fc -
      footer.stoppage_amount_fc;

    
    // @oguz 22 mayıs 2023 tarihinde devredışı bıraktı 
    // @furkan geri açıldı 12 aralık 2023 fatura içeri alırken other amaount değerini hesaba katmıyor
    // if (otherAmount) {
    //   const rate = calculatedFormData.invoice.currency_rate || 1;
    //   if (otherAmount > 0) {
    //     footer.taxes_excvat_amount_fc = otherAmount * rate;
    //     footer.taxes_excvat_amount = otherAmount;
    //   } else {
    //     footer.stoppage_amount_fc = otherAmount * rate * -1;
    //     footer.stoppage_amount = otherAmount * -1;
    //   }
    //   footer.total_amount += otherAmount;
    //   footer.total_amount_fc += otherAmount * rate;
    //   footer.payable_amount += otherAmount;
    //   footer.payable_amount_fc += otherAmount * rate;
    // }
    // @furkan
    footer.total_amount = footer.payable_amount;
    footer.total_amount_fc = footer.payable_amount_fc;
    footer.discount_rate =
      Math.round((footer.discount_amount_fc / footer.price_amount_fc) * 10000) /
      10000;
    footer.received_amount_fc = 0;
    footer.received_amount = 0;
    calculatedFormData.footer = footer;

    let tableData = this.state.tableData;

    Object.keys(footer).map((key) => {
      if (footer[key] > 0) {
        tableData.footerTable.map((tableEl) => {
          if (tableEl.id === key && tableEl.visible) {
            tableEl.visible = true;
          }

          return tableEl;
        });
      }

      return (tableData.footerData[key] = Util.convertDecimalToMoney(
        footer[key]
      ));
    });

    // for (var key in footer) {
    //
    //     if (footer[key] > 0) {
    //         tableData.footerTable = tableData.footerTable.map(function (tableEl) {
    //             if (tableEl.id === key) tableEl.visible = true
    //             return tableEl
    //         })
    //     }
    //     tableData.footerData[key] = Util.convertDecimalToMoney(footer[key])
    // }

    this.setState({ tableData: tableData });

    if (!this.isPermitted()) {
      const selfEmploymentDatas = parseSelfEmploymentData();

      if (this.props.documentType === 3) {
        calculatedFormData.invoice.customer_id =
          selfEmploymentDatas.self_employment.default_customer;
      } else {
        calculatedFormData.invoice.supplier_id =
          selfEmploymentDatas.self_employment.default_supplier;
      }
    }

    return calculatedFormData;
  }

  onChange(type, field, value) {
    let formData = { ...this.state.formData };
    var self = this;
    if (field === "document_type_tag") {
      const documentType = this.props.documentType;
      if (documentType == 2) {
        const documentType = JSON.parse(
          localStorage.getItem("getDBSExpenseDocumentType")
        );
        const get = Sugar.Array.find(
          documentType.documentType,
          function (type) {
            return type.id === value;
          }
        );
        if (get.code !== undefined) {
          this.setState({ serial_order_choose: get.serial_order_choose });
          Api.get(
            "/DBS/purchaseExpenseCategoriesSelect/list/1/" + get.code,
            function (response, err) {
              let items = response.purchaseExpenseCategories.map(function (
                item
              ) {
                item.codeName = item.description;
                return item;
              });
              let stateItems = {};
              items.forEach(function (item, index) {
                stateItems[item.id] = item;
              });

              let tableData = self.state.tableData;
              tableData.rows.map((row) => {
                row[0].data = items;
                row[0].selectedOptionValue = "codeName";
                row[0].searchFilter = "key_words";
                //row[0].defaultKey = "diğer";
                row[0].id = "expense_category_id";
                return row;
              });
              tableData.headers[0].label = "Kategori";
              self.setState({ tableData: tableData, items: stateItems });
            }
          );
        }
      }
    }
    if (field === "warehouse_id") {
      formData.rows.forEach(function (row, index) {
        formData.rows[index]["warehouse_id"] = value;
        self.onInvoiceLineChange(index, formData.rows[index], "warehouse_id");
      });
    }
    formData[type][field] = value;

    this.setState({ formData: formData }, function () {
      this.resetErrorForInvoice(field);
    });
  }

  onRefresh() {
    if (this.props.onRefresh) {
      this.props.onRefresh();
    }
  }

  handleSuccess(redirectToBase = false) {
    const self = this;
    toastr.success("Başarılı şekilde kaydedildi");
    self.setState({ saving: false });

    //if (!self.props.router.params.id) check here if a strange bug happens

    if (!this.props.router.params.id) {
      if (redirectToBase === true) {
        this.props.redirectToBase();
      } else {
        let tableData = this.state.tableData;
        let footerData = {
          discount_amount: "0,00",
          discount_amount_fc: "0,00",
          discount_rate: "0,00",
          net_amount: "0,00",
          net_amount_fc: "0,00",
          price_amount: "0,00",
          price_amount_fc: "0,00",
          receivable_amount: "0,00",
          receivable_amount_fc: "0,00",
          received_amount: "0,00",
          received_amount_fc: "0,00",
          stoppage_amount: "0,00",
          stoppage_amount_fc: "0,00",
          taxes_excvat_amount: "0,00",
          taxes_excvat_amount_fc: "0,00",
          taxes_sbjvat_amount: "0,00",
          taxes_sbjvat_amount_fc: "0,00",
          total_amount: "0,00",
          total_amount_fc: "0,00",
          vat_amount: "0,00",
          vat_amount_fc: "0,00",
          vat_reduction_amount: "0,00",
          vat_reduction_amount_fc: "0,00",
        };

        self.props.refreshPage();

        tableData = Object.assign(tableData, { footerData: footerData });

        this.setState({ tableData: tableData }, function () {
          self.fetchItems(false);
          self.renewTableData();
        });
      }
    } else {
      if (redirectToBase === true) {
        this.props.redirectToBase();
      } else {
        let type = this.props.documentType;
        let url;

        if (type === 1) {
          url =
            "/dashboard/purchases/purchaseInvoice/add/productServiceInvoice";
        } else if (type === 2) {
          url = "/dashboard/purchases/purchaseInvoice/add/expenseInvoice";
        } else if (type === 3) {
          url =
            "/dashboard/purchases/purchaseInvoice/add/customerReturnInvoice";
        }
        self.props.router.push(url);
      }

      if (self.props.onSave) {
        self.props.onSave();
      }
    }
  }

  paymentApiAdd(purchase_invoice_id) {
    let formData = this.state.formData;

    if (formData.invoice.radioButtons === "paid") {
      var title = JSON.parse(localStorage.getItem("user"));

      let company_id = title["company_id"];

      let bank_currency = this.state.bank_currency;

      let payable_amount =
        this.state.tableData.footerData.payable_amount.split(".");
      let payable_amount_fc =
        this.state.tableData.footerData.payable_amount_fc.split(".");


      let new_payable_amount = "";
      for (let i = 0; i < payable_amount.length; i++) {
        new_payable_amount += payable_amount[i];
      }

      let new_payable_amount_fc = "";
      for (let i = 0; i < payable_amount_fc.length; i++) {
        new_payable_amount_fc += payable_amount_fc[i];
      }

      let payment = Object.assign(
        {},
        {
          company_id: company_id,
          date: formData.invoice.date1,
          amount:
            bank_currency === "TRY"
              ? new_payable_amount.replace(",", ".")
              : new_payable_amount_fc.replace(",", "."),
          currency: formData.invoice.currency,
          currency_rate: Util.convertMoneyToDecimal(
            formData.invoice.currency_rate
          ),
          amount_fc:
            bank_currency !== "TRY"
              ? new_payable_amount_fc.replace(",", ".")
              : new_payable_amount_fc.replace(",", "."),
          paid_amount: 0,
          table_type: 1,
          paid_amount_fc: 0,
          description: "Ödeme",
          type: 1,
          type_source_id: formData.invoice.supplier_id,
          method: this.state.method,
          method_source_id: formData.invoice.method_source_id,
          currency_rate_bank: Util.convertMoneyToDecimal(
            formData.invoice.currency_rate
          ),
          purchase_invoice_id: purchase_invoice_id,
        }
      );

      let func = "post";
      let url = "/payment";

      Api[func](url, payment, function (response, postErrors) {
        if (response) {
          console.log("işlem başarılı");
        } else {
          console.log("burada bir sorun var");
        }
      });
    }
  }

  bankCurrencyAndMethod = (data, data2) => {
    this.setState({ bank_currency: data, method: data2 });
  };

  onSave(refresh, isChecked) {
    const redirectToBase = arguments[0];
    const self = this;

    let formData = self.state.formData;
    if (formData.invoice.legal_no) {
      formData.invoice.legal_no = formData.invoice.legal_no.toUpperCase();
    }
    const baseCurrency = formData.invoice.currency;

    // for (var fi in formData.rows) {
    //   const items = this.state.tableData.rows[fi][0].data;
    //   for (var i in items) {
    //     if (items[i].id === formData.rows[fi].item_id) {
    //       // iade faturası ise
    //       if (this.props.documentType === 3) {
    //         if (items[i].currency_sales !== baseCurrency) {
    //           toastr.error(
    //             "Faturanın döviz tipi ile seçili ürünlerin döviz tipi aynı olmalıdır."
    //           );
    //           return;
    //         }
    //       } else {
    //         // alım faturası ise
    //         if (items[i].currency_purchase !== baseCurrency) {
    //           toastr.error(
    //             "Faturanın döviz tipi ile seçili ürünlerin döviz tipi aynı olmalıdır."
    //           );
    //           return;
    //         }
    //       }
    //     }
    //   }
    // }
    if (this.state.formData.invoice.purchase_types === 2) {
      const formData = this.state.formData;
      const hasInvalidRow = formData.rows.some((row) => {
        if (row.item_id === 0) {
          toastr.error("Uyarı! Ürün hizmet seçmek istemiyorsanız lütfen Normal Satış giriniz.");
          return true;
        }
        return false;
      });
      if (hasInvalidRow) return;
    }

    self.setState({ saving: true });

    self.validateInvoiceLines(true, function (linesOk) {
      if (linesOk) {
        if (
            !self.state.passModal && (
          formData.rows.filter((r) => !r.item_id && !r.unSelected).length > 0 &&
          !self.props?.editId &&
          self.props?.documentType !== 2 && self.props?.documentType !== 4 &&
          !formData.invoice.purchase_types
            )
        ) {
          self.setState({
            itemModalValues: { showItemModal: true, refresh, isChecked },
          });
          return;
        }
        self.purchaseInvoice(function (invoiceOk) {
          if (invoiceOk) {
            let formData = self.state.formData;
            formData.rows = formData.rows.map(function (row) {
              row.purchase_invoice_id = formData.invoice.id;
              return row;
            });

            self.paymentApiAdd(formData.rows[0].purchase_invoice_id);

            self.purchaseInvoiceLines(function (invoiceLinesSaved) {
              let calculatedFormData = self.prepareFormToPost(
                self.state.formData
              );
              calculatedFormData.rows.forEach(function (row) {
                row.otherTaxes = row.otherTaxes.map(function (tax) {
                  tax.purchase_invoice_line_id = row.id;
                  tax.purchase_invoice_id = calculatedFormData.invoice.id;
                  return tax;
                });
                //self.handleSuccess(redirectToBase);
                self.saveTaxLines(row.otherTaxes, function (taxLinesOk) {
                  if (taxLinesOk) {
                    if (isChecked === true) {
                      Api.get(
                        `/purchaseInvoice/next/${self.props?.editId}`,
                        function (res, error) {
                          if (res.item) {
                            const path = window.location.pathname;
                            const ex = path.split("/");
                            ex.pop();
                            ex.push(res.item.id);
                            self.setState({
                              saving: false,
                            });
                            window.location.href = ex.join("/");
                          } else {
                            self.handleSuccess(true);
                          }
                        }
                      );
                    } else {
                      self.handleSuccess(redirectToBase);
                    }
                  }
                });
              });
            });
          } else {
            self.setState({ saving: false });
            console.log("invoice not validated");
          }
        }, !!isChecked);
      } else {
        self.setState({ saving: false });
        console.log("lines not validated");
      }
    });
  }

  onCancel() {
    if (this.props.onCancel) this.props.onCancel();
  }

  renewTableData(data) {
    const self = this;
    let tableData = this.state.tableData;
    const formData = data || this.state.formData;
    var count = 0;
    if (this.props.documentType == 2) {
      count = 0;
    }

    tableData.rows.forEach(function (row, index) {
      //tableData.rows[index][10].onClick = self.onOtherTaxClick.bind(self,index)
      const fRow = formData.rows[index] || {};
      if (self.props.isGetIn) {
        fRow.otherTaxes = fRow.otherTaxess;
      }
      const taxCount = fRow.otherTaxes ? fRow.otherTaxes.length : 0;
      tableData.rows[index][11 + count].props = {
        itemCount: taxCount,
        warning: "",
      };
      tableData.rows[index][11 + count].onRender =
        self.invoiceLineButtonGroup.bind(self, index);
      if (self.props.documentType != 2) {
        tableData.rows[index][2].onSelect = self.onSelectDescription.bind(
          self,
          index
        );
        tableData.rows[index][12 + count].onClick =
          self.onOpenChangingModal.bind(self, index);
      }
      if (self.props.documentType === 4) {
        tableData.rows[index][13 + count].onClick =
            self.onOpenAssetModal.bind(self, index);
      }

      tableData.rows[index][9 + count]["data"] = self.props.vatRateTypes;
      tableData.rows[index][5 + count]["data"] = self.props.units;

      if (self.props.documentType !== 2 && self.props.documentType !== 4)
        tableData.rows[index][0].onAddNewItemClick = self.onAddNewItemClick.bind(self, index);
      if (self.props.documentType !== 2 && self.props.documentType !== 4)
        tableData.rows[index][0].onEditItemClick = self.onEditItemClick.bind(self, index);
      if (fRow["item_id"]) {
        tableData.rows[index][5 + count].disabled = true;
      } else tableData.rows[index][5 + count].disabled = false;
    });
    self.setState({ tableData: tableData });
  }

  onInvoiceLineRemoved(index, row) {
    let formData = this.state.formData;
    let errors = this.state.errors;
    let deleteRowIds = this.state.deleteRowIds;
    errors.rows = sugarRemoveAt(errors.rows, index);
    this.setState({ errors: errors });
    if (formData.invoice.id && row.id) {
      deleteRowIds.push(row.id);
    }

    this.prepareFormToPost(this.state.formData);
    this.renewTableData();
  }

  onInvoiceLineAdded() {
    var formData = this.state.formData;
    formData.rows[formData.rows.length - 1]["warehouse_id"] =
      formData.invoice.warehouse_id ?? 0;
    this.setState({
      formData: {
        ...formData,
        rows: [...formData.rows],
      },
    });
    this.renewTableData();
    this.validateInvoiceLines(false, null);
  }

  onInvoiceChange(newFormData) {
    // TODO: oluşturulcak
  }

  onInvoiceChange() {
    // todo
  }

  onTaxRowChange() {
    ///
  }

  onTaxChange() {
    //
  }

  onTaxFormSave(taxForm) {
    console.log('onTaxFormSave',taxForm);
    var count = 0;
    if (this.props.documentType == 2) {
      count = 0;
    }
    let formData = this.state.formData;
    let tableData = this.state.tableData;
    let deleteTaxIds = this.state.deleteTaxIds;
    const index = this.state.selectedLine;
    let row = formData.rows[index];
    if (!taxForm) {
      tableData.rows[index][11 + count]["props"]["itemCount"] = 0;
      row.otherTaxes.map((item) => deleteTaxIds.push(item.id));
      row.otherTaxes = false;
    } else {
      row.otherTaxes = [...taxForm.rows];
      tableData.rows[index][11 + count]["props"]["itemCount"] =
        row.otherTaxes.length;
      if (taxForm.deleteRowIds) {
        taxForm.deleteRowIds.forEach(function (id) {
          deleteTaxIds.push(id);
        });
      }
    }

    formData.rows[index] = row;
    const calculatedFormData = this.prepareFormToPost(formData);
    calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
      calculatedFormData.rows[index]
    );
    formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
      calculatedFormData.rows[index].vat_amount_fc
    );
    this.prepareFormToPost(formData);
    this.setState({
      showAddTax: false,
      formData: formData,
      tableData: tableData,
      deleteTaxIds: deleteTaxIds,
    });
  }

  getDiscountAmount(rowData) {
    return rowData.unit_price_fc * rowData.quantity * rowData.discount_rate;
  }

  getNetAmount(rowData) {
    return (
      rowData.unit_price_fc * rowData.quantity - rowData.discount_amount_fc
    );
  }

  getDiscountRate(rowData) {
    return (
      rowData.discount_amount_fc / (rowData.unit_price_fc * rowData.quantity)
    );
  }

  getVatAmount(rowData) {
    return (
      (rowData.taxes_sbjvat_amount_fc + rowData.net_amount_fc) *
      rowData.vat_rate
    );
  }

  resetErrorForInvoiceLine(index, field) {
    let errors = this.state.errors;
    let tableData = this.state.tableData;
    var count = 0;
    if (this.props.documentType == 2) {
      count = 0;
    }
    if (!errors.rows) errors.rows = [];
    if (!errors.rows[index]) errors.rows[index] = {};
    errors.rows[index][field] = false;
    if (field === "vat_amount_fc" || field === "net_amount_fc") {
      if (tableData.rows[index][11 + count]["props"]["itemCount"] > 0)
        toastr.warning(" İlişkili diğer vergilerini de kontrol ediniz",{ disableCloseButtonFocus: true});
    }
    this.setState({ errors: errors });
  }

  resetErrorForInvoice(field) {
    let errors = this.state.errors;
    errors.invoice[field] = false;

    this.setState({ errors: errors });
  }

  onInvoiceLineReallyChange(index, field, value, oldValue) {
    if (!this.props.isGetIn && !this.state.formData.inXml) {
      this.resetErrorForInvoiceLine(index, field);

      if (field === "item_id") {
        this.resetErrorForInvoiceLine(index, "vat_rate_type_id");
        this.resetErrorForInvoiceLine(index, "unit_price_fc");
        this.resetErrorForInvoiceLine(index, "unit_id");
        this.resetErrorForInvoiceLine(index, "description");
      }

      if (
        field === "item_id" ||
        field === "unit_price_fc" ||
        field === "quantity" ||
        field === "discount_rate"
      ) {
        this.resetErrorForInvoiceLine(index, "discount_amount_fc");
        this.resetErrorForInvoiceLine(index, "net_amount_fc");
        this.resetErrorForInvoiceLine(index, "vat_amount_fc");
      }

      if (field === "discount_amount_fc") {
        this.resetErrorForInvoiceLine(index, "discount_rate");
        this.resetErrorForInvoiceLine(index, "net_amount_fc");
        this.resetErrorForInvoiceLine(index, "vat_amount_fc");
      }

      if (field === "vat_rate_type_id") {
        this.resetErrorForInvoiceLine(index, "vat_amount_fc");
      }
    }
  }
  closeChangingModal = () => {
    this.setState({
      ChangingModal: !this.state.ChangingModal,
    });
  };
  onInvoiceLineChange(index, rowData, field, oldRowData) {
    let formData = this.state.formData;
    var count = 0;
    if (this.props.documentType == 2) {
      count = 0;
    }
    //  this.resetErrorForInvoiceLine(index,field)
    var emptyString = true;
    if (this.props.isGetIn && formData.rows[index].net_amount_fc !== "0,00" && field === "item_id") {
      emptyString = false;
    }
    if (this.props.documentType !== 2) {
      this.fetchWarehousesToItem(rowData.item_id).then((warehouses) => {
        let tableData = this.state.tableData;
        tableData.rows[index][1]["data"] = warehouses;
        this.setState({ tableData });
      });
    }

    if(field === "item_id" ){
      let itemsx = this.state.items;
      let itemIdx = rowData.item_id;
      if (!this.props.isGetIn && !this.state.formData.inXml){
        formData.rows[index].description = itemsx[itemIdx].item_name;
      }
      formData.rows[index].item_code = itemsx[itemIdx].item_code;
      if (this.props.documentType === 1 && itemsx[itemIdx].purchase_description)
        formData.rows[index].description +=
            " - " + itemsx[itemIdx].purchase_description;
      else if (this.props.documentType === 3 && itemsx[itemIdx].sales_description)
        formData.rows[index].description +=
            " - " + itemsx[itemIdx].sales_description;
    }

    if (!this.props.isGetIn && !this.state.formData.inXml && field === "item_id" && emptyString === true) {
      let items = this.state.items;
      let itemId = rowData.item_id;
      formData.rows[index].description = items[itemId].item_name;
      formData.rows[index].item_code = items[itemId].item_code;
      if (this.props.documentType === 1 && items[itemId].purchase_description)
        formData.rows[index].description +=
          " - " + items[itemId].purchase_description;
      else if (this.props.documentType === 3 && items[itemId].sales_description)
        formData.rows[index].description +=
          " - " + items[itemId].sales_description;

      if (this.props.documentType === 1) {
        formData.rows[index].unit_price_fc =
          UtilHelper.money.convertDecimalToMoney8(
            items[itemId].purchase_unit_price
          );

        formData.rows[index].vat_rate_type_id =
          items[itemId].purchase_vat_rate_type_id;
      } else if (this.props.documentType === 3) {
        formData.rows[index].unit_price_fc =
          UtilHelper.money.convertDecimalToMoney8(
            items[itemId].sales_unit_price
          );
        formData.rows[index].vat_rate_type_id =
          items[itemId].sales_vat_rate_type_id;
      }

      formData.rows[index].unit_id = items[itemId].unit_id;

      const calculatedFormData = this.prepareFormToPost(formData);
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );

      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      let tableData = this.state.tableData;
      tableData.rows[index][5 + count].disabled = true;
      this.setState({ tableData: tableData });
      this.onInvoiceLineChange(index, formData.rows[index], "vat_rate_type_id");
    }

    if (
      (!this.props.isGetIn &&
        emptyString === true &&
        field === "item_id" &&
        !formData.inXml) ||
      field === "unit_price_fc" ||
      field === "quantity" ||
      field === "discount_rate"
    ) {
      formData.rows[index][field] = rowData[field];
      const calculatedFormData = this.prepareFormToPost(formData);
      calculatedFormData.rows[index].discount_amount_fc =
        this.getDiscountAmount(calculatedFormData.rows[index]);
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );
      formData.rows[index].discount_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].discount_amount_fc
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
    } else if (field === "discount_amount_fc") {
      formData.rows[index][field] = rowData[field];
      const calculatedFormData = this.prepareFormToPost(formData);

      calculatedFormData.rows[index].discount_rate = this.getDiscountRate(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].net_amount_fc = this.getNetAmount(
        calculatedFormData.rows[index]
      );
      calculatedFormData.rows[index].vat_amount_fc = this.getVatAmount(
        calculatedFormData.rows[index]
      );

      formData.rows[index].discount_rate = Util.convertNumberToPercent(
        calculatedFormData.rows[index].discount_rate
      );
      formData.rows[index].net_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].net_amount_fc
      );
      formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
        calculatedFormData.rows[index].vat_amount_fc
      );
    } else if (field === "vat_rate_type_id") {
      formData.rows[index][field] = rowData[field];
      const vatRate = sugarFind(this.props.vatRateTypes, function (type) {
        return type.id === rowData[field];
      });
      if (vatRate) {
        formData.rows[index].vat_rate = vatRate.rate;
        const calculatedFormDataq = this.prepareFormToPost(formData);
        calculatedFormDataq.rows[index].vat_amount_fc = this.getVatAmount(
          calculatedFormDataq.rows[index]
        );
        formData.rows[index].vat_amount_fc = Util.convertDecimalToMoney(
          calculatedFormDataq.rows[index].vat_amount_fc
        );
      }
    } else {
      formData.rows[index][field] = rowData[field];
    }
    if(field==='unit_price_fc' && oldRowData &&rowData.unit_price_fc && (rowData.unit_price_fc===oldRowData || rowData.unit_price_fc==='' || rowData.unit_price_fc===0 || rowData.unit_price_fc.match(/0/g).length>7)){
      let sayiStr = formData.rows[index]['unit_price_fc'].toString();
      sayiStr = sayiStr.replace(/0+$/, '');
      if(sayiStr.slice(-1)===',' || sayiStr.slice(-1)==='.'){
        sayiStr = sayiStr+'00';
      }
      formData.rows[index]['unit_price_fc']=sayiStr;
    }
    this.setState({ formData: formData }, function () {
      this.prepareFormToPost(this.state.formData);
    });
  }

  validateInvoiceLines(all, next) {
    const self = this;
    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    const url = "/purchaseInvoiceLine/validation";
    let endpoints = [];
    calculatedFormData.rows.forEach(function (row) {
      endpoints.push({ endpoint: url, method: "post", params: row });
    });

    if (!all) {
      endpoints.pop(); //son eklenen rowu kontrol etme
    }

    Api.serialPutPost(endpoints, function (responses, errors) {
      if (errors.length === 0) {
        if (next !== null) next(true);
      } else {
        let formErrors = self.state.errors;
        formErrors.rows = errors;
        self.setState({ errors: formErrors }, function () {
          if (next !== null) next(false);
        });
      }
    });
  }

  deleteInvoiceLines(next) {
    const self = this;
    if (self.state.deleteRowIds.length > 0) {
      let endpointWithParams = [];
      self.state.deleteRowIds.forEach(function (id) {
        endpointWithParams.push({
          endpoint: "/purchaseInvoiceLine/" + id,
          method: "delete",
        });
      });

      Api.paralelAll(endpointWithParams, function (responses, error) {
        next(true);
      });
    } else next(true);
  }

  purchaseInvoiceLines(next) {
    const self = this;
    const calculatedFormData = this.prepareFormToPost(this.state.formData);

    let formData = this.state.formData;
    let endpoints = [];
    var rowcount = 0;

    calculatedFormData.rows.forEach(function (row) {
      rowcount++;
      row.sortable_id = rowcount;
      const method = row.id ? "put" : "post";
      const url = row.id
        ? "/purchaseInvoiceLine/" + row.id
        : "/purchaseInvoiceLine";
      endpoints.push({ endpoint: url, method: method, params: row });
    });

    Api.serialPutPost(endpoints, function (responses, errors) {
      if (errors.length === 0) {
        responses.forEach(function (response, index) {
          formData.rows[index].id = response.purchaseInvoiceLine.id;
        });
        self.setState({ formData: formData }, function () {
          if (next !== null) {
            self.deleteInvoiceLines(next);
          }
        });
      } else {
        let formErrors = self.state.errors;
        formErrors.rows = errors;
        self.setState({ errors: formErrors }, function () {
          if (next !== null) next(false);
        });

        if (errors.message) toastr.error(Util.getErrorAsStr(errors));
      }
    });
  }

  deleteTaxLines(next) {
    const self = this;
    if (self.state.deleteTaxIds.length > 0) {
      let endpointWithParams = [];
      self.state.deleteTaxIds.forEach(function (id) {
        endpointWithParams.push({
          endpoint: "/purchaseInvoiceTaxLine/" + id,
          method: "delete",
        });
      });

      Api.paralelAll(endpointWithParams, function (responses, error) {
        next(true);
      });
    } else next(true);
  }

  saveTaxLines(otherTaxes, next) {
    const self = this;

    let endpoints = [];
    otherTaxes.forEach(function (tax) {
      // E-fatura gönderiminde bu alan frontend ile aynı değer olarak kayıt edilsin.
      tax.tax_amount = Util.convertMoneyToDecimal(
        Util.convertDecimalToMoney(tax.tax_amount)
      );
      tax.tax_amount_fc = Util.convertMoneyToDecimal(
        Util.convertDecimalToMoney(tax.tax_amount_fc)
      );
      const url = tax.id
        ? "/purchaseInvoiceTaxLine/" + tax.id
        : "/purchaseInvoiceTaxLine";
      const method = tax.id ? "put" : "post";
      endpoints.push({ endpoint: url, method: method, params: tax });
    });

    if (endpoints.length === 0) {
      self.deleteTaxLines(next);
      return next(true);
    }
    Api.serialPutPost(endpoints, function (responses, errors) {
      console.log(responses);
      if (errors.length === 0) {
        console.log(responses);
        self.deleteTaxLines(next);
      } else {
        errors.forEach(function (error) {
          toastr.error(Util.getErrorAsStr(error));
        });

        /*  let formErrors = self.state.errors
                  formErrors.rows = errors
                  self.setState({errors:formErrors},function(){
                     if(next !==null) next(false)
                  })*/
      }
    });
  }

  isPermitted = () => {
    if (this.props.documentType !== 2) {
      return true;
    }

    return CompanyTypePermission.isPermitted([1, 3, 4, 5, 6, 9]);
  };

  purchaseInvoice(next, isChecked) {
    const self = this;
    const formData = this.state.formData;
    const calculatedFormData = this.prepareFormToPost(this.state.formData);
    if (calculatedFormData.invoice.document_type_tag !== undefined) {
      var selectedExpenseDocumentType =
        calculatedFormData.invoice.document_type_tag;
      const documentType = JSON.parse(
        localStorage.getItem("getDBSExpenseDocumentType")
      );
      const get = Sugar.Array.find(documentType.documentType, function (type) {
        return type.id === selectedExpenseDocumentType;
      });
      if (get !== undefined && get.code !== undefined) {
        this.setState({ serial_order_choose: get.serial_order_choose });
      }
    }
    if (this.state.serial_order_choose == 1) {
      formData.invoice.legal_no2 = "";
      calculatedFormData.invoice.legal_no2 = "";
      formData.invoice.legal_serial = "";
      calculatedFormData.invoice.legal_serial = "";
      if (formData.invoice.legal_no === undefined) {
        formData.invoice.legal_no = "";
        calculatedFormData.invoice.legal_no = "";
      }
      if (
        calculatedFormData.invoice.legal_no.replace(/_/g, "").length < 16 &&
        calculatedFormData.invoice.document_type_tag != 18
      ) {
        this.openWarningModal();
        self.setState({
          saving: false,
        });
        return false;
      }
    } else {
      formData.invoice.legal_no =
        calculatedFormData.invoice.legal_serial
          .replace(/_/g, "")
          .toUpperCase() +
        "" +
        calculatedFormData.invoice.legal_no2.replace(/_/g, "").toUpperCase();
      calculatedFormData.invoice.legal_no =
        calculatedFormData.invoice.legal_serial
          .replace(/_/g, "")
          .toUpperCase() +
        "" +
        calculatedFormData.invoice.legal_no2.replace(/_/g, "").toUpperCase();
      calculatedFormData.invoice.description =
        calculatedFormData.invoice.legal_no + " nolu fatura";
    }
    if (this.props.documentType === 2) {
      calculatedFormData.invoice.stock_status = 1;
    }
    if (!Util.companyModules(2)) {
      calculatedFormData.invoice.stock_status = 0;
    }
    if (formData.invoice.purchase_types === undefined) {
      formData.invoice.purchase_types = this.props.documentType === 1 ? 0 : 3;
    }
    if (formData.invoice.id !== undefined) {
      delete formData.invoice.purchase_types;
    }
    if (this.props.uuid) calculatedFormData.invoice.uuid = this.props.uuid;
    let manualValidations = true;
    let formErrors = self.state.errors;
    if (calculatedFormData.footer.price_amount_fc === 0) {
      toastr.error("Toplam fatura tutarı sıfır olamaz");
      next(false);
      manualValidations = false;
    }

    if (this.state.formData.invoice.radioButtons === "paid") {
      if (this.state.formData.invoice.method_source_id === undefined) {
        toastr.error("İlişkili hesap boş olamaz");
        manualValidations = false;
      }
    }

    const url = formData.invoice.id
      ? "/purchaseInvoice/" + formData.invoice.id
      : "/purchaseInvoice";
    const postData = Object.assign(
      {},
      calculatedFormData.invoice,
      calculatedFormData.footer
    );

    if (
      this.props.addDocument?.uri &&
      postData.document_url !== this.props.addDocument?.uri &&
      !self.props.isGetIn
    ) {
      postData.document_url = this.props.addDocument?.uri;
    }
    postData.is_checked = isChecked === true;
    postData.newTagRows=this.state.newTagRows;
    if (formData.invoice.legal_serial !== undefined) {
      postData.legal_serial = formData.invoice.legal_serial.replace(/_/g, "");
    }
    if (formData.invoice.legal_no2 !== undefined) {
      postData.legal_no2 = formData.invoice.legal_no2.replace(/_/g, "");
    }
    if (formData.legal_no !== undefined) {
      postData.legal_no = formData.invoice.legal_no.replace(/_/g, "");
    }
    var rowAmountTotal = 0;
    calculatedFormData.rows.map(function (row) {
      rowAmountTotal += parseFloat(row.total_amount);
      return row;
    })
    console.log('fark',postData.total_amount - rowAmountTotal);
    if(postData.total_amount - rowAmountTotal>1){
      toastr.error("Beklenmeyen bir hata oluştu. Satır toplamları ile dip toplam tutmuyor. Tekrar kaydet tuşuna basınız.");
      return next(false);
    }

    Api[formData.invoice.id ? "put" : "post"](
      url,
      postData,
      function (response, errors) {
        if (errors) {
          if (errors.message) toastr.error(Util.getErrorAsStr(errors));

          formErrors.invoice = Object.assign({}, formErrors.invoice, errors);
          self.setState({ errors: formErrors }, function () {
            next(false);
          });
        } else if (!manualValidations) {
          self.setState({ errors: formErrors }, function () {
            next(false);
          });
          next(false);
        } else {
          if (!response.purchaseInvoice) {
            toastr.error("Muhtemel SQL Hatası");
            return next(false);
          }
          let formData = self.state.formData;
          formData.invoice.id = response.purchaseInvoice.id;
          self.setState({ formData: formData }, function () {
            next(true);
          });
        }
      }
    );
  }

  saveExempt = () => {
    const formData = { ...this.state.formData };

    if (formData.invoice) {
      const invoice = { ...formData.invoice };

      invoice.is_exempt = 1;

      formData.invoice = invoice;
    }

    this.setState({ formData }, () => {
      this.onSave(true);
    });
  };

  saveNormal(refresh) {
    const formData = { ...this.state.formData };

    if (formData.invoice) {
      const invoice = { ...formData.invoice };

      invoice.is_exempt = 0;

      formData.invoice = invoice;
    }

    this.setState({ formData }, () => {
      this.onSave(refresh);
    });
  }
  save(refresh, isChecked) {
    const formData = { ...this.state.formData };

    if (formData.invoice) {
      const invoice = { ...formData.invoice };

      invoice.is_exempt = 0;

      formData.invoice = invoice;
    }

    this.setState({ formData }, () => {
      this.onSave(refresh, isChecked);
    });
  }
  itemModalClose() {
    this.setState({
      saving: false,
      itemModalValues: { ...this.state.itemModalValues, showItemModal: false },
    });
  }
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  getNewTagModalList = () => {
    if (this.props.params && this.props.params.id) {
      Api.get("/company/newTagRecordDocumentList/2/" + this.props.params.id, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          });
        }
      });
    }
  }
  render() {
    let fromWhos = this.props.fromWhos.map(function (fromWho) {
      // fromWho.name = fromWho.firstname;
      // if (fromWho.lastname) fromWho.name += " " + fromWho.lastname;
      fromWho.name = fromWho.fullname;
      return fromWho;
    });

    let headerTitle = "MAL/HİZMET ALIM FATURASI";
    if (this.props.documentType === 2) headerTitle = "GİDER FATURASI";
    if (this.props.documentType === 3) headerTitle = "MÜŞTERİDEN FATURA";
    if (this.props.documentType === 4) headerTitle = "DEMİRBAŞ FATURASI";

    let buttonsArr = [
      {
        id: "saveExempt",
        onClick: this.saveExempt,
        labelText: "Proforma / Taslak Olarak Kaydet",
      },
      {
        id: "saveNormal",
        onClick: this.saveNormal.bind(this, false),
        labelText: "Alış Faturası Kaydet ve Yeni Ekle",
      },
    ];
    if (this.props.isGetIn) buttonsArr = buttonsArr.splice(1);
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}} onClick={Util.backButton.bind(this, "/dashboard/purchases")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          <AddPurchaseInvoiceHeader
              editId={this.props?.editId}
              paramType={this.props.paramType}
              fromWhos={fromWhos}
              callBackXMLData={this.props.callBackXMLData}
              fromWhoId={
                  this.props.formData.invoice.customer_id ||
                  this.props.formData.invoice.supplier_id
              }
              documentType={this.props.documentType}
              currencies={this.props.currencies}
              isGetIn={this.props.isGetIn}
              formData={this.state.formData.invoice}
              calculate={this.props.calculate}
              vatRateTypes={this.props.vatRateTypes}
              bankAccounts={this.props.bankAccounts}
              units={this.props.units}
              errors={this.state.errors.invoice}
              onChange={this.onChange.bind(this)}
              onRefresh={this.onRefresh.bind(this)}
              isPermitted={this.isPermitted}
              title={headerTitle}
              bankCurrencyAndMethod={this.bankCurrencyAndMethod}
          >
            <EkoInputTableButtonGroup
                data={this.state.tableData}
                orderNumber={true}
                currency={this.state.formData.invoice.currency}
                formData={this.state.formData}
                errors={this.state.errors}
                onSave={(refresh, isChecked) => this.save(refresh, isChecked)}
                saving={this.state.saving}
                isGetIn={this.props.isGetIn}
                formId={"add_purchase_invoice"}
                onRowAdd={this.onInvoiceLineAdded.bind(this)}
                defaultRow={this.props.defaultRowData}
                onCancel={this.onCancel.bind(this)}
                onChangeRow={this.onInvoiceLineChange.bind(this)}
                onRowDelete={this.onInvoiceLineRemoved.bind(this)}
                onRealChange={this.onInvoiceLineReallyChange.bind(this)}
                //onChange={this.onInvoiceChange.bind(this)}
                buttons={buttonsArr}
                isCheckButton={true}
                editId={this.props?.editId}
                updatedAt={
                  this.state?.formData?.invoice?.is_checked
                      ? this.state?.formData?.invoice?.updated_at
                      : null
                }
            />
            <div className={"row"}>
              <div className={"col-md-12"}>
                <div style={{
                  top: 35,
                  opacity: 1,
                  position: "absolute",
                  height: "1px",
                  background: "#eeeeee",
                  width: "100%"
                }}></div>
                <a onClick={this.openNewTagModal} style={{top: 50, opacity: 1, position: "absolute"}}>Etiket Ekle</a>
              </div>
            </div>
            <NewTagDocumentModal
                newTagRows={this.state.newTagRows}
                saveNewTag={this.saveNewTag}
                showNewTagModal={this.state.showNewTagModal}
                closeNewTagModal={this.closeNewTagModal}
            />
            <AddItem
                showModal={this.state.showAddItem}
                formData={this.state.ItemModalFormData}
                onSave={this.onItemSaved.bind(this)}
                onCancel={() => {
                  this.setState({showAddItem: false});
                }}
                errors={false}
            />
            <ChangingModal
                showModal={this.state.ChangingModal}
                closeModal={this.closeChangingModal}
                ChangingModalData={this.state.ChangingModalData}
                ChangingModalRowData={this.state.ChangingModalRowData}
                onSave={this.onChangingModalSave.bind(this)}
            />
            <EkoModal
                showModal={this.state.openWarningModal}
                onHide={this.closeWarningModal.bind(this)}
                title={"UYARI"}
            >
              <p style={{color: "#767676", "text-align": "center"}}>
                Lütfen evrak üzerindeki belge noyu TAM olarak giriniz.
              </p>
              <p style={{color: "#767676", "text-align": "center"}}>
                Gider kaydı henüz belgelendirilmemiş ise doküman tipinde{" "}
                <span style={{color: "red"}}>
              "Henüz Belgelendirilmemiş Gider"
            </span>{" "}
                seçeneğini seçebilir veya{" "}
                <span style={{color: "red"}}>"Proforma / Taslak Olarak Kaydet"</span> seçeneği
                ile devam edebilirsiniz.
                <br/>
                Eğer belgelendirilmiş ise lütfen ilgili doküman tipini seçiniz.
              </p>
            </EkoModal>
            <AddAsset
                showModal={this.state.showAssetModal}
                formData={this.state.formDataAsset}
                line_row_list={this.state.formData.rows}
                to_invoice={true}
                onSave={this.onSaveAsset.bind(this)}
                onCancel={() => {
                  this.setState({showAssetModal: false})
                }}
                errors={this.state.errors}
            />
            <AddOtherTax
                taxTypes={this.props.taxTypes}
                inXml={this.state.formData.inXml}
                vatReductionTypeCodes={this.props.vatReductionTypeCodes}
                onChangeRow={this.onTaxRowChange.bind(this)}
                onChange={this.onTaxChange.bind(this)}
                showModal={this.state.showAddTax}
                formData={this.state.taxForm}
                errors={{}}
                onSave={this.onTaxFormSave.bind(this)}
                onCancel={() => {
                  this.setState({showAddTax: false});
                }}
            />
            {this.props.documentType !== 2 && this.props.documentType !== 4 ? (
                <SelectItemModal
                    onSave={() => {
                      this.onSave(
                          this.state.itemModalValues.refresh,
                          this.state.itemModalValues.isChecked
                      );
                      this.fetchItems();
                    }}
                    passModal={() => {
                      this.setState({passModal:true})
                    }}
                    itemModalValues={this.state.itemModalValues}
                    showModal={this.state.itemModalValues.showItemModal}
                    onClose={this.itemModalClose.bind(this)}
                    items={this.state.formData.rows}
                    onChangeLines={(rows, callback) =>
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            rows: [...rows]
                          }
                        }, () => callback ? callback() : null)
                    }
                    isSales={this.props.documentType === 3 ? true : false}
                />
            ) : null}
          </AddPurchaseInvoiceHeader></div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vatRateTypes: state.data.vatRateTypes,
    vatReductionTypeCodes: state.data.vatReductionTypeCodes,
    units: state.data.units,
    bankAccounts: state.data.bankAccountsAll,
    taxTypes: state.data.taxTypes,
    currencies: state.data.currencies,
    addDocument: state.page.addDocument,
  };
}

export default connect(
  mapStateToProps,
  actions
)(withRouter(AddPurchaseInvoice));
