import React, { Component } from 'react'
import EkoModal from '../../components/elements/EkoModal'
import { EkoFormInputText, EkoFormButton, EkoFormSwitch } from '../../components/elements/EkoForm'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Api from "../../util/Api";
import { toastr } from "react-redux-toastr";

class AskCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true,
            saving: false,
            savingModal: false,
            copied: false,
            active: false,
            linkName: "TAHSİLAT LİNKİNİ GÖNDER",
            buttonDisabled: false,
            switchValueCommission: true,
            switchValueInstallment: true,
            vposList: [],
            bankPairList: [],
            invoiceLink: null,
        }
    }

    componentDidMount() {
        this.getFlexOdemeVposList();
    }

    closeModal() {
        this.setState({ active: false, buttonDisabled: false, linkName: "TAHSİLAT LİNKİNİ GÖNDER", saving: false, formData: {} });
        this.props.closeModal();
    }

    onMount() {
    }

    onClick() {
        this.setState({ active: true, buttonDisabled: true, linkName: "LÜTFEN BEKLEYİNİZ" });
    }

    tgl(link, switchStatus) {
        this.setState({ switchValueCommission: switchStatus });
        var linkParam = link.split('/').pop();
        Api.get("/paynet/setPaynetTransaction/commission/" + linkParam + "/" + switchStatus, (response, err) => {
            if (err) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                toastr.info("Başarılı!", "Komisyon Bilgileri Güncellendi");
            }
        });
    }

    tgl2(link, switchStatus) {
        this.setState({ switchValueInstallment: switchStatus });
        var linkParam = link.split('/').pop();
        Api.get("/paynet/setPaynetTransaction/installment/" + linkParam + "/" + switchStatus, (response, err) => {
            if (err) {
                toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
            } else {
                toastr.info("Başarılı!", "Taksit Bilgileri Güncellendi");
            }
        });
    }

    getFlexOdemeVposList = async () => {
        Api.get("/getCompanyFlexOdemeVposList", (response, errors) => {
            if (errors) {
                toastr.error("Sayfa yüklenirken hata oluştu. Lütfen tekrar deneyiniz.");
                return;
            }
            if (response.result == "1") {
                this.setState({ vposList: response.data, bankPairList: response.bank_account_pair_data });
            }
        });
    }

    handleSendSalesInvoiceLink = () => {
        this.setState({ buttonDisabled: true, linkName: "LÜTFEN BEKLEYİNİZ" });
        Api.post("/createFlexOdemeSalesInvoiceLink", { salesInvoiceId: this.props.salesInvoiceId }, (response, err) => {
            this.setState({ buttonDisabled: false, linkName: "TAHSİLAT LİNKİNİ GÖNDER" });
            if (err) {
                toastr.error("Hata", "Tahsilat linki gönderilemedi");
            } else {
                if (response.result == "1") {
                    toastr.success("Başarılı", "Tahsilat linki gönderildi");
                    this.setState({invoiceLink: response.link});
                }
                else{
                    toastr.error("Hata", response.message);
                }
            }
        });
    };

    render() {
        const paynet_status = JSON.parse(localStorage.getItem("paynetStatus"));
        return (
            <EkoModal
                showModal={this.props.showModal}
                onHide={this.closeModal.bind(this)}
                spinner={this.state.savingModal}
                title={"Ödeme Linki Oluştur"}
            >
                <div className="row">
                    {/* FLEXÖDE.ME Bölümü */}
                    <div className="col-md-12">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3>FlexOde.me Tahsilat Sistemi</h3>
                            </div>
                            <div className="panel-body">
                                {this.state.bankPairList.length === 0 ? (
                                    <div className="row">
                                        <div className="col-md-12" style={{ height: "100px" }}>
                                            FlexÖde.me Modülü Aktif Değil veya Banka Hesap Eşleşmesi Yapılmamış.<br /><br />
                                            <a href="/dashboard/integrations" className="btn blue" target="_blank">
                                                Modül Ayarları İçin Tıklayın
                                            </a>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>Seçtiğiniz satış faturası bilgileri baz alınarak tahsilat linki oluşturulacak. Eğer müşterinize EPOSTA ve SMS olarak iletilmesini isterseniz MÜŞTERİ KARTI > İLETİŞİM bölümünden bilgileri doldurduğunuzdan emin olunuz.</p><br />
                                            <EkoFormButton
                                                label={this.state.linkName}
                                                className="btn blue"
                                                onClick={this.handleSendSalesInvoiceLink.bind(this)}
                                                disabled={this.state.buttonDisabled}
                                            />
                                            {this.state.invoiceLink && (
                                                <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                                                    <p>Oluşturulan tahsilat linki: {this.state.invoiceLink}</p>
                                                    <a target={"_blank"} className="btn success green  btn-sm" href={"https://api.whatsapp.com/send?phone="+this.state.invoiceLink+"&text=Oluşturulan faturanız için ödeme linkiniz: "+this.state.invoiceLink}>
                                                        <i style={{color:"white",fontSize:"16px"}} className={"fab fa-whatsapp"}></i> Whatsapp ile gönder
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* PAYNET Bölümü */}
                    <div className="col-md-12">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3>PAYNET</h3>
                            </div>
                            <div className="panel-body">
                                {paynet_status === 0 ? (
                                    <div className="row">
                                        <div className="col-md-12" style={{ height: "100px" }}>
                                            Paynet Üyesi Olmadığınız İçin Bu Hizmetten Yararlanamıyorsunuz. Kayıt Olmak İçin Aşağıdaki Linke Tıklayınız <br /><br />
                                            <a href="https://basvuru.paynet.com.tr/basvuru-formu" className="enrollLink" target="_blank">Kayıt İşlemi İçin Tıklayınız</a>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col-md-12">
                                            Ödeme Aracı Kurum Komisyonunu Kimin Ödeyeceğini Seçiniz
                                            <EkoFormSwitch
                                                leftBox={false}
                                                md={12}
                                                textOn="Komisyonu Ben Ödeyeceğim"
                                                textOff="Karşı Taraf Ödeyecek"
                                                onChange={(newSwitchStatus) => this.tgl(this.props.link, newSwitchStatus)}
                                                value={this.state.switchValueCommission}
                                            />
                                        </div>
                                        <div className="col-md-12" style={{ marginTop: "20px" }}>
                                            Ödeme Aracı Kurum Bu Ödeme İçin Taksit Seçeneği Sunsun Mu?
                                            <EkoFormSwitch
                                                leftBox={false}
                                                md={12}
                                                textOn="Evet"
                                                textOff="Hayır"
                                                onChange={(newSwitchStatus) => this.tgl2(this.props.link, newSwitchStatus)}
                                                value={this.state.switchValueInstallment}
                                            />
                                        </div>
                                        <div className="col-md-8">
                                            <EkoFormInputText
                                                id="paynet"
                                                onMount={this.onMount}
                                                disabled
                                                type="text"
                                                value={this.props.link}
                                                style={{ paddingLeft: "15px" }}
                                                label="Aşağıdaki Linki Kopyalayarak Müşterinize İletebilirsiniz"
                                                isVertical={true} />
                                        </div>
                                        <div className="col-md-4">
                                            <CopyToClipboard text={this.props.link}
                                                             onCopy={() => this.setState({ copied: true })}>
                                                <EkoFormButton label="Kopyala"
                                                               className={'btn blue pull-right'}
                                                               onClick={this.onClick.bind(this)}
                                                               disabled={this.state.buttonDisabled}
                                                />
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </EkoModal>
        )
    }
}

export default AskCollection;
