import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../actions'
import Portlet from "../../components/elements/Portlet";
import EkoInputTableButtonGroup from "../../components/elements/EkoInputTableButtonGroup";
import Api from "../../util/Api";
import UtilHelper from "../../util/UtilHelper";
import {FETCH_BANK_ACCOUNTS_ALL, FETCH_ZREPORT} from "../../actions/types";
import Util from "../../util/Util";
import {toastr} from 'react-redux-toastr'
import EkoModal from "../../components/elements/EkoModal";
import AddDocumentButton from '../../components/forms/AddDocumentButton';
import {EkoFormSelect} from "../../components/elements/EkoForm";
import axios from "axios";
import {ROOT_URL} from "../../actions";
import AddBank from "../Bank/AddBank";
import NewTagDocumentModal from "../Company/newTagDocument";

class ZReport extends PureComponent {
    constructor(props) {
        super(props);

        this.state = this.defaultState;
    }
    get defaultRows() {
        return [
            [
                {
                    type: 'select',
                    id: 'nace_code_id',
                    optionValue: 'description',
                    defaultKey: 'diğer',
                    searchFilter: 'key_words',
                    optionId: 'id',
                    defaultText: 'SEÇ',
                    searchable: true,
                    data: []
                },
                {type: 'number', id: 'okc_serial_number', vertical: true},
                {type: 'date', id: 'record_date', vertical: true},
                {type: 'money', id: 'amount_vat1', vertical: true, placeholder: "0"},
                {type: 'money', id: 'amount_vat1kdv', vertical: true, placeholder: "0",precision: 4},
                //{type: 'money', id: 'amount_vat8', vertical: true, placeholder: "0"},
                //{type: 'money', id: 'amount_vat8kdv', vertical: true, placeholder: "0",precision: 4},
                {type: 'money', id: 'amount_vat10', vertical: true, placeholder: "0"},
                {type: 'money', id: 'amount_vat10kdv', vertical: true, placeholder: "0",precision: 4},
                //{type: 'money', id: 'amount_vat18', vertical: true, placeholder: "0"},
                //{type: 'money', id: 'amount_vat18kdv', vertical: true, placeholder: "0",precision: 4},
                {type: 'money', id: 'amount_vat20', vertical: true, placeholder: "0"},
                {type: 'money', id: 'amount_vat20kdv', vertical: true, placeholder: "0",precision: 4},
                {type: 'money', id: 'amount_without_vat', vertical: true, placeholder: "0"},
                {type: 'money', id: 'amount_cash', vertical: true, placeholder: "0"},
                {type: 'money', id: 'amount_creditcard', vertical: true, placeholder: "0"},
            ]
        ];

    }

    get tableData() {
        return {
            headers: [
                {label: 'Faaliyet Kodu', width: '6%',},
                {label: 'Z No', width: '8%'},
                {label: 'Evrak T.', width: '10%'},
                {label: '%1 KDV Dhl Tutar', width: '6%'},
                {label: '%1 KDV Tutarı', width: '6%'},
                //{label: '%8 KDV Dhl Tutar', width: '6%'},
                //{label: '%8 KDV Tutarı', width: '6%'},
                {label: '%10 KDV Dhl Tutar', width: '6%'},
                {label: '%10 KDV Tutarı', width: '6%'},
                //{label: '%18 KDV Dhl Tutar', width: '6%'},
                //{label: '%18 KDV Tutarı', width: '6%'},
                {label: '%20 KDV Dhl Tutar', width: '6%'},
                {label: '%20 KDV Tutarı', width: '6%'},
                {label: "%0 Kdv'li Tutar", width: '6%'},
                {label: 'Nakit Tutar', width: '5%'},
                {label: 'Kredi Kartı Tutar', width: '5%'},
            ],

            footer: [],
            footerData: {},
            footerTable: [],
            customFooter: null,
            rows: this.defaultRows
        }
    }

    get firstRow() {
        return Object.assign({}, {
            record_date: '',
            okc_serial_number: null,
        });
    }

    get fields() {
        return [
            'amount_cash',
            'amount_creditcard',
            'amount_vat1',
            'amount_vat1kdv',
            //'amount_vat8',
            //'amount_vat8kdv',
            'amount_vat10',
            'amount_vat10kdv',
            //'amount_vat18',
            //'amount_vat18kdv',
            'amount_vat20',
            'amount_vat20kdv',
            'amount_without_vat'
        ]
    }
    convertDecimalToMoney(money) {
        if (!Number.isInteger(money)) {
            money = parseFloat(money)
        }
        return money.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    }
    cleanMoney(money) {
        if (!money) {
            return ""
        }

        if (money === "0000") {
            return "00";
        }

        let split = money.split("");

        if (split[3] === "0") {
            split = split.splice(0, 3);
        }

        if (split[2] === "0") {
            split = split.splice(0, 4);
        }


        return split.join("");
    }
    convertDecimalToMoney2(money) {

        if (!Number.isInteger(money)) {
            money = parseFloat(money)
        }
        let moneyNew = money.toFixed(4).toString().split('.');
        const moneyPrepared = moneyNew[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
        return moneyPrepared + ',' + this.cleanMoney(moneyNew[1]);

    }
    prepepareLines = (lines) => {
        const self = this;
        lines = lines.map(line => {
            const money = UtilHelper.money.mapFieldsToDecimal(this.fields, line);
            const record_date = Util.convertToDatabaseDate(line.record_date);
            let document_url = self.props.addDocument?.uri;
            return Object.assign({}, line, money, {record_date, document_url});
        });
        return lines;
    }

    get defaultState() {
        return Object.assign({}, {
            tableData: this.tableData,
            special_amount_type_id:0,
            bank_accounts_id:0,
            cash_bank_accounts_id:0,
            amount_vat1:0,
            amount_vat1kdv:0,
            errors: {
                rows: [{}]
            },
            formData: {
                rows: [
                    this.firstRow
                ],

                footer: {}
            },
            saving: false,
            spinnerModal:true,
            selectNotesWarningModal:false,
            success:0,
            showNewTagModal: false,
            newTagRows: {
                rows: [{ id:null,new_tag_type_id:6,new_tag_id: "",record_id:null, rate: "%100,00" }],
            },
        });
    }

    componentWillMount() {
        if (this.props.router.params.id) {
            this.props.fetchData('/zreport/' + this.props.router.params.id, FETCH_ZREPORT);
            Api.get("/zreport/" + this.props.router.params.id, (response, error) => {
                this.setState({special_amount_type_id:response.zReports.special_amount_type_id,bank_accounts_id:response.zReports.bank_accounts_id,cash_bank_accounts_id:response.zReports.cash_bank_accounts_id});
            });
        }
        this.naceCode();
        this.getNewTagModalList();
    }

    naceCodeHidden() {
        if (JSON.parse(localStorage.getItem("nace_code_list")).length <= 1) {
            return true;
        } else {
            return false;
        }
    }
    naceCode (){
        let tableData = this.state.tableData;
        tableData.rows[0][0].data = JSON.parse(localStorage.getItem("nace_code_list"));
        if (!this.props.router.params.id) {
            var json = JSON.parse(localStorage.getItem("nace_code_list"));
            if (json.length > 1) {
                let result = json.find(obj => obj.varsayilan === 1);
                this.state.formData.rows[0].nace_code_id = result.id;
            }
        }
        this.setState({tableData: tableData});
    }

    closeSelectItemModal() {
        this.setState({selectNotesWarningModal:false});
    }
    prepareLineForShow = (line) => {
        return Object.assign(
            {},
            line,
            UtilHelper.money.mapDecimalToMoney(
                this.fields,
                line,
            ),
            {record_date: Util.convertFromDatabaseDate(line.record_date)}
        )
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.zreport) {
            this.onLineChange(0, this.prepareLineForShow(nextProps.zreport));
        }
        if (nextProps.zreport && nextProps.zreport.document_url && nextProps.zreport.document_url !== this.props.addDocument?.uri) {
            const docUrl = nextProps.zreport.document_url;
            if(docUrl && this.props.setAddDocument){
                const type = docUrl.split('.').pop()
                this.props.setAddDocument({uri: docUrl, type});
            }
            // this.onLineChange(0, this.prepareLineForShow(nextProps.othersales));
        }
    }


    validateAndSaveLines = (redirect, isChecked,addRefresh) => {
        this.validateLines(true, (validated) => {
            if (validated) {
                let endpoints = [];
                let formData = this.state.formData;
                const self = this;
                const method = this.props.router.params.id ? 'put' : 'post';
                let url = "/zreport";
                const data = this.prepepareLines(formData.rows);

                data.forEach(row => {
                    if (method === 'put') {
                        url = "/zreport/" + row.id;
                    }
                    if(this.state.special_amount_type_id!=0){
                        row.special_amount_type_id = this.state.special_amount_type_id;
                    }
                    if(this.state.bank_accounts_id!=0){
                        row.bank_accounts_id = this.state.bank_accounts_id;
                    }
                    if(this.state.cash_bank_accounts_id!=0){
                        row.cash_bank_accounts_id = this.state.cash_bank_accounts_id;
                    }
                    row.newTagRows=this.state.newTagRows;
                    endpoints.push({endpoint: url, method: method, params: {...row, is_checked: isChecked}})
                });

                Api.serialPutPost(endpoints, function (responses, errors) {


                    if (errors.length === 0) {
                        let allOk = true;

                        responses.forEach(line => {
                            if (allOk && !line.zReports.id) {
                                allOk = false;
                            }

                        });


                        if (allOk) {
                            toastr.success(method === 'put' ? 'Başarıyla Güncellendi' : 'Başarıyla Eklendi');
                            if(isChecked === true){
                                Api.get(`/zreport/next/${self.props.router?.params?.id}`,function (res, error) {
                                  if(res.item) {
                                    const path = window.location.pathname;
                                    const ex = path.split('/');
                                    ex.pop();
                                    ex.push(res.item.id);
                                    self.setState({saving: false});
                                    self.props.setAddDocument({ uri: null, type: null });
                                    window.location.href = ex.join('/');
                                  } else {
                                    self.props.router.push('/dashboard/salesInvoice/zreports');
                                  }
                                })
                              } else {
                                if (redirect) {
                                    if(addRefresh===false){
                                        window.location.replace("/dashboard/salesInvoice/zreports/add")
                                    }else{
                                        self.props.router.push('/dashboard/salesInvoice/zreports');
                                    }
                                } else {

                                    if (method === 'put') {
                                        self.props.router.push('/dashboard/salesInvoice/zreports');
                                    } else {
                                        self.setState(self.defaultState);
                                        self.onLineChange(0, self.firstRow);

                                    }


                                }
                            }
                        } else {

                            toastr.error(method === 'put' ? 'Güncellenemedi' : 'Eklenemedi');
                            self.setState({saving: false});
                        }
                    } else {


                        if (errors.length > 0) {
                            const error = errors[0]

                            if (error.message) {
                                toastr.error(error.message)
                            }

                        }




                        self.setState({errors: {rows: errors}, saving: false});
                    }
                });
            } else {
                this.setState({
                    saving: false
                });

            }

        })
    }

    convertMoney(money){
        console.log('değişim',money,parseFloat(money.toString().replace('.','').replace(',','.')));
        if(money.toString().indexOf(",")==-1){
            return parseFloat(money.toString());
        }else{
            return parseFloat(money.toString().replace('.','').replace(',','.'));
        }

    }

    onSave(refresh, isChecked) {
        this.state.formData.rows.forEach(row => {
            // Create a copy of the row to avoid modifying the original data
            const rowCopy = { ...row };

            let x = 0;
            let y = 0;

            // Convert and validate amounts without modifying the original row
            const amount_cash = typeof rowCopy.amount_cash === "undefined" || rowCopy.amount_cash == 0
                ? parseFloat("0.00")
                : this.convertMoney(rowCopy.amount_cash);

            const amount_creditcard = typeof rowCopy.amount_creditcard === "undefined" || rowCopy.amount_creditcard == 0
                ? parseFloat("0.00")
                : this.convertMoney(rowCopy.amount_creditcard);

            const amount_vat1 = typeof rowCopy.amount_vat1 === "undefined" || rowCopy.amount_vat1 == 0
                ? parseFloat("0.00")
                : this.convertMoney(rowCopy.amount_vat1);

            const amount_vat10 = typeof rowCopy.amount_vat10 === "undefined" || rowCopy.amount_vat10 == 0
                ? parseFloat("0.00")
                : this.convertMoney(rowCopy.amount_vat10);

            const amount_vat20 = typeof rowCopy.amount_vat20 === "undefined" || rowCopy.amount_vat20 == 0
                ? parseFloat("0.00")
                : this.convertMoney(rowCopy.amount_vat20);

            const amount_without_vat = typeof rowCopy.amount_without_vat === "undefined" || rowCopy.amount_without_vat == 0
                ? parseFloat("0.00")
                : this.convertMoney(rowCopy.amount_without_vat);

            // Calculate x and y using the copied values
            console.log('amount_cash',amount_cash);
            console.log('amount_creditcard',amount_creditcard);
            x = amount_cash + amount_creditcard;
            y = amount_vat1 + amount_vat10 + amount_vat20 + amount_without_vat;



            x = x.toFixed(2);
            y = y.toFixed(2);
            console.log('x', x);
            console.log('y', y);
            if (x != y) {
                this.setState({ selectNotesWarningModal: true, success: 0 });
            } else {
                this.setState({ success: 1 });
            }
        });

        setTimeout(this.saveAndGo.bind(this, refresh, isChecked), 1000);
    }

    saveAndGo(refresh, isChecked){
        if(this.state.success==1){
            this.setState({
                saving: true
            }, () => this.validateAndSaveLines(true, isChecked === true,refresh));
        }
    }

    onLineChange(index, rowData, field) {
        var cleanNumber = "";
        var finalNumber = "";
        //
        if(field=='amount_vat1' || field === undefined){
            cleanNumber = rowData.amount_vat1.replace('.','').replace(',','.');
            finalNumber = cleanNumber-(cleanNumber/1.01)
            rowData.amount_vat1kdv = this.convertDecimalToMoney2(finalNumber);
        }else if(field=='amount_vat1kdv'){
            cleanNumber = rowData.amount_vat1kdv.replace('.','').replace(',','.');
            finalNumber = (cleanNumber/0.01)+cleanNumber*1
            rowData.amount_vat1 = this.convertDecimalToMoney(finalNumber);
        }

        /*if(field=='amount_vat8' || field === undefined){
            cleanNumber = rowData.amount_vat8.replace('.','').replace(',','.');
            finalNumber = cleanNumber-(cleanNumber/1.08)
            rowData.amount_vat8kdv = this.convertDecimalToMoney2(finalNumber);
        }else if(field=='amount_vat8kdv'){
            cleanNumber = rowData.amount_vat8kdv.replace('.','').replace(',','.');
            finalNumber = (cleanNumber/0.08)+cleanNumber*1
            rowData.amount_vat8 = this.convertDecimalToMoney(finalNumber);
        }*/

        if(field=='amount_vat10' || field === undefined){
            cleanNumber = rowData.amount_vat10.replace('.','').replace(',','.');
            finalNumber = cleanNumber-(cleanNumber/1.1)
            rowData.amount_vat10kdv = this.convertDecimalToMoney2(finalNumber);
        }else if(field=='amount_vat10kdv'){
            cleanNumber = rowData.amount_vat10kdv.replace('.','').replace(',','.');
            finalNumber = (cleanNumber/0.1)+cleanNumber*1
            rowData.amount_vat10 = this.convertDecimalToMoney(finalNumber);
        }

        /*if(field=='amount_vat18' || field === undefined){
            cleanNumber = rowData.amount_vat18.replace('.','').replace(',','.');
            finalNumber = cleanNumber-(cleanNumber/1.18)
            rowData.amount_vat18kdv = this.convertDecimalToMoney2(finalNumber);
        }else if(field=='amount_vat18kdv'){
            cleanNumber = rowData.amount_vat18kdv.replace('.','').replace(',','.');
            finalNumber = (cleanNumber/0.18)+cleanNumber*1
            rowData.amount_vat18 = this.convertDecimalToMoney(finalNumber);
        }*/
        if(field=='amount_vat20' || field === undefined){
            cleanNumber = rowData.amount_vat20.replace('.','').replace(',','.');
            finalNumber = cleanNumber-(cleanNumber/1.2)
            rowData.amount_vat20kdv = this.convertDecimalToMoney2(finalNumber);
        }else if(field=='amount_vat20kdv'){
            cleanNumber = rowData.amount_vat20kdv.replace('.','').replace(',','.');
            finalNumber = (cleanNumber/0.2)+cleanNumber*1
            rowData.amount_vat20 = this.convertDecimalToMoney(finalNumber);
        }

        const formData = {...this.state.formData};
        formData.rows[index] = rowData;
        const errors = {...this.state.errors};
        if (errors.rows[index] && errors.rows[index][field]) {
            delete errors.rows[index][field];
        }
        this.setState({
            errors: errors,
            formData: formData,
            amount_vat1:rowData.amount_vat1
        });
    }

    onLineDelete(index, row) {


        const formData = {...this.state.formData};

        if (formData.rows[index]) {
            delete formData.rows[index];
        }

        this.setState({
            formData
        });
    }

    onCancel() {
        this.props.router.push('/dashboard/salesInvoice/zreports')

    }

    onLineAdd() {
        this.validateLines();
    }

    validateLines = (all = false, next = null) => {
        let endpoints = []
        let formData = this.state.formData;
        const self = this;
        const url = "/zreport/validateLine";
        const data = this.prepepareLines(formData.rows);
        data.forEach(row => {
            endpoints.push({endpoint: url, method: 'post', params: row})
        });


        if (!all) {
            endpoints.pop() //son eklenen rowu kontrol etme
        }

        Api.serialPutPost(endpoints, function (responses, errors) {
            if (errors.length === 0) {
                self.setState({errors: {rows: []}});
                if (next !== null) next(true)
            }
            else {
                self.setState({errors: {rows: errors}}, function () {
                    if (next !== null) next(false)
                })
            }
        })

    }
    setFieldChange(type, field, value) {
        if(type=='special_amount_type_id'){
            this.setState({
                special_amount_type_id : field
            });
        }else if(type=='bank_accounts_id'){
            this.setState({
                bank_accounts_id : field
            });
        }else if(type=='cash_bank_accounts_id'){
            this.setState({
                cash_bank_accounts_id : field
            });
        }
    }
    newBank() {
        this.setState({ showBankModal: true });
    }
    onBankSave() {
        this.props.fetchData("/bankAccount/showAll", FETCH_BANK_ACCOUNTS_ALL);
        this.setState({ showBankModal: false, savingBank: true });
    }
    methodToAccountType(method) {
        switch (method) {
            case 2:
                return 4;
            case 3:
                return 2;
            default:
                return method;
        }
    }
    closeNewTagModal = () => {
        this.setState({
            showNewTagModal: !this.state.showNewTagModal,
        });
    };
    openNewTagModal = () => {
        this.setState({
            showNewTagModal: !this.state.showNewTagModal,
        });
    };
    saveNewTag = (rows) => {
        this.setState({ newTagRows: rows });
    };
    getNewTagModalList = () => {
        if (this.props.params && this.props.params.id) {
            Api.get("/company/newTagRecordDocumentList/6/" + this.props.params.id, (response, err) => {
                if (response && response.rows && response.rows.length>0) {
                    this.setState({
                        newTagRows: response,
                    });
                }
            });
        }
    }
    render() {
        const SpecialAmountTypes = JSON.parse(localStorage.getItem("dbs_special_amount_types"));
        var BankAccounts = [];
        var CashBankAccounts = [];
        JSON.parse(localStorage.getItem("bank_accounts")).find(function (element) {
            if(element.bank_account_type == 4){
                BankAccounts.push(element);
            }else if(element.bank_account_type == 1){
                CashBankAccounts.push(element);
            }
        });
        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard/salesInvoice/zreports")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

                <Portlet title={"Z RAPORU"}
                         addDocument={<AddDocumentButton style={{margin: 0, transform: 'translateY(-50%)'}}/>}>
                    <div className={"row"}>
                        <div className={"col-md-12 text-left"}>
                            <div className={"row text-left"}>
                                <div className={"col-md-4"}>
                                    <EkoFormSelect
                                        onChange={this.setFieldChange.bind(this)}
                                        value={this.state.cash_bank_accounts_id}
                                        searchable={true}
                                        id="cash_bank_accounts_id"
                                        clearable={true}
                                        optionValue="name"
                                        options={CashBankAccounts}
                                        optionId="id"
                                        onAddNewItemClick={this.newBank.bind(this)}
                                        label="Nakit İlişkili Hesap"
                                        defaultText="Nakit İlişkili Hesap Seçiniz"
                                        colMd={9}
                                        labelMd={3}
                                    />
                                </div>
                                <div className={"col-md-4"}>
                                    <EkoFormSelect
                                        onChange={this.setFieldChange.bind(this)}
                                        value={this.state.bank_accounts_id}
                                        searchable={true}
                                        id="bank_accounts_id"
                                        clearable={true}
                                        optionValue="name"
                                        options={BankAccounts}
                                        optionId="id"
                                        onAddNewItemClick={this.newBank.bind(this)}
                                        label="Pos İlişkili Hesap"
                                        defaultText="Pos İlişkili Hesap Seçiniz"
                                        colMd={9}
                                        labelMd={3}
                                    />
                                </div>
                                <div className={"col-md-4"}>
                                    <EkoFormSelect
                                        onChange={this.setFieldChange.bind(this)}
                                        value={this.state.special_amount_type_id}
                                        searchable={true}
                                        id="special_amount_type_id"
                                        clearable={true}
                                        optionValue="name"
                                        options={SpecialAmountTypes}
                                        optionId="id"
                                        label="Özel Matrah Kodu"
                                        defaultText="Özel Matrah Satışınız Olması Durumunda Seçiniz"
                                        colMd={9}
                                        labelMd={3}
                                    />
                                </div>
                            </div>
                            <div style={{height: "20px"}}></div>
                        </div>
                    </div>
                    <EkoInputTableButtonGroup
                        data={this.state.tableData}
                        errors={this.state.errors}
                        formData={this.state.formData}
                        defaultRow={this.defaultRows}
                        saving={this.state.saving}
                        onRowAdd={this.props.router.params.id ? false : this.onLineAdd.bind(this)}
                        onCancel={this.onCancel.bind(this)}
                        onChangeRow={this.onLineChange.bind(this)}
                        onRowDelete={this.onLineDelete.bind(this)}
                        formId={"add_zreport_form"}
                        onSave={(refresh, isChecked) => this.onSave(refresh, isChecked)}
                        buttons={[
                            {
                                onClick: this.onSave.bind(this, false),
                                labelText: 'Kaydet ve Yeni Ekle'
                            },
                        ]}
                        isCheckButton={true}
                        editId={this.props.router.params.id}
                        updatedAt={this.state?.formData?.rows[0]?.id && this.state?.formData?.rows[0]?.is_checked ? this.state?.formData?.rows[0]?.updated_at : null}
                    />
                    <a onClick={this.openNewTagModal} style={{top: 25, position: "relative"}}>Etiket Ekle</a>
                    <NewTagDocumentModal
                        newTagRows={this.state.newTagRows}
                        saveNewTag={this.saveNewTag}
                        showNewTagModal={this.state.showNewTagModal}
                        closeNewTagModal={this.closeNewTagModal}
                    />
                    <EkoModal
                        headerStyle={{
                            color: "red", textAlign: "center", marginTop: "40%"
                        }}
                        showModal={this.state.selectNotesWarningModal}
                        spinner={this.state.spinnerModal}
                        onHide={this.closeSelectItemModal.bind(this)}
                        title={"Uyarı!"}
                    >
                        <div style={{textAlign: "center"}}>%20, %10, %1 ve 0 KDV'li Tutarların Toplamı, Nakit
                            Tutar
                            ve Kredi Kartı Tutarının Toplamına Eşit Olması Gerekmektedir.
                        </div>
                    </EkoModal>
                    <AddBank
                        showModal={this.state.showBankModal}
                        formData={false}
                        onSave={this.onBankSave.bind(this)}
                        onCancel={() => {
                            this.setState({showBankModal: false});
                        }}
                        errors={{}}
                    />
                </Portlet>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        zreport: state.data.zreport,
        addDocument: state.page.addDocument
    };
};

export default connect(mapStateToProps, actions)(ZReport);