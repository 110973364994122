import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import * as actions from "../../actions";
import { connect } from "react-redux";

class SalesInvoiceImportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            showModal: false,
            saving: false,
            iFrameHeight: "800",
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            showModal: nextProps.showModal,
        });
    }
    render() {
        var apiUrl = process.env.REACT_APP_API_URL;
        apiUrl = apiUrl.substring(0, apiUrl.length-3);
        const time = new Date();

        return (
            <EkoModal
                showModal={this.state.showModal}
                title="İçeri Al"
                bsSize="xlg" // xlg
                onHide={() => {
                    this.setState({ showModal: false });
                    this.props.onClose();
                }}
                spinner={this.state.saving}
            >
                <iframe
                    title={"İçeri Al"}
                    style={{ overflow: "visible" }}
                    ref="iframe"
                    src={apiUrl + "sales_invoice_import/index.php?time="+time.getTime()+"&user=" + this.state.token}
                    scrolling="auto"
                    frameBorder="0"
                    width="100%"
                    height={this.state.iFrameHeight}
                />
            </EkoModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions,
    };
}

export default connect(mapStateToProps, actions)(SalesInvoiceImportModal);
