import React, { Component } from "react";
import { EkoFormDate, EkoFormSelect } from "../../components/elements/EkoForm";
import EkoFormInput from "../../components/elements/form/EkoFormInputText";
import Api from "../../util/Api";
import Portlet from "../../components/elements/Portlet";
import { toastr } from "react-redux-toastr";
import EkoInputTable from "../../components/elements/EkoInputTable";
import Util from "../../util/Util";

//@todo: Kaydetme işlemi yapılacak.
//ProductionPlaning, Planlandı ve Üretildi adında 2 durumdan oluşuyor.
//İlk kayıtta Planlandı durumuyla kaydedilecek.
//Kullanıcı daha sonra Üretildi

class ProductionPlaning extends Component {
    constructor(props) {
        super(props);

        this.state = this.defaultData;
    }

    componentWillMount() {
        //Düzenleme sırasında çalışacak alan.
        if (this.props.params && this.props.params.id) {
            if (this.props.params && this.props.params.id) {
                Api.getAsync("/production-planing/show/" + this.props.params.id)
                    .then((resp) => {
                        return { lines: resp.data.poLines, data: resp.data.po };
                    })
                    .then((plan) => {
                        this.setState({
                            planing: {
                                ...plan.data,
                                date: plan.data.start_date,
                            },
                        });
                        if(this.state.rows.length === 0){
                            this.setState({
                                rows:plan.lines
                            })
                        }
                    })
                    .then(() => {
                        if (this.state.warehouses.length > 0) {
                            this.warehouseChange(this.state.warehouse_id);
                        }
                    });
            }
        }
    }

    componentDidMount(){
        Api.get("/warehouses/showAll", (response, err) => {
            if (response.status === "ok" && !err)
                this.setState({
                    warehouses: response.items,
                });
            if (this.state.planing.warehouse_id) {
                this.warehouseChange(this.state.planing.warehouse_id);
            }
        });

        Api.get("/recipe/showAll", (response, err) => {
            if (response && response.items) {
                this.setState({
                    recipes: response.items,
                });

                if (this.state.planing.bom_id) {
                    this.bomChange(this.state.planing.bom_id);
                }
            }
        });
    }

    get defaultData() {
        return {
            warehouses: [],
            rows: [],
            product: "",
            recipes: [],
            items: [],
            errors: {
                rows: [],
            },

            planing: {
                quantity: 1,
                date: "",
                name: "",
                warehouse_id: "",
                bom_id: 0
            },

            saving: false,
        };
    }

    get row() {
        return [
            {
                label: "",
                type: "text",
                colMd: 12,
                disabled: true,
                id: "item_name",
            },

            {
                label: "",
                type: "text",
                disabled: true,
                colMd: 12,
                id: "quantity_per_prod",
            },
            {
                label: "",
                type: "text",
                disabled: true,
                colMd: 12,
                id: "total_quantity",
            },
            {
                label: "",
                type: "text",
                disabled: true,
                colMd: 12,
                id: "stock",
            },
            {
                label: "",
                type: "text",
                disabled: true,
                colMd: 12,
                id: "missing",
            },
            {
                label: "",
                disabled: true,
                type: "text",
                colMd: 12,
                id: "other",
            },
        ];
    }

    /**
     *  kaydet butonuna tıklandığında çağrılacak
     */
    onSave = () => {
        let method = "post",
            endpoint = "/production-planing";

        //if there is id in formdata, change the method and endpoint
        if (this.props.params && this.props.params.id) {
            method = "put";
            endpoint += "/update";
        }

        //send save request
        Api[method](endpoint, { header: this.state.planing, lines: this.state.rows }, (response, err) => {
            if (err !== false) {
                toastr.error("Hata!", response.message);
            } else {
                if (response.status === "ok") {
                    toastr.success("Başarılı", response.message);
                    window.location.replace("/dashboard/product-service#production-planing");
                } else {
                    toastr.error("Hata!", "Bir veya daha fazla alanda hata oluştu!");
                    if(response.status === "header_validation"){
                        this.setState({
                            errors: {
                                ...this.state.errors,
                                ...response.validation
                            }
                        });
                    }else if(response.status === "line_validation"){
                        this.setState({
                            errors: {
                                ...this.state.errors,
                                rows: [...this.state.rows, ...response.validation],
                            },
                        });
                    }
                }
            }
        });
    };

    /**
     *  veri değişitiğinde çağrılacak
     */
    onChange = ({ rows }) => {
        this.setState({
            rows,
        });
    };

    bomChange(value) {
        console.log("bom change ")
        let product = this.state.recipes.find((recipe) => recipe.id === value).item;

        this.setState({
            product: product.name,
        });

        Api.getAsync(`/recipe/line/${value}`)
            .then((res) => {
                if (res.data.status === "fail") {
                    toastr.error('Hata!', res.data.message);
                    window.location.replace("/dashboard/product-service#production-planing");
                }
                if (this.state.rows.length !== 0) {
                    this.setState({
                        rows: [],
                    });
                }
                if(res.data.status !== "fail"){
                    res.data.lines.map((line) => {
                        this.setState({
                            rows: [
                                ...this.state.rows,
                                {
                                    item_id: line.material,
                                    item_name: line.name,
                                    quantity_per_prod: line.quantity,
                                    total_quantity: line.quantity * this.state.planing.quantity,
                                },
                            ],
                        });
                    });
                }
            })
            .then(() => {
                if (this.state.warehouses.length > 0) {
                    this.warehouseChange(this.state.warehouse_id);
                }
            });

    }
    quantityChange(value) {
        const rows = [...this.state.rows];
        rows.map((row, index) => {
            console.log("row", row);
            let total_quantity = row.quantity_per_prod * value;
            rows[index].total_quantity = total_quantity;
            let missing =
                total_quantity - row.stock < 0 ? 0 : total_quantity - row.stock;
            rows[index].missing = parseFloat(missing).toFixed(2);
        });
        this.setState({
            rows: rows,
        });
    }
    warehouseChange(value) {
        console.log("warehouseChange Çalıştı", value);

        const rows = [...this.state.rows];
        console.log("rows Çalıştı", rows);
        rows.map((row, index) => {
            Api.get(
                `/production-planing/checkStock/${row.item_id}/${value}`,
                (resp, err) => {
                    if (resp.status === "ok" && !err) {
                        let current = parseFloat(resp.current).toFixed(2);
                        let other = resp.other;
                        let otherStock = 0.0;
                        if (other.length > 0) {
                            other.map((warehouse) => {
                                otherStock += parseFloat(warehouse.stock_quantity);
                            });
                        } else {
                            other = 0;
                        }
                        rows[index].stock = current;
                        let missing =
                            row.total_quantity - current < 0
                                ? 0
                                : row.total_quantity - current;
                        rows[index].missing = parseFloat(missing).toFixed(2);
                        rows[index].other = parseFloat(otherStock).toFixed(2);
                    }
                    this.setState({
                        rows: rows,
                    });
                    this.quantityChange(this.state.planing.quantity);
                }
            );
        });
    }
    /**
     *
     * @param field
     * @param value
     */
    onFieldChange = (field, value) => {
        console.log("onFieldChange", field, value);
        //Reçete seçildiğinde çalışacak.

        if (field === "bom_id") {
            this.bomChange(value);
        }
        //Üretim adedi değiştiğinde çalışacak.
        if (field === "quantity") {
            this.quantityChange(value);
        }

        if (field === "warehouse_id") {
            this.warehouseChange(value);
        }
        this.setState({
            planing: {
                ...this.state.planing,
                [field]: value,
            },
        });
    };

    onMount() {}

    render() {
        const inputProps = {
            onChange: this.onFieldChange,
            onMount: this.onMount.bind(this),
            formData: this.state.planing,
            errors: this.state.errors,
        };

        return (
            <div>
                <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
                   onClick={Util.backButton.bind(this, "/dashboard/product-service")}><i
                    className="fa fa-arrow-left"></i> Geri Dön</a>
                <Portlet title={"Üretim Planlama"}>
                    <div className="col-md-12">
                        <div className="col-md-6">
                            <EkoFormSelect
                                isVertical={true}
                                {...inputProps}
                                id={"bom_id"}
                                defaultText={"Reçete Seçiniz"}
                                optionId={"id"}
                                options={this.state.recipes}
                                optionValue={"name"}
                                label={"Reçete"}
                                searchable={true}
                            />
                            <EkoFormInput
                                {...inputProps}
                                isVertical
                                readOnly={true}
                                disabled={true}
                                label={"Ürün"}
                                value={this.state.product}
                                id={"item"}

                            />

                            <EkoFormSelect
                                isVertical={true}
                                {...inputProps}
                                id={"warehouse_id"}
                                defaultText={"Depo Seçiniz"}
                                optionId={"id"}
                                options={this.state.warehouses}
                                optionValue={"name"}
                                label={"Depo"}
                                searchable={true}
                            />

                            <EkoFormInput
                                placeHolder={0}
                                isVertical={true}
                                label={"Adet"}
                                id={"quantity"}
                                {...inputProps}
                            />
                        </div>
                        <div className="col-md-6">
                            <EkoFormDate
                                {...inputProps}
                                id={"date"}
                                dateFormat="DD-MM-YYYY"
                                isVertical={true}
                                clearable
                                label={"Planlanan Tarih"}
                            />

                            <EkoFormInput
                                isVertical
                                label={"Adı"}
                                id={"name"}
                                {...inputProps}
                            />

                            <label className="control-label control-line">Not</label>

                            <textarea
                                onChange={(data) => this.onFieldChange("note", data.target.value)}
                                name="note"
                                style={{
                                    height: 140,
                                }}
                                value={
                                    this.state.planing && this.state.planing.note
                                        ? this.state.planing.note
                                        : ""
                                }
                                placeholder={"Not Giriniz Giriniz"}
                                className={"form-control adress-area address-editable"}
                            ></textarea>
                        </div>
                    </div>

                    <div
                        className="col-md-12"
                        style={{
                            padding: 25,
                        }}
                    >
                        <EkoInputTable
                            onSave={this.onSave}
                            onCancel={this.props.router.goBack}
                            onChange={this.onChange}
                            errors={this.state.errors}
                            formData={this.state}
                            data={{
                                headers: [
                                    {label: "Tüketilecek Ürün Adı"},
                                    {label: "Birim Başı Tüketim"},
                                    {label: "Toplam İhtiyaç"},
                                    {label: "Bu Depodaki Stok"},
                                    {label: "Bu Depodaki Eksik"},
                                    {label: "Diğer depolardaki Stok"},
                                ],
                                rows: this.state.rows.map(() => this.row),
                            }}
                        />
                    </div>
                </Portlet>
            </div>
        );
    }
}

export default ProductionPlaning;
