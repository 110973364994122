import React, {Component} from "react";
import EkoModal from "../../components/elements/EkoModal";
import {toastr} from "react-redux-toastr";
import * as actions from "../../actions";
import {connect} from "react-redux";
import Api from "../../util/Api";
import styled from "styled-components";
import {EkoFormButton, EkoFormInputText, EkoFormRadioButton, EkoFormSelect} from "../elements/EkoForm";
import moment from "moment";
import Util from "../../util/Util";
class SalesShipmentSendModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            price_preview:"",
            formData: props.formData,
            formData2: {
                value2: { profileID: 1, note: null }
            },
            showModal: false,
            saving: false,
            sendBtnDisabledStatus: false,
            invoiceId: 0,
            errors: {},
            formFields: {},
            selectedReceiver:null,
            radioButtons3: [
                {
                    label: "Ticari İrsaliye",
                    id: "profileID",
                    radio_id: "ticari",
                    value: 1,
                    checked: true
                },
                {
                    label: "Temel İrsaliye",
                    id: "profileID",
                    radio_id: "temel",
                    value: 0
                }
            ],
            page:0,
            response:null
        };
    }

    componentWillReceiveProps(nextProps) {
        let formData = nextProps.formData;
        let errors = nextProps.errors || {};
        this.setState({
            formData: formData,
            errors: errors,
            showModal: nextProps.showModal
        });
    }
    componentWillMount() {
        this.setState({
            page:0
        });
    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
        this.setState({
            page:0
        });
    }
    sendRequirementCheck(){
        if(this.state.formData!==0){
            Api.get("/salesShipment/sendRequirementCheck/" + this.state.formData, (data, errors) => {
                if(data){
                    if(data.status==='error'){
                        toastr.error(data.message);
                    }else{
                        this.onSend();
                    }
                }
            });
        }
    }

    onSend() {
        var selectedRow = this.state.formData;
        const self = this;
        self.setState({ eInvoiceLoading: true,sendBtnDisabledStatus:true });
        var findReceiver = this.props.receiver.find((x) => x.identifier === this.state.selectedReceiver);
        if(findReceiver){
            let integrator = JSON.parse(localStorage.getItem("integrator"));
            var url = "";
            if (integrator === 1) {
                url = "/sendSalesShipment";
                //@oguz sovos gonderimi
            } else if (integrator === 3) {
                url = "/nesv2/sendSalesShipment";
                //@oguz nesv2 gönderimi
            }
            Api.post(
                url,
                {
                    sales_shipment_id: selectedRow,
                    receiver: findReceiver,
                    note:this.state.formData2.value2.note,
                    profileID:this.state.formData2.value2.profileID,
                    price_preview:this.state.price_preview
                },
                (response, err) => {
                    if (response) {
                        if(response.status=='fail'){
                            toastr.error(response.message);
                        }else{
                            toastr.success("Başarılı");
                        }
                        self.setState({ eInvoiceLoading: false,sendBtnDisabledStatus:false });
                        this.onCancel();
                    } else {
                        toastr.error("Hata", "Sistemsel Bir Hata Oluştu.");
                        self.setState({ eInvoiceLoading: false,sendBtnDisabledStatus:false });
                        err =
                            err === "Unauthorized"
                                ? "e-Fatura hesabınıza bağlanılamıyor kullanıcı adı şifrenizi kontrol ediniz"
                                : err;
                        toastr.error("Hata", err.message);
                    }
                }
            );
        }else{
            toastr.error("Hata", "Gönderici adresi seçmelisiniz.");
            self.setState({ eInvoiceLoading: false,sendBtnDisabledStatus:false });
        }
    }
    onFieldChange = (field, value) => {
        if (field === "identifier") {
            this.setState({
                selectedReceiver: value,
            });
        }
    };
    onFalse() {}
    setFieldChange(type, field, value) {
        let formData = this.state.formData2[type];
        formData[field] = value;
        let errors = this.state.errors || {};
        if (errors[type]) errors[type][field] = false;

        let bigForm = this.state.formData2;
        bigForm[type] = formData;
        this.setState({ formData2: bigForm, errors: errors });
    }

    setFieldMount(type, field) {
        var self = this;
        let formFields = this.state.formFields;
        if (!formFields[type]) {
            formFields[type] = [field];
        } else formFields[type].push(field);
        this.setState({ formFields: formFields });
    }
    go() {
        this.setState({ page: 1 });
        Api.get("/salesShipment/show/" + this.props.formData, (response, err) => {
            if (response && response.status === "ok") {
                const notes = response.salesShipment.noteRows.rows.map(row => row.note);
                const displayNotes = notes.join('\n');
                this.setFieldChange("value2", "note", displayNotes);
                console.log('Sales Shipment Notes:\n' + displayNotes);
            }
        });
    }

    staticHtml(lbl, text, special,currency=false) {
        let style =
            special === 2
                ? {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "left",
                    fontWeight: "bold"
                }
                : {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textAlign: "left",
                    textOverflow: "ellipsis"
                };
        if (!lbl) return <div className="row col-md-11">{text}</div>;
        return (
            <div className="row">
                <label htmlFor="name" className="col-md-4 control-label">
                    <span>{lbl}</span>
                </label>
                <div id="name" style={style} className="col-md-8 form-control-static">
                    {text}
                    {currency ? " "+currency : ""}
                </div>
            </div>
        );
    }
    handleCheckboxChange = (e) => {
        const { checked } = e.target;
        const newValue = (checked ? 'checked' : '');
        this.setState({price_preview: newValue});
    };
    render() {
        let response = this.props.formDatax;
        let name = response && response.customer?.firstname ? response.customer?.firstname : null;
        const value2Props = {
            onChange: this.setFieldChange.bind(this, "value2"),
            onMount: this.setFieldMount.bind(this, "value2"),
            formData: this.state.formData2.value2,
            errors: this.state.errors.value2
        };
        const Wrap = styled.div`
          .hidden{display:none !important}
          .showDiv{display:block !important}`;
        return (
            <EkoModal
                showModal={this.state.showModal}
                title={"Sevk İrsaliyesi Gönder"}
                onHide={() => {
                    this.onCancel();
                }}
            >
                {
                    this.state.page == 0 ?
                        <div className={"row showDiv"}>
                            <div className={"col-md-12 text-center"}>
                                Sevk İrsaliyesini Göndermek Üzeresiniz.<br/>Bu İşlemi Yapmak İstediğinize Eminmisiniz?
                                <div style={{"height": "10px"}}></div>
                            </div>
                            {
                                /*
                                <div className="row">
                                <div className="col-md-12" style={{"textAlign": "center"}}>
                                    <EkoFormRadioButton
                                        {...value2Props}
                                        id="radioButtons3"
                                        buttons={this.state.radioButtons3}
                                        onFalse={this.onFalse}
                                    />
                                </div>
                            </div>
                                */
                            }
                            <div className="col-md-12">
                                <EkoFormSelect
                                    label="Adres *"
                                    optionId="identifier"
                                    optionValue="identifier"
                                    labelMd={4}
                                    colMd={7}
                                    value={this.state.selectedReceiver}
                                    options={this.props.receiver}
                                    onChange={this.onFieldChange}
                                    id="identifier"
                                />

                            </div>
                            <div className={"col-md-12"} style={{"marginTop": "50px"}}>
                                <div className="col-md-12 text-center">
                                    <button disabled={this.state.sendBtnDisabledStatus}
                                            className="btn btn-default blue btn-sm"
                                            onClick={this.go.bind(this)}>
                                        <i className="fa fa-arrow-right"></i> Devam Et
                                    </button>
                                    <button className="btn btn-default btn-sm" onClick={this.onCancel.bind(this)}>
                                        İptal
                                    </button>
                                </div>
                            </div>
                        </div> :
                        <div className={"row showDiv"}>
                            <div className={"col-md-12 text-center"}>
                                Sevk İrsaliyesini Göndermek Üzeresiniz.<br/>Bu İşlemi Yapmak İstediğinize Eminmisiniz?
                                <div style={{"height": "10px"}}></div>
                            </div>
                            <div style={{textAlign: "left"}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Müşteri: {name}</p>
                                        <p>Düzenlenme Tarihi: {response.date
                                            ? moment(response.date, "DD-MM-YYYY").format(
                                                "DD/MM/YYYY"
                                            )
                                            : null}</p>
                                        {response.total_amount_fc !== "0.00" ?
                                            <p>Genel Toplam: {response.total_amount_fc
                                                ? Util.convertDecimalToMoney(response.total_amount_fc)
                                                : null}</p> : ""}
                                        <p>Senaryo: {this.state.formData2.value2.profileID === 1 ? (
                                            <span>Ticari İrsaliye</span>
                                        ) : (
                                            <span>Temel İrsaliye</span>
                                        )}</p>
                                        <input type="checkbox" onChange={(e) => this.handleCheckboxChange(e)}/>
                                        &nbsp;&nbsp;İrsaliyede Fiyat Bilgileri Gösterilmesin
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <EkoFormInputText
                                        {...value2Props}
                                        id="note"
                                        type="textarea"
                                        placeHolder={"İrsaliye Notu Giriniz"}
                                        colMd={12}
                                        rows={8}
                                    />
                                </div>
                            </div>
                            <div className={"col-md-12"} style={{"marginTop": "50px"}}>
                                <div className="col-md-12 text-center">
                                    <button disabled={this.state.sendBtnDisabledStatus}
                                            className="btn btn-default blue btn-sm"
                                            onClick={this.sendRequirementCheck.bind(this)}>
                                        Gönder
                                    </button>
                                    <button className="btn btn-default btn-sm" onClick={this.onCancel.bind(this)}>
                                        İptal
                                    </button>
                                </div>
                            </div>
                        </div>
                }
            </EkoModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        taxOffices: state.data.taxOffices
    };
}

export default connect(mapStateToProps, actions)(SalesShipmentSendModal);
