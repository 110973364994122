import React, { Component } from "react";
import { CSS } from "../../css/tab_datatable";
import Style from "style-it";
import * as actions from "../../actions";
import { connect } from "react-redux";
import PrintModal from "../../components/forms/PrintModal";
import EInvoiceModal from "../EInvoice/EInvoiceModal";
import EInvoiceNes from "../EInvoice/EInvoiceNes";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";
import HelperContainer from "../../util/HelperContainer";
import AddCustomer from "../Customer/AddCustomer";
import EArchiveSendModal from "../../components/forms/EArchiveSendModal";
import AddSupplier from "../Supplier/AddSupplier";
import Util from "../../util/Util";
import PaginatedDataTable from "../../components/elements/PaginatedDataTable";
import ModalTransaction from "../Transaction/ModalTransaction";
import AskCollection from "../Collection/AskCollection";
import EInvoicePreview from "../../components/elements/EInvoicePreview";
import {Link} from "react-router";
import MarketplaceModal from "./MarketplaceModal";
import SalesInvoiceImportModal from "./SalesInvoiceImport";
import NewTagDocumentModal from "../Company/newTagDocument";
import SalesInvoiceCustomDeclaration from "../../components/elements/SalesInvoiceCustomDeclaration";
import EkoModal from "../../components/elements/EkoModal";

class SalesInvoice1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSalesInvoiceId:0,
      showModalSalesInvoiceCustomDeclaration: false,
      showNewTagModal: false,
      showInsertModal: false,
      openPreviewModal: false,
      previewID: null,
      showPrintModal: false,
      showEInvoiceModal: false,
      showEInvoiceNes: false,
      saving: false,
      selectedId: 0,
      legalNo: null,
      legalSerial: null,
      salesInvoices: {
        lastPrintSerial: null,
        salesInvoices: [],
        lastPrintNo: null
      },
      showCustomer: false,
      showeArchiveSendModal: false,
      selectedeArchiveSendId: 0,
      showModalSales: false,
      showModal: false,
      showSupplier: false,
      errors: {},
      formDataCustomer: AddCustomer.getDefaultForm(),
      formDataSupplier: AddSupplier.getDefaultForm(),
      printLoading: false,
      eInvoiceLoading: false,
      cancelLoading: false,
      eInvoiceResponse: { customer: {}, invoice: {}, receiver: [] },
      eInvoiceType: 0,
      selected: [],
      hideSelectButtons: false,
      addUpdateShow: true,
      addDeleteShow: true,
      fetchApi: {},
      dataLoading: false,
      salesApi: {},
      erroredInvoiceCount: {},
      newTagRows: {
        rows: [{ id:null,new_tag_type_id:1,new_tag_id: "",record_id:null, rate: "%100,00" }],
      },
      selectedNewTagModalId:0
    };
  }

  fetchSalesInvoices = (
      sizePerPage = 10,
      page = 1,
      order = [],
      date = [],
      search = "",
      invoiceStatus = "",
  ) => {
    this.setState({ dataLoading: true });

    const url = Api.generateUrl(
        "/salesInvoice/list",
        //search === "" ? "/salesInvoice/list" : "/search/salesInvoice",
        sizePerPage,
        page,
        order,
        date,
        search,
        invoiceStatus,
    );
    if (this.table) this.table.cleanSelect();

    Api.get(url, (data, errors) => {
      console.log("fetchsales url", url);
      if (!errors) {
        if (data && data.salesInvoices) {
          this.setState({
            salesInvoices: {
              ...this.state.salesInvoices,
              ...data
            },
            dataLoading: false
          });
        }
      }
    });
  };

  fetchErroredInvoiceCount() {
    const integrator = JSON.parse(localStorage.getItem("integrator"));
    Api.get(`/${integrator === 3 ? 'nesv2' : 'nes' }/check-incorrect-invoices`, (response, err) => {
      if (response) {
        console.log("response", response);
        this.setState({ erroredInvoiceCount: response });
      }
    });
  }

  componentDidMount() {
    this.fetchSalesInvoices();
    this.fetchErroredInvoiceCount();
  }

  openUpdate(selectedRow) {
    this.props.router.push("/dashboard/salesInvoice/edit/" + selectedRow.id);
  }

  showMethod(selectedRow) {
    this.showBill(selectedRow);
  }

  eArchiveShowButton(selectedRow) {
    this.eArchiveShowBill(selectedRow);
  }

  openDetail(selectedRow) {
    this.goToApi(selectedRow);
  }

  openCopied(selectedRow) {
    this.copiedInvoce(selectedRow);
  }

  askCollection(selectedRow) {
    let self = this;
    let params = Object.assign(
        {},
        {
          customer_id: selectedRow.customer_id,
          price: selectedRow.receivable_amount,
          row : selectedRow
        }
    );

    let paynet_status = JSON.parse(localStorage.getItem("paynetStatus"));

    let new_paynet_status = paynet_status.toString();

    if (new_paynet_status === "0") {
      self.setState({ showModal: true, salesInvoiceId: selectedRow.id });
    } else if (new_paynet_status === "1") {
      Api.post("/paynet/create-payment", params, function (response,err) {
        if (response) {
          let url = process.env.REACT_APP_API_URL.substr(
              0,
              process.env.REACT_APP_API_URL.length - 3
          );

          self.setState({ showModal: true, salesInvoiceId: selectedRow.id, link: url + "paynet/" + response });
        }

        if(err){
          toastr.error(err.message)
        }
      });
    }
  }

  openArchiveCancel(state) {
    const self = this;
    let integrator = JSON.parse(localStorage.getItem("integrator"));
    let id = state.selectedRow.id;
    let endpoint = "";

    if (integrator === 1) {
      endpoint = "/eInvoice/archiveCancel";
    } else if (integrator === 2) {
      endpoint = "/nes/cancel-earchive";
    } else if (integrator === 3) {
      endpoint = "/nesv2/cancel-earchive";
    } else {
      toastr.error("Entegratorunuz bulunamdı.");
    }
    let message = (
        <span>
        Bu e-Arşiv faturayı iptal ettikten sonra ilgili faturaya ait kayıdı
        silmeyi unutmayınız! <br />
        İptal etmek istediğinize emin misiniz?
      </span>
    );
    toastr.confirm(message, {
      onOk: () => {
        self.setState({ cancelLoading: true });

        Api.post(endpoint, { object_id: id }, (response, err) => {
          if (response) {
            self.fetchSalesInvoices();
            self.table.cleanSelect();
            self.setState({ cancelLoading: false, selectedId: 0 });
            toastr.success("Başarılı", response.message);
          } else {
            self.fetchSalesInvoices();
            self.table.cleanSelect();
            self.setState({ cancelLoading: false, selectedId: 0 });
            toastr.error("Hata", err.message);
          }
        });
      },
      onCancel: () => { },
      okText: "Evet",
      cancelText: "Hayır"
    });
  }

  sendButton(state) {
    const self = this;
    let integrator = JSON.parse(localStorage.getItem("integrator"));

    if (integrator === 1) {
      self.sendEInvoice(state);
    } else if (integrator === 2) {
      self.nesEInvoice(state);
    } else if (integrator === 3) {
      self.nesEInvoice(state);
    }
  }

  eArchivesendButton(state) {
    let integrator = JSON.parse(localStorage.getItem("integrator"));
    if(integrator !== 1 || integrator !== 2 || integrator !== 3){
      this.setState({showeArchiveSendModal:true,selectedeArchiveSendId:state.selectedRow.id});
    }
  }

  eInvoicePreview = selectedRow => {
    this.setState({ openPreviewModal: true, previewID: selectedRow.id });
  };

  openSelfPrint(state) {
    const self = this;
    if (state.selectedRow.print_date) {
      toastr.confirm(
          `Bu faturayı daha önce bastırmışsınız. Tekrardan yazdırmak istediğinize emin misiniz?`,
          {
            onOk: () => {
              self.checkPrint(state);
            },
            onCancel: () => { },
            okText: "Evet",
            cancelText: "Hayır"
          }
      );
    } else self.checkPrint(state);
  }

  openInsert(menuItem) {
    //localStorage.setItem("new_collection_from_who_type", menuItem);

    if (menuItem === 1) {
      this.props.router.push("/dashboard/salesInvoice/add/toCustomer");
    } else if (menuItem === 2) {
      this.props.router.push("/dashboard/salesInvoice/add/toSupplier");
    } else if (menuItem === 3) {
      this.props.router.push("/dashboard/salesInvoice/zreports/add");
    } else if (menuItem === 4) {
      this.props.router.push("/dashboard/salesInvoice/proforma/add");
    }else if (menuItem === 5) {
      this.props.router.push('/dashboard/salesInvoice/otherSales/add')
    }else if (menuItem === 6) {
      this.props.router.push('/dashboard/salesInvoice/soleprofv2/add')
    } else if (menuItem === 7) {
      this.props.router.push('/dashboard/salesInvoice/salesShipment/add')
    }
  }

  onDelete(selectedRow) {
    const self = this;
    Api.get("/salesInvoice/" + selectedRow.id, function (response, err) {
      if (response) {
        var payable_id = response.salesInvoice.payable_id;
        if(payable_id !==null){
          toastr.confirm(`Bu kayda ait tahsilat bulunmaktadır. Tahsilat kaydı da silinsin mi?`, {
            onOk: () => {
              Api.delete("/salesInvoice/" + selectedRow.id, function (response, err) {
                if (response) {
                  Api.delete("/collection/" +payable_id, function(response, err) {});
                  self.fetchSalesInvoices();
                  toastr.success("Başarılı", "Başarıyla Silindi");
                } else toastr.error("Hata", err.message);
              });
            },
            onCancel: () => {
              Api.delete("/salesInvoice/" + selectedRow.id, function (response, err) {
                if (response) {
                  self.fetchSalesInvoices();
                  toastr.success("Başarılı", "Başarıyla Silindi");
                } else toastr.error("Hata", err.message);
              });
            },
            okText: "Evet",
            cancelText: "Hayır"
          });
        }else{
          Api.delete("/salesInvoice/" + selectedRow.id, function (response, err) {
            if (response) {
              self.fetchSalesInvoices();
              toastr.success("Başarılı", "Başarıyla Silindi");
            } else toastr.error("Hata", err.message);
          });
        }

      } else toastr.error("Hata", err.message);
    });
  }

  closeModal() {
    this.props.closeModal();
  }

  closePrintModal() {
    this.setState({
      showPrintModal: false,
      selectedId: 0,
      legalNo: null,
      legalSerial: null
    });
  }

  closeEInvoiceModal() {
    this.setState({
      showEInvoiceModal: false,
      selectedId: 0,
      eInvoiceResponse: { customer: {}, invoice: {}, receiver: [] }
    });
  }

  closeEInvoiceNes() {
    this.setState({
      showEInvoiceNes: false
    });
  }

  setCustomer(id, next) {
    var self = this;

    Api.paralel(
        [
          "/customer/" + id,
          "/customerContact/showAll/" + id,
          "/customerAddress/showAll/" + id
        ],
        function (responses) {
          let formData = {};
          formData.customer = HelperContainer.filterObject(responses[0].customer);
          formData.customer.opening_balance = Util.convertDecimalToMoney(
              formData.customer.opening_balance
          );
          formData.customer.balance = Util.convertDecimalToMoney(
              formData.customer.balance
          );

          if (
              responses[1].customerContacts &&
              responses[1].customerContacts.length > 0
          )
            formData.contact = HelperContainer.filterObject(
                responses[1].customerContacts[0]
            );
          else formData.contact = {};

          if (
              responses[2].customerAddresses &&
              responses[2].customerAddresses.length > 0
          ) {
            const address = responses[2].customerAddresses.filter(function (a) {
              return a.address_type === 2;
            });
            address[0].is_abroad = !address[0].city_id;
            formData.address = HelperContainer.filterObject(address[0]);
            if (!formData.customer.delivery_same_as_invoice_address) {
              const addressDelivery = responses[2].customerAddresses.filter(
                  function (a) {
                    return a.address_type === 1;
                  }
              );
              if (addressDelivery[0].city_id === null)
                addressDelivery[0].is_abroad = true;
              formData.deliveryAddress = HelperContainer.filterObject(
                  addressDelivery[0]
              );
            }
          } else {
            formData.deliveryAddress = {};
            formData.address = {};
          }

          self.setState(
              {
                formDataCustomer: formData
              },
              () => {
                next();
              }
          );
        }
    );
  }

  addCollectionn(selectedRow) {
    localStorage.setItem("new_collection_from_who_type", 1);

    localStorage.setItem("selected_list_sales_invoice_id", selectedRow.id);
    localStorage.setItem(
        "new_customer_id_take_collection",
        selectedRow.customer_id
    );
    Api.get("/salesInvoice/" + selectedRow.id, (response, error) => {
      if (response) {
        if(response.salesInvoice.currency!=='TRY'){
          localStorage.setItem("new_collection_amount_fc", selectedRow.receivable_amount_fc);
          localStorage.setItem("selected_list_receivable_amount_fc", Util.convertDecimalToMoney(selectedRow.receivable_amount_fc));
          this.props.router.push("/dashboard/collections/add");
        }else{
          localStorage.setItem("new_collection_amount_fc", selectedRow.receivable_amount);
          localStorage.setItem("selected_list_receivable_amount_fc", Util.convertDecimalToMoney(selectedRow.receivable_amount));
          this.props.router.push("/dashboard/collections/add");
        }
      }
    });
  }
  closeNewTagModal = () => {
    this.setState({
      showNewTagModal: !this.state.showNewTagModal,
    });
  };
  openSalesInvoiceCustomDeclarationModal = (selected) => {
    this.setState({
      showModalSalesInvoiceCustomDeclaration: true,
      selectedSalesInvoiceId: selected.id,
    });
  };
  openNewTagModal = (selected) => {
    this.getNewTagModalList(selected.id)
  };
  saveNewTag = (rows) => {
    this.setState({
      newTagRows: rows,
    });
  };
  directInsertUpdateFunc = (rows) => {
    rows = {
      newTagRows: {
        rows: rows,
      }
    };
    Api.put("/salesInvoice/newTagUpdate/"+this.state.selectedNewTagModalId, rows, function (res, err) {
      toastr.success("Başarılı!", "Etiket Başarıyla Güncellendi.");
    });
    this.setState({
      selectedNewTagModalId:0
    })
  };
  getNewTagModalList(id){
    if (id) {
      Api.get("/company/newTagRecordDocumentList/1/" +id, (response, err) => {
        if (response && response.rows && response.rows.length>0) {
          this.setState({
            newTagRows: response,
          }, () => {
            this.setState({
              selectedNewTagModalId: id,
              showNewTagModal: !this.state.showNewTagModal,
            });
          });
        }else{
          this.setState({
            selectedNewTagModalId: id,
            showNewTagModal: !this.state.showNewTagModal,
            newTagRows: {
              rows: [{ id:null,new_tag_type_id:1,new_tag_id: "",record_id:null, rate: "%100,00" }],
            }
          });
        }
      });
    }
  }
  setSupplier(id, next) {
    var self = this;

    Api.paralel(
        [
          "/supplier/" + id,
          "/supplierContact/showAll/" + id,
          "/supplierAddress/showAll/" + id
        ],
        function (responses) {
          let formData = {};
          formData.supplier = HelperContainer.filterObject(responses[0].supplier);

          if (
              responses[1].supplierContacts &&
              responses[1].supplierContacts.length > 0
          )
            formData.contact = HelperContainer.filterObject(
                responses[1].supplierContacts[0]
            );
          else formData.contact = {};

          if (
              responses[2].supplierAddresses &&
              responses[2].supplierAddresses.length > 0
          ) {
            const address = responses[2].supplierAddresses.filter(function (a) {
              return a.address_type === 2;
            });
            formData.address = HelperContainer.filterObject(address[0]);
          } else {
            formData.address = {};
          }

          self.setState(
              {
                formDataSupplier: formData
              },
              () => {
                next();
              }
          );
        }
    );
  }

  openPrint(state) {
    const self = this;
    if (state.selectedRow.print_date) {
      toastr.confirm(
          `Bu faturayı daha önce bastırmışsınız. Tekrardan yazdırmak istediğinize emin misiniz?`,
          {
            onOk: () => {
              self.checkPrint(state);
            },
            onCancel: () => { },
            okText: "Evet",
            cancelText: "Hayır"
          }
      );
    } else self.checkPrint(state);
  }

  openEInvoice(state) {
    const self = this;
    self.sendEInvoice(state);
  }

  checkPrint(state) {
    const self = this;
    self.setState({ printLoading: true });
    Api.post(
        "/template/checkPrint",
        { object_id: state.selectedRow.id },
        (response, err) => {
          if (response) {
            self.setState({
              showPrintModal: true,
              selectedId: state.selectedRow.id,
              legalNo: state.selectedRow.legal_no,
              legalSerial: state.selectedRow.legal_serial,
              printLoading: false,
              errors: {},
              showCustomer: false,
              showSupplier: false,
              showInsertModal: false,
              showEInvoiceModal: false
            });
          } else {
            if (state.selectedRow.customer_id) {
              let customerErr = { customer: err };
              if (err.address) {
                customerErr = { customer: err, address: err };
              }
              self.setCustomer(state.selectedRow.customer_id, () => {
                self.setState({
                  errors: customerErr,
                  showCustomer: true,
                  selectedId: state.selectedRow.id,
                  legalSerial: state.selectedRow.legal_serial,
                  legalNo: state.selectedRow.legal_no,
                  legalDate: state.selectedRow.legal_no,
                  showInsertModal: false,
                  showEInvoiceModal: false,
                  selected: state
                });
              });
            } else {
              let supplierErr = { supplier: err };
              if (err.address) {
                supplierErr = { supplier: err, address: err };
              }
              self.setSupplier(state.selectedRow.supplier_id, () => {
                self.setState({
                  errors: supplierErr,
                  showSupplier: true,
                  selectedId: state.selectedRow.id,
                  legalSerial: state.selectedRow.legal_serial,
                  legalNo: state.selectedRow.legal_no,
                  selected: state,
                  showInsertModal: false,
                  showEInvoiceModal: false
                });
              });
            }
          }
        }
    );
  }

  nesEInvoice(state) {
    const integrator = JSON.parse(localStorage.getItem("integrator"));
    const self = this;
    self.setState({ eInvoiceLoading: true });
    Api.post(
        "/template/checkPrint",
        { object_id: state.selectedRow.id, type: 0 },
        (response, err) => {
          if (response) {
            Api.post(
                `/${integrator === 3 ? 'nesv2' : 'nes' }/send`,
                {
                  sales_invoice_id: state.selectedRow.id,
                  receiver: null
                },
                (response, err) => {
                  if (response) {
                    let eInvoiceType = response.receiver[0]
                        ? response.receiver[0].status
                        : response.receiver.status;
                    self.setState({
                      showEInvoiceNes: true,
                      selectedId: state.selectedRow.id,
                      eInvoiceLoading: false,
                      errors: {},
                      showCustomer: false,
                      showSupplier: false,
                      showInsertModal: false,
                      showPrintModal: false,
                      eInvoiceResponse: response,
                      eInvoiceType: eInvoiceType
                    });
                  } else {
                    self.setState({ eInvoiceLoading: false });
                    err =
                        err === "Unauthorized"
                            ? "e-Fatura hesabınıza bağlanılamıyor kullanıcı adı şifrenizi kontrol ediniz"
                            : err;
                    toastr.error("Hata", err.message);
                  }
                }
            );
          } else {
            if (state.selectedRow.customer_id) {
              let customerErr = { customer: err };
              if (err.address) {
                customerErr = { customer: err, address: err };
              }
              self.setCustomer(state.selectedRow.customer_id, () => {
                self.setState({
                  errors: customerErr,
                  showCustomer: true,
                  selectedId: state.selectedRow.id,
                  selected: state,
                  legalNo: null,
                  showInsertModal: false,
                  showPrintModal: false
                });
              });
            } else {
              let supplierErr = { supplier: err };
              if (err.address) {
                supplierErr = { supplier: err, address: err };
              }
              self.setSupplier(state.selectedRow.supplier_id, () => {
                self.setState({
                  errors: supplierErr,
                  showSupplier: true,
                  selectedId: state.selectedRow.id,
                  selected: state,
                  legalNo: null,
                  showInsertModal: false,
                  showPrintModal: false
                });
              });
            }
          }
        }
    );
  }

  sendEInvoice(state) {
    const self = this;
    self.setState({ eInvoiceLoading: true });
    Api.post(
        "/template/checkPrint",
        { object_id: state.selectedRow.id, type: 0 },
        (response, err) => {
          if (response) {
            Api.post(
                "/eInvoice/send",
                {
                  sales_invoice_id: state.selectedRow.id,
                  receiver: null
                },
                (response, err) => {
                  if (response) {
                    let eInvoiceType = response.receiver[0]
                        ? response.receiver[0].status
                        : response.receiver.status;
                    self.setState({
                      showEInvoiceModal: true,
                      selectedId: state.selectedRow.id,
                      eInvoiceLoading: false,
                      errors: {},
                      showCustomer: false,
                      showSupplier: false,
                      showInsertModal: false,
                      showPrintModal: false,
                      eInvoiceResponse: response,
                      eInvoiceType: eInvoiceType
                    });
                  } else {
                    self.setState({ eInvoiceLoading: false });
                    err =
                        err === "Unauthorized"
                            ? "e-Fatura hesabınıza bağlanılamıyor kullanıcı adı şifrenizi kontrol ediniz"
                            : err;
                    toastr.error("Hata", err.message);
                  }
                }
            );
          } else {
            if (state.selectedRow.customer_id) {
              let customerErr = { customer: err };
              if (err.address) {
                customerErr = { customer: err, address: err };
              }
              self.setCustomer(state.selectedRow.customer_id, () => {
                self.setState({
                  errors: customerErr,
                  showCustomer: true,
                  selectedId: state.selectedRow.id,
                  selected: state,
                  legalNo: null,
                  showInsertModal: false,
                  showPrintModal: false
                });
              });
            } else {
              let supplierErr = { supplier: err };
              if (err.address) {
                supplierErr = { supplier: err, address: err };
              }
              self.setSupplier(state.selectedRow.supplier_id, () => {
                self.setState({
                  errors: supplierErr,
                  showSupplier: true,
                  selectedId: state.selectedRow.id,
                  selected: state,
                  legalNo: null,
                  showInsertModal: false,
                  showPrintModal: false
                });
              });
            }
          }
        }
    );
  }

  onCustomerSave(id) {
    if (parseInt(this.state.selected.selectedRow.einvoice_type, 10) === 0)
      this.checkPrint(this.state.selected);
    else this.sendEInvoice(this.state.selected);
  }

  archiveCancel(state) {
    const self = this;
    let id = state.selectedRow.id;
    let message = (
        <span>
        Bu e-Arşiv faturayı iptal ettikten sonra ilgili faturaya ait kayıdı
        silmeyi unutmayınız! <br />
        İptal etmek istediğinize emin misiniz?
      </span>
    );
    toastr.confirm(message, {
      onOk: () => {
        self.setState({ cancelLoading: true });
        Api.post(
            "/eInvoice/archiveCancel",
            { object_id: id },
            (response, err) => {
              if (response) {
                self.fetchSalesInvoices();
                self.table.cleanSelect();
                self.setState({ cancelLoading: false, selectedId: 0 });
                toastr.success("Başarılı", response.message);
              } else {
                self.fetchSalesInvoices();
                self.table.cleanSelect();
                self.setState({ cancelLoading: false, selectedId: 0 });
                toastr.error("Hata", err.message);
              }
            }
        );
      },
      onCancel: () => { },
      okText: "Evet",
      cancelText: "Hayır"
    });
  }

  // invoiceCancel(state) {
  //     const self = this;
  //     let id = state.selectedRow.id;
  //     toastr.confirm(`Bu e-Fatura iptal ettiğinizde ilgili faturaya ait hesabınızda bulunan kayıt da silinecektir, iptal etmek istediğinize emin misiniz?`, {
  //         onOk: () => {
  //             self.setState({cancelLoading: true});
  //             Api.post('/eInvoice/cancelEInvoice', {object_id: id}, (response, err) => {
  //                 if (response) {
  //                     self.fetchSalesInvoices()
  //                     toastr.success('Başarılı', response.message)
  //                 } else {
  //                     self.setState({cancelLoading: false});
  //                     toastr.error('Hata', err.message)
  //                 }
  //             })
  //         },
  //         onCancel: () => {
  //         },
  //         okText: "Evet",
  //         cancelText: "Hayır"
  //     })
  // }

  copiedInvoce(selectedRow) {
    localStorage.setItem("sales_invoice_copied", selectedRow.id);
    this.props.router.push("/dashboard/salesInvoice/copied");
  }

  showBill = row => {
    window.open(
        process.env.REACT_APP_API_URL +
        "/bill-print/" +
        row.id +
        "?token=" +
        localStorage.getItem("token"),
        "_blank"
    );
  };

  eArchiveShowBill = row => {
    window.open(
        "https://earsiv.co/s-"+btoa(row.id),
        "_blank"
    );
  };

  get items() {
    var user_json = JSON.parse(localStorage.getItem("user"));
    var list = [];
    if(user_json.company_type == 9 || user_json.company_type == 6){
      window.location.replace("/dashboard/salesInvoice/soleprofv2s");
    }
    if(user_json.company_type == 9 || user_json.company_type == 6){
      list = [
        {
          id: 5,
          link: "/dashboard/salesInvoice/soleprofv2s",
          title: "Serbest Meslek Makbuzu"
        },
        {
          id: 2,
          link: "/dashboard/salesInvoice/zreports",
          title: "Z Raporları"
        },
        {
          id: 3,
          link: '/dashboard/salesInvoice/proformas',
          title: 'Teklifler/Siparişler'
        },
        {
          id: 4,
          link: "/dashboard/salesInvoice/otherSales",
          title: "Diğer Gelirler"
        }
      ];
    }else{
      list = [
        {
          id: 1,
          link: '/dashboard/salesInvoice',
          title: 'Satışlar'
        },

        {
          id: 2,
          link: '/dashboard/salesInvoice/zreports',
          title: 'Z Raporları'
        },
        {
          id: 3,
          link: '/dashboard/salesInvoice/proformas',
          title: 'Teklifler/Siparişler'
        },
        {
          id: 4,
          link: "/dashboard/salesInvoice/otherSales",
          title: "Diğer Gelirler"
        }
      ];
      if(Util.companyModules(2)===true){
        //@oguz stok modulu satın alındıysa
        list.push({
          id: 7,
          link: "/dashboard/salesInvoice/salesShipment",
          title: "Sevk İrsaliyesi"
        },);
      }
    }
    return list;
  }

  goToApi(selectedRow) {
    var self = this;
    const id = selectedRow.id;

    let formData = {};
    Api.get("/salesInvoiceLine/showAll/" + id, function (responses) {
      formData = {};
      if (responses.salesInvoiceLines) {
        formData.sales = HelperContainer.filterObject(
            responses.salesInvoiceLines
        );

        let values = formData.sales;

        self.setState({ fetchApi: values, showModalSales: true });
      }
    });

    Api.get("/salesInvoice/" + id, function (responses) {
      formData = {};
      if (responses.salesInvoice) {
        formData.sales = HelperContainer.filterObject(responses.salesInvoice);

        let values = formData.sales;

        self.setState({ salesApi: values });
      }
    });
  }

  sideLink() {
    const all = this.state.erroredInvoiceCount.total;
    if (all) {
      return (
          <div>
            <div style={{margin: 10,float:"inline-start"}}>
          <span className="alert alert-danger">
            <i className="fa fa-file"/> &nbsp;
            {this.state.erroredInvoiceCount.message}
          </span>
            </div>
            <Link className="btn btn-default blue" onClick={() => this.openMarketplaceModal()}><i
                className="fa fa-reply"></i> Toplu Fatura İşlemleri</Link>
            {JSON.parse(localStorage.getItem("integrator")) === 3 ? (
                <Link style={{"marginLeft":"10px"}} className="btn btn-default blue" onClick={() => this.openSalesInvoiceImportModal()}><i
                    className="fa fa-share-square"></i> İçeri Al</Link>
            ) : ("")}
          </div>
      );
    } else {
      return (<div><Link className="btn btn-default blue" onClick={() => this.openMarketplaceModal()}><i
          className="fa fa-reply"></i> Toplu Fatura İşlemleri</Link>
        {JSON.parse(localStorage.getItem("integrator")) === 3 ? (
            <Link style={{"marginLeft":"10px"}} className="btn btn-default blue" onClick={() => this.openSalesInvoiceImportModal()}><i
                className="fa fa-share-square"></i> İçeri Al</Link>
          ) : ("")}</div>);
    }
    ;
  }

  openMarketplaceModal() {
    let self = this;
    self.setState({showMarketplaceModal: true});
  }
  closeMarketplaceModal(){
    let self = this;
    self.setState({showMarketplaceModal: false});
  }

  openSalesInvoiceImportModal() {
    let self = this;
    self.setState({showSalesInvoiceImportModal: true});
  }
  closeSalesInvoiceImportModal(){
    let self = this;
    self.setState({showSalesInvoiceImportModal: false});
  }
  render() {
    const insertMenux = this.props.viewOptions
        ? this.props.viewOptions.sales.addRecordMenu
        : false;
    if(insertMenux!=false){
      var user_json = JSON.parse(localStorage.getItem("user"));
      if(user_json.company_type == 9 || user_json.company_type == 6){
        var insertMenu = insertMenux.filter(obj => obj.id !== 1 && obj.id !== 2);
        insertMenu.unshift({id: 6, name: 'Serbest Meslek Makbuzu'});
      }else{
        insertMenu = insertMenux;
      }
    }
    if(Util.companyModules(2)!==true){
      //@oguz stok modulu satın alınmadıysa sevk irsaliyesini insertMenu dizisinden bul ve sil
      insertMenu = insertMenu.filter(obj => obj.id !== 7);
    }
    return (
        <div>
          <a style={{"bottom":"6px","position":"relative","color":"grey"}} onClick={Util.backButton.bind(this,"/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          <MarketplaceModal
              showModal={this.state.showMarketplaceModal}
              onClose={this.closeMarketplaceModal.bind(this)}
              token={localStorage.getItem("token")}
          />
          <SalesInvoiceImportModal
              showModal={this.state.showSalesInvoiceImportModal}
              onClose={this.closeSalesInvoiceImportModal.bind(this)}
              token={localStorage.getItem("token")}
          />
          {Style.it(
              CSS,
              <div>
                <PaginatedDataTable
                    onRef={ref => (this.table = ref)}
                    tabMenu={this.items}
                    selectedTab={1}
                    title={"SATIŞLAR"}
                    sideLink={this.sideLink()}
                    openInsert={this.openInsert.bind(this)}
                    openArchiveCancel={this.openArchiveCancel.bind(this)}
                    askCollection={this.askCollection.bind(this)}
                    openUpdate={this.openUpdate.bind(this)}
                    showMethod={this.showMethod.bind(this)}
                    sendButton={this.sendButton.bind(this)}
                    eArchivesendButton={this.eArchivesendButton.bind(this)}
                    eArchiveShowButton={this.eArchiveShowButton.bind(this)}
                    openSelfPrint={this.openSelfPrint.bind(this)}
                    openDetail={this.openDetail.bind(this)}
                    openCopied={this.openCopied.bind(this)}
                    onDelete={this.onDelete.bind(this)}
                    previewButton={this.eInvoicePreview.bind(this)}
                    addCollectionn={this.addCollectionn.bind(this)}
                    newTagModal={this.openNewTagModal.bind(this)}
                    salesInvoiceCustomDeclarationModal={this.openSalesInvoiceCustomDeclarationModal.bind(this)}
                    data={
                        this.state.salesInvoices &&
                        this.state.salesInvoices.salesInvoices
                    }
                    insertMenu={insertMenu}
                    addUpdateShow={this.state.addUpdateShow}
                    hideSelectButtons={this.state.hideSelectButtons}
                    fetchData={this.fetchSalesInvoices}
                    totalCount={
                        this.state.salesInvoices && this.state.salesInvoices.total
                    }
                    pageCount={
                        this.state.salesInvoices && this.state.salesInvoices.last_page
                    }
                    currentPage={
                      this.state.salesInvoices &&
                      this.state.salesInvoices.current_page
                          ? this.state.salesInvoices.current_page
                          : 1
                    }
                    name={"salesInvoice"}
                    loading={this.state.dataLoading}
                />
              </div>
          )}

          <PrintModal
              showModal={this.state.showPrintModal}
              closeModal={this.closePrintModal.bind(this)}
              objectId={this.state.selectedId}
              legalNo={this.state.legalNo}
              legalSerial={this.state.legalSerial}
              lastPrintNo={
                  this.props.salesInvoices && this.props.salesInvoices.lastPrintNo
              }
              lastPrintSerial={
                  this.props.salesInvoices && this.props.salesInvoices.lastPrintSerial
              }
              fetchRefresh={this.fetchSalesInvoices}
          />
          {JSON.parse(localStorage.getItem("integrator")) === 1 ? (
              <EInvoiceModal
                  showModal={this.state.showEInvoiceModal}
                  closeModal={this.closeEInvoiceModal.bind(this)}
                  objectId={this.state.selectedId}
                  response={this.state.eInvoiceResponse}
                  type={this.state.eInvoiceType}
                  fetchRefresh={this.fetchSalesInvoices}
              />
          ) : (
              <EInvoiceNes
                  showModal={this.state.showEInvoiceNes}
                  closeModal={this.closeEInvoiceNes.bind(this)}
                  objectId={this.state.selectedId}
                  response={this.state.eInvoiceResponse}
                  type={this.state.eInvoiceType}
                  fetchRefresh={this.fetchSalesInvoices}
              />
          )}

          <AddCustomer
              activeTab={"address"}
              showModal={this.state.showCustomer}
              formData={this.state.formDataCustomer}
              onSave={this.onCustomerSave.bind(this)}
              onCancel={() => {
                this.setState({
                  showCustomer: false,
                  printLoading: false,
                  eInvoiceLoading: false
                });
              }}
              errors={this.state.errors}
          />

          <EArchiveSendModal
              activeTab={"address"}
              showModal={this.state.showeArchiveSendModal}
              formData={this.state.selectedeArchiveSendId}
              onSave={this.onCustomerSave.bind(this)}
              onCancel={() => {
                this.setState({
                  showeArchiveSendModal: false
                });
              }}
              errors={this.state.errors}
          />

          <AskCollection
              showModal={this.state.showModal}
              link={this.state.link}
              salesInvoiceId={this.state.salesInvoiceId}
              closeModal={() => {
                this.setState({ showModal: false });
              }}
          />

          <ModalTransaction
              showModal={this.state.showModalSales}
              fetchApi={this.state.fetchApi}
              salesApi={this.state.salesApi}
              closeModal={() => {
                this.setState({ showModalSales: false });
              }}
          />
          <AddSupplier
              activeTab={"address"}
              showModal={this.state.showSupplier}
              formData={this.state.formDataSupplier}
              onSave={this.onCustomerSave.bind(this)}
              onCancel={() => {
                this.setState({
                  showSupplier: false,
                  printLoading: false,
                  eInvoiceLoading: false
                });
              }}
              errors={this.state.errors}
          />
          <EInvoicePreview
              showModal={this.state.openPreviewModal}
              previewID={this.state.previewID}
              closeModal={() => {
                this.setState({ openPreviewModal: false });
              }}
          />
          <NewTagDocumentModal
              newTagRows={this.state.newTagRows}
              saveNewTag={this.saveNewTag}
              showNewTagModal={this.state.showNewTagModal}
              closeNewTagModal={this.closeNewTagModal}
              directInsertUpdate={true}
              directInsertUpdateFunc={this.directInsertUpdateFunc}
          />
          <EkoModal
              bsSize={"large"}
              dialogClassName={""}
              showModal={this.state.showModalSalesInvoiceCustomDeclaration}
              title="Gümrük Beyannamesi İşlemleri"
              onHide={() => {
                this.setState({showModalSalesInvoiceCustomDeclaration: false});
              }}>
            <SalesInvoiceCustomDeclaration
                sales_invoice_id={this.state.selectedSalesInvoiceId}
                closeModal={() => {
                  this.setState({showModalSalesInvoiceCustomDeclaration: false});
                }}
            />
          </EkoModal>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    salesInvoices: state.data.salesInvoices,
    viewOptions: state.data.viewOptions
  };
}

export default connect(mapStateToProps, actions)(SalesInvoice1);
