import React from "react";
import {
  EkoFormDate,
  EkoFormInputText,
  EkoFormSelect,
  EkoFormRadioButton,
} from "../../components/elements/EkoForm";
import Api from "../../util/Api";
import Util from "../../util/Util";
import HelperContainer from "../../util/HelperContainer";
import CompanyTypePermission from "../../services/CompanyTypePermission";
import AddCustomer from "../Customer/AddCustomer";
import AddSupplier from "../Supplier/AddSupplier";
import moment from "moment";
import EkoButtonOutline from "../../components/forms/EkoButtonOutline";
import AddDocumentButton from "../../components/forms/AddDocumentButton";
import UploadXMLButton from "../../components/forms/UploadXMLButton";
import { toastr } from "react-redux-toastr";
import EkoModal from "../../components/elements/EkoModal";
export default class AddSalesInvoiceHeader extends React.Component {
  constructor(props) {
    super(props);
    this.setReset = null;
    let self = this;
    this.state = {
      customerActiveTab: "address",
      showCustomer: false,
      showSupplier: false,
      detailIsOpened: false,
      gercekTuzel: false,
      saving: false,
      is_checked: "not_paid",
      formDataCustomer: AddCustomer.getDefaultForm(),
      formDataSupplier: AddSupplier.getDefaultForm(),
      fromWho: false,
      fromWhoId: 0,
      formData: {},
      defaultformData: {},
      fromWhoAddress: false,
      fromWhoAddress2: false,
      bankAccounts: [],
      docType: "",
      check1: false,
      check2: "",
      activeBuyOrSellTxt: 0,
      stock_status_warning: false,
      radioButtons: [
        {
          label: "Tahsil Edilecek",
          id: "is_net",
          radio_id: "not_paid",
          value: 1,
          checked: true,
        },
        {
          label: "Tahsil Edildi",
          id: "is_net",
          radio_id: "paid",
          value: 0,
        },
      ],
      radioButtonsSalesTypesSelectedVal:this.props.documentType === 1 || this.props.documentType === 4?0:3,
      radioButtonsSalesTypes: [
        { // 0:normal satış, 1:kur farkı, 2:fiyat farkı, 3:iade
          label: this.props.documentType === 1 || this.props.documentType === 4?"Normal Satış":"İade",
          id: "sales_types",
          radio_id: this.props.documentType === 1 || this.props.documentType === 4?"normal_satis":"iade",
          value: this.props.documentType === 1 || this.props.documentType === 4?0:3,
          checked: true
        },
        {
          label: "Kur Farkı",
          id: "sales_types",
          radio_id: "kur_farki",
          value: 1,
        },
        {
          label: "Fiyat Farkı",
          id: "sales_types",
          radio_id: "fiyat_farki",
          value: 2,
        },
      ],
      fields: [
        {
          title: "Kime",
          type: "select",
          id: "customer_id",
          defaultText: "SEÇİNİZ",
          optionValue: "name",
          data: [],
          value: this.props.fromWhoId || 0,

          onAddNewItemClick: this.onAddNewFromWhoClick.bind(this),
          optionId: "id",
          searchable: true,
          isVertical: true,
        },
        {
          title: "Döviz Tipi",
          type: "select",
          id: "currency",
          defaultText: "SEÇİNİZ",
          optionValue: "code",
          data: [],

          optionId: "code",
          isVertical: true,
        },

        {
          title: "Kur",
          type: "money",
          id: "currency_rate",
          isVertical: true,
          precision: 4,
        },
        {
          title: "Seri/Sıra No",
          type: "mask",
          maskChar: "",
          mask: "aaaaaa",
          colMd: 2,
          id: "legal_serial",
          placeHolder: "Seri",
        },
        {
          title: "/",
          type: "mask",
          maskChar: "",
          mask: "9999999999999999",
          labelMd: 1,
          colMd: 4,
          id: "legal_no",
          placeHolder: "Sıra No",
        },
        { title: "Fatura Tarihi", type: "date", id: "date" },
        { title: "Vade Tarihi", type: "date", id: "due_date" },
        { title: "Sevk Tarihi", type: "date", id: "shipping_date" },
        {
          title: "İrsaliye Bilgileri",
          text: "+",
          type: "button",
          id: "add_irsaliye",
        },
        {
          title: "Depo Seçin",
          type: "select",
          id: "warehouse_id",
          defaultText: "SEÇİNİZ",
          optionValue: "name",
          data: [],
          optionId: "id",
          isVertical:false,
          searchable: true
        },
        {
          title: "Stok Durum",
          type: "select",
          id: "stock_status",
          defaultText: "SEÇİNİZ",
          optionValue: "name",
          data: JSON.parse('[ { "id": 0, "name": "Stoktan Düş" },{ "id": 1, "name": "Stoktan Düşme" }]'),
          optionId: "id",
          isVertical:false
        },
      ],
      warehouses:[],
    };
  }

  componentWillMount() {
    let fields = this.state.fields;

    if (this.props.documentType === 1 || this.props.documentType === 4) {
      fields[0].id = "customer_id";
      this.setState({ docType: "customer", fields: fields });

      if (this.props.fromWhoId > 0) {
        this.setCustomer(this.props.fromWhoId, true);
      }
    } else {
      fields[0].id = "supplier_id";
      this.setState({ docType: "supplier", fields: fields });
      if (this.props.fromWhoId > 0) {
        this.setSupplier(this.props.fromWhoId, true);
      }
    }
  }

  isPermitted(fieldName) {
    let fieldCompanyTypes;

    if (
      this.props.viewOptions &&
      this.props.viewOptions.receipts.fieldCompanyTypes
    ) {
      fieldCompanyTypes = this.props.viewOptions.receipts.fieldCompanyTypes;
    }

    let finded = fieldCompanyTypes.filter((type) => type.id === fieldName);

    const returnValue = CompanyTypePermission.isPermitted(finded);

    return returnValue;
  }

  onSalesInvoiceChange(formData) {
    let stateFormData = { ...this.state.formData };
    let errors = this.state.errors;
    if (formData.items) {
      stateFormData.receipt = Object.assign(
        stateFormData.receipt,
        formData.items
      );
    }

    if (formData.rows) {
      stateFormData.rows = formData.rows;
      stateFormData = this.calculateTotal(stateFormData);
      if (formData.rows.length > errors.rows.length) {
        const defaultErrors = this.defaultErrors();
        while (errors.rows.length < formData.rows.length)
          errors.rows.push(defaultErrors.rows[0]);
      }
    }

    this.setState({ formData: stateFormData, errors: errors });
  }
  fetchWarehouses = async () => {
    let { data } = await Api.getAsync("/warehouses/showAll");
    return (data && data.items) || [];
  };
  componentWillReceiveProps(nextProps) {
    let fields = this.state.fields;
    let formData = nextProps.formData || this.defaultFormData();

    if (nextProps.paymentMethods) {
      fields[9]["data"] = nextProps.paymentMethods;
    }

    // if (formData.receipt.id) this.onReceiptChange({items: {payment_method: formData.receipt.payment_method}});
    if (formData.id){
      fields[10].disabled = true;
    }
    if (nextProps.fromWhoId && this.props.fromWhoId !== nextProps.fromWhoId) {
      if (nextProps.documentType === 1 || nextProps.documentType === 4) {
        this.setCustomer(nextProps.fromWhoId, false);
      } else this.setSupplier(nextProps.fromWhoId);
    }
    if (nextProps.fromWhos) {
      fields[0].data = nextProps.fromWhos;
    }

    if (nextProps.currencies) {
      fields[1].data = nextProps.currencies;
    }

    if(this.state.warehouses.length===0){
      this.fetchWarehouses().then((warehouses) => {
        this.setState({
          warehouses,
        });
        fields[9].data = warehouses;
      });
    }

    if (nextProps.formData.currency && nextProps.formData.currency === "TRY") {
      fields[2].disabled = true;
    }

    if (!nextProps.formData.currency) {
      fields[2].disabled = true;
      this.onChange("currency", "TRY");
    }

    if (this.props.formData.id !== undefined && this.props.formData.currency !== "TRY") {
      fields[1].disabled = true;
    }

    if (nextProps.calculate && !this.props.calculate) {
      if (
        (this.props.documentType === 1 || this.props.documentType === 4 && !nextProps.formData?.customer_id) ||
        (this.props.documentType !== 1 && this.props.documentType !== 4 && !nextProps.formData?.supplier_id)
      ) {
        const self = this;
        let data = null;
        if(this.props.documentType === 1 || this.props.documentType === 4){
         data = AddCustomer.getDefaultForm();
         const d = nextProps.formData?.supplierData || {};
         const lastname = d?.tckn ? d.name?.split(' ').pop() : "";
         const firstname = d?.tckn ? d.name?.replace(' '+lastname, '') : d.name;
         data.customer.firstname = firstname;
         data.customer.lastname = lastname;
         data.customer.tax_identity_number = d.tax_identity_number;
         data.customer.tax_office_code = d.tax_office_code;
         data.address.address = d.address;
         data.address.city_id = d.city_id;
         data.address.county_id = d.county_id;
        } else {
          data = AddSupplier.getDefaultForm();
          const d = nextProps.formData?.supplierData || {};
          const lastname = d.name?.split(' ').pop();
          const firstname = d.name?.replace(' '+lastname, '');
          data.supplier.firstname = firstname;
          data.supplier.lastname = lastname;
          data.supplier.tax_identity_number = d.tax_identity_number;
          data.supplier.tax_office_code = d.tax_office_code;
          data.address.address = d.address;
          data.address.city_id = d.city_id;
          data.address.county_id = d.county_id;
        }
        const customerOptions = {
          okText: "Evet",
          cancelText: "Hayır",
          onOk: () => self.onAddNewFromWhoClick(data),
          onCancel: () => null,
        };
        let customerText =
          this.props.documentType === 1 || this.props.documentType === 4
            ? "Bu müşteri kayıtlı değil kayıt etmek ister misiniz ?"
            : "Bu tedarikçi kayıtlı değil kayıt etmek ister misiniz ?";
        if(this.props.documentType === 1 && nextProps.formData?.customer_id===null){
          toastr.confirm(customerText, customerOptions);
        }else if(this.props.documentType === 4 && nextProps.formData?.supplier_id===null){
          toastr.confirm(customerText, customerOptions);
        }

      }
    }

    this.setState({ formData: formData, fields: fields });
  }

  onChange(field, value, passRateChange, next) {
    if (value === "not_paid") {
      this.setState({ check1: false, check2: true, is_checked: "not_paid" });
    }

    if (value === "paid") {
      let bankAccounts = this.props.bankAccounts;
      bankAccounts = bankAccounts.filter(account => account.currency === "TRY" || account.currency === this.props.formData.currency);
      this.setState({
        check1: true,
        check2: false,
        bankAccounts: bankAccounts,
        is_checked: "paid",
      });
    }
    if (value === "bank_list_change") {
      let bankAccounts = this.props.bankAccounts;
      if(bankAccounts){
        bankAccounts = bankAccounts.filter(account => account.currency === "TRY" || account.currency === this.props.formData.currency);
        this.setState({
          bankAccounts: bankAccounts,
        });
      }
    }
    if (field === "method_source_id") {
      this.getMethodBankAccount(value);
    }
    if (field === "stock_status") {
      this.openStockStatusWarningModal();
    }

    if (this.props.onChange && field !== "customer_id") {
      this.props.onChange("invoice", field, value);
    }

    if (!passRateChange && (field === "currency" || field === "date"))
      this.calculateCurrencyRate(field, value);

    if (field === "currency") {
      let fields = this.state.fields;
      if (value === "TRY") {
        fields[2].disabled = true;
      } else if (!passRateChange) fields[2].disabled = false;
      else fields[1].value = value;
      this.setState({ fields: fields });
    }

    if (field === "date") {
      this.setState({ formData: { date: value } }, () => {
        if (value) this.calculateDueDate();
      });
    }

    if (field === "customer_id") {
      //this.fetchAddresses(value);
      this.setState({ fromWhoId: value });
      this.setCustomer(value, false);
    }

    if (field === "supplier_id") {
      //this.fetchAddresses(value);
      this.setState({ fromWhoId: value });
      this.setSupplier(value);
    }

    if (field === "radioButtonsSalesTypes" && value==='kur_farki') {
      //@oguz kur farkı radio seçilirse currency i TRY ye çeviriyoruz
      this.state.formData.currency='TRY';
      this.onChange("currency_rate", "1,0000");
    }else if (field === "radioButtonsSalesTypes" && value!=='kur_farki' && this.state.radioButtonsSalesTypesSelectedVal===1) {
      this.setCustomer(this.state.fromWhoId, false);
    }
    if (field === "radioButtonsSalesTypes") {
      //0:normal satış, 1:kur farkı, 2:fiyat farkı, 3:iade
      var val = 0;
      if(value==='normal_satis'){ val=0; }
      else if(value==='kur_farki'){ val=1; }
      else if(value==='fiyat_farki'){ val=2; }
      else if(value==='iade'){ val=3; }
      this.setState({radioButtonsSalesTypesSelectedVal:val});
    }
  }

  getMethodBankAccount(bank_id) {
    if (Util.isUndefined(this.props.bankAccounts)) {
      return;
    }

    Api.get("/bankAccount/" + bank_id, (response, err) => {
      let method = response.bankAccount.bank_account_type;
      let bank_currency = response.bankAccount.currency;

      /*
collection ve payment'a yanlış method gönderiyordu o yüzden kaldırdım. @Gökberk
      if (method === 2) {
        method = 3;
      } else if (method === 3 || method === 4) {
        method = 2;
      }
*/

      this.props.bankCurrencyAndMethod(bank_currency, method);
    });
  }

  onAddNewFromWhoClick(data) {
    if (this.props.fromWhos.length === 0) {
      toastr.warning("İşlem", "Lütfen Bekleyin Müşteri Listesi Yükleniyor");
      setTimeout(() => {
        this.executeDocumentLogic(data);
      }, 5000);
    } else {
      this.executeDocumentLogic(data);
    }
  }

  executeDocumentLogic(data) {
    if (this.props.documentType === 1 || this.props.documentType === 4) {
      this.setState({
        showCustomer: true,
        formDataCustomer: data || AddCustomer.getDefaultForm(),
        detailIsOpened: data ? true : false,
        gercekTuzel: data?.tckn ? true : false,
      });
    } else {
      this.setState({
        showSupplier: true,
        formDataSupplier: data || AddSupplier.getDefaultForm(),
        detailIsOpened: data ? true : false,
        gercekTuzel: data?.tckn ? true : false,
      });
    }
  }


  fetchAddresses(id) {
    const url =
      this.props.documentType === 1 || this.props.documentType === 4
        ? "/customerAddress/showAll/" + id
        : "/supplierAddress/showAll/" + id;
    Api.get(url, function (response, err) {
      // console.log(response);
    });
  }

  calculateDueDate = () => {
    let self = this;
    let fromWhoId =
      this.props.formData.customer_id || this.props.formData.supplier_id;
    if (!fromWhoId) return;
    Api.get(
      "/calculation/invoiceDueDate?id=" +
        fromWhoId +
        "&date=" +
        moment(this.props.formData.date).format("YYYY-MM-DD") +
        "&type=" +
        this.state.docType,
      function (response, err) {
        if (!err) {
          var parsedDate = moment(response.response, "DD-MM-YYYY");
          if (parsedDate.isValid()) {
            const dateFromBackend = moment(
                response.response,
                "DD-MM-YYYY"
            ).format("YYYY-MM-DD");
            self.onChange("due_date", dateFromBackend);
          } else {
            if(response.response!==null){toastr.error("Hata", "Vade tarihi hesaplanamadı. Girilen Tarih: "+response.response);}
          }
        }
      }
    );
  };
  clickBuyorSell = (date,currency,sellOrBuy) => {
    var url = "/exchangeRate/" + currency + "/" + date;
    Api.get(url, (response, error) => {
      if(response.response && response.response.length!=0){
        if(sellOrBuy=='buy'){
          this.setState({activeBuyOrSellTxt:1})
          this.onChange(
              "currency_rate",
              Util.convertDecimalToString(response.response.buying_forex, 4)
          )
        }else if(sellOrBuy=='sell'){
          this.setState({activeBuyOrSellTxt:2})
          this.onChange(
              "currency_rate",
              Util.convertDecimalToString(response.response.selling_forex, 4)
          )
        }else{
          this.setState({activeBuyOrSellTxt:0})
        }
      }
    })
  }
  calculateCurrencyRate(field, value) {
    const self = this;

    if (value === "TRY") {
      setTimeout(function () {
        self.onChange("currency_rate", Util.convertDecimalToString(1, 4));
      }, 1000);
      return;
    }

    if (this.props.formData.currency === "TRY" && field === "date") {
    } else {
      let currency = this.props.formData.currency;

      let date = this.props.formData.date;

      if (field === "currency") {
        currency = value;
      }

      if (field === "date") date = value;
      if (currency) {
        Api.getCurrencyRate(
          currency,
          moment(date).format("YYYY-MM-DD"),
          function (rate, err) {
            if (rate) {
              self.onChange(
                "currency_rate",
                Util.convertDecimalToString(rate, 4)
              );
            }
          }
        );
      }
    }
  }
  getParamFromUrl(paramName) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(paramName);
  }
  setCustomer(id, passCustomerCurrencyCheck = true) {
    if (id == "0") {
      return;
    }
    localStorage.setItem("sales_invoices_page_customer_id", id);
    var self = this;

    Api.paralel(
      [
        "/customer/" + id,
        "/customerContact/showAll/" + id,
        "/customerAddress/showAll/" + id,
      ],
      (responses) => {
        responses[0].customer.maximum_debt_amount = parseFloat(responses[0].customer.maximum_debt_amount);
        responses[0].customer.balance = parseFloat(responses[0].customer.balance);
        if(
            (responses[0].customer.maximum_debt_amount!==0 && responses[0].customer.maximum_debt_amount!=="0.00") &&
            (responses[0].customer.balance!==0 && responses[0].customer.balance!=="0.00")
        ){
          if(responses[0].customer.balance > responses[0].customer.maximum_debt_amount){
            toastr.error("Dikkat", "Müşteri borç limitini aşmıştır.");
          }
        }
        let formData = {};
        formData.customer = HelperContainer.filterObject(responses[0].customer);
        this.props.onChange("invoice", "customer_id", id);
        this.props.onChange("invoice", "customer_data", responses[0].customer);

         if (!passCustomerCurrencyCheck) {
        if(this.state.radioButtonsSalesTypesSelectedVal===1){
          self.onChange("radioButtonsSalesTypes", 'kur_farki');
          this.onChange("currency_rate", "1,0000",false);
          const fields = self.state.fields;
          fields[1].disabled = true;
        }else{
          if (formData.customer.currency) {
            const fields = self.state.fields;
            if (formData.customer.currency !== "TRY") {
              fields[1].disabled = true;
              fields[2].disabled = false;
            }else{
              fields[1].disabled = false;
              fields[2].disabled = false;
            }
            if(this.props.formData.id !== undefined){
              self.onChange("currency", this.state.formData.currency, true);
              self.onChange("currency_rate", this.state.formData.currency_rate);
              self.calculateCurrencyRate("currency", this.state.formData.currency);
            }else{
              const proforma = self.getParamFromUrl('proforma');
              if(proforma){
                window.setTimeout(function () {
                  self.onChange("currency_rate", self.state.formData.currency_rate_proforma);
                }, 2000);
              }else{
                self.onChange("currency", formData.customer.currency, true);
                self.onChange("currency_rate", 1);
                self.calculateCurrencyRate("currency", formData.customer.currency);
              }
            }
            self.setState({ fields });
            this.calculateDueDate();
          } else {
            const fields = self.state.fields;
            fields[1].disabled = false;
            self.setState({ fields });
            self.onChange("currency", formData.customer.currency, true);
            self.onChange("currency_rate", 1);
          }
          }
         }

        if (
          responses[1].customerContacts &&
          responses[1].customerContacts.length > 0
        )
          formData.contact = HelperContainer.filterObject(
            responses[1].customerContacts[0]
          );
        else formData.contact = {};

        if (
          responses[2].customerAddresses &&
          responses[2].customerAddresses.length > 0
        ) {
          const address = responses[2].customerAddresses.filter(function (a) {
            return a.address_type === 2;
          });
          formData.address = HelperContainer.filterObject(address[0]);
          if (!formData.customer.delivery_same_as_invoice_address) {
            const addressDelivery = responses[2].customerAddresses.filter(
              function (a) {
                return a.address_type === 1;
              }
            );
            formData.deliveryAddress = HelperContainer.filterObject(
              addressDelivery[0]
            );
          } else formData.deliveryAddress = formData.address;
        } else {
          formData.deliveryAddress = {};
          formData.address = {};
        }

        let fromWhoAddress = self.state.fromWhoAddress;
        if (formData.address && formData.address.address) {
          formData.address.is_abroad = !formData.address.city;
          if (formData.address.city)
            fromWhoAddress =
              formData.address.address +
              "\n" +
              formData.address.county.name +
              "\n" +
              formData.address.city.name;
          else fromWhoAddress = formData.address.address;
        } else fromWhoAddress = false;

        let fromWhoAddress2 = self.state.fromWhoAddress2;
        if (formData.deliveryAddress && formData.deliveryAddress.address) {
          formData.deliveryAddress.is_abroad = !formData.deliveryAddress.city;
          if (formData.deliveryAddress.city)
            fromWhoAddress2 =
              formData.deliveryAddress.address +
              "\n" +
              formData.deliveryAddress.county.name +
              "\n" +
              formData.deliveryAddress.city.name;
          else fromWhoAddress2 = formData.deliveryAddress.address;
        } else fromWhoAddress2 = false;

        self.setState({
          formDataCustomer: formData,
          fromWhoAddress: fromWhoAddress,
          fromWhoAddress2: fromWhoAddress2,
        });
        self.onChange("bank_list_change", "bank_list_change");
        self.props.onChangeSet("invoice", {
          address: fromWhoAddress,
          address2: fromWhoAddress2,
        });
      }
    );
  }

  setSupplier(id, passCurrencyCheck = true) {
    var self = this;
    Api.paralel(
      [
        "/supplier/" + id,
        "/supplierContact/showAll/" + id,
        "/supplierAddress/showAll/" + id,
      ],
      function (responses) {
        let formData = {};
        formData.supplier = HelperContainer.filterObject(responses[0].supplier);

        if (formData.supplier.currency !== "TRY") {
          const fields = self.state.fields;
          fields[1].disabled = true;
          fields[2].disabled = false;
          self.onChange("currency", formData.supplier.currency, true);
          self.onChange("currency_rate", 1);
          self.calculateCurrencyRate("currency", formData.supplier.currency);

          setTimeout(function () {
            self.setState({ fields });
            this.calculateDueDate();
          }, 250);
        } else {
          const fields = self.state.fields;
          fields[1].disabled = false;
          self.setState({ fields }, () => {
            self.onChange("currency", formData.supplier.currency, true);
            self.onChange("currency_rate", 1);
          });
        }

        if (
          responses[1].supplierContacts &&
          responses[1].supplierContacts.length > 0
        )
          formData.contact = HelperContainer.filterObject(
            responses[1].supplierContacts[0]
          );
        else formData.contact = {};

        if (
          responses[2].supplierAddresses &&
          responses[2].supplierAddresses.length > 0
        ) {
          const address = responses[2].supplierAddresses.filter(function (a) {
            return a.address_type === 2;
          });
          formData.address = HelperContainer.filterObject(address[0]);
        } else {
          formData.address = {};
        }

        let fromWhoAddress = self.state.fromWhoAddress;
        if (formData.address && formData.address.address) {
          formData.address.is_abroad = !formData.address.city;
          if (formData.address.city)
            fromWhoAddress =
              formData.address.address +
              "\n" +
              formData.address.county.name +
              "\n" +
              formData.address.city.name;
          else fromWhoAddress = formData.address.address;
        } else fromWhoAddress = false;

        self.setState({
          formDataSupplier: formData,
          fromWhoAddress: fromWhoAddress,
          fromWhoAddress2: false,
        });

        self.onChange("address", fromWhoAddress);
        self.onChange("bank_list_change", "bank_list_change");
      }
    );
  }

  onMount() {}

  onPress = (field) => {
    if (field.id === "add_irsaliye") {
      return this.props.addIrsaliye;
    }
  };
  openStockStatusWarningModal = () => {
    this.setState({ stock_status_warning: true });
  };
  closeStockStatusWarningModal() {
    this.setState({ stock_status_warning: false });
  }
  getInputByType(field) {
    const inputProps = {
      onChange: this.onChange.bind(this),
      onMount: this.onMount.bind(this),
      formData: this.props.formData,
      errors: this.props.errors,
    };
    let rElement = null;
    switch (field.type) {
      case "button":
        rElement = (
          <div
            className={"form-group"}
            style={{
              marginTop: 2,
            }}
          >
            <div className={"col-md-4 control-label"}>{field.title}</div>
            <div className={"col-md-7"}>
              <EkoButtonOutline
                buttonProps={{ style: { width: "100%" } }}
                onClick={this.onPress(field)}
                label={this.irsaliyeLabel}
              />
            </div>
          </div>
        );

        break;
      case "text":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={false}
            type="text"
            labelMd={4}
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
      case "number":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={field.disabled}
            type="number"
            labelMd={4}
            precision={field.precision}
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
      case "money":
        rElement = (
          <div><EkoFormInputText
              {...inputProps}
              id={field.id}
              disabled={field.disabled}
              precision={field.precision}
              type="money"
              isVertical={field.isVertical}
              label={field.title}
          />
            {field.id ==='currency_rate' ? (
                <div className="row">
                  <div className="col-md-2"></div>
                  <div style={{"color":this.state.activeBuyOrSellTxt==1?"#4a8cc3":"black","cursor":"pointer"}} className="col-md-4" onClick={this.clickBuyorSell.bind(this,this.state.formData.date, this.state.formData.currency,'buy')}>Alış</div>
                  <div style={{"color":this.state.activeBuyOrSellTxt==2?"#4a8cc3":"black","cursor":"pointer"}} className="col-md-4 text-right" onClick={this.clickBuyorSell.bind(this,this.state.formData.date, this.state.formData.currency,'sell')}>Satış</div>
                </div>
            ):("")}
          </div>
        );
        break;
      case "mask":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={field.disabled}
            maskChar={field.maskChar}
            mask={field.mask}
            precision={field.precision}
            isVertical={field.isVertical}
            labelMd={field.labelMd || 4}
            colMd={field.colMd || 7}
            label={field.title}
            type="mask"
            placeHolder={field.placeHolder}
            tooltip={true}
          />
        );
        break;
      case "date":
        rElement = (
          <EkoFormDate
            {...inputProps}
            id={field.id}
            dateFormat="DD-MM-YYYY"
            clearable={true}
            labelMd={4}
            colMd={7}
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
      case "textarea":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            disabled={field.disabled}
            type="textarea"
            isVertical={field.isVertical}
            label={field.title}
          />
        );
        break;
      case "select":
        rElement = (
          <EkoFormSelect
            {...inputProps}
            onAddNewItemClick={field.onAddNewItemClick}
            disabled={field.disabled}
            searchable={field.searchable}
            id={field.id}
            isVertical={field.isVertical}
            defaultText={field.defaultText}
            optionId={field.optionId}
            options={field.data}
            optionValue={field.optionValue}
            label={field.title}
            labelMd={4}
            colMd={7}
          />
        );
        break;
      default:
    }
    return rElement;
  }

  onCustomerSave(id) {
    this.setState(
      {
        showCustomer: false,
        fromWhoAddress: false,
        fromWhoAddress2: false,
        formData: {
          ...this.state.formData,
          customer_id: null,
          fromWhoId: null,
        },
      },
      () => {
        if (this.props.onRefresh) {
          this.props.onRefresh();
        }

        setTimeout(() => this.onChange("customer_id", id), 500);
      }
    );
  }

  onSupplierSave(id) {
    this.setState({ showSupplier: false });
    this.setSupplier(this.state.fromWhoId);
    if (this.props.onRefresh) this.props.onRefresh();
    this.onChange("supplier_id", id);
  }

  openAddressForCustomer(type) {
    const self = this;
    this.setState({ customerActiveTab: type }, function () {
      self.setState({ showCustomer: true });
    });
  }

  filterOnlyPermittedFields() {
    const filtered = this.state.fields.filter((field) => {
      return this.isPermitted(field.id);
    });

    if (CompanyTypePermission.isSelfEmployment()) {
      filtered.push({});
    }

    return filtered;
  }

  render() {
    this.irsaliyeLabel =
      this.props.irsaliyeRows && this.props.irsaliyeRows[0].irsaliye_no
        ? this.props.irsaliyeRows.length + " ADET"
        : "Ekle";
    const inputProps = {
      onChange: this.onChange.bind(this),
      onMount: this.onMount.bind(this),
      formData: this.props.formData,
      errors: this.props.errors,
    };

    const fields = this.state.fields;
    let addAddress = "";
    let addAddress2 = "";
    let action = "Ekle";
    let action2 = "Ekle";
    let addressError = "";
    let addressError2 = "";
    if (this.props.errors.address) addressError = this.props.errors.address;
    if (this.props.errors.address2) addressError2 = this.props.errors.address2;
    if (this.props.formData.customer_id || this.props.formData.supplier_id) {
      if (this.state.fromWhoAddress) action = "Düzenle";
      if (this.state.fromWhoAddress2) action2 = "Düzenle";
      if (this.props.documentType === 2)
        addAddress = (
          <a
            onClick={() => {
              this.setState({ showSupplier: true });
            }}
          >
            Adres {action}&nbsp;&nbsp;&nbsp;
            <i className="fa fa-edit" aria-hidden="true" />
          </a>
        );
      else
        addAddress = (
          <a onClick={this.openAddressForCustomer.bind(this, "address")}>
            Adres {action}&nbsp;&nbsp;&nbsp;
            <i className="fa fa-edit" aria-hidden="true" />
          </a>
        );
      if (this.props.documentType === 1 || this.props.documentType === 4)
        addAddress2 = (
          <a
            onClick={this.openAddressForCustomer.bind(this, "deliveryAddress")}
          >
            Teslimat Adresi {action2}&nbsp;&nbsp;&nbsp;
            <i className="fa fa-edit" aria-hidden="true" />
          </a>
        );
    }

    return (
      <div className="portlet light" style={{ paddingBottom: 230 }}>
        <div
          className="portlet-title"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className="caption font-dark">
            <span className="caption-subject bold uppercase">
              {this.props.title}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div style={{display: this.props.documentType === 4 ? "none" : ""}}>
              <div style={{
                paddingBottom: 25,
                paddingTop: 10,
                display: this.props.formData.id === undefined ? "" : "none"
              }}>
                <EkoFormRadioButton
                    {...inputProps}
                    id="radioButtonsSalesTypes"
                    buttons={this.state.radioButtonsSalesTypes}
                    onFalse={this.onFalse}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  flex: "auto",
                }}
            >
              <AddDocumentButton
                  style={{margin: 0, transform: "translateY(-25%)"}}
              />
              <UploadXMLButton
                  style={{ margin: "0 0 0 10px", transform: "translateY(-25%)" }}
                  callBack={this.props?.callBackXMLData}
                  paramType={this.props.paramType}
              />
            </div>
          </div>
        </div>
        <div className="portlet-body">
          <div className="alldiv">
            <div className="col-md-3">
              <div className="row">
                <div className="col-md-12">
                  {this.getInputByType(fields[0])}
                </div>
                <div className="col-md-6">{this.getInputByType(fields[1])}</div>
                <div className="col-md-6">{this.getInputByType(fields[2])}</div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label control-line">
                    Fatura Adresi
                  </label>
                  {this.state.fromWhoAddress ? (
                    <div className="adress-area">
                      {this.props.saved === false
                        ? this.state.fromWhoAddress
                        : ""}{" "}
                      {this.props.saved === false ? addAddress : ""}
                    </div>
                  ) : (
                    <div className="adress-area">
                      {this.props.saved === false ? addAddress : ""}
                    </div>
                  )}
                  <label
                    style={{ color: "red" }}
                    className="control-label control-line"
                  >
                    {addressError}
                  </label>
                </div>
              </div>
              {this.props.documentType === 1 || this.props.documentType === 4 ? (
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label control-line">
                      Teslimat Adresi
                    </label>
                    {this.state.fromWhoAddress2 ? (
                      <div className="adress-area">
                        {this.props.saved === false
                          ? this.state.fromWhoAddress2
                          : ""}{" "}
                        {this.props.saved === false ? addAddress2 : ""}
                      </div>
                    ) : (
                      <div className="adress-area">
                        {this.props.saved === false ? addAddress2 : ""}
                      </div>
                    )}{" "}
                    <label
                      style={{ color: "red" }}
                      className="control-label control-line"
                    >
                      {addressError2}
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-4">
              <div className="heighte row">
                <div>
                  {this.getInputByType(fields[3])}
                  {this.getInputByType(fields[4])}
                </div>
              </div>
              <div className="heighte row">
                {this.getInputByType(fields[5])}
              </div>
              <div className="heighte row">
                {this.getInputByType(fields[6])}
              </div>
              <div className="heighte row">
                {this.getInputByType(fields[7])}
              </div>
              <div className="heighte row">
                {this.getInputByType(fields[8])}
              </div>
              <div className="heighte row" style={{"display":Util.companyModules(2)?"":"none"}}>
                {this.getInputByType(fields[9])}
              </div>
              <div className="heighte row" style={{"display":Util.companyModules(2)?"":"none"}}>
                {this.getInputByType(fields[10])}
              </div>
            </div>
          </div>
          <div className="clearfix" />

          <div style={{ marginTop: 30 }}>{this.props.children}</div>
          <div className="col-md-6" style={{ marginTop: -45 }}>
            <div className="row">
              {(this.props.documentType === 1 || this.props.documentType === 4) &&
              this.props.formData.id === undefined ? (
                <EkoFormRadioButton
                  {...inputProps}
                  id="radioButtons"
                  buttons={this.state.radioButtons}
                  onFalse={this.onFalse}
                />
              ) : (
                ""
              )}

              {this.state.check1 === true && (this.props.documentType === 1 || this.props.documentType === 4) ? (
                <div>
                  <div className="col-md-6 dropdownTop">
                    <EkoFormSelect
                      {...inputProps}
                      id={"method_source_id"}
                      defaultText={"Seçiniz"}
                      isVertical={true}
                      optionId={"id"}
                      searchable={true}
                      options={this.state.bankAccounts}
                      optionValue={"name"}
                      label={"İlişkili Hesap"}
                    />
                  </div>

                  <div className="col-md-6">
                    <EkoFormDate
                      {...inputProps}
                      id={"date1"}
                      dateFormat="DD-MM-YYYY"
                      isVertical={true}
                      calendarPlacement={"top"}
                      clearable
                      label={"Tahsilat Tarihi"}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <AddSupplier
          activeTab="address"
          showModal={this.state.showSupplier}
          formData={this.state.formDataSupplier}
          detailIsOpened={this.state.detailIsOpened}
          gercekTuzel={this.state.gercekTuzel}
          onSave={this.onSupplierSave.bind(this)}
          onCancel={() => {
            this.setState({ showSupplier: false });
          }}
          erors={{}}
        />
        <AddCustomer
          activeTab={this.state.customerActiveTab}
          showModal={this.state.showCustomer}
          detailIsOpened={this.state.detailIsOpened}
          gercekTuzel={this.state.gercekTuzel}
          formData={this.state.formDataCustomer}
          onSave={this.onCustomerSave.bind(this)}
          onCancel={() => {
            this.setState({ showCustomer: false });
          }}
          errors={{}}
        />
        <EkoModal
            showModal={this.state.stock_status_warning}
            onHide={this.closeStockStatusWarningModal.bind(this)}
            title={"STOK DURUM UYARISI"}
        >
          <p>Bu ürünleri sevk irsaliyesi ile stoklarınızdan düştüyseniz, STOKTAN DÜŞME'yi seçiniz. Aksi takdirde STOKTAN DÜŞ'ü seçiniz</p>
        </EkoModal>
      </div>
    );
  }
}
