import moment from "moment";
import {store} from "../reducers/store";
import {AUTH_NO, AUTH_YES} from "../actions/types";
import axios from "axios";

class LoginHelper{
    async logout(){
        let token = localStorage.getItem('token')

        return axios.post("/auth/logout", {}, {
            'Authorization':  `Bearer ${token}`
        })
    }

    checkLogin() {
        const token = localStorage.getItem('token');
        let lastActivity = localStorage.getItem('lastActivity')


        if (token && lastActivity /*&& lastLogin*/) {
            lastActivity = moment(lastActivity)
            const diffActivity = moment().diff(lastActivity, 'minutes')
            if (diffActivity < 600) {
                store.dispatch({type: AUTH_YES});
            } else {

                localStorage.clear();

                store.dispatch({type: AUTH_NO});
            }

        }
    }
}


export default new LoginHelper()