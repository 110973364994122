import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import { EkoForm, EkoFormButton } from "../../components/elements/EkoForm";
import { Modal } from "react-bootstrap";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Api from "../../util/Api";

class ImportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadLoading: false,
      uploadLoading: false,
      errMessages: [],
      youtubeLink: "",
      balanceCheckbox: false
    };
  }

  closeModal() {
    this.setState({
      downloadLoading: false,
      uploadLoading: false,
      errMessages: []
    });
    this.props.closeModal(false);
  }

  youtubeLink() {
    const self = this;
    const dtConfig = this.props.dtConfig;

    if (dtConfig.name === "supplier") {
      self.setState({ youtubeLink: "https://youtu.be/UUiNilEc_LQ" });
    } else if (dtConfig.name === "customer") {
      self.setState({ youtubeLink: "https://youtu.be/UUiNilEc_LQ" });
    } else if (dtConfig.name === "item") {
      self.setState({ youtubeLink: "https://youtu.be/spol5i_hmAs" });
    }
  }

  onExportClicked() {
    const self = this;
    const dtConfig = this.props.dtConfig;
    self.setState({ downloadLoading: true });
    Api.postArrayBuffer(
      "/" + dtConfig.name + "/excel/",
      { type: "template" },
      (response, err) => {
        if (response) {
          // const reader = new FileReader();
          let blob = new Blob([response], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
          // reader.readAsDataURL(blob);
          let downloadUrl = URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = downloadUrl;
          a.download = dtConfig.page_title + " Şablon.xlsx";
          document.body.appendChild(a);
          a.click();
          self.setState({ downloadLoading: false });
        } else {
          let decodedString = String.fromCharCode.apply(
            null,
            new Uint8Array(err)
          );
          let obj = JSON.parse(decodedString);
          self.setState({ downloadLoading: false });
          toastr.error("Hata", obj.message);
        }
      }
    );
  }

  onImportClicked(e) {
    e.preventDefault();
    const dtConfig = this.props.dtConfig;
    const self = this;
    self.setState({ uploadLoading: true });
    let reader = new FileReader();
    let target = e.target;
    let file = target.files[0];
    var form = new FormData();
    form.append("excel", file);
    form.append("type", "import");
    form.append("balanceCheckbox", this.state.balanceCheckbox);
    if (this.props.importId) form.append("id", this.props.importId);
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      self.setState({ uploadLoading: false });
      return toastr.error("Hata", "Lütfen geçerli bir excel dosyası seçiniz!");
    }
    reader.onloadend = () => {
      self.setState({ uploadImage: true });
      Api.post("/" + dtConfig.name + "/excel", form, (response, err) => {
        if (response) {
          if (self.props.onFetch) self.props.onFetch(); //listeyi yenile
          toastr.success("Başarılı", response.message);
          self.closeModal();
        } else {
          if (err.message) toastr.error("Hata", err.message);
          self.setState({
            uploadLoading: false,
            errMessages: err.errors || []
          });
          target.value = null;
        }
      });
    };
    reader.readAsDataURL(file);
  }
  checkboxChange = (status) => {
    this.setState({ balanceCheckbox: !this.state.balanceCheckbox });
  }
  render() {
    const dtConfig = this.props.dtConfig;
    return (
      <EkoModal
        showModal={this.props.showModal}
        title={"İçeri Aktarma"}
        onHideDisable={this.state.uploadLoading}
        onHide={this.closeModal.bind(this)}
        spinner={this.state.uploadLoading}
      >
        <EkoForm>
          <br />
          <div className="row">
            {this.state.errMessages.length !== 0 ? (
              <div className="alert alert-danger">
                <i
                  style={{ cursor: "pointer", marginTop: "-8px" }}
                  onClick={() => this.setState({ errMessages: [] })}
                  className="pull-right fa fa-times"
                />
                <span
                  style={{
                    textAlign: "center",
                    display: "block",
                    fontWeight: "bold"
                  }}
                >
                  Lütfen Kontrol Ediniz!!
                </span>
                <ul
                  style={{
                    marginLeft: -25,
                    lineHeight: 1.5,
                    paddingTop: 5,
                    maxHeight: "350px",
                    overflow: "auto"
                  }}
                >
                  {this.state.errMessages.map((a, i) => {
                    return (
                      <li style={{ color: "red" }} key={i}>
                        {a}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              ""
            )}

            <div className="col-md-8 col-md-push-2">
              <span>1. Sizin için hazırladığımız excel şablonunu indirin.</span>
              <br />
              <span>
                2. Bilgilerinizi şablon içerisindeki alanlara aktarın.
              </span>
              <br />
              <span>3. Şablonu kaydedip buraya tekrar yükleyin.</span>
              <br />
              <br />
              <a
                className="youtubeLink"
                href={this.state.youtubeLink}
                onClick={this.youtubeLink.bind(this)}
                target="_blank"
              >
                Nasıl yapılacağını gösteren videoyu izle
              </a>
            </div>
          </div>
          {dtConfig.name === "supplier" || dtConfig.name === "customer" ? (
              <div style={{"textAlign":"center","marginTop":"15px"}}>
                <input
                    type="checkbox"
                    checked={this.state.balanceCheckbox}
                    onChange={() => {
                      this.checkboxChange();
                    }}
                /> Güncelleme Kayıtlarında Açılış Bakiyesinin de Güncellenmesini İstiyorum
              </div>
          ) : ""}
          <Modal.Footer>
            <EkoFormButton
                label={"Excel Şablonu İndir"}
                faClass="fa fa-download"
                isLoading={this.state.downloadLoading}
                disabled={this.state.downloadLoading || this.state.uploadLoading}
                onClick={this.onExportClicked.bind(this)}
            />
            <label
                style={{ marginLeft: "0px" }}
              className="btn btn-default blue"
            >
              <input
                type="file"
                required
                style={{ position: "fixed", top: "-1000px" }}
                onChange={e => this.onImportClicked(e)}
                disabled={this.state.uploadLoading}
              />
              {this.state.uploadLoading ? (
                <i className={"fa fa-spinner fa-pulse fa-1x fa-fw"} />
              ) : (
                <span>
                  <i className={"fa fa-upload"} /> Şablonu Geri Yükle
                </span>
              )}
            </label>
            <EkoFormButton
              label="İptal"
              className="btn"
              disabled={this.state.downloadLoading || this.state.uploadLoading}
              onClick={this.closeModal.bind(this)}
            />
          </Modal.Footer>
        </EkoForm>
      </EkoModal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(ImportModal);
