import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoDataTable from "../../components/elements/EkoDataTable";

import AddCheque from "./AddCheque";
import { toastr } from "react-redux-toastr";
import HelperContainer from "../../util/HelperContainer";
import Util from "../../util/Util";
import Api from "../../util/Api";
//import EkoToastrError from '../../components/elements/EkoToastrError'
import { FETCH_CHEQUES } from "../../actions/types";
import moment from "moment";

class Cheques extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permAdd: true,
      selectedRow: {},
      showInsertModal: false,
      isGiro: false,
      isOut: 0,
      isCollection: 0,
      errors: {},
      validationFields: [],
      cheques: [] //table Item
    };
  }

  fetchCheques() {
    if (this.table) this.table.cleanSelect();
    this.props.fetchData("/cheque/list", FETCH_CHEQUES);
  }

  componentWillMount() {
    this.fetchCheques();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cheques) {
      this.setState({ cheques: nextProps.cheques });
    }
    const {
      router: {
        location: { query }
      }
    } = this.props;
    if (query.frame_in_modal) {
      const chequeFound = nextProps.cheques.find(cheque =>
          cheque.id.toString() === query.frame_in_modal
      );
      this.openUpdate(chequeFound);
    }
  }

  getCheque(id, isGiro, isOut, isCollection) {
    const self = this;
    Api.get("/cheque/" + id, function(response) {
      let formData = {};
      if (response.cheque) {
        formData.cheque = HelperContainer.filterObject(response.cheque);
        try {
          formData.cheque.amount = Util.convertDecimalToMoney(
            formData.cheque.amount
          );
          formData.cheque.commission = Util.convertDecimalToMoney(
            formData.cheque.commission
          );
          formData.cheque.currency_rate = formData.cheque.currency_rate
            .toString()
            .replace(/[#.]/g, ",");
          formData.cheque.due_date = Util.convertFromDatabaseDate(
            formData.cheque.due_date
          );
          formData.cheque.transaction_date = Util.convertFromDatabaseDate(
            formData.cheque.transaction_date
          );
          if (isGiro) {
            formData.cheque.status_cid = 2;
            formData.cheque.in_out = 2;
            formData.cheque.transaction_date = moment().format('YYYY-MM-DD')
          } else if (isCollection === 1) {
            if (formData.cheque.to_id) formData.cheque.status_cid = 9;
            else formData.cheque.status_cid = 8;
          }
          console.log(this.response.cheque);
        } catch (ex) {
          console.log(ex);
        }
      }
      self.setState({
        showInsertModal: true,
        errors: {},
        formData: formData,
        isOut: isOut,
        isCollection: isCollection,
        isGiro: isGiro
      });
    });
  }

  openGiro(selectedRow) {
    const id = selectedRow.id;
    this.getCheque(id, true, 0, 0);
  }

  printShow = row => {
    window.open(
      process.env.REACT_APP_API_URL +
        "/cheque/print/" +
        row.id +
        "?token=" +
        localStorage.getItem("token"),
      "_blank"
    );
  };

  openCollection(selectedRow) {
    const id = selectedRow.id;
    let isOut = 0;
    if (selectedRow.from_id && selectedRow.to_id) isOut = 0;
    else if (selectedRow.from_id) isOut = 1;
    else if (selectedRow.to_id) isOut = 2;
    this.getCheque(id, false, isOut, 1);
  }

  openUpdate(selectedRow) {
    const id = selectedRow.id;
    let isOut = 0;
    if (selectedRow.from_id && selectedRow.to_id) isOut = 0;
    else if (selectedRow.from_id) isOut = 1;
    else if (selectedRow.to_id) isOut = 2;
    this.getCheque(id, false, isOut, 0);
  }

  openNewCollection(selectedRow) {
    this.openCollection(selectedRow);
  }

  giro(selectedRow) {
    this.openGiro(selectedRow);
  }
  backGiro(selectedRow) {
    var self = this;
    toastr.confirm("Cirolama işlemini geri almak istediğinize eminmisiniz?", {
      onOk: () => {
        Api.get("/cheque/backGiro/" + selectedRow.id, function(response, err) {
            if (response) {
                self.fetchCheques();
                toastr.success("Başarılı", "İşlem başarılı");
            } else toastr.error("Hata", err.message);
        });
      },
      onCancel: () => { },
      okText: "Evet",
      cancelText: "Hayır"
    });
  }

  openReceipt(selectedRow) {
    this.printShow(selectedRow);
  }

  onDelete(selectedRow) {
    const self = this;
    Api.delete("/cheque/" + selectedRow.id, function(response, err) {
      if (response) {
        self.fetchCheques();
        toastr.success("Başarılı", "Başarıyla Silindi");
      } else toastr.error("Hata", err.message);
    });
  }

  openInsert(menuItem) {
    this.setState({
      showInsertModal: true,
      formData: false,
      isGiro: false,
      isCollection: 0,
      isOut: menuItem
    });
  }

  onSave(formData, formFields) {
    this.fetchCheques();
    this.setState({ showInsertModal: false, formData: false });
  }

  render() {
    const insertMenu = [
      { id: 1, name: "Gelen Çek/Senet" },
      { id: 2, name: "Giden Çek/Senet" }
    ];
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>
          <AddCheque
              showModal={this.state.showInsertModal}
              formData={this.state.formData}
              isOut={this.state.isOut}
              isGiro={this.state.isGiro}
              isCollection={this.state.isCollection}
              onSave={this.onSave.bind(this)}
              onCancel={() => {
                this.setState({showInsertModal: false});
              }}
              errors={this.state.errors}
          />
          <EkoDataTable
              onRef={ref => (this.table = ref)}
              openInsert={this.openInsert.bind(this)}
              openUpdate={this.openUpdate.bind(this)}
              giro={this.giro.bind(this)}
              openReceipt={this.openReceipt.bind(this)}
              openNewCollection={this.openNewCollection.bind(this)}
              onDelete={this.onDelete.bind(this)}
              backGiro={this.backGiro.bind(this)}
              insertMenu={insertMenu}
              // addButtonShow={this.state.permAdd}
              data={this.props.cheques}
              name={"cheque"}
          ></EkoDataTable>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cheques: state.data.cheques
  };
}

export default connect(mapStateToProps, actions)(Cheques);
