import React from "react";
import {
  EkoFormButton,
  EkoFormDate,
  EkoFormInputText,
  EkoFormSelect
} from "./EkoForm";
import { toastr } from "react-redux-toastr";
import Sugar from "sugar";
import EkoFormTooltip from "./EkoFormTooltip";
import PropTypes from "prop-types";

export default class EkoInputTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {},
      rows:
        this.props.data.rows && this.props.data.rows.length > 0
          ? this.props.data.rows
          : [],
      spinner: false,
      defaultRows: [],
      errors: { rows: {}, footer: {} }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.rows) {
      this.setState({
        rows: nextProps.data.rows,
        defaultRows: nextProps.data.rows
      });
    }
  }

  deleteRow(index) {
    if(this.props.deletable !== false){
    if (this.state.rows.length > 1) {
      this.props.data.rows = Sugar.Array.removeAt(this.props.data.rows, index);
      let formData = this.props.formData;
      const deletedRow = formData.rows[index];
      formData.rows = Sugar.Array.removeAt(formData.rows, index);
      if (this.props.onChange) {
        this.props.onChange(formData);
      }

      if (this.props.onRowDelete) {
        this.props.onRowDelete(index, deletedRow);
      }

      this.setState({ rows: this.props.data.rows });
    } else if (!this.props.notMust) {
      toastr.warning("Dikkat!", "En az 1 kayıt girilmelidir");
    }
  }
  }

  addRow() {
    let row = [];
    this.props.data.rows[0].forEach(function(field, index) {
      row.push({ ...field });
    });
    this.props.data.rows.push(row);
    let formData = this.props.formData;
    if (this.props.defaultRow) formData.rows.push({ ...this.props.defaultRow });
    else formData.rows.push({});
    this.setState({ rows: this.props.data.rows });
    this.props.onChange(formData);
    if (this.props.onRowAdd) this.props.onRowAdd(formData.rows.length - 1);
  }

  setFieldChange(type, index, field, value) {
    let reallyChanged = false;
    let oldValue = false;
    let formData = this.props.formData[type];

    if (index > -1) {
      if (!formData[index]) {
        reallyChanged = true;
        oldValue = null;
        formData[index] = {};
      }
      // TODO: burası kontrol edilecek
      if (formData[index][field] !== value) {
        reallyChanged = true;
        oldValue = formData[index][field];
      }
      formData[index][field] = value;
    } else {
      // TODO: burası kontrol edilecek

      if (formData[field] !== value) {
        reallyChanged = true;
        oldValue = formData[field];
      }
      formData[field] = value;
    }

    // let errors = this.state.errors || {}
    // if (errors[type]) errors[type][field] = false

    let bigForm = this.props.formData;
    bigForm[type] = formData;

    //	this.setState({ formData: bigForm, errors: errors })
    if (this.props.onChange) {
      this.props.onChange(bigForm);
    }

    if (this.props.onChangeRow) {
      this.props.onChangeRow(index, bigForm.rows[index], field);
    }

    if (reallyChanged && this.props.onRealChange) {
      this.props.onRealChange(index, field, oldValue, value);
    }
  }

  setFieldMount(type, field) {
    let formFields = this.state.formFields;
    if (!formFields[type]) {
      formFields[type] = [field];
    } else formFields[type].push(field);
    this.setState({ formFields: formFields });
  }

  createInput(field, inputProps) {
    let rElement = null;
    switch (field.type) {
      case "text":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            colMd={field.colMd || undefined}
            disabled={field.disabled}
            tooltip={true}
            label={field.label ? field.label : ""}
            type="text"
            isVertical={field.vertical ? true : false}
          />
        );
        break;
      case "textarea":
        rElement = (
            <EkoFormInputText
                {...inputProps}
                id={field.id}
                colMd={field.colMd || undefined}
                disabled={field.disabled}
                tooltip={true}
                label={field.label ? field.label : ""}
                type="textarea"
                isVertical={field.vertical ? true : false}
            />
        );
        break;
      case "number":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            tooltip={true}
            colMd={field.colMd || undefined}
            disabled={field.disabled}
            label={field.label ? field.label : ""}
            type="number"
            isVertical={field.vertical ? true : false}
          />
        );
        break;
      case "percent":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            tooltip={true}
            disabled={field.disabled}
            label={field.label ? field.label : ""}
            type="percent"
            isVertical={field.vertical ? true : false}
          />
        );
        break;
      case "money":
        rElement = (
          <EkoFormInputText
            {...inputProps}
            id={field.id}
            tooltip={true}
            disabled={field.disabled}
            label={field.label ? field.label : ""}
            type="money"
            isVertical={field.vertical ? true : false}
          />
        );
        break;
      case "date":
        rElement = (
          <EkoFormDate
            {...inputProps}
            label={field.label || ""}
            tooltip={true}
            id={field.id}
            dateFormat="DD-MM-YYYY"
            isVertical={true}
            clearable={false}
          />
        );
        break;
      case "select":
        rElement = (
          <EkoFormSelect
            tooltip={true}
            {...inputProps}
            selectedOptionValue={field.selectedOptionValue}
            searchable={field.searchable}
            searchFilter={field.searchFilter}
            disabled={field.disabled}
            id={field.id}
            isVertical={true}
            defaultText={field.defaultText}
            optionId={field.optionId}
            options={field.data}
            onAddNewItemClick={field.onAddNewItemClick}
            optionValue={field.optionValue}
          />
        );
        break;
      case "clickarea":
        rElement = <i className={field.faClass} aria-hidden="true"></i>;
        break;
      case "custom":
        rElement = <span>{field.onRender(field.props)}</span>;
        break;
      case "footertext":
        rElement = (
          <tr>
            <td>{field.name}</td>
          </tr>
        );
        break;
      default:
    }

    return rElement;
  }

  showRowInput(field, i) {
    const inputProps = {
      onChange: this.setFieldChange.bind(this, "rows", i),
      onMount: this.setFieldMount.bind(this, "rows", i),
      formData: this.props.formData.rows[i],
      errors: this.props.errors.rows[i]
      //errors : this.state.errors.rows
    };

    return this.createInput(field, inputProps);
  }

  showFooterInput(field) {
    const inputProps = {
      onChange: this.setFieldChange.bind(this, "footer", -1),
      onMount: this.setFieldMount.bind(this, "footer", -1),
      formData: this.props.formData.footer,
      errors: this.props.errors.footer
    };

    return this.createInput(field, inputProps);
  }

  onCancel() {
    if (this.props.onCancel) this.props.onCancel();
  }

  render() {
    const headers = this.props.data.headers.filter(function(header) {
      return !header.hide;
    });
    const rows = this.state.rows.map(function(row) {
      row = row.filter(function(field) {
        return !field.hide;
      });
      return row;
    });
    let className =
      "tinvoice table-cucu table table-striped table-bordered table-hover";
    if (this.props.className)
      className = this.props.className + " " + className;
    let addButtonClass = "btn tinvoicend btn-outline";
    if (this.props.buttonAddClass)
      addButtonClass = addButtonClass + " " + this.props.buttonAddClass;

    let footerTable = [];
    if (this.props.data.footerTable) {
      footerTable = this.props.data.footerTable.filter(function(f, i) {
        return f.visible;
      });
    }


    return (
      <EkoFormTooltip formId={this.props.formId || null}>
        <table id="tableFirst" className={className}>
          <thead>
            <tr style={{"display":this.props.visible === true ? "none":""}}>
              {headers.map((h, i) => (
                <th
                  key={i}
                  width={h.width ? h.width : 100 / headers.length + "%"}
                  colSpan={i === 0 ? 2 : 0}
                  className={i === 0 ? "fcolumn" : ""}
                >
                  {h.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, x) => (
              <tr key={x}>
                <td className="ticon" onClick={() => this.deleteRow(x)}>
                  <i style={{"display":this.props.deletable === false ? "none":""}} className="fa fa-times delete" aria-hidden="true" />
                </td>
                {row.map((r, i) => (
                  <td key={i} onClick={r.onClick} className={r.tdClass} style={{"display":r.visible === true ? "none":""}}>
                    {this.showRowInput(r, x)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button
            style={{"display":this.props.hideAddButton === false ? "none":""}}
          id="add"
          onClick={this.addRow.bind(this)}
          className={addButtonClass}
          data-dismiss="modal"
        >
          <i className="fa fa-plus" aria-hidden="true" />
        </button>
        {this.props.data.footer && (
          <div className="buttonProccess footerItems">
            <table className="tplus2">
              <tbody>
                {this.props.data.footer.map((f, i) => (
                  <tr key={i}>
                    <td>{this.showFooterInput(f)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {this.props.data.footerTable && (
          <div className="buttonProccess">
            <table className="tplus">
              <tbody>
                {footerTable.map((f, i) => (
                  <tr key={i} className={f.className}>
                    <td>{f.label}</td>
                    <td>{this.props.data.footerData[f.id]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {this.props.data.customFooter}
        <br />
        <div className="buttonProccess actionButtons">
          <div>
            <EkoFormButton
              label={this.props.buttonSaveLabel || "Kaydet"}
              faClass="fa fa-plus"
              className="btn blue mt-ladda-btn ladda-button"
              isLoading={this.props.saving}
              onClick={this.props.onSave}
            />
            &nbsp;
            <EkoFormButton
              label="İptal"
              className="btn dark btn-outline"
              onClick={this.onCancel.bind(this)}
            />
          </div>
        </div>
      </EkoFormTooltip>
    );
  }
}

EkoInputTable.propTypes = {
  buttonSaveLabel: PropTypes.string
};
