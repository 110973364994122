import React, { Component } from "react";

import * as actions from "../../actions";
import { connect } from "react-redux";
import EkoDataTable from "../../components/elements/EkoDataTable";
import { toastr } from "react-redux-toastr";
import AddSupplier from "./AddSupplier";
import HelperContainer from "../../util/HelperContainer";
import Util from "../../util/Util";

import Api from "../../util/Api";
import EkoModal from "../../components/elements/EkoModal";
import {EkoForm, EkoFormButton, EkoFormDate, EkoFormSwitch} from "../../components/elements/EkoForm";
import {Modal} from "react-bootstrap";

//import EkoToastrError from '../../components/elements/EkoToastrError'

class Suppliers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: {},
      showUpdateModal: false,
      showInsertModal: false,
      errors: {},
      validationFields: [],
      suppliers: [], //table Item,
      sums: false,
      passiveButton: "Pasifleştir",
      activeButton: "Aktifleştir",
      dataLoading: false,
      switchValueCommission:true,
      switchValueInstallment:true,
      switchValuePaynet:false,
      formDataModal: [],
      emailTextarea:"",
      trade_reconciliation_start_date:"",
      trade_reconciliation_end_date:"",
      showTradeReconciliationModal: false,
    };
  }

  fetchSuppliers = () => {
    this.setState({ dataLoading: true });

    if (this.table) this.table.cleanSelect();
    Api.get("/supplier/list", (res, err) => {
      if (res.status === "ok") {
        this.setState({
          suppliers: res.suppliers,
          sums: res.sums,
          dataLoading: false,
          formDataModal:res.suppliers
        });
      }
    });
  };

  componentWillMount() {
    localStorage.removeItem("new_customer_id_take_collection");
    localStorage.removeItem("new_collection_amount_fc");
    localStorage.removeItem("selected_list_sales_invoice_id");
    localStorage.removeItem("selected_list_purchases_invoice_id");
    localStorage.removeItem("selected_list_receivable_amount_fc");
    localStorage.removeItem("selected_list_payable_amount_fc");
    localStorage.removeItem("new_collection_from_who_type", 1);
    this.fetchSuppliers();
  }

  accountTransaction(selectedRow) {
    this.props.router.push("/dashboard/transaction/supplier/" + selectedRow.id);
  }

  openUpdate = selectedRow => {
    var self = this;
    const id = selectedRow.id;
    Api.paralel(
      [
        "/supplier/" + id,
        "/supplierContact/showAll/" + id,
        "/supplierAddress/showAll/" + id
      ],
      function(responses) {
        let formData = {};
        formData.supplier = HelperContainer.filterObject(responses[0].supplier);
        formData.supplier.opening_balance = Util.convertDecimalToMoney(
          formData.supplier.opening_balance
        );
        formData.supplier.balance = Util.convertDecimalToMoney(
          formData.supplier.balance
        );

        if (
          responses[1].supplierContacts &&
          responses[1].supplierContacts.length > 0
        )
          formData.contact = HelperContainer.filterObject(
            responses[1].supplierContacts[0]
          );
        else formData.contact = {};

        if (
          responses[2].supplierAddresses &&
          responses[2].supplierAddresses.length > 0
        ) {
          formData.address = HelperContainer.filterObject(
            responses[2].supplierAddresses[0]
          );
          if (formData.address && formData.address.length > 0) {
            formData.address.is_abroad = !formData.address.city_id;
          }
        } else formData.address = {};
        self.setState({
          showInsertModal: true,
          errors: {},
          formData: formData
        });
      }
    );
  };

  onDelete = selectedRow => {
    const self = this;
    Api.delete("/supplier/" + selectedRow.id, function(response, err) {
      if (response) {
        self.fetchSuppliers();
        toastr.success("Başarılı", "Başarıyla Silindi");
      } else toastr.error("Hata", err.message);
    });
    /*  let newData = Sugar.Array.filter(this.props.suppliers,function(object){
              return (object.id!==selectedRow.id);
          });
          this.props.updateFetchedData(FETCH_SUPPLIERS,{suppliers:newData});*/
  };

  onSave = (formData, formFields) => {
    this.fetchSuppliers();
    this.setState({ showInsertModal: false, formData: false });
  };

  addPayment(selectedRow) {
    localStorage.setItem("new_payment_from_who_type", 1);
    localStorage.setItem("new_customer_id_take_collection", selectedRow.id);
    var balance = 0;
    if(selectedRow.balance > 0){
      balance = 0;
    }else{
      balance = Util.convertDecimalToMoney(Math.abs(selectedRow.balance));
    }

    console.log('balancebalancebalance',balance);
    localStorage.setItem("new_payment_amount_fc", balance);
    this.props.router.push("/dashboard/payments/add");
  }

  onCancel = () => {
    this.setState({ showInsertModal: false });
  };

  openInsert = (menuItem) => {
    if (menuItem === 1) {
      this.setState({ showInsertModal: true, formData: false, errors: {} });
    }else if (menuItem === 2) {
      this.props.router.push("/dashboard/BulkBalanceUpdateSupplier");
    }

  };

  passiveActive(state) {
    var self = this;
    Api.get("/supplier/" + state.selectedRow.id, function(responses) {
      let formData = {};
      formData.supplier = HelperContainer.filterObject(responses.supplier);
      if (responses.supplier) {
        console.log(responses.supplier);
        let toggleName = "";
        if (formData.supplier.is_active === 1) {
          toggleName = " pasifleştirmek ";
        } else {
          toggleName = " aktifleştirmek ";
        }
        toastr.confirm(
          responses.supplier.firstname +
            " hesabını" +
            toggleName +
            "istediğinize emin misiniz?",
          {
            onOk: () => {
              let func = "put";
              let url = "/supplier/" + formData.supplier.id;

              if (formData.supplier.is_active === 1) {
                formData.supplier.is_active = 0;
              } else if (formData.supplier.is_active === 0) {
                formData.supplier.is_active = 1;
              }

              Api[func](url, formData.supplier, function(response, postErrors) {
                if (postErrors) {
                  if (postErrors.message) {
                    toastr.error("Başarısız", postErrors.message);
                  }
                } else {
                  if ((formData.supplier.is_active = 1)) {
                    let toggleName = "";
                    if (responses.supplier.is_active === 1) {
                      toggleName = " pasifleştirildi";
                    } else {
                      toggleName = " aktifleştirildi";
                    }
                    toastr.success("Başarılı", "Hesap" + toggleName + "");
                    self.fetchSuppliers();
                  }
                }
              });
            },
            onCancel: () => {},
            okText: "Evet",
            cancelText: "Hayır"
          }
        );
      }
    });
  }

  getSumText = sums => {
    return sums
      .map(sum => {
        let currency = "₺";
        if (sum.currency === "USD") currency = "$";
        else if (sum.currency === "EUR") currency = "€";
        else if (sum.currency === "GBP") currency = "£";

        const totalBalance =
          sum.total_balance < 0 ? sum.total_balance * -1 : sum.total_balance;
        const money =
          sum.currency && sum.currency !== "TRY"
            ? currency + " " + Util.convertDecimalToMoney(totalBalance)
            : Util.convertDecimalToMoney(totalBalance) + " " + currency;
        return sum.total_balance < 0
          ? money + " Borç <br/>"
          : money + " Alacak <br/>";
      })
      .join("");
  };
  handleSubmit2 = async () => {
    this.setState({saving:true});
    const hasCheckboxCustomerId = this.state.formDataModal.some(item => item.checkbox_customer_id !== undefined && item.checkbox_customer_id !== '');
    if (!hasCheckboxCustomerId) {
      toastr.error("En az 1 müşteri seçmelisiniz.");
      return false;
    }
    if (this.state.trade_reconciliation_start_date==='' || this.state.trade_reconciliation_end_date==='') {
      toastr.error("Tarih seçmelisiniz.");
      return false;
    }
    var data = {};
    data.data = this.state.formDataModal;
    data.emailTextarea = this.state.emailTextarea;
    data.commission = this.state.switchValueCommission;
    data.installment = this.state.switchValueInstallment;
    data.start_date = this.state.trade_reconciliation_start_date;
    data.end_date = this.state.trade_reconciliation_end_date;
    data.paynet = this.state.switchValuePaynet;
    data.paynet_status = JSON.parse(localStorage.getItem("paynetStatus"));
    data.type = 1;
    Api.post("/customerTransaction/sendEmailTradeReconciliation", {data}, (resp, errors) => {
      if (resp) {
        this.setState({showTradeReconciliationModal: false,showBulkPaymentModalFinal:true});
        this.setState({saving:false});
      }else{
        toastr.error("Bir Hata Oluştu");
        this.setState({saving:false});
      }
    });
  };
  onTradeReconciliationStartDateChange(dateField, value, formattedValue) {
    this.setState({
      trade_reconciliation_start_date:value
    })
  }
  onTradeReconciliationEndDateChange(dateField, value, formattedValue) {
    this.setState({
      trade_reconciliation_end_date:value
    })
  }
  tgl(switchStatus) {
    this.setState({switchValueCommission: switchStatus});
  }
  tgl2(switchStatus) {
    this.setState({switchValueInstallment: switchStatus});
  }
  tgl3(switchStatus) {
    this.setState({switchValuePaynet: switchStatus});
  }
  emailTextareaChange = (e) => {
    this.setState({ emailTextarea:e.target.value });
  }
  handleInputChange = (e, index, rowId) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 'checked' : '') : value;
    let formDataModal = [...this.state.formDataModal];
    formDataModal[index] = { ...formDataModal[index], ['customer_id']: rowId };
    formDataModal[index] = { ...formDataModal[index], [name]: newValue };
    this.setState({ formDataModal });
  };
  showTradeReconciliationModal(e) {
    this.setState({showTradeReconciliationModal: true});
  }
  render() {
    const sum =
      this.state.sums !== false ? this.getSumText(this.state.sums) : null;
    const insertMenu = [
      {id: 1, name: 'Tedarikçi Ekle'},
      {id: 2, name: 'Toplu Bakiye Düzenle'}
    ];
    let paynet_status = JSON.parse(localStorage.getItem("paynetStatus"));
    return (
        <div>
          <a style={{"bottom": "6px", "position": "relative", "color": "grey"}}
             onClick={Util.backButton.bind(this, "/dashboard")}><i className="fa fa-arrow-left"></i> Geri Dön</a>

          <AddSupplier
              showModal={this.state.showInsertModal}
              formData={this.state.formData}
              onSave={this.onSave}
              onCancel={this.onCancel}
              errors={this.state.errors}
          />

          <EkoDataTable
              onRef={item => (this.table = item)}
              passiveActive={this.passiveActive.bind(this)}
              openInsert={this.openInsert}
              openUpdate={this.openUpdate}
              addCollectionn={this.addPayment.bind(this)}
              accountTransaction={this.accountTransaction.bind(this)}
              onDelete={this.onDelete}
              onFetch={this.fetchSuppliers}
              showTradeReconciliationModal={this.showTradeReconciliationModal.bind(this)}
              columnClassNames={{
                3: "bakiye-punto"
              }}
              subTitle={sum}
              data={this.state.suppliers}
              name={"supplier"}
              loading={this.state.dataLoading}
              insertMenu={insertMenu}
          />
          <EkoModal
              showModal={this.state.showBulkPaymentModalFinal}
              title={"İşlem Başarılı"}
              onHide={() => {
                this.setState({showBulkPaymentModalFinal: false});
              }}>

            {
              <div>
                <p className={"alert alert-info"} style={{"paddingBottom": "10px"}}>Başarılı, eposta
                  gönderildi.<br/><span style={{"color": "red"}}>Müşterilerinizi spam klasörlerini kontrol etme konusunda uyarınız.</span>
                </p>
              </div>
            }

          </EkoModal>
          <EkoModal
              bsSize="xlg"
              showModal={this.state.showTradeReconciliationModal}
              title={"Toplu Mutabakat Gönder"}
              onHide={() => {
                this.setState({showTradeReconciliationModal: false});
              }}>

            {
              <div>
                <EkoForm formId={"add_bulk_payment_data"}>
                  <div className="row" id={"form-fields"}>
                    <div className="col-md-12" style={{"padding": "20px"}}>
                      <div className="row">
                        <div className="col-md-8">

                          <p>
                            <b>Eposta İçeriği:</b><br/>
                            Merhaba Sayın ...,<br/>
                            ... firması ile ..., ... Tarihleri arasında mutabakat evrakı gönderilmiştir. <br/>Aşağıdaki
                            linke tıklayıp mutabakat sayfasını görüntüleyebilirsiniz.<br/>

                            ...<br/>
                            Güncel Bakiyeniz: ... TL<br/>
                            <br/>
                            <b>Eposta içeriği yukarıdadır. Ekstra not eklemek isterseniz aşağıdaki kutuya giriş
                              yapabilirsiniz.</b>
                          </p>
                          <textarea style={{"height": "100px"}} className="form-control"
                                    onChange={(e) => this.emailTextareaChange(e)}></textarea>
                        </div>
                        <div className="col-md-4">
                          <div className="col-md-12" style={{"marginTop": "20px"}}>
                            <EkoFormDate
                                id="trade_reconciliation_start_date"
                                dateFormat="DD-MM-YYYY"
                                clearable={false}
                                label="Mutabakat Başlangıç Tarihi"
                                value={this.state.trade_reconciliation_start_date}
                                onChange={this.onTradeReconciliationStartDateChange.bind(this)}
                            />
                          </div>
                          <div className="col-md-12" style={{"marginTop": "20px"}}>
                            <EkoFormDate
                                id="trade_reconciliation_end_date"
                                dateFormat="DD-MM-YYYY"
                                clearable={false}
                                label="Mutabakat Bitiş Tarihi"
                                value={this.state.trade_reconciliation_end_date}
                                onChange={this.onTradeReconciliationEndDateChange.bind(this)}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{"height": "20px"}}></div>
                      <div>
                        <table className="table table-striped table-bordered table-hover">
                          <thead>
                          <tr>
                            <th style={{"width": "30px"}}>#</th>
                            <th>Müşteri Bilgisi</th>
                            <th>Bakiye</th>
                            <th>Eposta Adresi</th>
                          </tr>
                          </thead>
                          <tbody>
                          {this.state.suppliers.map((row, i) => {
                            return (
                                <tr key={i}>
                                  <td style={{"width": "30px"}}><input type="checkbox" name="checkbox_customer_id"
                                                                       onChange={(e) => this.handleInputChange(e, i, row.id)}/>
                                  </td>
                                  <td>{row.fullname}</td>
                                  <td>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="balance"
                                        value={this.state.formDataModal[i]?.balance || ''}
                                        onChange={(e) => this.handleInputChange(e, i, row.id)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="contactEmail"
                                        value={this.state.formDataModal[i]?.contactEmail || ''}
                                        onChange={(e) => this.handleInputChange(e, i, row.id)}
                                    />
                                  </td>
                                </tr>
                            );

                          })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <Modal.Footer>
                    <div className="row buttonProccess2">
                      <EkoFormButton label={"Gönder"}
                                     faClass="fa fa-envelope"
                                     isLoading={this.state.saving}
                                     onClick={this.handleSubmit2}
                      />
                    </div>
                  </Modal.Footer>

                </EkoForm>
              </div>
            }

          </EkoModal>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    suppliers: state.data.suppliers
  };
}

export default connect(mapStateToProps, actions)(Suppliers);
