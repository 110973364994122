import React, { Component } from "react";
import {
    EkoForm,
    EkoFormCheckbox,
    // EkoFormInputText, // Kaldırıldı
    EkoFormSelect,
    EkoFormSwitch,
    EkoFormDate
} from "../../components/elements/EkoForm";
import { Modal } from "react-bootstrap";
import Sugar from "sugar";
import EkoModal from "../../components/elements/EkoModal";
import HelperContainer from "../../util/HelperContainer";
import { toastr } from "react-redux-toastr";
import * as actions from "../../actions";
import { connect } from "react-redux";
import Api from "../../util/Api";
import Util from "../../util/Util";
import moment from "moment";
import ReactDOM from 'react-dom';
import EkoSpinner from "../elements/EkoSpinner";
import { Link } from "react-router";
import swal from "sweetalert";
import "sweetalert/dist/sweetalert.css";

class FlexOdeme extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showActivationModal: false,
            showApplicationModal: false,
            errors: {},
            saving: false,
            page_loading: false,
            companyData: {},
            activated: 0,
            activationCode: "",
            activationSubmitting: false,
            bankAccountList: [],
            vposList: [],
            bankPairList: [],
        };
    }

    componentDidMount() {
        this.getCompanyDetails();
        this.getFlexOdemeActivation();
        this.getFlexOdemeVposList();
    }

    // Modal açma ve kapama fonksiyonları
    handleOpenActivationModal = () => {
        this.setState({ showActivationModal: true });
    }

    handleCloseActivationModal = () => {
        this.setState({ showActivationModal: false });
    }

    handleOpenApplicationModal = () => {
        this.setState({ showApplicationModal: true });
    }

    handleCloseApplicationModal = () => {
        this.setState({ showApplicationModal: false });
    }

    getCompanyDetails = async () => {
        Api.get("/getUserCompanyDetails", (response, errors) => {
            if (errors) {
                toastr.error("Sayfa yüklenirken hata oluştu. Lütfen tekrar deneyiniz.");
                return;
            }
            if (response.result != null) {
                let formDataApplication = {};
                formDataApplication.company_name = response.result.firstname + " " + response.result.lastname;
                formDataApplication.tax_office = response.result.tax_office_name;
                formDataApplication.tax_number = response.result.tax_identity_number;
                formDataApplication.address = response.result.address;
                let user = JSON.parse(localStorage.getItem("user"));
                formDataApplication.email = user.email;
                formDataApplication.name = user.firstname;
                formDataApplication.lastname = user.lastname;
                formDataApplication.phone = user.phone;
                this.setState({ companyData: formDataApplication });
            }
        });
    }
    getBankAccountsList = async () => {
        Api.get("/bankAccount/list", (response, errors) => {
            if (errors) {
                toastr.error("Sayfa yüklenirken hata oluştu. Lütfen tekrar deneyiniz.");
                return;
            }
            if (response.status == "ok") {
                this.setState({ bankAccountList: response.bankAccounts });
            }
        });
    }

    getFlexOdemeActivation = async () => {
        Api.get("/getCompanyFlexOdemeActivationStatus", (response, errors) => {
            if (errors) {
                toastr.error("Sayfa yüklenirken hata oluştu. Lütfen tekrar deneyiniz.");
                return;
            }
            if (response.result != null && response.result == "1") {
                this.setState({ activated: 1 });
                this.getBankAccountsList();
                this.getFlexOdemeVposList();
            }
        });
    }
    getFlexOdemeVposList = async () => {
        Api.get("/getCompanyFlexOdemeVposList", (response, errors) => {
            if (errors) {
                toastr.error("Sayfa yüklenirken hata oluştu. Lütfen tekrar deneyiniz.");
                return;
            }
            if (response.result != null && response.result == "1") {
                this.setState({ vposList: response.data, bankPairList: response.bank_account_pair_data });
            }
        });
    }

    handleActivationSubmit = (event) => {
        event.preventDefault();
        const { activationCode } = this.state;
        if (!activationCode) {
            toastr.error("Lütfen aktivasyon kodunu giriniz.");
            return;
        }
        this.setState({ activationSubmitting: true });
        Api.post("/setCompanyFlexOdemeActivate", { activationCode }, (response, errors) => {
            if (errors) {
                toastr.error("Aktivasyon sırasında hata oluştu. Lütfen tekrar deneyiniz.");
                this.setState({ activationSubmitting: false });
                return;
            }
            if (response.result == "1") {
                toastr.success("Aktivasyon başarılı!");
                window.location.reload();
            } else {
                toastr.error("Aktivasyon kodu yanlış.");
                this.setState({ activationSubmitting: false });
            }
        });
    }
    handleBankAccountPairChangeForVpos = (vposBank, currency, event) => {
        const selectedBankAccountId = event.target.value;
        if (selectedBankAccountId) {
            // Seçilen banka hesabını bankAccountList'ten buluyoruz.
            const account = this.state.bankAccountList.find(
                (acc) => acc.id === parseInt(selectedBankAccountId, 10)
            );
            // Hesap bulunduysa ve para birimleri uyuşmuyorsa hata mesajı gösteriyoruz.
            if (account && account.currency !== currency) {
                toastr.error("Hata", "Seçilen banka hesabının para birimi ile seçili para birimi uyuşmuyor");
                return;
            }
        }

        Api.post(
            "/setCompanyFlexOdemeBankAccountPair",
            { bank: vposBank, bankAccountId: selectedBankAccountId, currency: currency },
            (response, err) => {
                if (err) {
                    toastr.error("Hata", "Eşleştirme kaydedilemedi");
                } else {
                    if (response.result == "1") {
                        toastr.success("Başarılı", "Eşleştirme kaydedildi");
                        const updatedVposList = this.state.vposList.map((vpos) => {
                            if (vpos.bank === vposBank) {
                                return {...vpos, selectedBankAccountId: selectedBankAccountId};
                            }
                            return vpos;
                        });
                        this.setState({vposList: updatedVposList});

                        let updatedPairList = [...this.state.bankPairList];
                        const index = updatedPairList.findIndex(
                            (pair) => pair.vpos_name === vposBank && pair.vpos_currency === currency
                        );
                        if (index !== -1) {
                            updatedPairList[index] = {
                                bank_account_id: selectedBankAccountId,
                                vpos_name: vposBank,
                                vpos_currency: currency,
                            };
                        } else {
                            updatedPairList.push({
                                bank_account_id: selectedBankAccountId,
                                vpos_name: vposBank,
                                vpos_currency: currency,
                            });
                        }
                        this.setState({bankPairList: updatedPairList});
                    }else{
                        toastr.error("Hata", "Eşleştirme kaydedilemedi. Aynı döviz kuru ile eşleştirme yaptığınızdan emin olunuz.");
                    }
                }
            }
        );
    };

    render() {
        const { companyData, activated, activationCode, activationSubmitting } = this.state;
        const iframeUrl = `https://app.flexode.me/panel/integrator/ekohesap?c=${encodeURIComponent(companyData.company_name || '')}&p=${encodeURIComponent(companyData.phone || '')}&e=${encodeURIComponent(companyData.email || '')}&vn=${encodeURIComponent(companyData.tax_number || '')}&vd=${encodeURIComponent(companyData.tax_office || '')}&n=${encodeURIComponent(companyData.name || '')}&s=${encodeURIComponent(companyData.lastname || '')}`;

        return (
            <>
                <div>
                    {this.state.page_loading === true ? (
                        <EkoSpinner />
                    ) : (
                        <>
                            <div className="portlet light">
                                <div className="portlet-title">
                                    <div className="caption font-dark">
                                        <span className="caption-subject bold uppercase">
                                            FlexÖde.me Tahsilat Yazılımı
                                        </span>
                                    </div>
                                    <div className="pull-right">
                                        <img
                                            src="https://app.flexode.me/assets/images/flexodeme_logo_dark_blue.png"
                                            width={120}
                                            alt="flexödeme"
                                        />
                                    </div>
                                </div>
                                <div className="portlet-body">
                                    {activated === 1 ? (
                                        <div className="row">
                                            <div className="col-md-12 col-xs-12 text-center margin-bottom-5">
                                                <p></p>
                                                <p></p>
                                                <i className={"fa fa-check-circle fa-5x text-success"} style={{ color: "#4CAF50" }}></i>
                                                <h3 style={{ color: "#4CAF50", fontWeight: "bold" }}>
                                                    Tebrikler! FlexÖde.me Tahsilat Yazılımı hesabınız ile Ekohesap entegre edilmiş durumda.
                                                </h3>
                                                <p style={{fontSize: "11px"}}>Lütfen öncelikle FlexÖdeme panelinize giderek Ayarlar bölümünden sanal POS hesabınızın olduğu bankayı/aracı kurumu aktif ediniz. Tüm ayarlamaları FlexÖdeme üzerinde yaptıktan sonra alt bölümden sistemdeki kasa/banka eşleştirmesini yapmanız yeterli olacaktır.</p>
                                            </div>
                                            <div className={"col-md-6 col-xs-12 col-md-offset-3"}>
                                                <div className={"text-center mt-4"}>
                                                    <h3>Sistem Kasa/Banka Eşleştirmesi</h3>
                                                    <p>Tahsilatlarınız aşağıda eşleştireceğiniz Kasa/Banka hesabınıza otomatik işlenecektir.</p>
                                                    <table className="table table-striped table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>FLEXÖDEME'deki Aktif Sanal POS Hesabı</th>
                                                                <th>EKOHESAP'ta Kayıtlı Kasa/Banka Hesabı</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.vposList.length > 0 ? (
                                                            this.state.vposList.map((vpos) => {
                                                                return vpos.active_currencies.map((currency, index) => {
                                                                    const pair = this.state.bankPairList.find(
                                                                        (item) =>
                                                                            item.vpos_name === vpos.bank && item.vpos_currency === currency
                                                                    );
                                                                    const selectedBankAccountId = pair ? pair.bank_account_id : "";
                                                                    return (
                                                                        <tr key={`${vpos.id}-${currency}-${index}`}>
                                                                            <td>
                                                                                {vpos.bank} ({vpos.bank_name}) - {currency}
                                                                            </td>
                                                                            <td>
                                                                                <select
                                                                                    className="form-control"
                                                                                    value={selectedBankAccountId}
                                                                                    onChange={(e) =>
                                                                                        this.handleBankAccountPairChangeForVpos(vpos.bank, currency, e)
                                                                                    }
                                                                                >
                                                                                    <option value="">Seçiniz</option>
                                                                                    {this.state.bankAccountList
                                                                                        .filter(
                                                                                            (account) =>
                                                                                                account.bank_account_type === 1 ||
                                                                                                account.bank_account_type === 2
                                                                                        )
                                                                                        .map((account) => (
                                                                                            <option key={account.id} value={account.id}>
                                                                                                {account.name} ({account.currency})
                                                                                            </option>
                                                                                        ))}
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                });
                                                            })
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="2" className="text-center" style={{ color: "red" }}>
                                                                    Lütfen sanal POS ayarlarınızı yapmak için FlexÖde.me panelinizi ziyaret ediniz
                                                                </td>
                                                            </tr>
                                                        )}
                                                        </tbody>
                                                    </table>
                                                    <br />
                                                    <a href={"https://app.flexode.me"} target="_blank" className="btn btn-primary margin-top-10">
                                                        FlexÖde.me Paneline Git
                                                    </a>
                                                </div>
                                                <p>&nbsp;</p>

                                                <p><i className={"fa fa-check"} style={{ marginRight: "5px" }}></i>
                                                    Ekohesap üzerinde oluşturduğunuz satış faturalarının tahsilatı için link oluşturabilirsiniz. Müşteriniz ödemeyi yaptığında satış faturasının tahsilat bilgisi Ekohesap üzerinde de otomatik olarak işlenir.</p>
                                                <p><i className={"fa fa-check"} style={{ marginRight: "5px" }}></i>
                                                    Mutabakat gönderimlerinde cari bakiye tahsilatı için otomatik link oluşturulur ve müşteriniz ödeme yaptığında cari bakiye güncellenir.
                                                </p>
                                                <p><i className={"fa fa-check"} style={{ marginRight: "5px" }}></i>
                                                    Müşteri kartı üzerinden müşterinizin bakiyesini tahsil etmek için link oluşturabilirsiniz.
                                                </p>

                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="col-md-12 col-xs-12 text-center margin-bottom-5">
                                                <h3>FlexÖde.me Tahsilat Yazılımı</h3>
                                            </div>

                                            <div className={"col-md-6 col-xs-12 col-md-offset-3 margin-bottom-5"}>
                                                <p><i className={"fa fa-check"} style={{ marginRight: "5px" }}></i>
                                                    Kendi sanal POS hesaplarınızı kullanarak tahsilatlarınızı hızlı ve güvenli bir şekilde gerçekleştirin.
                                                </p>
                                                <p><i className={"fa fa-check"} style={{ marginRight: "5px" }}></i>
                                                    Ekohesap üzerinde oluşturduğunuz satış faturalarının tahsilatı için link oluşturabilirsiniz. Müşteriniz ödemeyi yaptığında satış faturasının tahsilat bilgisi Ekohesap üzerinde de otomatik olarak işlenir.</p>
                                                <p><i className={"fa fa-check"} style={{ marginRight: "5px" }}></i>
                                                    Mutabakat gönderimlerinde cari bakiye tahsilatı için otomatik link oluşturulur ve müşteriniz ödeme yaptığında cari bakiye güncellenir.
                                                </p>
                                                <p><i className={"fa fa-check"} style={{ marginRight: "5px" }}></i>
                                                    Müşteri kartı üzerinden müşterinizin bakiyesini tahsil etmek için link oluşturabilirsiniz.
                                                </p>
                                            </div>
                                            <div className={"col-md-12 col-xs-12 margin-bottom-5 text-center"}>
                                                <div className="button-container" style={{ marginTop: "20px" }}>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={this.handleOpenActivationModal}
                                                    >
                                                        Aktivasyon Kodu Gir
                                                    </button>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={this.handleOpenApplicationModal}
                                                        style={{ marginLeft: "10px" }}
                                                    >
                                                        Yeni Başvuru Yap
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <Modal show={this.state.showActivationModal} onHide={this.handleCloseActivationModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Aktivasyon Kodu Gir</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EkoForm>
                            <input
                                type="text"
                                name="activationCode"
                                placeholder="Aktivasyon kodunuzu giriniz"
                                value={activationCode}
                                onChange={(e) => this.setState({ activationCode: e.target.value })}
                                className="form-control"
                            />
                            <button className="btn btn-primary margin-top-10" type="button" disabled={activationSubmitting} onClick={this.handleActivationSubmit}>
                                {activationSubmitting ? "Gönderiliyor..." : "Gönder"}
                            </button>
                        </EkoForm>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showApplicationModal}
                    onHide={this.handleCloseApplicationModal}
                    dialogClassName="modal-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Yeni Başvuru Yap</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe
                            title="Yeni Başvuru"
                            src={iframeUrl}
                            style={{ width: "100%", height: "650px", border: "none" }}
                        ></iframe>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        viewOptions: state.data.viewOptions
    };
}

export default connect(mapStateToProps, actions)(FlexOdeme);