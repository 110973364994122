import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import EkoInputTable from "../../components/elements/EkoInputTable";
import Util from "../../util/Util";
import { toastr } from "react-redux-toastr";
import {Button, DropdownButton, MenuItem} from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import Api from "../../util/Api";
import {ROOT_URL} from "../../actions";
import {
    EkoForm,
    EkoFormButton,
    EkoFormSelect,
} from "../../components/elements/EkoForm";
class ModalNote extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultData;
        this.getRecordedNoteList();
    }
    row(index) {
        return [
            {
                label: "",
                placeholder: "Not",
                type: "textarea",
                colMd: 11,
                id: "note",
                vertical: true,
            },
            {
                colMd: 1,
                type: "custom",
                id: "button",
                tdClass: "ticon2",
                onRender: this.getButton.bind(this, index),
            },
        ];
    }
    get defaultData() {
        return {
            rows: [this.row],
            errors: {
                rows: []
            },
            saving: false,
            recorded_list: [],
        };
    }


    async getRecordedNoteList(){
        const response = await axios.get(ROOT_URL + "/salesShipment/getRecordedNoteList", {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        this.state.recorded_list = response.data.data || [];
    }

    checkValid = data => {
        return !!(data && data !== "");
    };

    onSave = () => {
        const errors = this.checkErrors();

        this.setState({ errors: { rows: errors } });

        if (errors.length === 0) {
            this.props.closeModal();
        }
    };

    checkErrors = () => {
        const rows = this.props.noteRows.rows;

        const errors = [];

        rows.map((row, index) => {
            if (
                (row.note === "" || row.note === null)
            ) {
                return row;
            }
            if (this.checkValid(row.note) === false) {
                errors[index] = {
                    note: ["Lütfen Geçerli Bir Not Giriniz"]
                };
            }
            return row;
        });

        return errors;
    };

    closeModal = () => {
        const errors = this.checkErrors();

        this.setState({ errors: { rows: errors } });

        if (errors.length > 0) {
            toastr.warning("Hatalı bir veri girdiniz, lütfen kontrol ediniz");
            return;
        }
        if (this.props.closeModal && errors.length === 0) {
            this.props.closeModal();
        }
    };

    onChange = data => {
        this.props.saveNote(data);
    };
    getButton(index) {
        let ret = (
                <Button
                    className="btn"
                    style={{ width: "100%" }}
                    onClick={this.addNoteButtonClick.bind(this, index)}
                >
                    <i className="fa fa-save" />
                </Button>
            );

        return ret;
    }
    addNoteButtonClick(index){
        console.log('this.props.noteRows.rows',this.props.noteRows.rows[index].note);
        if(this.props.noteRows.rows[index].note==='' ||  this.props.noteRows.rows[index].note===null || this.props.noteRows.rows[index].note===undefined){
            toastr.error("Lütfen not giriniz.");
            return;
        }else{
            Api.post("/salesShipment/addRecordedNoteList", {note: this.props.noteRows.rows[index].note}, (response, err) => {
                if (err != false) {
                    toastr.error("Hata!", "Sistemsel Bir Sorun Oluştu");
                    return false;
                }
                if(response && response.message){
                    if(response.status=='error'){
                        toastr.error("Hata!", response.message);
                        this.getRecordedNoteList();
                    }else{
                        toastr.success("Başarılı!", "Not Eklendi");
                        this.getRecordedNoteList();
                    }
                }

            });
        }
    }

    addNote(){
        let select = document.querySelector('select');
        let note_text = select.options[select.selectedIndex].text;
        console.log('Seçilen not:', note_text);
        if(note_text === ''){
            toastr.error("Lütfen not seçiniz.");
            return;
        } else{
            let note = this.props.noteRows.rows;
            note.push({note: note_text});
            this.props.saveNote({
                rows: note
            });
        }
    }

    render() {
        return (
            <EkoModal
                bsSize={"large"}
                dialogClassName={""}
                showModal={this.props.showModal}
                title={"Not Ekle"}
                onHide={this.closeModal.bind(this)}
                spinner={this.state.saving}
            >
                <div>
                    <div className="row" style={{ marginBottom: "30px" }}>
                        <div className={"col-md-2"}>Kayıtlı Notlar</div>
                        <div className={"col-md-8"}>
                            <select className={"form-control"}>
                                {
                                    this.state.recorded_list.map((item) => {
                                        return <option value={item.id}>{item.note}</option>
                                    })
                                }

                            </select>
                        </div>
                        <div className={"col-md-2"}>
                            <EkoFormButton
                                label="Not Getir"
                                faClass="fa fa-add"
                                onClick={this.addNote.bind(this)}
                            />
                        </div>
                    </div>
                    <EkoInputTable
                        onSave={this.onSave}
                        className={"modalTableFix"}
                        onCancel={this.closeModal}
                        onChange={this.onChange}
                        errors={this.state.errors}
                        formData={this.props.noteRows}
                        data={{
                            headers: [{ label: "İrsaliyeye Eklenecek Notlar" }],
                            rows: this.props.noteRows.rows.map((row, index) => this.row(index))
                        }}
                    />
                </div>
            </EkoModal>
        );
    }
}

export default ModalNote;
