//props list: newTagRows, showNewTagModal, saveNewTag,closeNewTagModal
import React, { Component } from "react";
import EkoModal from "../../components/elements/EkoModal";
import EkoInputTable from "../../components/elements/EkoInputTable";
import { toastr } from "react-redux-toastr";
import Util from "../../util/Util";
class NewTagDocumentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [this.row],
            errors: {
                rows: []
            },
            saving: false,
            hideAddButton: true,
            formData:this.props.newTagRows
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.showNewTagModal !== this.props.showNewTagModal && this.props.showNewTagModal) {
            this.setState({ formData: this.props.newTagRows })
        }
    }
    get row() {
        return [
            {
                label: "",
                placeholder: "Etiket Tipi",
                type: "text",
                colMd: 12,
                id: "new_tag_type_id",
                visible:true
            },
            {
                type: "select",
                id: "new_tag_id",
                optionId: "id",
                optionValue: "name",
                defaultText: "SEÇ",
                searchable: true,
                data: JSON.parse(localStorage.getItem("new_tag_list"))
            },
            {
                label: "",
                placeholder: "Kayıt No",
                type: "text",
                colMd: 12,
                id: "record_id",
                visible:true
            },
            {
                label: "",
                placeholder: "Oran",
                type: "percent",
                colMd: 12,
                id: "rate",
                vertical:true
            },
        ];
    }

    checkValid = data => {
        return !!(data && data !== "");
    };

    onSave = () => {
        const errors = this.checkErrors();
        this.setState({ errors: { rows: errors } });
        if (errors.length === 0) {
            this.props.closeNewTagModal();
        }
        if(this.props.directInsertUpdate && this.props.directInsertUpdate===true){
            this.props.directInsertUpdateFunc(this.state.formData.rows);
        }
    };

    checkErrors = () => {
        const rows = this.state.formData.rows;

        const errors = [];

        rows.map((row, index) => {
            if (row.new_tag_id === "" || row.new_tag_id === null) { return row; }

            if (this.checkValid(row.new_tag_id) === false) {
                errors[index] = {
                    new_tag_id: ["Lütfen Etiket Seçiniz"]
                };
            }
            if (this.checkValid(row.rate) === false) {
                errors[index] = {
                    rate: ["Lütfen Oran Seçiniz"]
                };
            }
            return row;
        });

        return errors;
    };

    closeModal = () => {
        const errors = this.checkErrors();

        this.setState({ errors: { rows: errors } });

        if (errors.length > 0) {
            toastr.warning("Hatalı bir veri girdiniz, lütfen kontrol ediniz");
            return;
        }
        if (this.props.closeNewTagModal && errors.length === 0) {
            this.props.closeNewTagModal();
        }
    };
    saveNewTag = (rows) => {
        this.setState({ formData: rows });
    };
    onChange = data => {
        this.saveNewTag(data);
        this.props.saveNewTag(data);
    };

    addRow = () => {
        const { formData } = this.state;
        const { rows } = formData;
        const lastRowIndex = rows.length - 1;
        const updatedRow = {
            ...rows[lastRowIndex],
            id:null,new_tag_type_id:rows[0]['new_tag_type_id'],new_tag_id: "",record_id:null, rate: "%100,00"
        };
        const updatedRows = [...rows.slice(0, lastRowIndex), updatedRow];
        this.setState({
            formData: {
                ...formData,
                rows: updatedRows,
            },
        });
        if (rows.length >= 5) {
            this.setState({ hideAddButton: false });
            return false;
        }
    };



    onRowDelete = () => {
        if(this.state.formData.rows.length<5){
            this.setState({ hideAddButton: true });
        }
        return false;
    };

    onChangeRow(index, value,input) {
        var data = this.state.formData;
        var row = data.rows[index];
        if(input==='rate'){
            var rate = value.rate.replace('%','');
            if(rate>100){
                row.rate = "%100,00";
            }
        }
        this.setState(
            {
                data:data
            }
        );
    }
    render() {
        return (
            <EkoModal
                bsSize={"large"}
                dialogClassName={""}
                showModal={this.props.showNewTagModal}
                title={"Etiket Ekle"}
                onHide={this.closeModal.bind(this)}
                spinner={this.state.saving}
            >
                <EkoInputTable
                    onSave={this.onSave}
                    className={"modalTableFix"}
                    onCancel={this.closeModal}
                    onChange={this.onChange}
                    errors={this.state.errors}
                    formData={this.state.formData}
                    onRowAdd={this.addRow}
                    onRowDelete={this.onRowDelete}
                    hideAddButton={this.state.hideAddButton}
                    onChangeRow={this.onChangeRow.bind(this)}
                    data={{
                        headers: [{ label: "Etiket Seçiniz" }, { label: "Oran" }],
                        rows: this.state.formData.rows.map(() => this.row)
                    }}
                />
            </EkoModal>
        );
    }
}

export default NewTagDocumentModal;
