import React from "react";
import { Provider } from "react-redux";
import { browserHistory, IndexRoute, Route, Router } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import App from "./components/App";
import Login from "./screens/Auth/Login";
import Register from "./screens/Auth/Signup";
import Invitation from "./screens/Auth/Invitation";
import Forgot from "./screens/Auth/Forgot";
import Reset from "./screens/Auth/Reset";
import Activation from "./screens/Auth/Activation";
import Dashboard from "./screens/Dashboard/Index";
import Collections from "./screens/Collection/Collection";
import Transfers from "./screens/Transfer/Transfers";
import CollectionAddUpdate from "./screens/Collection/CollectionAddUpdate";
import TransferAddUpdate from "./screens/Transfer/TransfersAddUpdate";
import Payments from "./screens/Payment/Payments";
import PaymentAddUpdate from "./screens/Payment/PaymentAddUpdate";
import CompanyUpdate from "./screens/Company/UpdateCompany";
import CompanySettings from "./screens/Company/CompanySettings";
import IVDScreen from "./screens/Company/IVD";
import DBSPurchases from "./screens/DBS/purchases";
import DBSSales from "./screens/DBS/sales";
import UpdateUser from "./screens/User/UpdateUser";
import SoleProfSalesInvoiceAddUpdate from "./screens/SoleProf/SoleProfSalesInvoiceAddUpdate";
import OtherReceiptAddUpdate from "./screens/OtherReceipt/OtherReceiptsAddUpdate";
import CompanyOtherInfoUpdate from "./screens/Company/CompanyOtherInfoUpdate";
import PolicyAddUpdate from "./screens/Policy/PolicyAddUpdate";
import Transactions from "./screens/Transaction/Transactions";
import Subscriber from "./screens/Auth/Subscriber";
import SubscriberPayment from "./screens/Auth/SubscriberPayment";
import PaymentStatus from "./screens/Payment/PaymentStatus";
import Template from "./template/App";
import Templates from "./components/dashboard/Templates";
import Statements from "./screens/Statement/Statements";
import EInBoxes from "./screens/EInBox/EInBoxes";
import EInBox from "./screens/EInBox/EInBox";
import StatementPreview from "./screens/Statement/StatementPreview";
import Suppliers from "./screens/Supplier/Supplier";
import Cheques from "./screens/Cheque/Cheques";
import Banks from "./screens/Bank/Banks";
import Loans from "./screens/Loan/Loans";
import LoanPaymentPlans from "./screens/Loan/LoanPaymentPlans";
import Assets from "./screens/Asset/Assets";
import ReceiptAddUpdate from "./screens/Receipt/ReceiptAddUpdate";
import Employees from "./screens/Employee/Employees";
import SecureAuth from "./screens/Auth/Secure";
import PagePermit from "./screens/Auth/PermittedPage";
import Customers from "./screens/Customer/Customer";
import ProductService from "./screens/Item/ProductService";
import SoleProfSalesInvoice from "./screens/SoleProf/SoleProfSalesInvoice";
import Purchases from "./screens/PurchaseInvoice/Purchases";
import Project from "./screens/Project/Project";
import Report from "./screens/Report/Reports";
import CompanyUserRole from "./screens/Company/CompanyUserRole";
import Integrations from "./components/dashboard/Integrations";
import Obifin from "./components/dashboard/Obifin";
import ObifinBankApis from "./components/dashboard/ObifinBankApis";
import paynetApplication from "./components/dashboard/paynetApplication";
import SalesInvoiceAddUpdate from "./screens/SalesInvoice/SalesInvoiceAddUpdate";
import PurchaseAddUpdate from "./screens/PurchaseInvoice/PurchaseInvoiceAddUpdate";
import NotFound from "./components/dashboard/NotFound";
import SalesInvoice from "./screens/SalesInvoice/SalesInvoice";
import EmployeeSalary from "./screens/Employee/EmployeeSalary";
import AddOrUpdatePayroll from "./screens/Payroll/AddOrUpdatePayroll";
import SalaryPreviewPage from "./screens/SalaryPreview/SalaryPreviewPage";
import WitholdingPreview from "./components/dashboard/WitholdingPreview";
import AccountingSettings from "./components/dashboard/AccountingSettings";
import BulkStockUpdate from "./components/dashboard/bulkStockUpdate";
import BulkBalanceUpdateCustomer from "./components/dashboard/bulkBalanceUpdateCustomer";
import BulkBalanceUpdateSupplier from "./components/dashboard/bulkBalanceUpdateSupplier";
import PersonnelSalaryAccrual from "./components/dashboard/personnelSalaryAccrual";
import SelfEmployment from "./screens/Employment/SelfEmployment";
import ZReport from "./screens/ZReport/ZReport";
import OtherSales from "./screens/OtherSales/OtherSales";
import Proforma from "./screens/Proforma/Proforma";
import ProformaAddUpdate from "./screens/Proforma/ProformaAddUpdate";
import Soleprofv2Invoice from "./screens/SoleProfv2/Soleprofv2Invoice";
import Soleprofv2InvoiceAddUpdate from "./screens/SoleProfv2/Soleprofv2InvoiceAddUpdate";
import OutstandingCollections from "./screens/Outstanding/OutstandingCollections";
import OutstandingPayments from "./screens/Outstanding/OutstandingPayments";
import ZReportList from "./screens/ZReport/ZReportList";
import OtherSalesList from "./screens/OtherSales/OtherSalesList";
import Maintenance from "./components/maintenance";
import TransactionPdf from "./screens/Transaction/TransactionPdf";
import ExportLayout from "./components/dashboard/ExportLayout";
import RecipeAddUpdate from "./screens/Recipe/RecipeAddUpdate";
import TransferWarehousesAddUpdate from "./screens/Transfer/TransferWarehousesAddUpdate";
import ProductionPlaning from "./screens/Plan/ProductionPlaning";
import ProductPlaningList from "./screens/Plan/ProductPlaningList";
import TransferWarehousesList from "./screens/Transfer/TransferWarehousesList";
import RecipeList from "./screens/Recipe/RecipeList";
import SpecialOffer from "./components/forms/SpecialOffer";
import HocPermission from "./components/common/HocPermission";
import HocPermissionPage from "./components/common/HocPermissionPage";
import LoginHelper from "./services/LoginHelper";
import { store } from "./reducers/store";
import Index from "./components/Index";
import DetailReports from "./screens/Report/DetailReports.js";
import Welcome from './screens/Payment/Welcome'
import UserList from "./screens/Dashboard/userList";
import ExpenseCategory from "./screens/ExpenseCategory/ExpenseCategory";
import ExpenseCategoryAddUpdate from "./screens/ExpenseCategory/ExpenseCategoryAddUpdate";
import axios from "axios";
import {ROOT_URL} from "./actions";
import Declarations from "./screens/Declarations/Declarations";
import DeclarationsList from "./screens/Declarations/DeclarationsList";
import Warehouses from "./screens/Warehouses/Warehouses";
import WarehouseProducts from "./screens/Warehouses/WarehouseProducts";
import SalesShipmentList from "./screens/SalesShipment/SalesShipmentList";
import SalesShipmentAddUpdate from "./screens/SalesShipment/SalesShipmentAddUpdate";
import PurchaseReceiptList from "./screens/PurchaseReceipt/PurchaseReceiptList";
import PurchaseReceiptAddUpdate from "./screens/PurchaseReceipt/PurchaseReceiptAddUpdate";
import WageSheetDetails from "./screens/Employee/WageSheetDetails";
import WageSheetDetailsEmployee from "./screens/Employee/WageSheetDetailsEmployee";
import EmployeeWageSheet from "./screens/Employee/EmployeeWageSheet";
import FixedAsset from "./screens/FixedAsset/FixedAsset";
import FixedAssetAddUpdate from "./screens/FixedAsset/FixedAssetAddUpdate";
import FixedAssetSalesInvoice from "./screens/FixedAssetSalesInvoice/FixedAssetSalesInvoice";
import FixedAssetSalesInvoiceAddUpdate from "./screens/FixedAssetSalesInvoice/FixedAssetSalesInvoiceAddUpdate";
import Api from "./util/Api";
import Marketplace from "./components/dashboard/Marketplace";
import zreportIntegration from "./components/dashboard/zreportIntegration";
import Depreciation from "./screens/Depreciation/Depreciation";
import SoftwareSettings from "./screens/SoftwareSettings/SoftwareSettings";
import FlexOdeme from "./components/dashboard/FlexOdeme";
//import DeclarationsView from "./screens/Declarations/DeclarationsView";
// prod https://62f7eee94cf04f348c230d92b5741082@sentry.io/294534
// dev https://a43d87b981cd44deac06679bcdaea357@sentry.io/290690

const history = syncHistoryWithStore(browserHistory, store);
browserHistory.listen((location) => {
  window.fbq("track", "ViewContent", { content_name: location.pathname });
});
export function setDefaultStorage(pass=false) {
  if(localStorage.getItem("nace_code_list")===null || pass) {
    axios.get(ROOT_URL + "/company/naceCodeListSelect", {headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}}
    ).then(response => {
      var data = JSON.stringify(response.data.naceCode, null, 2);
      localStorage.setItem("nace_code_list", '');
      localStorage.setItem("nace_code_list", data);
    });
  }
  //
  if(localStorage.getItem("country_list")===null) {
    axios.get(ROOT_URL + "/DBS/countryList", {headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}}
    ).then(response => {
      var data = JSON.stringify(response.data.countryList, null, 2);
      localStorage.setItem("country_list", '');
      localStorage.setItem("country_list", data);
    });
  }
  //
  if(localStorage.getItem("purchase_expense_categories")===null || pass) {
  axios.get(ROOT_URL + "/DBS/purchaseExpenseCategoriesSelect/list/0", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
  ).then(response => {
    var data = JSON.stringify(response.data.purchaseExpenseCategories, null, 2);
    localStorage.setItem("purchase_expense_categories", '');
    localStorage.setItem("purchase_expense_categories", data);
  });
  }
  //
  if(localStorage.getItem("new_tag_list")===null || pass) {
    axios.get(ROOT_URL + "/company/newTagList", {headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}}
    ).then(response => {
      var data = JSON.stringify(response.data.list, null, 2);
      localStorage.setItem("new_tag_list", '');
      localStorage.setItem("new_tag_list", data);
    });
  }
  //
  if(localStorage.getItem("stoppage_code")===null) {
    axios.get(ROOT_URL + "/DBS/vatReductionTypeCodesGet", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data.stoppage_code, null, 2);
      localStorage.setItem("stoppage_code", '');
      localStorage.setItem("stoppage_code", data);
      //
      localStorage.setItem("getAllVatReductionTypeCode", '');
      localStorage.setItem("getAllVatReductionTypeCode", JSON.stringify(response.data, null, 2));
    });
  }
  //
  if(localStorage.getItem("dbs_expense_document_type")===null || pass) {
    axios.get(ROOT_URL + "/DBS/getDBSExpenseDocumentType/list", {headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}}
    ).then(response => {
      var data = JSON.stringify(response.data.documentType, null, 2);
      localStorage.setItem("dbs_expense_document_type", '');
      localStorage.setItem("dbs_expense_document_type", data);
    });
  }
  if(localStorage.getItem("dbs_special_amount_types")===null || pass) {
    axios.get(ROOT_URL + "/DBS/getDBSSpecialAmountTypes/list", {headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}}
    ).then(response => {
      var data = JSON.stringify(response.data.specialAmountTypes, null, 2);
      localStorage.setItem("dbs_special_amount_types", '');
      localStorage.setItem("dbs_special_amount_types", data);
    });
  }
  //
  if(localStorage.getItem("other_sales_types")===null) {
    axios.get(ROOT_URL + "/otherSales/types", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data.otherSalesTypes, null, 2);
      localStorage.setItem("other_sales_types", '');
      localStorage.setItem("other_sales_types", data);
    });
  }
  //
  if(localStorage.getItem("bank_accounts")===null || pass) {
    axios.get(ROOT_URL + "/bankAccount/list", {headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}}
    ).then(response => {
      var data = JSON.stringify(response.data.bankAccounts, null, 2);
      localStorage.setItem("bank_accounts", '');
      localStorage.setItem("bank_accounts", data);
    });
  }
  //
  if(localStorage.getItem("exemption_reasons")===null) {
    axios.get(ROOT_URL + "/eInvoice/getTaxExemptionReasons", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data.reasons, null, 2);
      localStorage.setItem("exemption_reasons", '');
      localStorage.setItem("exemption_reasons", data);
    });
  }
  //
  if(localStorage.getItem("company_modules")===null || pass) {
    axios.get(ROOT_URL + "/company/CompanyModules", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("company_modules", '');
      localStorage.setItem("company_modules", data);
    });
  }
  //
  if(localStorage.getItem("fixed_asset_types")===null) {
    axios.get(ROOT_URL + "/fixedAsset/getTypes", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("fixed_asset_types", '');
      localStorage.setItem("fixed_asset_types", data);
    });
  }
  //
  if(localStorage.getItem("getDBSExpenseDocumentType")===null || pass) {
    axios.get(ROOT_URL + "/DBS/getDBSExpenseDocumentType/list", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("getDBSExpenseDocumentType", '');
      localStorage.setItem("getDBSExpenseDocumentType", data);
    });
  }
  //
  if(localStorage.getItem("fixed_asset_list")===null || pass) {
    axios.get(ROOT_URL + "/fixedAsset/list", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data.fixedAssets, null, 2);
      localStorage.setItem("fixed_asset_list", '');
      localStorage.setItem("fixed_asset_list", data);
    });
  }
  //
  if(localStorage.getItem("employee_list")===null || pass) {
    axios.get(ROOT_URL + "/employee/activePersonal", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("employee_list", '');
      localStorage.setItem("employee_list", data);
    });
  }
  //
  if(localStorage.getItem("account_code_list")===null || pass) {
    axios.get(ROOT_URL + "/customer/allCustomerAccountCode", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("account_code_list", '');
      localStorage.setItem("account_code_list", data);
    });
  }
  //
  if(localStorage.getItem("item_code_list")===null || pass) {
    axios.get(ROOT_URL + "/item/allItemCode", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
    ).then(response => {
      var data = JSON.stringify(response.data, null, 2);
      localStorage.setItem("item_code_list", '');
      localStorage.setItem("item_code_list", data);
    });
  }
  if(localStorage.getItem("company_settings")===null || pass) {
    axios.get(ROOT_URL + "/getCompanySettings", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}).then(response => {
      var data = JSON.stringify(response.data.result, null, 2) ?? null;
      localStorage.setItem("company_settings", '');
      localStorage.setItem("company_settings", data);
    });
  }
}
setDefaultStorage();
LoginHelper.checkLogin();
//onceden sakladigimiz bir token varsa browserda kullan
// PWA START
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/service-worker.js').then(function (registration) {
      console.log('EkoHesap Browser Application Installed & Registered! Thank you!');

    }, function (err) {
      console.log('ServiceWorker registration failed: ', err);
    }).catch(function (err) {
      console.log(err);
    });
  });
} else {
  console.log('service worker is not supported');
}
let deferredPrompt = null;
window.addEventListener('beforeinstallprompt', e => {
  e.preventDefault();
  deferredPrompt = e;
  return false;
});
if (deferredPrompt) {
  deferredPrompt.prompt();
  deferredPrompt.userChoice.then(choice => {
    console.log(choice);
  });
  deferredPrompt = null;
}
// PWA END
const transactions = ["customer", "supplier", "employee", "bank", "item","warehouse"];
const Application = () => (
  <Provider store={store}>
    <Router history={history}>
      {process.env.REACT_APP_MAINTENANCE == "true" ? (
        <Route path="*" component={Maintenance} />
      ) : null}

      <Route path="/" component={App}>
        <IndexRoute component={Index} />
        <Route path="login" component={Login} />
        <Route path="signup" component={Register} />
        <Route path="forgot" component={Forgot} />
        <Route path="invitation/:code" component={Invitation} />
        <Route path="reset/:code" component={Reset} />
        <Route path="activation/:code" component={Activation} />

        <Route path={"export"} component={ExportLayout}>
          <Route path={"transaction"}>
            <Route path={"pdf/:id"} component={TransactionPdf} />
          </Route>
        </Route>
        <Route path="DBS" component={SecureAuth(Dashboard)}>
          <Route path={"purchases"} component={DBSPurchases}/>
          <Route path={"sales"} component={DBSSales}/>

        </Route>
        <Route path="dashboard" component={SecureAuth(Dashboard)}>
          <Route path="employee/wagesheets/:id" component={EmployeeWageSheet} />
          <Route path="declarations" component={Declarations} />
          <Route path="declarations-list" component={DeclarationsList} />
          <Route path="softwareSettings" component={SoftwareSettings} />
          <Route path="wagesheet/:id" component={WageSheetDetails} />
          <Route path="wagesheet-details/:id" component={WageSheetDetailsEmployee} />
          <Route path={"self-employment"} component={SelfEmployment} />
          <Route path="statements" component={PagePermit(Statements, "38")} />
          <Route
            path="statement-preview"
            component={PagePermit(StatementPreview, "54")}
          />
          <Route
            path="witholding-preview"
            component={PagePermit(WitholdingPreview, "55")}
          />
          <Route
                        path="accountingSettings"
                        component={PagePermit(AccountingSettings, "55")}
                    />
          <Route
                        path="bulkStockUpdate"
                        component={BulkStockUpdate}
                    />
          <Route
                        path="bulkBalanceUpdateCustomer"
                        component={BulkBalanceUpdateCustomer}
                    />
          <Route
              path="bulkBalanceUpdateSupplier"
              component={BulkBalanceUpdateSupplier}
          />
          <Route
                        path="personnelSalaryAccrual"
                        component={PagePermit(PersonnelSalaryAccrual, "55")}
                    />
          <Route
            path="suppliers"
            component={HocPermissionPage(Suppliers, "SUPPLIER_LIST")}
          />
          <Route
            path="cheques"
            component={HocPermissionPage(Cheques, "CHEQUE_LIST")}
          />
          <Route
            path="banks"
            component={HocPermissionPage(Banks, "BANK_LIST")}
          />
          <Route
              path="user-list"
              component={HocPermissionPage(UserList, "USER_LIST")}
          />
          <Route
            path="loans"
            component={HocPermissionPage(Loans, "LOAN_LIST")}
          />
          <Route
            path="paymentPlans/:id"
            component={PagePermit(LoanPaymentPlans, "34")}
          />

          <Route path={"transfers"}>
            <IndexRoute component={PagePermit(Transfers, "8")} />
            <Route path="add" component={PagePermit(TransferAddUpdate, "9")} />
            <Route
              path="edit/:id"
              component={PagePermit(TransferAddUpdate, "9")}
            />
          </Route>

          <Route path={"collections"}>
            <IndexRoute
              component={HocPermissionPage(Collections, "COLLECTION_LIST")}
            />
            <Route
              path="add"
              component={PagePermit(CollectionAddUpdate, "9")}
            />
            <Route
              path="edit/:id"
              component={PagePermit(CollectionAddUpdate, "9")}
            />
            <Route
              path="outstanding"
              component={PagePermit(OutstandingCollections, "8")}
            />
          </Route>

          <Route path={"salesInvoice"}>
            <IndexRoute
              component={HocPermissionPage(SalesInvoice, "SALES_INVOICE_LIST")}
            />
            <Route
              path="add/:type"
              component={PagePermit(SalesInvoiceAddUpdate, "23")}
            />
            <Route
              path="edit/:id"
              component={PagePermit(SalesInvoiceAddUpdate, "23")}
            />
            <Route path="proformas" component={PagePermit(Proforma, "19")} />
            <Route
              name="copied"
              path="copied"
              component={PagePermit(SalesInvoiceAddUpdate, "23")}
            />

            <Route path={"proforma"}>
              <IndexRoute component={PagePermit(Proforma, "19")} />

              <Route
                path="add"
                component={PagePermit(ProformaAddUpdate, "19")}
              />
              <Route
                path="edit/:id"
                component={PagePermit(ProformaAddUpdate, "19")}
              />
            </Route>
            <Route path="soleprofv2s" component={PagePermit(Soleprofv2Invoice, "19")} />
            <Route path={"soleprofv2"}>
              <IndexRoute component={PagePermit(Soleprofv2Invoice, "19")} />
              <Route
                  path="add"
                  component={PagePermit(Soleprofv2InvoiceAddUpdate, "19")}
              />
              <Route
                  path="edit/:id"
                  component={PagePermit(Soleprofv2InvoiceAddUpdate, "19")}
              />
            </Route>

            <Route path={"zreports"}>
              <IndexRoute component={PagePermit(ZReportList, "55")} />
              <Route path={"edit/:id"} component={PagePermit(ZReport, "55")} />
              <Route path="add" component={PagePermit(ZReport, "19")} />
            </Route>
            <Route path={"otherSales"}>
                            <IndexRoute component={PagePermit(OtherSalesList, "55")} />
                            <Route path={"edit/:id"} component={PagePermit(OtherSales, "55")} />
                            <Route path="add" component={PagePermit(OtherSales, "19")} />
                          </Route>
          </Route>

          <Route path={"purchases"}>
            <IndexRoute
              name="purchaseInvoice"
              component={HocPermissionPage(Purchases, "PURCHASE_INVOICE_LIST")}
            />

            <Route path={"purchaseInvoice"}>
              <IndexRoute
                name="purchaseInvoice"
                component={PagePermit(Purchases, "18")}
              />

              <Route
                type="productServiceInvoice"
                path="add/productServiceInvoice"
                component={PagePermit(PurchaseAddUpdate, "21")}
              />
              <Route
                  type="toFixedAssetInvoice"
                  path="add/toFixedAssetInvoice"
                  component={PagePermit(PurchaseAddUpdate, "21")}
              />
              <Route
                type="expenseInvoice"
                path="add/expenseInvoice"
                component={PagePermit(PurchaseAddUpdate, "20")}
              />
              <Route
                type="customerReturnInvoice"
                path="add/customerReturnInvoice"
                component={PagePermit(PurchaseAddUpdate, "22")}
              />
              <Route
                path="edit/:id"
                component={PagePermit(PurchaseAddUpdate, "70")}
              />
              <Route
                isDetail={true}
                path="insert/:type/:uuid"
                component={PagePermit(PurchaseAddUpdate, "70")}
              />
              <Route
                isDetail={false}
                path="insert/:type/:uuid/summary"
                component={PagePermit(PurchaseAddUpdate, "70")}
              />
            </Route>

            <Route
              name="otherReceipt"
              path="otherReceipt"
              component={PagePermit(Purchases, "40")}
            />

            <Route path={"receipt"}>
              <IndexRoute
                name="receipt"
                component={PagePermit(Purchases, "42")}
              />

              <Route
                path="add"
                component={PagePermit(ReceiptAddUpdate, "43")}
              />
              <Route
                path="edit/:id"
                component={PagePermit(ReceiptAddUpdate, "43")}
              />
            </Route>
            <Route path={"expensecategory"}>
                            <IndexRoute
                                name="expensecategory"
                                component={PagePermit(ExpenseCategory, "42")}
                            />

                            <Route
                                path="add"
                                component={PagePermit(ExpenseCategoryAddUpdate, "43")}
                            />
                            <Route
                                path="edit/:id"
                                component={PagePermit(ExpenseCategoryAddUpdate, "43")}
                            />
                          </Route>
            <Route path={"otherreceipt"}>
              <Route
                path="add"
                component={PagePermit(OtherReceiptAddUpdate, "72")}
              />
              <Route
                path="edit/:id"
                component={PagePermit(OtherReceiptAddUpdate, "72")}
              />
            </Route>

            <Route path={"insurancePolicy"}>
              <IndexRoute
                name="insurancePolicy"
                component={PagePermit(Purchases, "41")}
              />
              <Route
                name={"insurancePolicyAdd"}
                path="add"
                component={PagePermit(PolicyAddUpdate, "73")}
              />
              <Route
                name={"insurancePolicyEdit"}
                path="edit/:id"
                component={PagePermit(PolicyAddUpdate, "73")}
              />
            </Route>

            <Route path={"eInBoxes"}>
              <IndexRoute component={PagePermit(EInBoxes, "56")} />
              <Route path=":id" component={PagePermit(EInBox, "56")} />
            </Route>
          </Route>

          {/*<Route path="purchases/:tabName" component={PagePermit(Purchases,"19")}/>

                    {/*<Route path="purchaseInvoice/add/:type" component={PagePermit(PurchaseAddUpdate,"21")}/>*/}

          <Route path={"employee"}>
            <IndexRoute
              component={HocPermissionPage(Employees, "EMPLOYEE_LIST")}
            />
            <Route
              path={":id/salary/:type"}
              component={PagePermit(AddOrUpdatePayroll, "38")}
            />

            <Route path={"employeeSalary"}>
              <IndexRoute component={PagePermit(EmployeeSalary, "32")} />

              <Route
                path={"preview/:id/:employee_id"}
                component={PagePermit(SalaryPreviewPage, "38")}
              />
            </Route>
          </Route>

          <Route path={"recipe"}>
            <IndexRoute component={RecipeList} />
            <Route path={"add"} component={RecipeAddUpdate} />
            <Route path={"edit/:id"} component={RecipeAddUpdate} />
          </Route>

          <Route path={"transfer-warehouse"}>
            <IndexRoute component={TransferWarehousesList} />

            <Route path={"add"} component={TransferWarehousesAddUpdate} />
            <Route path={"edit/:id"} component={TransferWarehousesAddUpdate} />
          </Route>

          <Route path={"production-planing"}>
            <IndexRoute component={ProductPlaningList} />
            <Route path={"add"} component={ProductionPlaning} />
            <Route path={"edit/:id"} component={ProductionPlaning} />
          </Route>
          <Route path={"warehouses"}>
            <IndexRoute component={Warehouses} />
            <Route path={"add"} component={ProductionPlaning} />
            <Route path={"edit/:id"} component={ProductionPlaning} />
            <Route path={"products"} component={WarehouseProducts} />
            <Route path={"products/:id"} component={WarehouseProducts} />
          </Route>
          <Route path={"payments"}>
            <IndexRoute
              component={HocPermissionPage(Payments, "PAYMENT_LIST")}
            />
            <Route path="add" component={PagePermit(PaymentAddUpdate, "7")} />
            <Route
              path="edit/:id"
              component={PagePermit(PaymentAddUpdate, "7")}
            />
            <Route
              path="outstanding"
              component={PagePermit(OutstandingPayments, "6")}
            />
          </Route>

          <Route path={"soleprofsalesinvoice"}>
            <IndexRoute component={PagePermit(SoleProfSalesInvoice, "39")} />
            <Route
              path="add"
              component={PagePermit(SoleProfSalesInvoiceAddUpdate, "71")}
            />
            <Route
              path="edit/:id"
              component={PagePermit(SoleProfSalesInvoiceAddUpdate, "71")}
            />
          </Route>

          <Route path="fixedAsset" component={PagePermit(FixedAsset, "19")} />
          <Route path={"fixedAsset"}>
            <IndexRoute component={PagePermit(FixedAsset, "19")} />

            <Route
                path="add"
                component={PagePermit(FixedAssetAddUpdate, "19")}
            />
            <Route
                path="edit/:id"
                component={PagePermit(FixedAssetAddUpdate, "19")}
            />
          </Route>

          <Route path="fixedAssetSalesInvoice" component={PagePermit(FixedAssetSalesInvoice, "19")} />
          <Route path={"fixedAssetSalesInvoice"}>
            <IndexRoute component={PagePermit(FixedAssetSalesInvoice, "19")} />

            <Route
                path="add"
                component={PagePermit(FixedAssetSalesInvoiceAddUpdate, "19")}
            />
            <Route
                path="edit/:id"
                component={PagePermit(FixedAssetSalesInvoiceAddUpdate, "19")}
            />
          </Route>
          <Route
            path="customers"
            component={HocPermissionPage(Customers, "CUSTOMER_LIST")}
          />
          <Route
            path="companyUserRole"
            component={PagePermit(CompanyUserRole, "2")}
          />
          <Route
            path="companyTradeInfo"
            component={PagePermit(CompanyUpdate, "5")}
          />
          <Route
            path="companySettings"
            component={PagePermit(CompanySettings, "5")}
          />
          <Route
              path="IVDScreen"
              component={PagePermit(IVDScreen, "5")}
          />
          <Route
            path="companyOtherInfo"
            component={PagePermit(CompanyOtherInfoUpdate, "4")}
          />
          <Route path="integrations" component={Integrations} />
          <Route path="obifin" component={Obifin} />
          <Route path="obifin/bank-apis" component={ObifinBankApis} />
          <Route path="paynetApplication" component={paynetApplication} />
          <Route path="marketplace" component={Marketplace} />
          <Route path="zreportIntegration" component={zreportIntegration} />
          <Route path="flexodeme" component={FlexOdeme} />
          <Route
            path="product-service"
            component={HocPermissionPage(ProductService, "ITEM_LIST")}
          />
          <Route
              path="assets/:main_id"
              component={HocPermissionPage(Assets, "ITEM_LIST")}
          />
          <Route
            path="assets"
            component={HocPermissionPage(Assets, "ITEM_LIST")}
          />

          <Route
              path="depreciation"
              component={HocPermissionPage(Depreciation, "ITEM_LIST")}
          />

          <Route path="project" component={Project} />
          <Route path="detailReports" component={DetailReports} />
          <Route
            path="reports"
            component={HocPermissionPage(Report, "REPORT_LIST")}
          />
          <Route path="templates" component={PagePermit(Templates, "56")} />
          <Route path="salesInvoice/salesShipment" component={HocPermissionPage(SalesShipmentList, "SALES_SHIPMENT")}  />
          <Route path="salesInvoice/salesShipment/add" component={HocPermissionPage(SalesShipmentAddUpdate, "SALES_SHIPMENT")} />
          <Route path="salesInvoice/salesShipment/edit/:id" component={HocPermissionPage(SalesShipmentAddUpdate, "SALES_SHIPMENT")} />
          <Route path="purchases/purchaseReceipt" component={HocPermissionPage(PurchaseReceiptList, "PURCHASE_SHIPMENT")} />
          <Route path="purchases/purchaseReceipt/add" component={HocPermissionPage(PurchaseReceiptAddUpdate, "PURCHASE_SHIPMENT")} />
          <Route path="purchases/purchaseReceipt/add/:uuid" component={HocPermissionPage(PurchaseReceiptAddUpdate, "PURCHASE_SHIPMENT")} />
          <Route path="purchases/purchaseReceipt/edit/:id" component={HocPermissionPage(PurchaseReceiptAddUpdate, "PURCHASE_SHIPMENT")} />
          <Route path="userInfo" component={UpdateUser} />
          <Route
            path="template/:id"
            component={PagePermit(Template, ["57", "58"])}
          />
          {transactions.map((item, i) => {
            return (
              <Route
                key={i}
                path={"transaction/" + item + "/:id"}
                component={Transactions}
              />
            );
          })}
        </Route>
        <Route path="subscriber" component={SecureAuth(Subscriber)} />
        <Route path="welcome" component={SecureAuth(Welcome)} />
        <Route
          path="subscriberPayment/:id"
          component={SecureAuth(SubscriberPayment)}
        />
        <Route
          path="subscriberPayment/specialOffer/:id"
          component={SecureAuth(SpecialOffer)}
        />
        <Route
          path="payment-status/:token"
          component={SecureAuth(PaymentStatus)}
        />

        <Route path="*" component={NotFound} />
      </Route>
    </Router>
  </Provider>
);

export default Application;
