import React, {Component} from "react";
import Layout from "../../layouts/Dashboard";
import {connect} from "react-redux";
import * as actions from "../../actions";
import SelfEmployment from "../../screens/Employment/SelfEmployment";
import {browserHistory, Link} from "react-router";
import {
    FETCH_ASSET_TYPES,
    FETCH_CITIES,
    FETCH_COUNTIES,
    FETCH_CURRENCIES,
    FETCH_PAYROLL_STATUSES,
    FETCH_PURCHASE_CHART_VALUES,
    FETCH_SALES_CHART_VALUES,
    FETCH_PIE_CHART_VALUES,
    FETCH_STOPPAGE_TYPES,
    FETCH_TABLE_DATA,
    FETCH_TAX_OFFICES,
    FETCH_TAX_TYPES,
    FETCH_DELIVERY_TYPES,
    FETCH_UNITS,
    FETCH_VAT_RATE_TYPES,
    FETCH_VAT_REDUCTION_TYPE_CODES,
    FETCH_VAT_REDUCTION_TYPES, FETCH_TRANSPORT_TYPES, FETCH_PACKAGE_TYPES,
} from "../../actions/types";
import Loading from "react-loading-spinner";
import EkoSpinner from "../../components/elements/EkoSpinner";
import Style from "style-it";
import moment from "moment";
import {CSS} from "../../css/dashboard.js";
import DashboardTable from "../../components/elements/DashboardTable";
import Util from "../../util/Util";
import getSelfEmploymentData from "../../services/SelfEmploymentDataService";
import CompanyTypePermission from "../../services/CompanyTypePermission";
import "../../services/Onesignal";
import {initOneSignal} from "../../services/Onesignal";
import HocPermission from "../../components/common/HocPermission";
import LazyComponent from "../../components/LazyComponent";
import axios from "axios";
import {ROOT_URL} from "../../actions";
import {EkoFormButton} from "../../components/elements/EkoForm";
import EkoModal from "../../components/elements/EkoModal";
import {toastr} from "react-redux-toastr";

const ekoChartPromise = import("../../components/elements/EkoChartDonut");
const ekoChartDonutPromise = import('../../components/elements/EkoChartDoughnut')
const ekoChartBarPromise = import('../../components/elements/EkoChartBar')

const EkoChartDonut = React.lazy(() => ekoChartPromise)
const EkoChartDoughnut = React.lazy(() => ekoChartDonutPromise)
const EkoChartBar = React.lazy(() => ekoChartBarPromise)

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            historyPage: 0,
            histories: [],
            position: 0,
            smmmVideoLink:false,
            smmmVideoModal:false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.pieChartValues &&
            nextProps.purchaseChartValues &&
            nextProps.salesChartValues
        ) {
            const history = this.historyMerge(nextProps);
            const changePage = this.getClosest(history, "days", 0);
            let page = this.state.historyPage;

            if (changePage >= 5 && page === 0) page = Math.floor(changePage / 5);
            this.getModules();
            this.setState({histories: history, historyPage: page});
            var user = JSON.parse(localStorage.getItem("user"));
            if((user.phone || "") ==""){
                window.location = '/dashboard/userInfo';
            }
        }
    }

    getModules(){
        axios.get(ROOT_URL + "/company/CompanyModules", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
        ).then(response => {
            var data = JSON.stringify(response.data, null, 2);
            localStorage.setItem("company_modules", '');
            localStorage.setItem("company_modules", data);
        });
        //
        axios.get(ROOT_URL + "/company/smmmVideo", {headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }}
        ).then(responsex => {
            if(responsex.data.final==='view'){
                this.setState({smmmVideoModal: true, smmmVideoLink: "https://www.youtube.com/embed/EtKrc71QY0Q?autoplay=1&mute=1"});
                setTimeout(() => {
                    this.setState({
                        smmmVideoModal: false
                    });
                }, 41000);
            }
        });
    }

    componentDidMount() {
        localStorage.removeItem("new_customer_id_take_collection");
        localStorage.removeItem("new_collection_amount_fc");
        localStorage.removeItem("selected_list_sales_invoice_id");
        localStorage.removeItem("selected_list_purchases_invoice_id");
        localStorage.removeItem("selected_list_receivable_amount_fc");
        localStorage.removeItem("selected_list_payable_amount_fc");
        localStorage.removeItem("new_collection_from_who_type", 1);
        const self = this;
        this.props.changePageTitle("AnaSayfa")
        if (this.props.location.pathname === "/dashboard") self.fetchChartValues();

        //NOTE @bocek genel kullanılacagi kesin olan seyleri bir kere burada cekelim,
        // her componentde cekmenin anlami ne dimi , zaten localStorage da sakliyoruz
        this.props.checkUser();
        window.fbq("track", "CompleteRegistration", {
            currency: 'TL',
            value: 1
        });
        this.props.fetchViewOptions(false);
        getSelfEmploymentData();


        this.props.fetchCachedData(
            "/data/taxOffices",
            FETCH_TAX_OFFICES,
            "taxOffices",
            "1:day"
        );
        this.props.fetchCachedData("/data/cities", FETCH_CITIES, "cities", "4:day");
        this.props.fetchCachedData(
            "/data/counties",
            FETCH_COUNTIES,
            "counties",
            "4:day"
        );
        this.props.fetchCachedDataAsKey(
            "/data/currencies",
            FETCH_CURRENCIES,
            "currencies",
            "4:day"
        );
        this.props.fetchCachedDataAsKey(
            "/data/units",
            FETCH_UNITS,
            "units",
            "1:day"
        );
        this.props.fetchCachedDataAsKey(
            "/data/taxTypes",
            FETCH_TAX_TYPES,
            "taxTypes",
            "1:day"
        );
        this.props.fetchCachedDataAsKey(
            "/data/deliveryTypes",
            FETCH_DELIVERY_TYPES,
            "deliveryTypes",
            "1:day"
        );
        this.props.fetchCachedDataAsKey(
            "/data/transportTypes",
            FETCH_TRANSPORT_TYPES,
            "transportTypes",
            "1:day"
        );
        this.props.fetchCachedDataAsKey(
            "/data/packageTypes",
            FETCH_PACKAGE_TYPES,
            "packageTypes",
            "1:day"
        );
        this.props.fetchCachedDataAsKey(
            "/data/vatRateTypes",
            FETCH_VAT_RATE_TYPES,
            "vatRateTypes",
            "1:day"
        );
        this.props.fetchCachedDataAsKey(
            "/data/vatReductionTypes",
            FETCH_VAT_REDUCTION_TYPES,
            "vatReductionTypes",
            "1:day"
        );
        this.props.fetchCachedDataAsKey(
            "/data/vatReductionTypeCodes",
            FETCH_VAT_REDUCTION_TYPE_CODES,
            "vatReductionTypeCodes",
            "1:day"
        );
        this.props.fetchCachedDataAsKey(
            "/data/fixedAssetTypes",
            FETCH_ASSET_TYPES,
            "fixedAssetTypes",
            "1:day"
        );
        this.props.fetchCachedDataAsKey(
            "/data/payrollStatuses",
            FETCH_PAYROLL_STATUSES,
            "payrollStatuses",
            "1:day"
        );
        this.props.fetchCachedDataAsKey(
            "/data/stoppageTypes",
            FETCH_STOPPAGE_TYPES,
            "stoppageTypes",
            "1:day"
        );
        this.props.fetchDtConfigs();

        initOneSignal();
        browserHistory.listen((location) => {
            if (this.props.location.pathname.includes("/dashboard"))
                self.props.checkUser();
            if (this.props.location.pathname === "/dashboard")
                self.fetchChartValues();
        });

        const user = JSON.parse(localStorage.getItem("user"));

        if (
            parseInt(user.plan_id, 10) === 0 &&
            this.props.location.pathname.includes("/dashboard") &&
            !this.props.location.query.isRedirected
        ) {
            this.props.router.push("/subscriber");
        }
    }

    setCacheAndRedirect(cacheName, cacheValue) {
        localStorage.setItem(cacheName, cacheValue);
    }

    fetchChartValues() {
        this.props.fetchData("/dashboard/tableData", FETCH_TABLE_DATA);
        this.props.fetchData(
            "/customer/outStandingSalesInvoices",
            FETCH_SALES_CHART_VALUES
        );
        this.props.fetchData(
            "/supplier/outStandingPurchaseInvoices",
            FETCH_PURCHASE_CHART_VALUES
        );
        this.props.fetchData("/dashboard/pie", FETCH_PIE_CHART_VALUES);
    }

    getClosest(arr, key, goal) {
        if (arr.length !== 0) {
            let current = arr.reduce((prev, curr) =>
                Math.abs(curr[key] - goal) < Math.abs(prev[key] - goal) ? curr : prev
            );
            return this.findWithAttr(arr, key, current[key]);
        } else return -1;
    }

    findWithAttr(array, attr, value) {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    historyMerge(nextProps) {
        const purchaseChartValues =
            nextProps.purchaseChartValues &&
            nextProps.purchaseChartValues.outStandingPurchasePerSupplier
                ? nextProps.purchaseChartValues.outStandingPurchasePerSupplier
                : [];
        const salesChartValues =
            nextProps.salesChartValues &&
            nextProps.salesChartValues.outStandingSalesPerCustomer
                ? nextProps.salesChartValues.outStandingSalesPerCustomer
                : [];
        const pieChartValues =
            nextProps.pieChartValues && nextProps.pieChartValues.table1
                ? nextProps.pieChartValues.table1
                : [];

        let result = {};
        let resultArray = [];

        // if (nextProps.purchaseChartValues && nextProps.salesChartValues) {
        Object.keys(salesChartValues).forEach((key) => {
            result[key] = {
                data: {
                    collections: {
                        days: parseInt(key, 10),
                        amount: 0,
                        members: [],
                        type: false,
                    },
                },
            };
            Object.keys(salesChartValues[key]).forEach((key2) => {
                result[key].data.collections.amount +=
                    salesChartValues[key][key2]["sumAmount"] || 0;
                result[key].data.collections.members[key2] =
                    salesChartValues[key][key2];
            });
        });

        Object.keys(purchaseChartValues).forEach((key) => {
            if (typeof result[key] !== "undefined") {
                result[key].data.payments = {};
                result[key].data.payments.amount = 0;
                result[key].data.payments.members = [];
                result[key].data.payments.days = parseInt(key, 10);
                result[key].data.payments.type = true;
            } else
                result[key] = {
                    data: {
                        payments: {
                            days: parseInt(key, 10),
                            members: [],
                            amount: 0,
                            type: true,
                        },
                    },
                };
            Object.keys(purchaseChartValues[key]).forEach((key2) => {
                result[key].data.payments.amount +=
                    purchaseChartValues[key][key2]["sumAmount"] || 0;
                result[key].data.payments.members[key2] =
                    purchaseChartValues[key][key2];
            });
        });

        Object.values(result).map((days, i) => {
            return Object.values(days.data).map((item, i2) => {
                return resultArray.push(item);
            });
        });

        return resultArray.sort((a, b) => {
            if (a.days > b.days) return -1;
            if (a.days < b.days) return 1;
            return 0;
        });
    }

    renderDashboard() {
        let history = this.state.histories;

        let page = this.state.historyPage;
        moment.locale("tr");

        if (CompanyTypePermission.isSelfEmployment()) {
            return <SelfEmployment/>;
        }

        const purchaseChartValues =
            this.props.purchaseChartValues &&
            this.props.purchaseChartValues.chartValues;
        const salesChartValues =
            this.props.salesChartValues && this.props.salesChartValues.chartValues;
        const pieChartValues =
            this.props.pieChartValues &&
            Object.values(this.props.pieChartValues.table1);

        let chart1Data = [];
        let chart1Total = 0;


        salesChartValues &&
        salesChartValues.forEach((s) => {
            // salesChartValuesData.push([s.description,s.amount])
            chart1Data.push({name: s.description, y: s.amount});
            chart1Total += s.amount;
        });

        const chart1Title =
            "<b>Tahsil Edilecekler</b> <br/> " +
            Util.convertDecimalToMoney(chart1Total) +
            " ₺";

        let chart2Data = [];
        let chart2Total = 0;
        // let purchaseChartValuesData = JSON.parse(JSON.stringify(defaultData))
        purchaseChartValues &&
        purchaseChartValues.forEach((s) => {
            // purchaseChartValuesData.push([s.description,s.amount])
            chart2Data.push({name: s.description, y: s.amount});
            chart2Total += s.amount;
        });
        const chart2Title =
            "<b>Ödenecekler</b> <br/> " +
            Util.convertDecimalToMoney(chart2Total) +
            " ₺";

        let chart3Data = [];

        purchaseChartValues &&
        purchaseChartValues.forEach((s) => {
            // purchaseChartValuesData.push([s.description,s.amount])
            chart3Data.push({y: s.amount});
        });

        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let quarter = 0;

        if (month === 1 || month === 2 || month === 3) {
            quarter = 1;
        } else if (month === 4 || month === 5 || month === 6) {
            quarter = 2;
        } else if (month === 7 || month === 8 || month === 9) {
            quarter = 3;
        } else if (month === 10 || month === 11 || month === 12) {
            quarter = 4;
        }

        let market = 0;
        let yakit = 0;
        let ofis = 0;
        let yemek = 0;
        let kira = 0;
        let diger = 0;
        let calisan = 0;
        let konaklama_ulasim = 0;

        let chart4Data = [];
        let chart4Total = 0;

        pieChartValues &&
        pieChartValues.forEach((item) => {
            if (year === item.year && month === item.month) {
                market = parseFloat(item.Market);
                yakit = parseFloat(item.Yakıt);
                ofis = parseFloat(item.Ofis);
                yemek = parseFloat(item.Yemek);
                kira = parseFloat(item.Kira);
                diger = parseFloat(item.Diğer);
                calisan = parseFloat(item.Calisan);
                konaklama_ulasim = parseFloat(item["Seyahat"]);

                chart4Total =
                    parseFloat(item.Market) +
                    parseFloat(item.Yakıt) +
                    parseFloat(item.Ofis) +
                    parseFloat(item.Yemek) +
                    parseFloat(item.Kira) +
                    parseFloat(item["Seyahat"]) +
                    parseFloat(item.Diğer) +
                    parseFloat(item.Calisan);
            }
        });

        chart4Data.push(
            {name: "Market", y: market},
            {name: "Yakıt", y: yakit},
            {name: "Ofis", y: ofis},
            {name: "Yemek", y: yemek},
            {name: "Kira", y: kira},
            {name: "Diğer", y: diger},
            {name: "Çalışan", y: calisan},
            {name: "Seyahat", y: konaklama_ulasim}
        );

        market = 0;
        yakit = 0;
        ofis = 0;
        yemek = 0;
        kira = 0;
        diger = 0;
        calisan = 0;
        konaklama_ulasim = 0;

        let chart5Data = [];
        let chart5Total = 0;

        pieChartValues &&
        pieChartValues.forEach((item) => {
            // (year === item.year && month-1 === item.month) || (year === item.year && month-2 === item.month
            if (year === item.year && quarter === item.quarter) {
                market += parseFloat(item.Market);
                yakit += parseFloat(item.Yakıt);
                ofis += parseFloat(item.Ofis);
                yemek += parseFloat(item.Yemek);
                kira += parseFloat(item.Kira);
                diger += parseFloat(item.Diğer);
                calisan += parseFloat(item.Calisan);
                konaklama_ulasim += parseFloat(item["Seyahat"]);

                chart5Total = parseFloat(
                    market +
                    yakit +
                    ofis +
                    yemek +
                    kira +
                    diger +
                    calisan +
                    konaklama_ulasim
                );
                chart5Total = parseFloat(chart5Total).toFixed(2);
            }
        });

        chart5Data.push(
            {name: "Market", y: market},
            {name: "Yakıt", y: yakit},
            {name: "Ofis", y: ofis},
            {name: "Yemek", y: yemek},
            {name: "Kira", y: kira},
            {name: "Diğer", y: diger},
            {name: "Çalışan", y: calisan},
            {name: "Seyahat", y: konaklama_ulasim}
        );

        market = 0;
        yakit = 0;
        ofis = 0;
        yemek = 0;
        kira = 0;
        diger = 0;
        calisan = 0;
        konaklama_ulasim = 0;

        let chart6Data = [];
        let chart6Total = 0;

        pieChartValues &&
        pieChartValues.forEach((item) => {
            if (year === item.year) {
                market += parseFloat(item.Market);
                yakit += parseFloat(item.Yakıt);
                ofis += parseFloat(item.Ofis);
                yemek += parseFloat(item.Yemek);
                kira += parseFloat(item.Kira);
                diger += parseFloat(item.Diğer);
                calisan += parseFloat(item.Calisan);

                konaklama_ulasim += parseFloat(item["Seyahat"]);
                chart6Total =
                    market +
                    yakit +
                    ofis +
                    yemek +
                    kira +
                    diger +
                    calisan +
                    konaklama_ulasim;
                chart6Total = parseFloat(chart6Total).toFixed(2);
            }
        });

        chart6Data.push(
            {name: "Market", y: market},
            {name: "Yakıt", y: yakit},
            {name: "Ofis", y: ofis},
            {name: "Yemek", y: yemek},
            {name: "Kira", y: kira},
            {name: "Diğer", y: diger},
            {name: "Çalışan", y: calisan},
            {name: "Seyahat", y: konaklama_ulasim}
        );

        const chart4Title = "Toplam: <strong>" + chart4Total + "</strong> ₺";
        const chart5Title = "Toplam: <strong>" + chart5Total + "</strong> ₺";
        const chart6Title = "Toplam: <strong>" + chart6Total + "</strong> ₺";
        const tableData = this.props.tableData;

        if (
            !this.props.pieChartValues ||
            !this.props.purchaseChartValues ||
            !this.props.salesChartValues
        )
            return <EkoSpinner/>;
        const user = JSON.parse(localStorage.getItem("user"));
        return Style.it(
            CSS,
            <div className="page-wrapper-row full-height">
                <div className="page-wrapper-middle">
                    <div className="page-content">
                        <div className="container">
                            <div className="page-content-inner">
                                <div className="profile-content col-md-5">
                                    <div className="row mred">
                                        <div className="col-md-6">
                                            <div className="portlet">
                                                <div className="box row25 rowtext">
                                                    <div className="effect-hover effect1">
                                                        <div className="dtext">
                                                            <i className="glyphicon glyphicon-plus-sign i-color1"/>
                                                            <div className="rowtitle i-color1">
                                                                Satış Ekle
                                                            </div>
                                                            <div className="detay">
                                                                <Link to={ user.company_type == 9 || user.company_type == 6 ?  "/dashboard/salesInvoice/soleprofv2/add"  : "/dashboard/salesInvoice/add/toCustomer" }>
                                  <span className="effect-info">
                                    { user.company_type == 9 || user.company_type == 6 ?  "Serbest Meslek Makbuzu"  : "Müşteriye Satış" }
                                  </span>
                                                                </Link>

                                                                <Link to="/dashboard/salesInvoice/proforma/add">
                                                                    <span className="effect-info"> Teklif</span>
                                                                </Link>
                                                                <Link to="/dashboard/salesInvoice">
                                                                    <span className="effect-info">Diğerleri</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="portlet">
                                                <div className="box row25 rowtext">
                                                    <div className="effect-hover effect2">
                                                        <div className="dtext">
                                                            <i className="glyphicon glyphicon-minus-sign i-color2"/>
                                                            <div className="rowtitle i-color2">Alış Ekle</div>
                                                            <div className="detay">
                                                                <Link
                                                                    to={ user.company_type == 9 || user.company_type == 6 ?  "/dashboard/purchases/purchaseInvoice/add/expenseInvoice"  : "/dashboard/purchases/purchaseInvoice/add/productServiceInvoice" }>
                                  <span className="effect-info">
                                    {" "}
                                      { user.company_type == 9 || user.company_type == 6 ?  "Gider Faturası"  : "Mal/Hizmet Alımı" }
                                  </span>
                                                                </Link>
                                                                <Link to="/dashboard/purchases/receipt/add">
                                                                    <span className="effect-info">Fiş</span>
                                                                </Link>
                                                                <Link to="/dashboard/purchases/purchaseInvoice">
                                                                    <span className="effect-info">Diğerleri</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="portlet">
                                                <div className="box row25 rowtext">
                                                    <div className="effect-hover effect3">
                                                        <div className="dtext">
                                                            <i
                                                                style={{fontSize: "2.5em"}}
                                                                className="fas fa-coins i-color3"
                                                            />
                                                            {/*<i className="i-color3">*/}
                                                            {/*<img width={50} height={50} src="/app/images/coins.png" alt="sadasdad"/>*/}
                                                            {/*</i>*/}
                                                            <div className="rowtitle i-color3">
                                                                Tahsilat Ekle
                                                            </div>
                                                            <div className="detay">
                                                                <Link
                                                                    to="/dashboard/collections/add"
                                                                    onClick={this.setCacheAndRedirect.bind(
                                                                        this,
                                                                        "new_collection_from_who_type",
                                                                        1
                                                                    )}
                                                                >
                                  <span className="effect-info">
                                    Müşteriden Tahsilat
                                  </span>
                                                                </Link>
                                                                <Link
                                                                    to="/dashboard/collections/add"
                                                                    onClick={this.setCacheAndRedirect.bind(
                                                                        this,
                                                                        "new_collection_from_who_type",
                                                                        3
                                                                    )}
                                                                >
                                  <span className="effect-info">
                                    Müşteriden Sipariş Avansı
                                  </span>
                                                                </Link>
                                                                <Link to="/dashboard/collections">
                                                                    <span className="effect-info">Diğerleri</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="portlet">
                                                <div className="box row25 rowtext">
                                                    <div className="effect-hover effect2">
                                                        <div className="dtext">
                                                            <i className="icon-credit-card i-color2"/>
                                                            <div className="rowtitle i-color2">
                                                                Ödeme Ekle
                                                            </div>
                                                            <div className="detay">
                                                                <Link
                                                                    to="/dashboard/payments/add"
                                                                    onClick={this.setCacheAndRedirect.bind(
                                                                        this,
                                                                        "new_payment_from_who_type",
                                                                        1
                                                                    )}
                                                                >
                                  <span className="effect-info">
                                    Tedarikçiye Ödeme
                                  </span>
                                                                </Link>

                                                                <Link
                                                                    to="/dashboard/payments/add"
                                                                    onClick={this.setCacheAndRedirect.bind(
                                                                        this,
                                                                        "new_payment_from_who_type",
                                                                        5
                                                                    )}
                                                                >
                                  <span className="effect-info">
                                    Maaş Ödemesi
                                  </span>
                                                                </Link>
                                                                <Link to="/dashboard/payments">
                                                                    <span className="effect-info">Diğerleri</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <HocPermission pkey="DASHBOARD">
                                    <div
                                        className="profile-sidebar col-md-4"
                                        style={{marginRight: "13px"}}
                                    >
                                        <div className="col-md-6">
                                            <LazyComponent>
                                                <EkoChartDonut
                                                    data={chart1Data}
                                                    title={chart1Title}
                                                    formatter="amount"
                                                />
                                            </LazyComponent>
                                        </div>
                                        <div className="col-md-6">
                                            <LazyComponent>
                                                <EkoChartDonut
                                                    data={chart2Data}
                                                    title={chart2Title}
                                                    formatter="amount"
                                                />
                                            </LazyComponent>
                                        </div>
                                    </div>
                                </HocPermission>
                                {user.role_id==1?<div style={{
                                    paddingLeft:"870px",
                                    paddingBottom:"10px"
                                }}>
                                    <EkoFormButton
                                        isLoading={this.state.saving}
                                        label="İş Zekası Raporları"
                                        faClass="fa fa-list"
                                        onClick={this.modal.bind(this, true)}
                                        style={{width: "235px"}}
                                    />
                                </div>:""}
                                <ul
                                    className="MyFeed col-md-3"
                                    style={{
                                        overflow: "hidden",
                                        padding: 0,
                                        width: 260,
                                        listStyle: "none",
                                        margin: 0,
                                    }}
                                >
                                    <div
                                        className="feedContainer"
                                        style={{width: 240, height: 600}}
                                    >
                                        <HocPermission pkey="DASHBOARD">
                                            <div
                                                className="feedPane"
                                                style={{padding: "20px 0px 0px", top: 0, width: 240}}
                                            >
                                                {page > 0 ? (
                                                    <li
                                                        onClick={() => {
                                                            this.setState({historyPage: page - 1});
                                                        }}
                                                        className="MyFeed-item MyFeed-itemFirst"
                                                    >
                                                        <i className="fa fa-arrow-circle-up MyFeed-icon"/>
                                                        <section className="MyFeed-item-content">
                              <span className="classH" style={{fontSize: 11}}>
                                {page}. SAYFAYI GÖSTER
                              </span>
                                                        </section>
                                                        <div className="MyFeed-line"/>
                                                    </li>
                                                ) : (
                                                    ""
                                                )}
                                                {this.props.purchaseChartValues &&
                                                this.props.salesChartValues ? (
                                                    history.length === 0 ? (
                                                        <li
                                                            className={
                                                                "MyFeed-item MyFeed-itemWithAnchor u-hasHiddenContent MyFeed-itemToday"
                                                            }
                                                        >
                                                            <a>
                                                                {" "}
                                                                <i className={"fa MyFeed-icon fa-circle-o"}/>
                                                                <section className="MyFeed-item-content">
                                                                    <div className="swapWithContent">
                                    <span
                                        style={{fontSize: 13}}
                                        className="classH u-swapWithHiddenContent"
                                    >
                                      Bugün {moment().format("D MMMM")}
                                    </span>
                                                                    </div>
                                                                </section>
                                                                <div className="MyFeed-line"/>
                                                            </a>
                                                        </li>
                                                    ) : (
                                                        history
                                                            .slice(page * 5, (page + 1) * 5)
                                                            .map((item, i2) => {
                                                                const members = item.members;
                                                                let message = null;
                                                                let liClass = null;
                                                                let height = {
                                                                    height:
                                                                        Object.keys(members).length * 40 + "px",
                                                                };
                                                                let amount = item.amount
                                                                    .toFixed(2)
                                                                    .toString()
                                                                    .split(".");
                                                                if (item.days === 0) {
                                                                    message = "Bugün";
                                                                    liClass = "MyFeed-itemToday";
                                                                } else if (item.days > 0) {
                                                                    message = item.days + " Gün sonra";
                                                                    liClass = "MyFeed-itemFuture";
                                                                } else {
                                                                    message = item.days + " Gün gecikti";
                                                                    liClass = "MyFeed-itemOverdue";
                                                                }
                                                                return (
                                                                    <li
                                                                        key={i2}
                                                                        className={
                                                                            "MyFeed-item MyFeed-itemWithAnchor u-hasHiddenContent " +
                                                                            liClass
                                                                        }
                                                                    >
                                                                        <a>
                                                                            {" "}
                                                                            <i
                                                                                className={
                                                                                    item.days < 0
                                                                                        ? "fa MyFeed-icon fa-exclamation-circle"
                                                                                        : "fa MyFeed-icon fa-circle-o"
                                                                                }
                                                                            />
                                                                            <section className="MyFeed-item-content">
                                                                                <div className="swapWithContent">
                                          <span
                                              style={{fontSize: 13}}
                                              className="classH u-swapWithHiddenContent"
                                          >
                                            {message}
                                          </span>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 13,
                                                                                            marginLeft: "15px",
                                                                                            marginTop: "-15px",
                                                                                        }}
                                                                                        className="classH u-hiddenContent"
                                                                                    >
                                            {moment()
                                                .add(item.days, "days")
                                                .format("D MMMM")}
                                          </span>
                                                                                </div>
                                                                                <div
                                                                                    className="u-swapWithHiddenContent">
                                                                                    {item.type ? "Borç" : "Alacak"}
                                                                                    <b style={{fontWeight: 700}}>
                                            <span className="classPre">
                                              &nbsp;
                                                {amount[0].replace(
                                                    /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                    "$1."
                                                )}
                                                <small>
                                                ,
                                                    {typeof amount[1] ===
                                                    "undefined"
                                                        ? "00"
                                                        : amount[1]}
                                                    <i className="fa fa-try"/>
                                              </small>
                                            </span>
                                                                                    </b>
                                                                                </div>
                                                                                <div
                                                                                    className="u-hiddenContent u-truncatedText"
                                                                                    style={height}
                                                                                >
                                                                                    <ul className="MyFeed-inside-ul">
                                                                                        {Object.keys(members).map(
                                                                                            (key, i) => {
                                                                                                let sumAmount = members[
                                                                                                    key
                                                                                                    ].sumAmount
                                                                                                    .toFixed(2)
                                                                                                    .toString()
                                                                                                    .replace(/[#.]/g, ",")
                                                                                                    .replace(
                                                                                                        /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                                                                        "$1."
                                                                                                    );
                                                                                                let url =
                                                                                                    "/dashboard/transaction/" +
                                                                                                    members[key].type +
                                                                                                    "/" +
                                                                                                    key;
                                                                                                return (
                                                                                                    <li
                                                                                                        key={i + members[key].type}
                                                                                                    >
                                                    <span
                                                        style={{
                                                            cursor: "pointer",
                                                            padding: "5px",
                                                            textDecoration:
                                                                "inherit",
                                                            display: "inline-block",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                        }}
                                                        onClick={() =>
                                                            browserHistory.push(url)
                                                        }
                                                        title={
                                                            sumAmount +
                                                            " TL " +
                                                            members[key].name
                                                        }
                                                    >
                                                      {sumAmount}
                                                        <i className="fa fa-try"/>{" "}
                                                        {members[key].name}
                                                    </span>
                                                                                                    </li>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            </section>
                                                                            <div className="MyFeed-line"/>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })
                                                    )
                                                ) : (
                                                    <EkoSpinner/>
                                                )}
                                                {history.length > (page + 1) * 5 ? (
                                                    <li
                                                        onClick={() => {
                                                            this.setState({historyPage: page + 1});
                                                        }}
                                                        className="MyFeed-item MyFeed-itemLast"
                                                    >
                                                        <i className="fa fa-arrow-circle-down MyFeed-icon"/>
                                                        <section className="MyFeed-item-content">
                              <span
                                  className="classH"
                                  style={{
                                      fontSize: 11,
                                      color: "#696462",
                                  }}
                              >
                                {page + 2}. SAYFAYI GÖSTER
                              </span>
                                                        </section>
                                                        <div className="MyFeed-line"/>
                                                    </li>
                                                ) : (
                                                    ""
                                                )}
                                                {this.props.purchaseChartValues &&
                                                this.props.salesChartValues &&
                                                history.length === 0 ? (
                                                    <li
                                                        style={{cursor: "auto"}}
                                                        className="MyFeed-item MyFeed-itemLast"
                                                    >
                                                        <i className="fa fa-arrow-circle-down MyFeed-icon"/>
                                                        <section className="MyFeed-item-content">
                              <span className="classH" style={{fontSize: 11}}>
                                GÖSTERİLECEK KAYIT YOK
                              </span>
                                                        </section>
                                                        <div className="MyFeed-line"/>
                                                    </li>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </HocPermission>

                                    </div>
                                </ul>

                                <HocPermission pkey="DASHBOARD">

                                    <div className="row">
                                        <div style={{marginTop: "-145px"}} className="col-md-5">
                                            <DashboardTable
                                                headers={["", "Ay", "Çeyrek", "Yıl", "Toplam"]}
                                                contents={tableData ? tableData.table1 : null}
                                            />
                                        </div>
                                        <div style={{marginTop: "-145px"}} className="col-md-4">
                                            <DashboardTable
                                                headers={["VERGİ & SGK", "GÜNCEL"]}
                                                reduce
                                                contents={tableData ? tableData.table2 : null}
                                            />
                                        </div>
                                    </div>
                                </HocPermission>

                                <HocPermission pkey="DASHBOARD">
                                    <div className="row">
                                        <div style={{marginTop: "20px"}} className="col-md-9">
                                            <LazyComponent>
                                                <EkoChartBar data={chart3Data}/>
                                            </LazyComponent>
                                        </div>
                                    </div>
                                </HocPermission>

                                <HocPermission pkey="DASHBOARD">
                                    <div
                                        className="row text-center"
                                        style={{marginTop: "40px"}}
                                    >
                                        <div
                                            style={{
                                                lineHeight: "3",
                                                marginLeft: "5px",
                                                width: "849px",
                                                marginBottom: "0px",
                                                fontWeight: "bold",
                                                fontSize: "16px",
                                                fontFamily: "Open Sans",
                                                backgroundColor: "#fff",
                                            }}
                                        >
                                            Masraflar
                                        </div>
                                        <div style={{display: "flex", paddingLeft: "5px"}}>
                                            <div style={{width: "283px", backgroundColor: "white"}}>
                                                Aylık
                                            </div>
                                            <div style={{width: "283px", backgroundColor: "white"}}>
                                                Çeyreklik
                                            </div>
                                            <div style={{width: "283px", backgroundColor: "white"}}>
                                                Yıllık
                                            </div>
                                        </div>
                                    </div>
                                </HocPermission>

                                <HocPermission pkey="DASHBOARD">
                                    <div className="row text-center">
                                        <div style={{display: "flex", paddingLeft: "5px"}}>
                                            <LazyComponent>
                                                <EkoChartDoughnut
                                                    data={chart4Data}
                                                    title={chart4Title}
                                                    formatter="amount"
                                                />
                                            </LazyComponent>
                                            <LazyComponent>
                                                <EkoChartDoughnut
                                                    data={chart5Data}
                                                    title={chart5Title}
                                                    formatter="amount"
                                                />
                                            </LazyComponent>
                                            <LazyComponent>
                                                <EkoChartDoughnut
                                                    data={chart6Data}
                                                    title={chart6Title}
                                                    formatter="amount"
                                                />
                                            </LazyComponent>
                                        </div>
                                    </div>
                                </HocPermission>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    modal(value) {
        this.setState({ modal: value });
    }
    render() {
        var apiUrl = process.env.REACT_APP_API_URL;
        apiUrl = apiUrl.substring(0, apiUrl.length-3);
        const time = new Date();
        return (
            <Layout>
                <Loading
                    isLoading={this.props.pageLoading}
                    loadingClassName="loading-spinner"
                    spinner={EkoSpinner}
                >
                    {this.props.children ? this.props.children : this.renderDashboard()}
                    <EkoModal
                        showModal={this.state.modal}
                        title="İş Zekası Raporları"
                        bsSize="xlg" // xlg
                        onHide={() => {
                            this.setState({
                                modal: false
                            });
                            //this.onCancel();
                        }}
                        spinner={this.state.saving}
                    >
                        <iframe
                            style={{overflow: 'visible'}}
                            ref="iframe"
                            src={apiUrl + "rapor/dashboard/index.php?time=" + time.getTime() + "&t=" + localStorage.getItem('token')}
                            scrolling="auto"
                            frameBorder="0"
                            width="100%"
                            height={800}
                        />
                    </EkoModal>
                </Loading>
                <EkoModal
                    showModal={this.state.smmmVideoModal}
                    title="Ekohesap Tanıtım Videosu"
                    bsSize="xlg" // xlg
                    onHide={() => {
                        toastr.success("Lütfen Tanıtım Videosunun Sesini Açıp İzleyiniz");
                    }}
                >
                    {this.state.smmmVideoLink?<iframe
                        style={{overflow: 'visible'}}
                        ref="iframe"
                        src={this.state.smmmVideoLink}
                        scrolling="auto"
                        frameBorder="0"
                        width="100%"
                        height={800}
                    />:""}
                </EkoModal>

            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        pageLoading: state.page.pageLoading,
        pageTitle: state.page.title, //NOTE @bocek sayfada pageTitle lazim olur diye koydum
        pieChartValues: state.data.pieChartValues,
        salesChartValues: state.data.salesChartValues,
        purchaseChartValues: state.data.purchaseChartValues,
        tableData: state.data.tableData,
        //simdilik birseye yaramiyor
    };
}

export default connect(mapStateToProps, actions)(Dashboard);
